/* eslint-disable no-shadow */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-useless-catch */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { images } from '@assets';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import ReactDOMServer from 'react-dom/server';
import { Piechart } from '../Promotion/Survey/Piechart';
import { PrmBarChart } from './PrmBarChart';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import {
  getProfiles,
  getPromoCharts,
  getPromoList,
  getPromoExcel,
  getPromoTypes,
} from '../../common/crud';
import { DataLabPieChart } from './DataLabPieChart';
import PdfDownloader from '../../common/PdfDownloader';
import PdfTitleModal from './PdfTitleModal';

export default React.memo(function PrmStatistics(props) {
  const allData = {
    label: '전체',
    value: null,
  };

  const labels = [
    {
      label: '슬라이드 배너',
      color: 'first',
      category: 'SLIDE',
      rgb: '#24427a',
    },
    { label: '바 배너', color: 'second', category: 'BAR', rgb: '#f99b2e' },
    { label: '박스 배너', color: 'third', category: 'BOX', rgb: '#5890c7' },
    { label: '통 배너', color: 'fourth', category: 'FULL', rgb: '#624aef' },
  ];
  const barlables = [
    { label: 'CPV', color: 'first', rgb: 'rgb(238, 83, 124)' },
    { label: 'CPC', color: 'second', rgb: 'rgb(235, 146, 123)' },
  ];

  const defaultBarOption = {
    categoryPercentage: 1.0,
    barPercentage: 0.5,
  };
  const defaultPieDataLabels = {
    labels: {
      name: {
        align: 'top',
        padding: 3,
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          if (value !== 0) {
            const total = ctx.dataset.data.reduce((sum, item) => sum + item, 0);
            return Utils.calculatePercentage(value, total, 1);
          }
          return '';
        },
      },
      value: {
        align: 'center',
        font: { size: 10, weight: '400' },
        formatter(value, ctx) {
          if (value !== 0) {
            return `${Utils.changeNumberComma(value)}건`;
          }
          return '';
        },
        padding: 4,
      },
    },
  };

  const defaultBarDataLabels = {
    labels: {
      name: {
        align: 'top',
        padding: 3,
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          if (value !== 0) {
            return `${Utils.changeNumberComma(value)}건`;
          }
          return '';
        },
      },
    },
  };

  const size = 10;

  const history = useHistory();

  const page = (history.location.state && history.location.state.page) || 1;
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const typeId =
    (history.location.state && history.location.state.typeId) || null;
  const startDate =
    (history.location.state && history.location.state.startDate) ||
    new Date().setDate(new Date().getDate() - 30);
  const endDate =
    (history.location.state && history.location.state.endDate) || new Date();
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [barChartData, setBarChartData] = useState();
  const [pieChartData, setPieChartData] = useState();
  const [totalCpv, setTotalCpv] = useState(0);
  const [totalCpc, setTotalCpc] = useState(0);

  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [searchProfile, setSearchProfile] = useState({});
  const [searchPrmType, setSearchPrmType] = useState({});
  const [profileList, setProfileList] = useState([]);
  const [promoTypeList, setPromoTypeList] = useState([]); // 홍보 유형 리스트

  const [totalPage, setTotalPage] = useState();
  const [totalCnt, setTotalCnt] = useState(0);
  const [promoList, setPromoList] = useState([]);
  // const [pdfTableList, setPdfTableList] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoTypeList = async () => {
    try {
      const { data } = await getPromoTypes();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPromoTypeList(tempList);
        if (typeId) {
          setSearchPrmType(tempList.find(v => v.value === typeId));
        } else {
          setSearchPrmType(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromos = async () => {
    try {
      const params = {
        page,
        size,
        profileId,
        promoTypeId: typeId,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data } = await getPromoList(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setTotalPage(data.data.totalPages);
          setTotalCnt(data.data.totalCnt);
          setPromoList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const getPromosForPdf = async reqSize => {
    const params = {
      page: 1,
      size: reqSize,
      profileId,
      promoTypeId: typeId,
      startDate: startDate
        ? format(startDate, 'yyyy-MM-dd').toString()
        : startDate,
      endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
    };
    const { data } = await getPromoList(params);
    if (data.code === 200) {
      return data.data.list;
    }
    throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
  };

  const getChartData = async () => {
    try {
      const params = {
        profileId,
        promoTypeId: typeId,
        startDate: format(startDate, 'yyyy-MM-dd').toString(),
        endDate: format(endDate, 'yyyy-MM-dd').toString(),
      };
      const { data } = await getPromoCharts(params);
      if (data.code === 200) {
        setTotalCpv(data.data.totalCpv);
        setTotalCpc(data.data.totalCpc);
        const tempBarChartObj = {};
        const tempPipChartObj = {};
        const chartLabels = [];

        data.data.data.forEach(item => {
          if (!tempBarChartObj[item.legend]) {
            tempBarChartObj[item.legend] = [];
          }

          if (!tempPipChartObj[item.category]) {
            tempPipChartObj[item.category] = [];
          }

          tempBarChartObj[item.legend].push(item);
          tempPipChartObj[item.category].push(item);

          if (!chartLabels.find(v => v.category === item.category)) {
            chartLabels.push(labels.find(lb => lb.category === item.category));
          }
        });
        const orderBy = (a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        };
        chartLabels.sort((a, b) => {
          return orderBy(a.category, b.category);
        });

        const tempBarChartData = {
          labels: chartLabels.map(v => {
            return v.label;
          }),
          datasets: [],
        };
        Object.keys(tempBarChartObj).forEach(key => {
          const tempBarChartDataSet = {
            ...defaultBarOption,
            data: tempBarChartObj[key]
              .sort((a, b) => {
                return orderBy(a.category, b.category);
              })
              .map((v, i) => {
                return v.value;
              }),
            datalabels: defaultBarDataLabels,
            backgroundColor: barlables.find(barlable => barlable.label === key)
              .rgb,
          };
          tempBarChartData.datasets = [
            ...tempBarChartData.datasets,
            tempBarChartDataSet,
          ];
        });

        const tempPieCharData = {
          labels: chartLabels.map(v => {
            return v.label;
          }),
          datasets: [
            {
              data: Object.keys(tempPipChartObj)
                .sort()
                .map(key =>
                  tempPipChartObj[key].reduce(
                    (sum, item) => sum + item.value,
                    0,
                  ),
                ),
              datalabels: defaultPieDataLabels,
              backgroundColor: labels
                .sort((a, b) => {
                  return orderBy(a.category, b.category);
                })
                .map(label => {
                  return label.rgb;
                }),
              hoverOffset: 4,
            },
          ],
        };

        setBarChartData(tempBarChartData);
        setPieChartData(tempPieCharData);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const setSearchState = () => {
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSearchPrmType(promoTypeList.find(v => v.value === typeId) || allData);
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      pageRefresh: !pageRefresh,
      profileId: isSearch ? searchProfile.value : profileId,
      typeId: isSearch ? searchPrmType.value : typeId,
      startDate: isSearch ? searchStartDate : startDate,
      endDate: isSearch ? searchEndDate : endDate,
    };

    props.history.push({
      pathname: RouterPath.prmstatistics,
      state,
    });
  };

  useEffect(() => {
    getPromoTypeList();
    getProfileList();
  }, []);

  useEffect(() => {
    setSearchState();
    getChartData();
    getPromos();
  }, [pageRefresh]);

  const splitArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  // pdf
  const pdfRef = useRef();
  const [pdfModalShow, setPdfModalShow] = useState(false);

  const createSearchPdfElement = ({ pdfTitle = '' }) => {
    const prmTypeObj = promoTypeList.find(v => v.value === typeId) || allData;
    const profileObj = profileList.find(v => v.value === profileId) || allData;
    return (
      <Form className="search-area">
        <h1>{pdfTitle}</h1>
        <Form.Group className="form-group">
          <Form.Label>사업단위</Form.Label>
          <Form.Label>{profileObj.label}</Form.Label>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>홍보타입</Form.Label>
          <Form.Label>{prmTypeObj.label}</Form.Label>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>조회일</Form.Label>
          <div className="datepicker-wrap mw-280">
            <Moment date={startDate} format="YYYY.MM.DD" interval={0} />
            <span>-</span>
            <Moment date={endDate} format="YYYY.MM.DD" interval={0} />
          </div>
        </Form.Group>
      </Form>
    );
  };

  const createElementWithContent = (tag, content) => {
    const element = document.createElement(tag);
    element.innerHTML = content;
    return element;
  };

  const createElementWithClass = (tag, className) => {
    const element = document.createElement(tag);
    element.className = className;
    return element;
  };

  const renderToString = (element, props = {}) =>
    ReactDOMServer.renderToString(React.createElement(element, props));

  // 주요 함수의 시작
  const createPdfImages = async pdfTitle => {
    setIsLoading(true);
    const tempPdfImages = [];
    const pdfTableList = await getPromosForPdf(9999999);

    let i = 0; // priceList의 시작 인덱스
    let chunkSize = 30; // 초기 청크 크기

    const originalStyle = pdfRef.current.style.cssText;
    pdfRef.current.style.cssText += 'max-width: 1920px;';
    await new Promise(resolve => setTimeout(resolve, 100));

    const chartCanvas = await html2canvas(pdfRef.current);

    pdfRef.current.style.cssText = originalStyle;

    const chartImageUrl = chartCanvas.toDataURL('image/png');
    const chartImg = document.createElement('img');
    chartImg.src = chartImageUrl;

    while (i < pdfTableList.length) {
      const pdfObj = pdfTableList.slice(i, i + chunkSize); // priceList에서 청크 크기만큼 잘라냄

      const firstDiv = document.createElement('div');
      firstDiv.style.position = 'fixed';
      firstDiv.style.top = '-10000px';
      firstDiv.style.maxWidth = '1920px';
      firstDiv.id = 'datalab-prmsta';

      // 검색 조건
      const searchDiv = createElementWithContent(
        'div',
        renderToString(createSearchPdfElement, { pdfTitle }),
      );

      const tableHeadDiv = createElementWithContent(
        'div',
        renderToString(tableHeaderComponent),
      );

      const tableArticle = createElementWithClass(
        'article',
        'mt-4 scrollbar prmtable',
      );

      const contentDiv = createElementWithContent(
        'div',
        renderToString(tableComponent, { list: pdfObj }),
      );

      if (i === 0) {
        firstDiv.appendChild(searchDiv);
        firstDiv.appendChild(chartImg);
        tableArticle.appendChild(tableHeadDiv);
      }

      tableArticle.appendChild(contentDiv);
      firstDiv.appendChild(tableArticle);

      document.body.appendChild(firstDiv);

      const pageWidth = 190;
      const pageHeight = 237;

      const widthRatioEstimate = pageWidth / (firstDiv.offsetWidth * 2);
      const customHeightEstimate =
        firstDiv.offsetHeight * 2 * widthRatioEstimate;

      if (pageHeight >= customHeightEstimate) {
        const addPdfCanvas = await html2canvas(firstDiv);

        const widthRatioReal = pageWidth / addPdfCanvas.width;
        const customHeightReal = addPdfCanvas.height * widthRatioReal;

        if (pageHeight >= customHeightReal) {
          const addPdfImage = addPdfCanvas.toDataURL('image/png');

          const addPdfObj = {
            canvas: addPdfCanvas,
            image: addPdfImage,
          };

          // A4 크기에 맞으면 tempPdfImages에 추가하고 다음 청크로 이동
          tempPdfImages.push(addPdfObj);
          i += chunkSize;
          chunkSize = 30;
        } else if (chunkSize > 1) {
          // A4 크기보다 크면 청크 크기를 하나 줄임
          chunkSize--;
        } else {
          throw new Error('Element size exceeds A4 page size.');
        }
      } else if (chunkSize > 1) {
        // A4 크기보다 크면 청크 크기를 하나 줄임
        chunkSize--;
      } else {
        throw new Error('Element size exceeds A4 page size.');
      }

      document.body.removeChild(firstDiv);
    }

    if (pdfTableList.length === 0) {
      // 데이터가 없을 때의 처리
      const tempDiv = document.createElement('div');

      // 검색 조건
      const searchDiv = createElementWithContent(
        'div',
        renderToString(createSearchPdfElement, { pdfTitle }),
      );

      // 테이블
      const firstDiv = document.createElement('div');

      const tableHeadDiv = createElementWithContent(
        'div',
        renderToString(tableHeaderComponent),
      );

      firstDiv.appendChild(searchDiv);
      firstDiv.appendChild(chartImg);

      const tableArticle = createElementWithClass(
        'article',
        'mt-4 scrollbar prmtable',
      );

      tableArticle.appendChild(tableHeadDiv);
      tableArticle.appendChild(tempDiv);

      firstDiv.appendChild(tableArticle);

      firstDiv.style.position = 'fixed'; // Add this line
      firstDiv.style.top = '-10000px'; // Add this line
      firstDiv.style.maxWidth = '1920px';
      firstDiv.id = 'datalab-prmsta';

      document.body.appendChild(firstDiv);

      const addPdfCanvas = await html2canvas(firstDiv);
      const addPdfImage = addPdfCanvas.toDataURL('image/png');

      const addPdfObj = {
        canvas: addPdfCanvas,
        image: addPdfImage,
      };
      // A4 크기에 맞으면 tempPdfImages에 추가하고 다음 청크로 이동
      tempPdfImages.push(addPdfObj);
    }

    setIsLoading(false);
    return tempPdfImages;
  };

  const downloadExel = async () => {
    setIsLoading(true);
    try {
      const params = {
        profileId,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data, headers } = await getPromoExcel(params);

      const contentDisposition = headers['content-disposition'];
      let fileName = 'datalab_promotions_by_area.xlsx';
      try {
        if (contentDisposition) {
          fileName = contentDisposition.split(';')[1].split('=')[1].trim();
        }
      } catch (error) {
        //
      }

      const blob = new Blob([data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      throw error;
    }
  };

  const tableComponent = ({ list = [] }) => {
    return (
      <Table className="table-hover text-start mt-3">
        <colgroup>
          <col width={80} />
          {!profileId && <col width={120} />}
          <col width={120} />
          <col width={140} />
          <col width={120} />
          <col width={120} />
          <col width={140} />
          <col width={140} />
        </colgroup>
        <thead>
          <tr>
            <th className="td-center">No</th>
            {!profileId && <th>사업단위</th>}
            <th>홍보영역</th>
            <th>홍보 타이틀</th>
            <th>과금 적용</th>
            <th>노출 CPV</th>
            <th>노출 CPC</th>
            <th>홍보 조회기간</th>
          </tr>
        </thead>

        <tbody>
          {list.length > 0 ? (
            list.map((v, i) => {
              return (
                <tr
                  key={`promo_${i}`}
                  onClick={() =>
                    props.history.push({
                      pathname: `${RouterPath.prmConfirmDetail}/${v.promoId}`,
                    })
                  }
                >
                  <td className="td-center">{v.promoId}</td>
                  {!profileId && <td>{v.profileNm}</td>}
                  <td>{v.promoArea}</td>
                  <td>{v.title}</td>
                  <td>
                    {[
                      { yn: v.cpcYn, label: 'CPC' },
                      { yn: v.cpvYn, label: 'CPV' },
                      { yn: v.cpaYn, label: 'CPA' },
                    ]
                      .filter(cp => cp.yn === 'Y')
                      .map(cp => cp.label)
                      .toString() || '미적용'}
                  </td>
                  <td>{v.cpvCnt}</td>
                  <td>{v.cpcCnt}</td>
                  <td>
                    {' '}
                    {v.startDate ? (
                      <Moment
                        date={v.startDate}
                        format="YYYY.MM.DD"
                        interval={0}
                      />
                    ) : (
                      '-'
                    )}
                    <span className="pd-lr"> - </span>
                    {v.endDate ? (
                      <Moment
                        date={v.endDate}
                        format="YYYY.MM.DD"
                        interval={0}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="no-data" colSpan={!profileId ? 8 : 7}>
                홍보 영역별 노출 현황이 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  const tableHeaderComponent = () => {
    return (
      <div className="flex-title">
        <h5>홍보 영역별 노출 현황</h5>
        <div className="prm-flex">
          <p>
            총 광고수{' '}
            <span className="prm-total-cnt-color">
              {Utils.changeNumberComma(totalCnt)}
            </span>
          </p>
          <p>
            총 노출 CPV{' '}
            <span className="prm-title-color">
              {Utils.changeNumberComma(totalCpv)}
            </span>
          </p>
          <p>
            총 노출 CPC{' '}
            <span className="prm-title-color">
              {Utils.changeNumberComma(totalCpc)}
            </span>
          </p>
        </div>
      </div>
    );
  };

  return (
    <main id="datalab-prmsta">
      {isLoading && <Loading />}
      <Header title="홍보노출수 통계" />
      <Container className="contents container-128">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보타입</Form.Label>
              <CustomSelect
                options={promoTypeList}
                value={searchPrmType}
                onChange={setSearchPrmType}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>조회일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  maxDate={new Date()}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
          </Form>
          <div className="btn-center">
            <Button
              onClick={() => {
                movePage(1, true);
              }}
            >
              검색
            </Button>
          </div>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              downloadExel()
                .catch(() => {
                  CustomSwal.fire({
                    text: 'XLSX 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i
              style={{ backgroundImage: `url(${images.icExcel})` }}
              className="xlsx me-2"
            />
            XLSX
          </Button>
          <Button
            onClick={() => {
              setPdfModalShow(true);
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>

        <div className="grid-section" ref={pdfRef}>
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>홍보 영역별 노출수 집계 현황</h5>
                <div className="title-labels">
                  {barlables.map((option, i) => (
                    <div className="oplabel-flex" key={`barLable_${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              {barChartData && (
                <div className="bar-height">
                  <PrmBarChart chartData={barChartData} isDatalabels />
                </div>
              )}
            </div>
          </article>
          <article className="mt-4">
            <div className="pie-section Responsive-pie">
              <div className="flex-title">
                <h5>홍보 영역별 노출수 비율</h5>
                <div className="title-labels">
                  {labels.map((option, i) => (
                    <div className="oplabel-flex" key={`label_${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              {pieChartData && (
                <div className="pie-height">
                  <DataLabPieChart
                    style={{ flex: 1, width: '100%' }}
                    chartData={pieChartData}
                  />
                </div>
              )}
            </div>
          </article>
        </div>

        <article className="mt-4 scrollbar prmtable">
          {tableHeaderComponent()}
          {tableComponent({ list: promoList })}
          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
      {pdfModalShow && (
        <PdfTitleModal
          show={pdfModalShow}
          handleClose={() => {
            setPdfModalShow(false);
          }}
          onDownload={pdfTitle => {
            createPdfImages(pdfTitle)
              .then(e => {
                return PdfDownloader(e);
              })
              .catch(e => {
                console.log('e :: ', e);
                CustomSwal.fire({
                  text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                  confirmButtonText: '확인',
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
    </main>
  );
});

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const adminTableList = [
  {
    tno: '1',
    prm: '슬라이드 배너',
    title: '홍보 타이틀이 나타납니다.',
    cpv: '100,000',
    cpc: '25,000',
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    tno: '2',
    prm: '바 배너',
    title: '홍보 타이틀이 나타납니다.',
    cpv: '100,000',
    cpc: '25,000',
    startDate: new Date(),
    endDate: new Date(),
  },
  {
    tno: '3',
    prm: '통 배너',
    title: '홍보 타이틀이 나타납니다.',
    cpv: '100,000',
    cpc: '25,000',
    startDate: new Date(),
    endDate: new Date(),
  },
];
