/* eslint-disable react/function-component-definition */
import React from 'react';
import { Marker, useNavermaps } from 'react-naver-maps';

const UnitMarker = ({ data, content, onClick }) => {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(11, 35),
        anchor: new navermaps.Point(40, 58),
      }}
      onClick={() => onClick(data)}
    />
  );
};

export default UnitMarker;
