import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouterPath } from '@common';

import Errors from '@pages/Errors';

// 소상공인 홍보 신규등록
import SmBusiness from '@pages/SmBusiness/Register';

import SignIn from '@pages/Auth/SignIn';
import FindId from '@pages/Auth/FindId';
import FindPwd from '@pages/Auth/FindPwd';

// 레이아웃
import Layout from './Layout';
import LayoutAuth from './LayoutAuth';

function SmbusinessRouters({ firstMenuUrl }) {
  return (
    <Switch>
      <Route
        exact
        path={RouterPath.slash}
        render={() => <Redirect to={firstMenuUrl} />}
      />
      {/*  소상공인 신규등록 */}
      <LayoutAuth
        exact
        path={RouterPath.smbusiness}
        component={SmBusiness}
        type="smbusiness"
      />

      {/* Auth */}
      <Layout exact path={RouterPath.signin} component={SignIn} />
      <Layout exact path={RouterPath.findid} component={FindId} />
      <Layout exact path={RouterPath.findpwd} component={FindPwd} />

      <LayoutAuth component={Errors} />
    </Switch>
  );
}

export default SmbusinessRouters;
