import React from 'react';
import ReactPaginate from 'react-paginate';

function CustomPagination({ pageCount, pageNum, setPage, ...rest }) {
  return (
    <ReactPaginate
      pageCount={pageCount || pageNum || 1}
      onPageChange={e => {
        setPage(e.selected + 1);
      }}
      forcePage={pageNum ? pageNum - 1 : 0}
      previousLabel=""
      nextLabel=""
      breakLabel="..."
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item"
      previousLinkClassName="page-link prev"
      nextClassName="page-item"
      nextLinkClassName="page-link next"
      breakClassName="page-item"
      breakLinkClassName="page-link-break"
      activeClassName="active"
      {...rest}
    />
  );
}

export default CustomPagination;
