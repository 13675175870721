/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-const */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Container,
  Button,
  Ratio,
  CloseButton,
  Badge,
  ListGroup,
} from 'react-bootstrap';
import { Header, LineChart, BarChart } from '@components';
import { Utils, Options } from '@common';
import classNames from 'classnames';

// map
import ReactDOMServer from 'react-dom/server';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';

// swiper
import 'swiper/css';
import 'swiper/css/thumbs';
import Moment from 'react-moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getProfiles,
  getPromoStatistics,
  getPromotionsListAll,
  getPromotionsListByProfile,
} from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import CustomSwal from '../../../components/CustomSwal';
import RouterPath from '../../../common/RouterPath';
import RESULT_CODE from '../../../common/ResultCode';

const chartdataDefult = {
  borderColor: '#305399',
  borderWidth: 1,
  pointBackgroundColor: '#305399',
  pointBorderColor: '#fff',
  pointBorderWidth: 2,
  pointHoverBorderColor: '#305399',
  pointHoverBorderWidth: 1,
  yAxisID: 'y',
};

export default React.memo(function Dashboard(props) {
  const navermaps = useNavermaps();
  const naverMap = useRef();

  const [moveCenter, setMoveCenter] = useState();
  const [defaultCenter, setDefaultCenter] = useState();

  const [profileList, setProfileList] = useState([]);
  const [selectProfile, setSelectProfile] = useState();

  const [cssActive, setCssActive] = useState('true');

  const [profileNm, setProfileNm] = useState();
  const [promoTitle, setPromoTitle] = useState();
  const [mapType, setMapType] = useState();

  const scrollContainerRef = useRef();
  const [promoAllList, setPromoAllList] = useState([]);
  const [promoList, setPromoList] = useState([]);

  const [promoDetail, setPromoDetail] = useState();
  const [barChartData, setBarChartData] = useState();
  const [lineChartData, setLineChartData] = useState();
  const [prefixText, setPrefixText] = useState('방문객');
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // 선택한 디테일 이미지

  const [page, setPage] = useState(1);
  const [isLast, setIsLast] = useState(false);

  const handleToggleCss = () => {
    setCssActive(!cssActive);
  };

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        setProfileList(data.data);
        setDefaultCenter(
          new navermaps.LatLng(data.data[0].latitude, data.data[0].longitude),
        );
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoListAll = async () => {
    try {
      const { data } = await getPromotionsListAll(page);
      if (data.code === 200) {
        if (page === 1) {
          setPromoList(data.data.list);
        } else {
          setPromoList([...promoList, ...data.data.list]);
        }
        setIsLast(data.data.isLast);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoListByProfileId = async () => {
    try {
      const params = {
        profileId: selectProfile.profileId,
        page,
        size: 10,
      };
      const { data } = await getPromotionsListByProfile(params);
      if (data.code === 200) {
        if (page === 1) {
          setPromoList([...data.data.list]);
        } else {
          setPromoList([...promoList, ...data.data.list]);
        }
        setIsLast(data.data.isLast);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAllListByProfileId = async profileId => {
    try {
      const params = {
        profileId,
        page: 1,
        promoState: 'ONAIR',
        size: 100000,
      };
      const { data } = await getPromotionsListByProfile(params);
      if (data.code === 200) {
        setPromoAllList(data.data.list);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromotionDetail = async promoId => {
    try {
      const { data } = await getPromoStatistics(promoId);
      if (data.code === 200) {
        const tempPrefixText =
          data.data.detail.codeCate === 'FESTIVAL' ? '방문객' : '홍보노출';

        setPrefixText(tempPrefixText);
        setPromoDetail(data.data);
        settingBarChartData(data.data.weekList);
        settingLineChartData(data.data.hourList, tempPrefixText);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      colsePromoDetail();
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const settingLineChartData = (hourList, prefix) => {
    let tmpsLabels = [];
    let tempDatasetData = [];
    hourList
      .sort((a, b) => {
        const dateA = new Date(a.dateTime);
        const dateB = new Date(b.dateTime);
        return dateA - dateB;
      })
      .forEach((v, i) => {
        const tempDate = new Date(v.dateTime);
        tmpsLabels = [...tmpsLabels, tempDate.getHours()];
        tempDatasetData = [...tempDatasetData, v.callCnt];
      });

    const tempChartdata = {
      labels: tmpsLabels,
      datasets: [
        {
          data: tempDatasetData,
          prefix,
          ...chartdataDefult,
        },
      ],
    };
    setLineChartData(tempChartdata);
  };

  const settingBarChartData = weekList => {
    let tmpsLabels = [];
    let tempDatasetData = [];
    weekList
      .sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA - dateB;
      })
      .forEach((v, i) => {
        tmpsLabels = [...tmpsLabels, Utils.getKoreanDayOfWeek(v.date)];
        tempDatasetData = [...tempDatasetData, v.callCnt];
      });

    const tempChartdata = {
      labels: tmpsLabels,
      datasets: [
        {
          data: tempDatasetData,
          backgroundColor: 'rgba(170, 206, 243, 0.5)',
        },
      ],
    };
    setBarChartData(tempChartdata);
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Errors);
        }
      },
    });
  };

  const colsePromoDetail = () => {
    setPromoDetail();
    setBarChartData();
    setPrefixText('방문객');
    setLineChartData();
  };

  const moveMap = (latitude, longitude) => {
    if (latitude && longitude) {
      const center = new navermaps.LatLng(latitude, longitude);
      setMoveCenter(center);
      naverMap.current.setZoom(13, true);
    }
  };

  const onClickPromo = promoId => {
    setThumbsSwiper();
    getPromotionDetail(promoId);
  };

  const onClickAllForMapHearder = () => {
    setMapType('PROFILE');
    colsePromoDetail();
    setSelectProfile();
    setPage(1);
    setPromoTitle();
    setProfileNm();
  };

  useEffect(() => {
    getProfileList();
  }, []);

  useEffect(() => {
    if (naverMap && moveCenter) {
      naverMap.current.panTo(moveCenter);
      naverMap.current.refresh();
    }
  }, [moveCenter]);

  useEffect(() => {
    if (promoDetail) {
      setMapType('PROMO');
      setProfileNm(promoDetail.detail.profileNm);
      setPromoTitle(promoDetail.detail.title);
      getPromoAllListByProfileId(promoDetail.detail.profileId);
    } else {
      if (!selectProfile) {
        setProfileNm();
        setMapType('PROFILE');
      } else {
        setMapType('PROMO');
      }
      setPromoTitle();
    }
  }, [promoDetail]);

  useEffect(() => {
    colsePromoDetail();
    setPromoList([]);
  }, [selectProfile]);

  useEffect(() => {
    if (selectProfile) {
      setProfileNm(selectProfile.profileNm);
      getPromoListByProfileId();
      setMapType('PROMO');
      getPromoAllListByProfileId(selectProfile.profileId);
    } else {
      getPromoListAll();
    }
  }, [selectProfile, page]);

  return (
    <main id="prm-situation">
      <Header title="홍보 상황판" />
      <Container fluid>
        {/* 지도영역 */}
        {defaultCenter && (
          <MapDiv className="map-box" fallback={null}>
            <NaverMap
              ref={naverMap}
              defaultCenter={defaultCenter}
              defaultZoom={10}
            >
              {(profileNm || promoTitle) && (
                <MapHeader
                  profileNm={profileNm}
                  promoTitle={promoTitle}
                  onClickAll={onClickAllForMapHearder}
                />
              )}
              {mapType === 'PROMO' &&
                promoAllList
                  .filter(f => f.longitude && f.latitude)
                  .map((v, i) => {
                    const content = ReactDOMServer.renderToString(
                      promoDetail &&
                        promoDetail.detail.promoId === v.promoId ? (
                        <Button variant="custom-map-unit-select">
                          {v.title}
                        </Button>
                      ) : (
                        <Button variant="custom-map-unit">{v.title}</Button>
                      ),
                    );
                    const mkPosition = new navermaps.LatLng(
                      v.latitude,
                      v.longitude,
                    );
                    return (
                      <Marker
                        key={`marker-${i}`}
                        position={mkPosition}
                        icon={{
                          content,
                          origin: new navermaps.Point(0, 0),
                          anchor: new navermaps.Point(12, 37),
                        }}
                        onClick={() => {
                          onClickPromo(v.promoId);
                          moveMap(v.latitude, v.longitude);
                        }}
                      />
                    );
                  })}
              {mapType === 'PROFILE' &&
                profileList.map((v, i) => {
                  const content = ReactDOMServer.renderToString(
                    <Button size="sm" variant="map-count">
                      {v.profileNm}
                    </Button>,
                  );
                  const mkPosition = new navermaps.LatLng(
                    v.latitude,
                    v.longitude,
                  );
                  return (
                    <Marker
                      key={`marker-${i}`}
                      position={mkPosition}
                      icon={{
                        content,
                        origin: new navermaps.Point(0, 0),
                        anchor: new navermaps.Point(12, 37),
                      }}
                      onClick={() => {
                        setPage(1);
                        setSelectProfile(v);
                      }}
                    />
                  );
                })}
            </NaverMap>
          </MapDiv>
        )}

        {/* 좌측 */}
        <div className="map-aside" id="map-aside">
          {selectProfile && (
            <section className="py-3">
              <h5 className="mb-0">{selectProfile.profileNm}</h5>
            </section>
          )}
          {promoList.length > 0 ? (
            <InfiniteScroll
              scrollableTarget="map-aside"
              dataLength={promoList && promoList.length}
              hasMore={!isLast}
              ref={scrollContainerRef}
              next={() => {
                setPage(prev => prev + 1);
              }}
            >
              <ListGroup variant="flush">
                {promoList.map((v, i) => {
                  return (
                    <section
                      key={`promo-${i}`}
                      onClick={() => {
                        onClickPromo(v.promoId);
                      }}
                    >
                      <div className="pr-box">
                        <div
                          className="pr-img"
                          style={{
                            backgroundImage: `url(${Utils.fileViewUrl(
                              v.imgPath,
                            )})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        />
                        <h6 className="mb-0 pr-title">
                          {v.title}
                          <span className="pr-span">{v.promoCate}</span>
                        </h6>
                        <p
                          className="ellipsisbody"
                          style={{
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {v.description}
                        </p>
                      </div>
                    </section>
                  );
                })}
              </ListGroup>
            </InfiniteScroll>
          ) : (
            <h5 className="no-list">등록된 홍보리스트가 없습니다.</h5>
          )}
        </div>
        {promoDetail && (
          <div className="map-aside">
            <div className="prm-request-detail">
              <CloseButton
                type="button"
                className="btn-close"
                aria-label="Close"
                variant="white"
                onClick={() => colsePromoDetail()}
              />
              <section>
                <Ratio aspectRatio="35x19">
                  <div
                    className="img-box"
                    style={{
                      backgroundColor: 'var(--bs-gray-100)',
                      backgroundImage: `url(${Utils.fileViewUrl(
                        thumbsSwiper
                          ? thumbsSwiper.filePath
                          : promoDetail.detail.images[0].filePath || null,
                      )})`,
                    }}
                  />
                </Ratio>
                <Swiper
                  spaceBetween={4}
                  slidesPerView={4}
                  className="swiper-box pt-1"
                >
                  {promoDetail.detail.images.length > 0 &&
                    promoDetail.detail.images.map((file, idx) => {
                      return (
                        <SwiperSlide
                          key={`thumb-${idx}`}
                          onClick={e => {
                            setThumbsSwiper(file);
                          }}
                        >
                          <Ratio aspectRatio="35x19">
                            <div
                              className="img-box"
                              style={{
                                backgroundImage: `url(${Utils.fileViewUrl(
                                  file.filePath,
                                )})`,
                              }}
                            />
                          </Ratio>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </section>
              <section className="info border-top-0 pt-0">
                <h5>{promoDetail.detail.title}</h5>
                <div className="title">
                  <div className="flex-between">
                    <h6>INFO</h6>
                    <Badge>{promoDetail.detail.promoCate}</Badge>
                  </div>
                </div>
                <div className="dl-box align-start">
                  <dl>
                    <dt>
                      <i className="material-icons">place</i>
                    </dt>
                    <dd>{promoDetail.detail.address}</dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">call</i>
                    </dt>
                    <dd>{promoDetail.detail.tel}</dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">calendar_month</i>
                    </dt>
                    <dd>
                      {promoDetail.detail.openDate &&
                        promoDetail.detail.closeDate && (
                          <>
                            <Moment
                              date={promoDetail.detail.openDate}
                              format="YYYY-MM-DD"
                            />
                            <span> ~ </span>
                            <Moment
                              date={promoDetail.detail.closeDate}
                              format="YYYY-MM-DD"
                            />
                          </>
                        )}
                      {promoDetail.detail.openDate &&
                        !promoDetail.detail.closeDate && (
                          <>
                            <Moment
                              date={promoDetail.detail.openDate}
                              format="YYYY-MM-DD"
                            />{' '}
                            <span> ~ </span>
                          </>
                        )}
                      {!promoDetail.detail.openDate &&
                        promoDetail.detail.closeDate && (
                          <>
                            <span> ~ </span>
                            <Moment
                              date={promoDetail.detail.closeDate}
                              format="YYYY-MM-DD"
                            />
                          </>
                        )}
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">home</i>
                    </dt>
                    <dd>{promoDetail.detail.url}</dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">description</i>
                    </dt>
                    <dd
                      className={cssActive ? 'ellipsisbody' : null}
                      style={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {promoDetail.detail.description}
                    </dd>
                    {cssActive && (
                      <i
                        className="material-icons align-self-end"
                        style={{ cursor: 'pointer' }}
                        onClick={handleToggleCss}
                      >
                        expand_more
                      </i>
                    )}
                    {!cssActive && (
                      <i
                        className="material-icons align-self-end"
                        style={{ cursor: 'pointer' }}
                        onClick={handleToggleCss}
                      >
                        expand_less
                      </i>
                    )}
                  </dl>
                </div>
              </section>
              <section className="mt-3 border-0">
                <h6 className="mb-0">{`최근 7일 기준 ${prefixText} 수`}</h6>
                <div className="chart-area">
                  <BarChart chartData={barChartData} />
                </div>
              </section>
              <section className="border-0 pt-0">
                <h6 className="mb-0">{`최근 24시간 기준 ${prefixText} 수`}</h6>
                <div className="chart-area">
                  {lineChartData && <LineChart data={lineChartData} />}
                </div>
              </section>
            </div>
          </div>
        )}
      </Container>
    </main>
  );
});

function MapHeader({ onClickAll, profileNm, promoTitle }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1000,
        padding: 5,
        minWidth: 500,
        maxWidth: 700,
      }}
    >
      <ul className="custom-map-breadcrumb">
        <li
          onClick={() => {
            onClickAll();
          }}
        >
          전체
        </li>
        <li>{profileNm}</li>
        {promoTitle && <li>{promoTitle}</li>}
      </ul>
    </div>
  );
}
