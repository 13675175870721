/** @jsxImportSource @emotion/react */
import React from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import { css } from '@emotion/react';

function CustomModal({ title, noCloseBtn, upper, children, ...rest }) {
  return (
    <Modal centered {...rest} backdropClassName={upper ? 'upper' : null}>
      <Modal.Header closeButton={!noCloseBtn}>
        <Modal.Title>{title || '안내'}</Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  );
}
export default CustomModal;
