/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/** @jsxImportSource @emotion/react */
import React, { useState, forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays, subDays, setHours, setMinutes } from 'date-fns';
import { images } from '@assets';
import { css } from '@emotion/react';
import { Form } from 'react-bootstrap';
import ko from 'date-fns/locale/ko';

registerLocale('ko', ko);

// 참고 https://reactdatepicker.com/
const CustomInput = forwardRef(
  ({ value, placeholder, onClick, onChange }, ref) => (
    <Form.Control
      css={customInput}
      className="form-control"
      onClick={onClick}
      placeholder={placeholder}
      ref={ref}
      value={value}
      onChange={onChange}
    />
  ),
);
function CustomTimePicker({ value, onChange, className, ...rest }) {
  return (
    <DatePicker
      selected={value}
      onChange={date => onChange(date)}
      locale="ko"
      customInput={<CustomInput />}
      timeClassName={e => {
        if (
          rest.minTime.getHours() > e.getHours() ||
          rest.maxTime.getHours() < e.getHours()
        ) {
          return 'time-list-item--removed';
        }
      }}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={60}
      dateFormat="a hh:mm"
      timeFormat="a hh:mm"
      calendarClassName="custom-calendar"
      wrapperClassName="timepicker"
      {...rest}
    />
  );
}

const customInput = css`
  min-width: 170px;
  text-align: left;
  background-image: url(${images.icDropdown});
  background-position: right 0.65rem center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
`;

export default CustomTimePicker;
