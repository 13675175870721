/* eslint-disable import/no-named-as-default */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Redux } from '@common';
import { ScrollTop } from '@components';
import { NavermapsProvider } from 'react-naver-maps';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';

import './index.scss';

// eslint-disable-next-line prefer-const
const { store, persistor } = Redux;
// console.log(store, persistor);

window.onload = () => {
  const lang = window.navigator.language;
  // console.log('[window.onload] language :: ', lang);
  render();
};

const naverKey = process.env.REACT_APP_NAVER_KEY;
const queryClient = new QueryClient();

function render() {
  ReactDOM.render(
    // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<div>로딩...</div>} persistor={persistor}>
        {/* <NavermapsProvider
            ncpClientId={naverKey}
            submodules={['visualization']}
          > */}
        <BrowserRouter>
          <ScrollTop />
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
        {/* </NavermapsProvider> */}
      </PersistGate>
    </Provider>,
    // </React.StrictMode>,
    document.getElementById('root'),
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
