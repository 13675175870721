/* eslint-disable jsx-a11y/media-has-caption */
/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from 'react';
import { Image, Button } from 'react-bootstrap';
import { images } from '@assets';
import { css } from '@emotion/react';
import classNames from 'classnames';

function DragDropFile({ onChange, ratio, recSize, ...rest }) {
  const fileInput = useRef(null);
  const [attachment, setAttachment] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const handleOndragOver = e => {
    // console.log('onDragOver');
    e.preventDefault();
  };

  const handleOndrop = e => {
    // console.log('onDrop');
    e.preventDefault();
    e.stopPropagation();
    const newFile = e.dataTransfer.files[0];
    handleFile(newFile);
  };

  const handleFile = newFile => {
    // console.log('handleFile', newFile);
    const file = newFile;
    const preview = URL.createObjectURL(newFile);
    setAttachment(file);
    setPreviewUrl(preview);
    onChange({ file, preview });
  };

  const resetFile = e => {
    // console.log('resetFile');
    setAttachment(null);
    setPreviewUrl('');
    onChange({});
    e.stopPropagation();
  };

  return (
    <div css={dragDropBox} {...rest}>
      <Button
        as="div"
        className={classNames('drop-zone', {
          [`ratio ratio-${ratio}`]: ratio,
        })}
        style={{
          backgroundImage:
            previewUrl && attachment.type.includes('image')
              ? `url(${previewUrl})`
              : 'none',
        }}
        onDragOver={e => handleOndragOver(e)}
        onDrop={e => handleOndrop(e)}
        onClick={() => fileInput.current.click()}
      >
        {/* 파일 없을 때 */}
        {!attachment && (
          <div className="default-box">
            <i className="material-icons">cloud_upload</i>
            <p>권장 사이즈 {recSize}</p>
            <Button size="lg" variant="outline-primary" className="mt-2">
              이미지 업로드
            </Button>
          </div>
        )}

        {/* 비디오 파일일 경우 */}
        {attachment && attachment.type.includes('video') && (
          <video controls>
            <source src={previewUrl} type="video/mp4" />
          </video>
        )}

        {/* 이미지 파일일 경우 */}
        {attachment &&
          !attachment.type.includes('image') &&
          !attachment.type.includes('video') && <p>{attachment.name}</p>}
        <input
          type="file"
          // accept="image/*"
          ref={fileInput}
          hidden
          onChange={e => handleFile(e.target.files[0])}
        />

        {/* 파일 있을 경우 */}
        {attachment && (
          <div className="btn-box">
            <Button size="lg" variant="outline-primary">
              이미지 바꾸기
            </Button>
            <Button
              variant="link"
              className="btn-delete"
              onClick={e => resetFile(e)}
            >
              제거
            </Button>
          </div>
        )}
      </Button>
    </div>
  );
}
const dragDropBox = css`
  .drop-zone {
    width: 100%;
    height: 350px;
    background: var(--bs-white);
    border: 2px dashed var(--bs-border);
    border-radius: 0.25rem;
    background-position: center;
    background-size: auto 100%;
    background-repeat: no-repeat;
    padding: 0;
    position: relative;
    .default-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      i {
        font-size: 60px;
        color: var(--bs-blue-bg-dark);
      }
      p {
        font-size: var(--fs-12);
        font-weight: 400;
        color: var(--bs-gray);
        margin-top: 0.5rem;
      }
    }
    &.ratio {
      height: inherit;
    }
    .btn-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 0.25rem;
      opacity: 0;
      visibility: hidden;
      transition: 0.1s ease-in-out;
      .btn-delete {
        margin-top: 1rem;
        background: var(--bs-ts) !important;
        color: var(--bs-white) !important;
        text-decoration: underline;
      }
    }
    video {
      max-width: 100%;
      max-height: 100%;
    }
  }
  @media (hover: hover) {
    &:hover {
      .btn-box {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export default DragDropFile;
