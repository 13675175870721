import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouterPath } from '@common';

// 유동 상황판
import Dashboard from '@pages/Dashboard';
import FullDashboard from '@pages/Dashboard/FullDashboard'; // 전체화면 모드

// 홍보
import PrmRequestList from '@pages/Promotion/Request/List';
import PrmRequestDetail from '@pages/Promotion/Request/Detail';
import PrmRequestRegister from '@pages/Promotion/Request/Register';
import PrmApprovalList from '@pages/Promotion/Approval/List';
import PrmApprovalDetail from '@pages/Promotion/Approval/Detail';
import PrmSchedule from '@pages/Promotion/Schedule';
import PrmConfirmList from '@pages/Promotion/Confirm/List';
import PrmConfirmDetail from '@pages/Promotion/Confirm/Detail';
import PrmDashoboard from '@pages/Promotion/Dashboard';
import PrmCoupon from '@pages/Promotion/Coupon/List';
import PrmCouponDetail from '@pages/Promotion/Coupon/Detail';
import PrmSurvey from '@pages/Promotion/Survey/List';
import PrmSurveyRegister from '@pages/Promotion/Survey/Register';
import PrmResult from '@pages/Promotion/Survey/Result';
// 데이터랩
import TopData from '@pages/DataLab/TopData';
import DataToCost from '@pages/DataLab/DataToCost';
import Population from '@pages/DataLab/Population';
import PrmStatistics from '@pages/DataLab/PrmStatistics';
import EsgPrm from '@pages/DataLab/EsgPrm';
import EsgSchedule from '@pages/DataLab/EsgSchedule';
import Monitoring from '@pages/DataLab/Monitoring';
// 시스템 관리
import Admin from '@pages/System/Admin';
import Auth from '@pages/System/Auth';
import Menu from '@pages/System/Menu';
import AdmAccesLog from '@pages/System/AdmAccesLog';
import AdmActionLog from '@pages/System/AdmActionLog';
import Code from '@pages/System/Code';
import MemberAccount from '@pages/System/MemberAccount';
import Apdevice from '@pages/System/Apdevice';
import Billing from '@pages/System/Billing';
// AP 모니터
import APMonitor from '@pages/APMonitor';

import Errors from '@pages/Errors';

// 소상공인 홍보 신규등록
import SmBusiness from '@pages/SmBusiness/Register';

import SignIn from '@pages/Auth/SignIn';
import FindId from '@pages/Auth/FindId';
import FindPwd from '@pages/Auth/FindPwd';

// 레이아웃
import { useSelector } from 'react-redux';
import Layout from './Layout';
import LayoutAuth from './LayoutAuth';

function AdminRouters({ firstMenuUrl }) {
  return (
    <Switch>
      <Route
        exact
        path={RouterPath.slash}
        render={() => <Redirect to={firstMenuUrl} />}
      />
      <LayoutAuth exact path={RouterPath.slash} component={Dashboard} />
      <LayoutAuth exact path={RouterPath.dashboard} component={Dashboard} />

      {/* 홍보 - 홍보 요청 리스트 */}
      <LayoutAuth
        exact
        path={RouterPath.prmRequestList}
        component={PrmRequestList}
      />
      <LayoutAuth
        exact
        path={`${RouterPath.prmRequestDetail}/:requestId`}
        component={PrmRequestDetail}
      />
      <LayoutAuth
        exact
        path={RouterPath.prmRequestRegister}
        component={PrmRequestRegister}
      />

      {/* 홍보 - 홍보 승인 관리 */}
      <LayoutAuth
        exact
        path={RouterPath.prmApprovalList}
        component={PrmApprovalList}
      />
      <LayoutAuth
        exact
        path={`${RouterPath.prmApprovalDetail}/:requestId`}
        component={PrmApprovalDetail}
      />

      {/* 홍보 - 홍보 스케쥴 조회 */}
      <LayoutAuth exact path={RouterPath.prmSchedule} component={PrmSchedule} />

      {/* 홍보 - 홍보 리스트 */}
      <LayoutAuth
        exact
        path={RouterPath.prmConfirmList}
        component={PrmConfirmList}
      />
      <LayoutAuth
        exact
        path={`${RouterPath.prmConfirmDetail}/:promoId`}
        component={PrmConfirmDetail}
      />

      {/* 홍보 - 홍보 상황판 */}
      <LayoutAuth
        exact
        path={RouterPath.prmDashboard}
        component={PrmDashoboard}
      />
      {/* 홍보 - 쿠폰 관리 */}
      <LayoutAuth exact path={RouterPath.prmCoupon} component={PrmCoupon} />
      {/* 홍보 - 쿠폰 관리 디테일 */}
      <LayoutAuth
        exact
        path={`${RouterPath.prmCouponDetail}/:promoId`}
        component={PrmCouponDetail}
      />
      {/* 홍보 - 설문조사 관리 */}
      <LayoutAuth exact path={RouterPath.prmSurvey} component={PrmSurvey} />
      {/* 홍보 - 설문조사 신규등록 */}
      <LayoutAuth
        exact
        path={`${RouterPath.prmSurveyRegister}/:promoId`}
        component={PrmSurveyRegister}
      />
      {/* 홍보 - 설문조사 결과 */}
      <LayoutAuth
        exact
        path={`${RouterPath.prmResult}/:surveyId`}
        component={PrmResult}
      />

      {/* 데이터랩 */}
      {/* 데이터랩 - 상위지역 데이터 사용량 */}
      <LayoutAuth exact path={RouterPath.topdata} component={TopData} />
      {/* 데이터랩 - 경영지수 : 데이터 비용 환산 */}
      <LayoutAuth exact path={RouterPath.datatocost} component={DataToCost} />
      {/* 데이터랩 - 유동인구 분석 */}
      <LayoutAuth exact path={RouterPath.population} component={Population} />
      {/* 데이터랩 - 홍보 노출수 통계  */}
      <LayoutAuth
        exact
        path={RouterPath.prmstatistics}
        component={PrmStatistics}
      />
      {/* 데이터랩 - 홍보 통계  */}
      <LayoutAuth exact path={RouterPath.esgprm} component={EsgPrm} />
      {/* 데이터랩 - ESG 스케줄 통계  */}
      <LayoutAuth exact path={RouterPath.esgschedule} component={EsgSchedule} />
      {/* 데이터랩 - 과금 모니터링  */}
      <LayoutAuth exact path={RouterPath.monitoring} component={Monitoring} />

      {/* 시스템 관리 */}
      {/* 시스템 - 관리자 계정관리 */}
      <LayoutAuth exact path={RouterPath.admin} component={Admin} />
      {/* 시스템 - 권한 관리 */}
      <LayoutAuth exact path={RouterPath.auth} component={Auth} />
      {/* 시스템 - 메뉴 관리 */}
      <LayoutAuth exact path={RouterPath.memu} component={Menu} />
      {/* 시스템 - 관리자 접속로그 */}
      <LayoutAuth exact path={RouterPath.admacceslog} component={AdmAccesLog} />
      {/* 시스템 - 관리자 액션 로그 */}
      <LayoutAuth
        exact
        path={RouterPath.admactionlog}
        component={AdmActionLog}
      />
      {/* 시스템 - 코드관리 */}
      <LayoutAuth exact path={RouterPath.code} component={Code} />
      {/* 시스템 - 소상공인 계정관리 */}
      <LayoutAuth
        exact
        path={RouterPath.memberaccount}
        component={MemberAccount}
      />
      {/* 시스템 - AP 기기 관리 */}
      <LayoutAuth exact path={RouterPath.apdevice} component={Apdevice} />
      {/* 시스템 - 과금 관리 */}
      <LayoutAuth exact path={RouterPath.billing} component={Billing} />

      {/*  소상공인 신규등록 */}
      {/* <LayoutAuth
        exact
        path={RouterPath.smbusiness}
        component={SmBusiness}
        type="smbusiness"
      /> */}
      {/* AP 상황판 */}
      <LayoutAuth exact path={RouterPath.APMonitor} component={APMonitor} />

      {/* 유동 상황판 전체 화면 모드 */}
      <LayoutAuth
        exact
        path={RouterPath.fullmode}
        component={FullDashboard}
        type="FullDashboard"
      />

      {/* Auth */}
      <Layout exact path={RouterPath.signin} component={SignIn} />
      <Layout exact path={RouterPath.findid} component={FindId} />
      <Layout exact path={RouterPath.findpwd} component={FindPwd} />

      <LayoutAuth component={Errors} />
    </Switch>
  );
}

export default AdminRouters;
