/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Button,
  Ratio,
  Badge,
  InputGroup,
  Table,
  Modal,
  ToggleButtonGroup,
  ToggleButton,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { RouterPath, Utils, Options } from '@common';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomTable,
  CustomPagination,
} from '@components';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';
import { useHistory } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import {
  getApListByProfile,
  getProfileDetailById,
  getProfiles,
} from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import CustomSwal from '../../../components/CustomSwal';
import RESULT_CODE from '../../../common/ResultCode';

export default React.memo(function BusinessModal({
  businessShow,
  handleClose,
  businessOption,
  setBusinessOptions,
}) {
  const navermaps = useNavermaps();
  const naverMap = useRef();
  const history = useHistory();

  const [profileList, setProfileList] = useState([]);
  const [profileDetail, setProfileDetail] = useState({});
  const [apList, setApList] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(
    businessOption || null,
  );
  const [selectUnit, setSelectUnit] = useState();
  const [moveCenter, setMoveCenter] = useState();

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempList = data.data.map(v => {
          const tempProfile = {
            value: v.profileId,
            label: v.profileNm,
          };
          return tempProfile;
        });
        setProfileList(tempList);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getProfileDetail = async profileId => {
    try {
      const { data } = await getProfileDetailById(profileId);
      if (data.code === 200) {
        setProfileDetail(data.data);
        getApListByProfileData(profileId);
        const center = new navermaps.LatLng(
          data.data.latitude,
          data.data.longitude,
        );
        setMoveCenter(center);
        naverMap.current.refresh();
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getApListByProfileData = async profileId => {
    try {
      const { data } = await getApListByProfile(profileId);
      if (data.code === 200) {
        setApList(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  useEffect(() => {
    getProfileList();
  }, []);

  useEffect(() => {
    if (naverMap.current && moveCenter && selectedProfile) {
      naverMap.current.panTo(moveCenter);
      naverMap.current.refresh();
    }
  }, [moveCenter]);

  useEffect(() => {
    if (selectedProfile && selectedProfile.value) {
      getProfileDetail(selectedProfile.value);
    }
  }, [selectedProfile]);

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Error);
        }
      },
    });
  };

  const apColumns = useMemo(
    () => [
      {
        Header: '설치주소',
        accessor: 'apAddress',
        width: '',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 위도',
        accessor: 'latitude',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 경도',
        accessor: 'longitude',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 이름',
        accessor: 'apName',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: '원격 게이트웨이',
        accessor: 'apIp',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'MAC',
        accessor: 'apMacAddress',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
    ],
    [],
  );

  // 행정동 사업 정보
  const locBsnsInfo = useMemo(
    () => ({
      location: '영양군',
      latitude: '32.123.1234',
      longitude: '123.1231231',
      addr: '영양군 영양읍 바들양지1길 33-32',
      apList: [
        {
          addr: '영양군 영양읍 바들양지1길 33-32',
          latitude: '32.123.1234',
          longitude: '123.1231231',
          apName: 'XP-123-영양-1-1',
          gateway: '123.123.12.12',
          mac: '1C:EC:73:89:D0:70',
        },
        {
          addr: '영양군 영양읍 시장5길 4 영양전통시장',
          latitude: '32.123.1234',
          longitude: '123.1231231',
          apName: 'XP-123-영양-1-1',
          gateway: '123.123.12.12',
          mac: '1C:EC:73:89:D0:70',
        },
        {
          addr: '영양군 영양읍 시장5길 4 영양전통시장',
          latitude: '32.123.1234',
          longitude: '123.1231231',
          apName: 'XP-123-영양-1-1',
          gateway: '123.123.12.12',
          mac: '1C:EC:73:89:D0:70',
        },
        {
          addr: '영양군 영양읍 시장5길 4 영양전통시장',
          latitude: '32.123.1234',
          longitude: '123.1231231',
          apName: 'XP-123-영양-1-1',
          gateway: '123.123.12.12',
          mac: '1C:EC:73:89:D0:70',
        },
      ],
    }),
    [],
  );

  return (
    <CustomModal
      title="사업단위"
      id="business-modal"
      size="lg"
      show={businessShow}
      onHide={() => {
        handleClose();
      }}
      noCloseBtn
    >
      <Modal.Body>
        <Form>
          <Form.Group className="form-group p-0 border-0">
            <CustomSelect
              options={profileList}
              value={selectedProfile}
              onChange={e => setSelectedProfile(e)}
              className="mw-160"
              placeholder="선택"
            />
          </Form.Group>
        </Form>
        <article className="mt-4">
          <div className="dl-box align-start">
            <dl>
              <dt>사업단위</dt>
              <dd>{profileDetail.profileNm}</dd>
            </dl>
            <dl>
              <dt>행정구역</dt>
              <dd>{profileDetail.regionNm}</dd>
            </dl>
            <dl>
              <dt>사업위도</dt>
              <dd>{profileDetail.latitude}</dd>
            </dl>
            <dl>
              <dt>사업경도</dt>
              <dd>{profileDetail.longitude}</dd>
            </dl>
            <dl>
              <dd>
                {profileDetail.profileId && (
                  <MapDiv className="map-box" fallback={null}>
                    <NaverMap
                      ref={naverMap}
                      defaultCenter={
                        new navermaps.LatLng(
                          profileDetail.latitude,
                          profileDetail.longitude,
                        )
                      }
                      maxZoom={13}
                      minZoom={13}
                    >
                      {apList &&
                        apList.length > 0 &&
                        apList.map((v, i) => {
                          let content = ReactDOMServer.renderToString(
                            <Button variant="map-unit-state" />,
                          );
                          if (selectUnit && v.apMacId === selectUnit.apMacId) {
                            content = ReactDOMServer.renderToString(
                              <div
                                className="test"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Button
                                  variant="map-unit-state"
                                  style={{ marginRight: 8 }}
                                />
                                <div
                                  className="popover unit-overlay-state custom-unit-overlay-state"
                                  style={{ position: 'relative !important' }}
                                >
                                  <div>
                                    <h3 className="popover-header">{v.apNm}</h3>
                                  </div>
                                  <div className="popover-body">
                                    <div className="dl-box">
                                      <dl>
                                        <dt>AP위도</dt>
                                        <dd className="dl-box">{v.latitude}</dd>
                                      </dl>
                                      <dl>
                                        <dt>AP경도</dt>
                                        <dd className="dl-box">
                                          {v.longitude}
                                        </dd>
                                      </dl>
                                      <dl>
                                        <dt>상태</dt>
                                        <dd className="dl-box">
                                          {v.deviceStateYn === 'Y'
                                            ? '정상'
                                            : '비정상'}
                                        </dd>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>,
                            );
                          }
                          const mkPosition = new navermaps.LatLng(
                            v.latitude,
                            v.longitude,
                          );
                          return (
                            <Marker
                              key={`marker-${i}`}
                              position={mkPosition}
                              icon={{
                                content,
                                origin: new navermaps.Point(0, 0),
                                anchor: new navermaps.Point(12, 37),
                              }}
                              onClick={() => {
                                setSelectUnit(v);
                              }}
                            />
                          );
                        })}
                    </NaverMap>
                  </MapDiv>
                )}
                <CustomTable
                  columns={apColumns}
                  data={apList}
                  className="sm mt-3"
                  name="AP"
                />
              </dd>
            </dl>
          </div>
        </article>
      </Modal.Body>
      <div className="btn-area justify-content-end">
        <Button variant="outline-primary" onClick={handleClose}>
          닫기
        </Button>
        <Button
          disabled={!selectedProfile}
          onClick={() => {
            handleClose();
            setBusinessOptions(selectedProfile);
          }}
        >
          저장
        </Button>
      </div>
    </CustomModal>
  );
});

const businessOptions = [
  { label: '영양군 수비면(10)', value: '1' },
  { label: '영양군 수비면(20)', value: '2' },
  { label: '영양군 수비면(10)', value: '3' },
  { label: '영양군 수비면(7)', value: '4' },
  { label: '영양군 수비면(20)', value: '5' },
  { label: '영양군 수비면(10)', value: '6' },
];
