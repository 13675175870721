/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Button, Table } from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Utils } from '@common';
import { images } from '@assets';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import { VerticalBarChart, dataObj as barData } from './VerticalBarChart';
import { PoPLineChart, dataObj as popLineData } from './PoPLineChart';
import { TopDataPieChart, dataObj as pieData } from './TopdataPieChart';
import PdfDownloader from '../../common/PdfDownloader';

// 스피너
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// 엑셀 다운
import { saveAs } from 'file-saver';
import * as ExcelJS from 'exceljs';
import { PrmBarChart } from './PrmBarChart';

import './assets/css/ap-data-table.css';
import { getFlowDataLab, getFlowDataLabExcel } from '../../common/crud';

export default React.memo(function TopData(props) {
  const [bsunitOptions, setBsUnitOptions] = useState(businessOptions[0]); // 사업단위
  const [citydoOptions, setCityDoOptions] = useState(cityOptions[0]); // 시/도
  const [regionOptions, setRegionOptions] = useState(GuOptions[0]); // 시/군/구
  const [provinceOptions, setProvinceOptions] = useState(); // 읍/면/동
  const [openDate, setOpenDate] = useState(
    new Date().setDate(new Date().getDate() - 30),
  ); // 일정
  const [closeDate, setCloseDate] = useState(new Date()); // 일정
  // 유동인구 접속자수 상위지역 TOP4(테이블)
  const [topDataTop4Data, setTopDataTop4Data] = useState([]);
  // 유동인구 접속자수 상위지역 TOP4(차트)
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [{}],
  });
  const [popLineChartData, setPopLineChartData] = useState({});
  const [pieChartData, setPieChartData] = useState({
    datasets: [{}],
  });
  // AP 데이터 사용량
  const [usedAPData, setUsedAPData] = useState([]);
  const [usedAPDataTotal, setUsedAPDataTotal] = useState([]);
  const [usedAPDataBarChartData, setusedAPDataBarChartData] = useState();
  const barlables = [
    {
      label: 'UL',
      color: 'ul',
      rgb: 'rgb(249, 65, 68)',
      rgba: 'rgba(249, 65, 68,0.5)',
      // rgb: 'rgb(36, 66, 122)',
      // rgba: 'rgba(36, 66, 122,0.5)',
    },
    {
      label: 'DL',
      color: 'dl',
      rgb: 'rgb(45, 156, 219)',
      rgba: 'rgba(45, 156, 219,0.5)',
      // rgb: 'rgb(249, 155, 46)',
      // rgba: 'rgba(249, 155, 46,0.5)',
    },
  ];
  const defaultBarOption = {
    categoryPercentage: 1.0,
    barPercentage: 0.5,
  };

  const [labels, setLabels] = useState([
    { label: '', color: 'first' },
    { label: '', color: 'second' },
    { label: '', color: 'third' },
    { label: '', color: 'fourth' },
    { label: '', color: 'etc' },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const pdfRef1p = useRef();
  const pdfRef2p = useRef();
  const pdfRef3p = useRef();

  const tempCallAPI = async (startDate, endDate, profileId) => {
    setIsLoading(true);

    try {
      const url = 'equip';
      const params = { startDate, endDate, profileId };
      const response = (await getFlowDataLab(url, params)).data;

      const { chart, top4Data, usedData } = response.data;

      // 유동인구 상위 지역의 데이터 사용량 조회(팝라인)
      const tempResult = chart.reduce((acc, obj) => {
        // eslint-disable-next-line camelcase
        const { apmac_id, count } = obj;
        // eslint-disable-next-line camelcase
        if (!acc[apmac_id]) {
          // eslint-disable-next-line camelcase
          acc[apmac_id] = {
            // eslint-disable-next-line camelcase
            apmac_id,
            counts: [],
          };
        }
        // eslint-disable-next-line camelcase
        acc[apmac_id].counts.push(count);
        return acc;
      }, {});
      const sortedCountsArray = [];
      top4Data.forEach(
        v =>
          tempResult[v.apmac_id] !== undefined &&
          sortedCountsArray.push(tempResult[v.apmac_id]),
      );
      const countsArray = sortedCountsArray.map(el => el.counts);
      const { labels: popLineLabels, datasets: popLineDatasets } = popLineData;
      const newDatasets = [];
      countsArray.forEach((v, i) =>
        newDatasets.push({ ...popLineDatasets[i], data: v }),
      );
      const popLineDataResult = {
        labels: popLineLabels,
        datasets: newDatasets,
      };
      setPopLineChartData(popLineDataResult);

      // 유동인구 접속자수 상위지역 TOP4(테이블)
      setTopDataTop4Data(top4Data);
      const tempLabels = top4Data.map(el => el.ap_nm);
      const mergedArray = labels.map((obj, index) => {
        return { ...obj, label: tempLabels[index] };
      });
      setLabels(mergedArray);
      // 유동인구 접속자수 상위지역 TOP4(바)
      const top4DataArr = top4Data.map(el => el.count);
      const tempBarChartData = {
        labels: tempLabels,
        datasets: [
          {
            data: top4DataArr,
            backgroundColor: barData.datasets[0].backgroundColor,
            categoryPercentage: 1.0,
            barPercentage: 0.5,
          },
        ],
      };
      setBarChartData(tempBarChartData);
      // 유동인구 접속자수 상위지역 비율(도넛)
      const tempPieChartData = {
        datasets: [
          {
            data: top4DataArr,
            backgroundColor: pieData.datasets[0].backgroundColor,
            borderWidth: 1,
          },
        ],
        sum: top4DataArr.reduce((a, b) => a + b, 0),
      };
      setPieChartData(tempPieChartData);

      // AP장비별 데이터 사용량(테이블) - 오전, 오후
      const resultUsedAPDataAmPmArr = Object.values(
        usedData.reduce((acc, cur) => {
          const { ap_nm: apNm, apmac_id: apmacId, hh, rx, tx } = cur;

          const hhNum = +hh;

          if (acc[apmacId]) {
            Object.assign(acc[apmacId].data[hh], {
              hh: hhNum,
              rx,
              tx,
            });
            acc[apmacId].rx_sum += rx;
            acc[apmacId].tx_sum += tx;
            if (hhNum >= 12) {
              acc[apmacId].rx_pm_sum += rx;
              acc[apmacId].tx_pm_sum += tx;
            } else {
              acc[apmacId].rx_am_sum += rx;
              acc[apmacId].tx_am_sum += tx;
            }
          } else {
            acc[apmacId] = {
              apmac_id: apmacId,
              ap_nm: apNm,
              data: [],
              rx_sum: rx ?? 0,
              tx_sum: tx ?? 0,
              rx_am_sum: rx ?? 0,
              tx_am_sum: tx ?? 0,
              rx_pm_sum: 0,
              tx_pm_sum: 0,
            };

            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < 24; i++) {
              if (i === hhNum) {
                acc[apmacId].data.push({
                  hh: i,
                  rx,
                  tx,
                });
                // acc[apmacId].rx_sum += rx;
                // acc[apmacId].tx_sum += tx;
              } else {
                acc[apmacId].data.push({
                  hh: i,
                  rx: 0,
                  tx: 0,
                });
              }
            }
            acc[apmacId] = { ...acc[apmacId] };
          }

          return acc;
        }, {}),
      );
      setUsedAPData(resultUsedAPDataAmPmArr);

      // AP 데이터 사용량(시간별 총합) - 오전, 오후
      const accData = (acc, cur) => {
        const { hh, rx, tx } = cur;
        if (acc[hh] === undefined) {
          acc[hh] = { hh, rx, tx };
        } else {
          acc[hh].rx += rx;
          acc[hh].tx += tx;
        }
        acc.total.rx += rx;
        acc.total.tx += tx;
        if (hh >= 12) {
          acc.totalPm.rx += rx;
          acc.totalPm.tx += tx;
        } else {
          acc.totalAm.rx += rx;
          acc.totalAm.tx += tx;
        }
        return acc;
      };

      const resultUsedAPDataTotal = resultUsedAPDataAmPmArr.reduce(
        (acc, v) => {
          return v.data.reduce(accData, acc);
        },
        {
          total: { hh: 'total', rx: 0, tx: 0 },
          totalAm: { hh: 'am', rx: 0, tx: 0 },
          totalPm: { hh: 'pm', rx: 0, tx: 0 },
        },
      );

      const resultUsedAPDataTotalArr = [
        ...Object.values(resultUsedAPDataTotal),
      ];

      setUsedAPDataTotal(resultUsedAPDataTotalArr);

      // AP 데이터 사용량 바 차트 데이터
      const resultUsedAPDataTotalArrSlice = resultUsedAPDataTotalArr.slice(
        0,
        24,
      );
      const tempUsedAPDataTotalBarChartData = {
        labels: resultUsedAPDataTotalArrSlice
          .filter(v => v.hh !== undefined)
          .map(v => v.hh),
        datasets: [
          {
            ...defaultBarOption,
            backgroundColor: barlables[0].rgb,
            data: resultUsedAPDataTotalArrSlice.map(v => v.rx).slice(0, 24),
          },
          {
            ...defaultBarOption,
            backgroundColor: barlables[1].rgb,
            data: resultUsedAPDataTotalArrSlice.map(v => v.tx).slice(0, 24),
          },
        ],
      };

      setusedAPDataBarChartData(tempUsedAPDataTotalBarChartData);

      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const createPdfIamge = async () => {
    setIsLoading(true);
    const tempPdfImages = [];
    const chartCanvas1p = await html2canvas(pdfRef1p.current);
    const chartImageUrl1p = chartCanvas1p.toDataURL('image/png');
    const chartPdfObj1p = {
      canvas: chartCanvas1p,
      image: chartImageUrl1p,
    };
    const chartCanvas2p = await html2canvas(pdfRef2p.current);
    const chartImageUrl2p = chartCanvas2p.toDataURL('image/png');
    const chartPdfObj2p = {
      canvas: chartCanvas2p,
      image: chartImageUrl2p,
    };
    const chartCanvas3p = await html2canvas(pdfRef3p.current);
    const chartImageUrl3p = chartCanvas3p.toDataURL('image/png');
    const chartPdfObj3p = {
      canvas: chartCanvas3p,
      image: chartImageUrl3p,
    };
    tempPdfImages.push(chartPdfObj1p);
    tempPdfImages.push(chartPdfObj2p);
    tempPdfImages.push(chartPdfObj3p);
    return tempPdfImages;
  };

  const downloadExcel = async () => {
    setIsLoading(true);
    // eslint-disable-next-line no-useless-catch
    try {
      const startDate = format(openDate, 'yyyy-MM-dd').toString();
      const endDate = format(closeDate, 'yyyy-MM-dd').toString();
      const profileId = bsunitOptions.value;

      const url = 'equip';
      const params = { startDate, endDate, profileId };
      const response = await getFlowDataLabExcel(url, params);
      const { data } = response.data;

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Datalab Topdata');

      worksheet.columns = [
        { header: '사업단위', key: 'profile', width: 10 },
        { header: 'AP명', key: 'ap_nm', width: 30 },
        { header: 'AP MAC 주소', key: 'apmac_address', width: 17 },
        { header: 'AP 위치 주소', key: 'ap_address', width: 50 },
        { header: 'AP 위치 위도', key: 'ap_lat', width: 12 },
        { header: 'AP 위치 경도', key: 'ap_lon', width: 12 },
        { header: '접속자 수', key: 'count', width: 9 },
        { header: '접속 시간', key: 'datetime', width: 20 },
      ];

      worksheet.getCell('A1').value = '검색조건';
      worksheet.getCell('B1').value = `조회 시작일 : ${startDate}`;
      worksheet.getCell('B2').value = `조회 종료일 : ${endDate}`;
      worksheet.getCell('C1').value = '';
      worksheet.getCell('D1').value = '';
      worksheet.getCell('E1').value = '';
      worksheet.getCell('F1').value = '';
      worksheet.getCell('G1').value = '';
      worksheet.getCell('H1').value = '';

      worksheet.spliceRows(3, 0, ['']);

      worksheet.columns.forEach((col, index) => {
        worksheet.getCell(`${String.fromCharCode(index + 65)}4`).value =
          col.header;
      });

      worksheet.addConditionalFormatting({
        ref: 'A4:H4',
        rules: [
          {
            type: 'expression',
            formulae: ['MOD(ROW()+COLUMN(),1)=0'],
            style: {
              fill: {
                type: 'pattern',
                pattern: 'solid',
                bgColor: { argb: '5890c7' },
              },
            },
          },
        ],
      });

      data.forEach(v => {
        worksheet.addRow(v);
      });

      // 다운로드
      const mimeType = {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], mimeType);

      const fileName = `datalab_topdata_${format(
        openDate,
        'yyyyMMdd',
      ).toString()}_${format(closeDate, 'yyyyMMdd').toString()}.xlsx`;

      saveAs(blob, fileName);
    } catch (error) {
      throw error;
    }
  };

  const doSearch = () => {
    const startDate = format(openDate, 'yyyy-MM-dd').toString();
    const endDate = format(closeDate, 'yyyy-MM-dd').toString();
    tempCallAPI(startDate, endDate, bsunitOptions.value);
  };

  useEffect(() => {
    doSearch();
  }, [bsunitOptions, openDate, closeDate]);

  return (
    <main id="datalab-topdata">
      {isLoading && <Loading />}
      <Header title="상위지역 데이터 사용량" />
      <Container className="contents container-128">
        <article>
          <div className="title-datalab">
            <h5>사업단위별 상위지역 조회</h5>
          </div>
          <Form className="search-area">
            <div className="form-flex">
              <Form.Group className="form-group">
                <Form.Label>시/도</Form.Label>
                <CustomSelect
                  options={cityOptions}
                  value={citydoOptions}
                  onChange={setCityDoOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label className="ms-3">시/군/구</Form.Label>
                <CustomSelect
                  options={GuOptions}
                  value={regionOptions}
                  onChange={setRegionOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>읍/면/동</Form.Label>
                <CustomSelect
                  options={dongOptions}
                  value={provinceOptions}
                  onChange={setProvinceOptions}
                  isDisabled
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>사업단위</Form.Label>
                <CustomSelect
                  options={businessOptions}
                  value={bsunitOptions}
                  onChange={setBsUnitOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
            </div>
            <Form.Group className="form-group">
              <Form.Label>기간</Form.Label>
              {/* <CustomSelect
                options={dateday}
                value={serchfilter}
                onChange={setSerchFilter}
                className="mw-200"
                placeholder="선택"
              /> */}
              {/* <div className="datepicker-wrap mw-280 ms-3"> */}
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={openDate}
                  // minDate={Utils.getTomorrow()}
                  maxDate={closeDate || Utils.getToday()}
                  onChange={e => {
                    setOpenDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={closeDate}
                  minDate={openDate || Utils.getToday()}
                  maxDate={Utils.getToday()}
                  onChange={setCloseDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
              {/* <div className="ms-3">
                <Button onClick={() => doSearch()}>검색</Button>
              </div> */}
            </Form.Group>
          </Form>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              downloadExcel()
                .catch(() => {
                  CustomSwal.fire({
                    text: 'XLSX 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i
              style={{ backgroundImage: `url(${images.icExcel})` }}
              className="xlsx me-2"
            />
            XLSX
          </Button>
          <Button
            onClick={() => {
              createPdfIamge()
                .then(e => {
                  return PdfDownloader(e);
                })
                .catch(e => {
                  CustomSwal.fire({
                    text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>

        <div ref={pdfRef1p}>
          <article className="mt-4 scrollbar">
            <h5>접속자수 상위지역 TOP4</h5>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={80} />
                <col width={80} />
                <col width={120} />
                <col width={140} />
                <col width={140} />
                <col width={120} />
              </colgroup>
              <thead>
                <tr>
                  <th>Top</th>
                  <th>통신상태</th>
                  <th>AP명</th>
                  <th>사업단위</th>
                  <th>기간</th>
                  <th className="td-right">전체 접속자수</th>
                </tr>
              </thead>

              <tbody>
                {topDataTop4Data.length > 0 ? (
                  topDataTop4Data.map((v, i) => {
                    return (
                      <tr key={`top4-${i}`}>
                        <td>{i + 1}</td>
                        <td>{v.device_state === 'Y' ? '원활' : '-'}</td>
                        <td>{v.ap_nm}</td>
                        <td>{v.profile_nm}</td>
                        <td>
                          {openDate ? (
                            <Moment
                              date={openDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                          <span className="pd-lr"> - </span>
                          {closeDate ? (
                            <Moment
                              date={closeDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        <td className="td-right">
                          {v.count && Utils.numberComma(v.count)}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
          <article className="mt-4">
            <div className="title-labels">
              {labels.map((option, index) => (
                <div key={`${option.label}-${index}`} className="oplabel-flex">
                  <span className={`option-boll ${option.color}`} />
                  <Form.Label>{option.label}</Form.Label>
                </div>
              ))}
            </div>
            <div className="grid-section">
              <div className="bar-section">
                <div className="flex-title">
                  <h5>접속자수 상위지역 TOP4</h5>
                  <p>단위 : 천 명</p>
                </div>
                <div className="bar-height">
                  {topDataTop4Data.length > 0 ? (
                    <VerticalBarChart data={barChartData} />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
              <div className="pie-section">
                <div className="flex-title">
                  <h5>접속자수 상위지역 비율</h5>
                  <p>단위 : 천 명</p>
                </div>
                <div className="pie-height">
                  {topDataTop4Data.length > 0 ? (
                    <TopDataPieChart
                      style={{ flex: 1, width: '100%' }}
                      data={pieChartData}
                    />
                  ) : (
                    <div className="no-data" colSpan={6}>
                      요청 내역이 없습니다.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="line-section">
              <div className="flex-title">
                <h5>시간별 접속자 수</h5>
                <p>
                  시작일 및 종료일 : {format(openDate, 'yyyy.MM.dd').toString()}{' '}
                  - {format(closeDate, 'yyyy.MM.dd').toString()}
                </p>
              </div>
              <div className="popline-box">
                {topDataTop4Data.length > 0 ? (
                  <PoPLineChart data={popLineChartData} />
                ) : (
                  <div className="no-data" colSpan={6}>
                    요청 내역이 없습니다.
                  </div>
                )}
              </div>
            </div>
          </article>
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>AP 데이터 시간별 사용량</h5>
                <div className="title-labels">
                  {barlables.map((option, i) => (
                    <div className="oplabel-flex" key={`barlable-${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              {usedAPData.length > 0 ? (
                <div className="bar-height">
                  {usedAPDataBarChartData && (
                    <PrmBarChart chartData={usedAPDataBarChartData} />
                  )}
                </div>
              ) : (
                <div className="no-data">
                  요청 내역이 없습니다. 금일 데이터의 경우 조회할 수 없습니다.
                </div>
              )}
            </div>
          </article>
        </div>
        <div ref={pdfRef2p}>
          <article className="mt-4 scrollbar aptable">
            <div className="flex-title">
              <h5>AP 데이터 시간별 사용량(오전)</h5>
              <p>단위 : MB</p>
            </div>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={50} />
                <col width={150} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-right center-text">
                    No
                  </th>
                  <th rowSpan={2} className="bd-right">
                    AP명
                  </th>
                  <th rowSpan={2} className="bd-right">
                    유형
                  </th>
                  <th colSpan={12} className="bd-bottom center-text">
                    시간대별 사용량
                  </th>
                  <th rowSpan={2} className="bd-left td-right">
                    전체사용량
                  </th>
                </tr>
                <tr>
                  <th className="bd-right center-text">00 ~ 01</th>
                  <th className="bd-right center-text">01 ~ 02</th>
                  <th className="bd-right center-text">02 ~ 03</th>
                  <th className="bd-right center-text">03 ~ 04</th>
                  <th className="bd-right center-text">04 ~ 05</th>
                  <th className="bd-right center-text">05 ~ 06</th>
                  <th className="bd-right center-text">06 ~ 07</th>
                  <th className="bd-right center-text">07 ~ 08</th>
                  <th className="bd-right center-text">08 ~ 09</th>
                  <th className="bd-right center-text">09 ~ 10</th>
                  <th className="bd-right center-text">10 ~ 11</th>
                  <th className="bd-right center-text">11 ~ 12</th>
                </tr>
                {usedAPData.length > 0 && (
                  <>
                    <tr>
                      <td rowSpan={2} className="center-text">
                        -
                      </td>
                      <td rowSpan={2}>시간별 총합</td>
                      <td className="right-text">UL</td>
                      {usedAPDataTotal !== undefined &&
                        usedAPDataTotal.slice(0, 12).map((w, i) => {
                          return (
                            <td
                              key={`used-data-total-ul-${i}`}
                              className="right-text"
                            >
                              {w.rx && Utils.numberComma(w.rx)}
                            </td>
                          );
                        })}
                      <td className="td-right">
                        {usedAPDataTotal.find(w => w.hh === 'am') !==
                          undefined &&
                          Utils.numberComma(
                            usedAPDataTotal.find(w => w.hh === 'am').rx,
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="right-text">DL</td>
                      {usedAPDataTotal !== undefined &&
                        usedAPDataTotal.slice(0, 12).map((w, i) => {
                          return (
                            <td
                              key={`used-data-total-dl-${i}`}
                              className="right-text"
                            >
                              {w.tx && Utils.numberComma(w.tx)}
                            </td>
                          );
                        })}
                      <td className="td-right">
                        {usedAPDataTotal.find(w => w.hh === 'am') !==
                          undefined &&
                          Utils.numberComma(
                            usedAPDataTotal.find(w => w.hh === 'am').tx,
                          )}
                      </td>
                    </tr>
                  </>
                )}
              </thead>
              {/* tx: 다운로드, rx: 업로드 */}
              <tbody>
                {usedAPData.length > 0 ? (
                  usedAPData.map((v, i) => {
                    const {
                      apmac_id: apmacId,
                      ap_nm: apNm,
                      data,
                      rx_am_sum: rxAmSum,
                      tx_am_sum: txAmSum,
                    } = v;
                    return (
                      <React.Fragment key={`used-data-${i}`}>
                        <tr>
                          <td rowSpan={2} className="center-text">
                            {i + 1}
                          </td>
                          <td rowSpan={2}>{apNm}</td>
                          <td className="right-text">UL</td>
                          {data !== undefined &&
                            data.slice(0, 12).map((w, j) => {
                              return (
                                <td
                                  key={`used-data-ul-${j}`}
                                  className="right-text"
                                >
                                  {w.rx && Utils.numberComma(w.rx)}
                                </td>
                              );
                            })}
                          <td className="td-right">
                            {rxAmSum && Utils.numberComma(rxAmSum)}
                          </td>
                        </tr>
                        <tr>
                          <td className="right-text">DL</td>
                          {data !== undefined &&
                            data.slice(0, 12).map((w, j) => {
                              return (
                                <td
                                  key={`used-data-dl-${j}`}
                                  className="right-text"
                                >
                                  {w.tx && Utils.numberComma(w.tx)}
                                </td>
                              );
                            })}
                          <td className="td-right">
                            {txAmSum && Utils.numberComma(txAmSum)}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={16}>
                      요청 내역이 없습니다. 금일 데이터의 경우 조회할 수
                      없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
        </div>
        <div ref={pdfRef3p}>
          <article className="mt-4 scrollbar aptable">
            <div className="flex-title">
              <h5>AP 데이터 시간별 사용량(오후)</h5>
              <p>단위 : MB</p>
            </div>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={50} />
                <col width={150} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-right center-text">
                    No
                  </th>
                  <th rowSpan={2} className="bd-right">
                    AP명
                  </th>
                  <th rowSpan={2} className="bd-right">
                    유형
                  </th>
                  <th colSpan={12} className="bd-bottom center-text">
                    시간대별 사용량
                  </th>
                  <th rowSpan={2} className="bd-left td-right">
                    전체사용량
                  </th>
                </tr>
                <tr>
                  <th className="bd-right center-text">12 ~ 13</th>
                  <th className="bd-right center-text">13 ~ 14</th>
                  <th className="bd-right center-text">14 ~ 15</th>
                  <th className="bd-right center-text">15 ~ 16</th>
                  <th className="bd-right center-text">16 ~ 17</th>
                  <th className="bd-right center-text">17 ~ 18</th>
                  <th className="bd-right center-text">18 ~ 19</th>
                  <th className="bd-right center-text">19 ~ 20</th>
                  <th className="bd-right center-text">20 ~ 21</th>
                  <th className="bd-right center-text">21 ~ 22</th>
                  <th className="bd-right center-text">22 ~ 23</th>
                  <th className="bd-right center-text">23 ~ 24</th>
                </tr>
                {usedAPData.length > 0 && (
                  <>
                    <tr>
                      <td rowSpan={2} className="center-text">
                        -
                      </td>
                      <td rowSpan={2}>시간별 총합</td>
                      <td className="right-text">UL</td>
                      {usedAPDataTotal !== undefined &&
                        usedAPDataTotal.slice(12, 24).map((w, i) => {
                          return (
                            <td
                              key={`used-data-ul-pm-${i}`}
                              className="right-text"
                            >
                              {w.rx && Utils.numberComma(w.rx)}
                            </td>
                          );
                        })}
                      <td className="td-right">
                        {usedAPDataTotal.find(w => w.hh === 'pm') !==
                          undefined &&
                          Utils.numberComma(
                            usedAPDataTotal.find(w => w.hh === 'pm').rx,
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className="right-text">DL</td>
                      {usedAPDataTotal !== undefined &&
                        usedAPDataTotal.slice(12, 24).map((w, i) => {
                          return (
                            <td
                              key={`used-data-dl-pm-${i}`}
                              className="right-text"
                            >
                              {w.tx && Utils.numberComma(w.tx)}
                            </td>
                          );
                        })}
                      <td className="td-right">
                        {usedAPDataTotal.find(w => w.hh === 'pm') !==
                          undefined &&
                          Utils.numberComma(
                            usedAPDataTotal.find(w => w.hh === 'pm').tx,
                          )}
                      </td>
                    </tr>
                  </>
                )}
              </thead>
              {/* tx: 다운로드, rx: 업로드 */}
              <tbody>
                {usedAPData.length > 0 ? (
                  usedAPData.map((v, i) => {
                    const {
                      apmac_id: apmacId,
                      ap_nm: apNm,
                      data,
                      rx_pm_sum: rxPmSum,
                      tx_pm_sum: txPmSum,
                    } = v;
                    return (
                      <React.Fragment key={`used-data-pm-${i}`}>
                        <tr>
                          <td rowSpan={2} className="center-text">
                            {i + 1}
                          </td>
                          <td rowSpan={2}>{apNm}</td>
                          <td className="right-text">UL</td>
                          {data !== undefined &&
                            data.slice(12).map((w, j) => {
                              return (
                                <td
                                  key={`used-data-pm-ul-${j}`}
                                  className="right-text"
                                >
                                  {w.rx && Utils.numberComma(w.rx)}
                                </td>
                              );
                            })}
                          <td className="td-right">
                            {rxPmSum && Utils.numberComma(rxPmSum)}
                          </td>
                        </tr>
                        <tr>
                          <td className="right-text">DL</td>
                          {data !== undefined &&
                            data.slice(12).map((w, j) => {
                              return (
                                <td
                                  key={`used-data-pm-dl-${j}`}
                                  className="right-text"
                                >
                                  {w.tx && Utils.numberComma(w.tx)}
                                </td>
                              );
                            })}
                          <td className="td-right">
                            {txPmSum && Utils.numberComma(txPmSum)}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={16}>
                      요청 내역이 없습니다. 금일 데이터의 경우 조회할 수
                      없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </article>
        </div>
      </Container>
    </main>
  );
});

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const businessOptions = [{ label: 'profile_7(명덕 마을)', value: 7 }];
const cityOptions = [{ label: '울산시', value: '1' }];
const GuOptions = [{ label: '동구', value: '1' }];
const dongOptions = [];
const dateday = [
  { label: '일별', value: '1' },
  { label: '월별', value: '2' },
  { label: '년별', value: '3' },
];
