/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      // max: 10000,
      maxTicksLimit: 5, // specify the maximum number of ticks on the y-axis
      ticks: {
        stepSize: 250, // specify the step size for ticks
        max: 1000, // specify the maximum value for ticks
      },
    },
  },
};

const labels = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

// TODO: datasets n개에 대응 가능하도록 개선 요망
export const dataObj = {
  labels,
  datasets: [
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(36, 66, 122)',
      borderWidth: 1,
      backgroundColor: 'rgb(36, 66, 122)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(249, 155, 46)',
      borderWidth: 1,
      backgroundColor: 'rgb(249, 155, 46)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(88, 144, 199)',
      borderWidth: 1,
      backgroundColor: 'rgb(88, 144, 199)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(98, 74, 239)',
      borderWidth: 1,
      backgroundColor: 'rgb(98, 74, 239)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(44, 138, 62)',
      borderWidth: 1,
      backgroundColor: 'rgb(44, 138, 62)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(255, 151, 214)',
      borderWidth: 1,
      backgroundColor: 'rgb(255, 151, 214)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(0, 222, 181)',
      borderWidth: 1,
      backgroundColor: 'rgb(0, 222, 181)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(235, 68, 15)',
      borderWidth: 1,
      backgroundColor: 'rgb(235, 68, 15)',
    },
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      borderColor: 'rgb(188, 187, 220)',
      borderWidth: 1,
      backgroundColor: 'rgb(188, 187, 220)',
    },
  ],
};

export function PoPLineChart({ data = dataObj }) {
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
      }}
    >
      <Line
        options={options}
        data={data}
        style={{
          flex: 1,
          width: '100%',
        }}
      />
    </div>
  );
}
