/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { images } from '@assets';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import classNames from 'classnames';
import CustomSwal from '../../../components/CustomSwal';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';
import {
  deleteCoupon,
  getCoupon,
  getPromotionDetail,
  patchCoupon,
  putCoupon,
} from '../../../common/crud';

export default React.memo(function SurveyItem({ data, index }) {
  return (
    <article
      className={classNames('', index !== 1 && 'mt-4')}
      style={{
        minHeight:
          data.question ||
          (data.options && data.options.filter(v => !!v.answer).length > 0)
            ? 0
            : 350,
      }}
    >
      {data.question && (
        <section className="border-line">
          <div>
            <h6>
              {index}. {data.question}
            </h6>
          </div>
        </section>
      )}
      <Form>
        {['radio'].map(type => (
          <div key={`inline-${type}`} className="mb-3">
            {data.options &&
              data.options.map((v, i) => {
                return (
                  <Form.Group key={`survey_${v.opUuid}`} className="flex-form">
                    {v.answer && (
                      <>
                        <Form.Check type={type} checked={i === 0} readOnly />
                        <Form.Label className="form-label">
                          {v.answer}
                        </Form.Label>
                      </>
                    )}
                  </Form.Group>
                );
              })}
          </div>
        ))}
      </Form>
    </article>
  );
});
