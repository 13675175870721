/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
  Collapse,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import {
  getAdmins,
  getCodeChilds,
  patchCodeChild,
  putCodeChild,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import AdminModal from './AdminModal';

export default React.memo(function CodeChildEdit({
  show,
  parentId,
  parentCode,
}) {
  const useStateOptions = [
    {
      value: 'Y',
      label: '사용',
    },
    {
      value: 'N',
      label: '미사용',
    },
  ];

  const history = useHistory();
  const [codeChildList, setCodeChildList] = useState([]);
  //   const [selectChildCode, setSelectChildCode] = useState();
  const [createChildCodeList, setCreateChildCodeList] = useState([]);
  const [updateChildCodeData, setUpdateChildCodeData] = useState();

  const getCodeChildList = async () => {
    try {
      const { data } = await getCodeChilds(parentId);
      if (data.code === 200) {
        setCodeChildList(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const createChildCode = async (event, create) => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!create.code) {
        throw new CustomException('대분류 코드를 입력해 주세요.');
      }

      if (!create.codeKo) {
        throw new CustomException('대분류 코드명(한글)을 입력해 주세요.');
      }

      if (!create.useYn) {
        throw new CustomException('사용여부를 선택해 주세요.');
      }
      const params = {
        ...create,
        parentId,
      };
      const { data } = await putCodeChild(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 추가 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            getCodeChildList();
            setCreateChildCodeList(
              createChildCodeList.filter(v => v.uuid !== create.uuid),
            );
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const updateChildCode = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!updateChildCodeData.codeKo) {
        throw new CustomException('상세코드명(한글)을 입력해 주세요.');
      }

      if (!updateChildCodeData.useYn) {
        throw new CustomException('사용여부를 선택해 주세요.');
      }

      const { data } = await patchCodeChild(updateChildCodeData);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 수정 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            setUpdateChildCodeData();
            getCodeChildList();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    getCodeChildList();
  }, []);

  return (
    <tr className="selected tr-detail">
      <td colSpan={7}>
        <Collapse in={show}>
          <div className="back-color-tb">
            <Table className="table-hover text-start">
              <colgroup>
                <col width={60} />
                <col width={80} />
                <col width={100} />
                <col width={100} />
                <col width={120} />
                <col width={140} />
                <col width={80} />
              </colgroup>
              <thead>
                <tr>
                  <th>대분류 코드</th>
                  <th>상세코드</th>
                  <th>상세코드명(한글)</th>
                  <th>상세코드명(영문)</th>
                  <th>사용여부</th>
                  <th>메모</th>
                  <th className="th-center">관리</th>
                </tr>
              </thead>
              <tbody>
                {codeChildList.map((v, i) => {
                  return (
                    <tr key={`child-${i}`}>
                      <td>{parentCode}</td>
                      <td>{v.code}</td>
                      <td>
                        {updateChildCodeData &&
                        updateChildCodeData.codeId === v.codeId ? (
                          <InputGroup>
                            <Form.Control
                              placeholder="상세코드명(한글)"
                              className="mw-200"
                              value={updateChildCodeData.codeKo || ''}
                              onChange={e => {
                                setUpdateChildCodeData({
                                  ...updateChildCodeData,
                                  codeKo: e.target.value,
                                });
                              }}
                            />
                          </InputGroup>
                        ) : (
                          v.codeKo
                        )}
                      </td>
                      <td>
                        {updateChildCodeData &&
                        updateChildCodeData.codeId === v.codeId ? (
                          <InputGroup>
                            <Form.Control
                              placeholder="상세코드명(영문)"
                              className="mw-200"
                              value={updateChildCodeData.codeEn || ''}
                              onChange={e => {
                                setUpdateChildCodeData({
                                  ...updateChildCodeData,
                                  codeEn: e.target.value,
                                });
                              }}
                            />
                          </InputGroup>
                        ) : (
                          v.codeEn
                        )}
                      </td>
                      <td>
                        {updateChildCodeData &&
                        updateChildCodeData.codeId === v.codeId ? (
                          <CustomSelect
                            options={useStateOptions}
                            className="mw-80"
                            placeholder="선택"
                            value={useStateOptions.find(
                              us => us.value === updateChildCodeData.useYn,
                            )}
                            onChange={e => {
                              setUpdateChildCodeData({
                                ...updateChildCodeData,
                                useYn: e.value,
                              });
                            }}
                          />
                        ) : (
                          useStateOptions.find(us => us.value === v.useYn).label
                        )}
                      </td>
                      <td>
                        {updateChildCodeData &&
                        updateChildCodeData.codeId === v.codeId ? (
                          <InputGroup>
                            <Form.Control
                              placeholder="내용 입력"
                              className="mw-200"
                              value={updateChildCodeData.memo}
                              onChange={e => {
                                setUpdateChildCodeData({
                                  ...updateChildCodeData,
                                  memo: e.target.value,
                                });
                              }}
                            />
                          </InputGroup>
                        ) : (
                          v.memo
                        )}
                      </td>

                      <td>
                        <div className="code-table">
                          {updateChildCodeData &&
                          updateChildCodeData.codeId === v.codeId ? (
                            <>
                              <Button
                                variant="outline-primary"
                                onClick={() => {
                                  setUpdateChildCodeData();
                                }}
                              >
                                취소
                              </Button>
                              <Button
                                onClick={e => {
                                  updateChildCode(e);
                                }}
                              >
                                저장
                              </Button>
                            </>
                          ) : (
                            <Button
                              onClick={() => {
                                setUpdateChildCodeData(v);
                              }}
                            >
                              수정
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
                {createChildCodeList.map((create, index) => {
                  return (
                    <tr key={`create-child${index}`}>
                      <td>{parentCode}</td>
                      <td>
                        <InputGroup>
                          <Form.Control
                            placeholder="상세코드"
                            className="mw-200"
                            value={create.code || ''}
                            onChange={e => {
                              setCreateChildCodeList(
                                createChildCodeList.map((v, i) => {
                                  if (v.uuid === create.uuid) {
                                    const tempData = {
                                      ...v,
                                      code: e.target.value,
                                    };
                                    return tempData;
                                  }
                                  return v;
                                }),
                              );
                            }}
                          />
                        </InputGroup>
                      </td>
                      <td>
                        <InputGroup>
                          <Form.Control
                            placeholder="상세코드명(한글)"
                            className="mw-200"
                            value={create.codeKo || ''}
                            onChange={e => {
                              setCreateChildCodeList(
                                createChildCodeList.map((v, i) => {
                                  if (v.uuid === create.uuid) {
                                    const tempData = {
                                      ...v,
                                      codeKo: e.target.value,
                                    };
                                    return tempData;
                                  }
                                  return v;
                                }),
                              );
                            }}
                          />
                        </InputGroup>
                      </td>
                      <td>
                        <InputGroup>
                          <Form.Control
                            placeholder="상세코드명(영문)"
                            className="mw-200"
                            value={create.codeEn || ''}
                            onChange={e => {
                              setCreateChildCodeList(
                                createChildCodeList.map((v, i) => {
                                  if (v.uuid === create.uuid) {
                                    const tempData = {
                                      ...v,
                                      codeEn: e.target.value,
                                    };
                                    return tempData;
                                  }
                                  return v;
                                }),
                              );
                            }}
                          />
                        </InputGroup>
                      </td>
                      <td>
                        <CustomSelect
                          options={useStateOptions}
                          className="mw-80"
                          placeholder="선택"
                          value={useStateOptions.find(
                            us => us.value === create.useYn,
                          )}
                          onChange={e => {
                            setCreateChildCodeList(
                              createChildCodeList.map((v, i) => {
                                if (v.uuid === create.uuid) {
                                  const tempData = {
                                    ...v,
                                    useYn: e.value,
                                  };
                                  return tempData;
                                }
                                return v;
                              }),
                            );
                          }}
                        />
                      </td>
                      <td>
                        <InputGroup>
                          <Form.Control
                            placeholder="내용"
                            className="mw-200"
                            value={create.memo || ''}
                            onChange={e => {
                              setCreateChildCodeList(
                                createChildCodeList.map((v, i) => {
                                  if (v.uuid === create.uuid) {
                                    const tempData = {
                                      ...v,
                                      memo: e.target.value,
                                    };
                                    return tempData;
                                  }
                                  return v;
                                }),
                              );
                            }}
                          />
                        </InputGroup>
                      </td>
                      <td>
                        <div className="code-table">
                          <Button
                            variant="outline-primary"
                            onClick={() => {
                              setCreateChildCodeList(
                                createChildCodeList.filter(
                                  v => v.uuid !== create.uuid,
                                ),
                              );
                            }}
                          >
                            취소
                          </Button>
                          <Button
                            onClick={e => {
                              createChildCode(e, create);
                            }}
                          >
                            저장
                          </Button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td colSpan={7}>
                    <div className="btn-area justify-content-end">
                      <Button
                        onClick={() => {
                          setCreateChildCodeList([
                            ...createChildCodeList,
                            { uuid: uuidv4(), useYn: 'Y' },
                          ]);
                        }}
                      >
                        상세코드 추가
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Collapse>
      </td>
    </tr>
  );
});
