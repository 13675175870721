/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { debounce } from 'lodash';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';
import {
  getProfiles,
  getPromoArea,
  getSurveys,
  toggleSurveyShowYn,
} from '../../../common/crud';

export default React.memo(function PrmSurvey(props) {
  const allData = {
    label: '전체',
    value: null,
  };

  const size = 10;

  const history = useHistory();
  const page = (history.location.state && history.location.state.page) || 1;
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const promoAreaId =
    (history.location.state && history.location.state.promoAreaId) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || '';
  const startDate =
    (history.location.state && history.location.state.startDate) || null;
  const endDate =
    (history.location.state && history.location.state.endDate) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [surveyList, setSurveyList] = useState([]);
  const [totalPage, setTotalPage] = useState();

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [searchPrmArea, setSearchPrmArea] = useState({});
  const [searchProfile, setSearchProfile] = useState({});

  const [profileList, setProfileList] = useState([]);
  const [prmAreaList, setPrmAreaList] = useState([]);

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaList = async () => {
    try {
      const { data } = await getPromoArea();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPrmAreaList(tempList);
        if (promoAreaId) {
          setSearchPrmArea(tempList.find(v => v.value === promoAreaId));
        } else {
          setSearchPrmArea(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getSurveyList = async () => {
    try {
      const params = {
        page,
        size,
        profileId,
        promoAreaId,
        keyword,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data } = await getSurveys(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setTotalPage(data.data.totalPages);
          setSurveyList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const setSearchState = () => {
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
    setSearchPrmArea(prmAreaList.find(v => v.value === promoAreaId) || allData);
    setSearchKeyword(keyword || '');
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      profileId: isSearch ? searchProfile.value : profileId,
      promoAreaId: isSearch ? searchPrmArea.value : promoAreaId,
      startDate: isSearch ? searchStartDate : startDate,
      endDate: isSearch ? searchEndDate : endDate,
    };

    props.history.push({
      pathname: RouterPath.prmSurvey,
      state,
    });
  };
  const handleDebounced = useCallback(
    debounce(async value => {
      try {
        const { data } = await toggleSurveyShowYn(value);
        if (data.code === 200) {
          getSurveyList();
        } else {
          throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
        }
      } catch (error) {
        Utils.handleError(error, customSwalGoError, false);
      }
    }, 200),
    [],
  );

  useEffect(() => {
    getPromoAreaList();
    getProfileList();
  }, []);

  useEffect(() => {
    setSearchState();
    getSurveyList();
  }, [pageRefresh]);
  return (
    <main id="prm-coupon-list">
      <Header title="설문조사 관리" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보영역</Form.Label>
              <CustomSelect
                options={prmAreaList}
                value={searchPrmArea}
                onChange={setSearchPrmArea}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>생성일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="설문명 검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      movePage(1, true);
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4 scrollbar">
          <Table className="table-hover text-start">
            <colgroup>
              <col width={80} />
              {/* <col width={80} /> */}
              <col width={120} />
              <col width={100} />
              <col width={120} />
              <col width={140} />
              <col width={100} />
              <col width={80} />
              <col width={100} />
              <col width={140} />
              <col width={80} />
              <col width={120} />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                {/* <th>스케줄링</th> */}
                <th>홍보일</th>
                <th>설문제목</th>
                <th>설문기간</th>
                <th>홍보 타이틀</th>
                <th>작성자</th>
                <th>홍보구분</th>
                <th>홍보영역</th>
                <th>사업단위</th>
                <th>적용상태</th>
                <th className="text-sm-center">관리</th>
              </tr>
            </thead>

            <tbody>
              {surveyList.length > 0 ? (
                surveyList.map((v, i) => {
                  return (
                    <tr
                      key={`survey_${i}`}
                      onClick={() => {
                        if (v.surveyId) {
                          history.push(`${RouterPath.prmResult}/${v.surveyId}`);
                        }
                      }}
                    >
                      <td>{v.promoId}</td>
                      {/* <td>{v.schdeule}</td> */}
                      <td>
                        {v.promoStartDate ? (
                          <Moment
                            date={v.promoStartDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                        <span className="pd-lr">-</span>
                        {v.promoEndDate ? (
                          <Moment
                            date={v.promoEndDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{v.surveyNm ?? '-'}</td>
                      {v.surveyId ? (
                        <td>
                          {v.surveyStartDate ? (
                            <Moment
                              date={v.surveyStartDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                          <span className="pd-lr">-</span>
                          {v.surveyEndDate ? (
                            <Moment
                              date={v.surveyEndDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                      ) : (
                        <td>-</td>
                      )}

                      <td className="ellipsis">{v.title}</td>
                      <td>{Utils.decrypt(v.admNm) || '-'}</td>
                      <td>{v.promoCategory}</td>
                      <td>{v.promoArea}</td>
                      <td className="ellipsis">{v.profileNm}</td>
                      <td>
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          onClick={e => {
                            e.stopPropagation();
                            if (v.surveyId) {
                              handleDebounced(v.surveyId);
                            }
                          }}
                          readOnly
                          checked={v.showYn === 'Y'}
                        />
                      </td>
                      <td>
                        <div className="flex-td">
                          {!v.surveyId && (
                            <Button
                              variant="outline-primary"
                              onClick={e => {
                                e.stopPropagation();
                                history.push({
                                  pathname: `${RouterPath.prmSurveyRegister}/${v.promoId}`,
                                });
                              }}
                            >
                              등록
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={11}>
                    설문 조사 내역이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
    </main>
  );
});
