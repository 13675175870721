/* eslint-disable react/react-in-jsx-scope */
import './assets/css/arrow-main.css';

function ArrowMain({ transform, color, value }) {
  return (
    <div>
      {/* arrow-main-blue */}
      {color === 'normal' && (
        <div
          className="arrow-container"
          style={{ transform: `rotate(${transform}deg)` }}
        >
          <div
            className="number-circle opacity"
            style={{ transform: `rotate(${-transform}deg)` }}
          >
            <p className="number blue">{value}</p>
          </div>
          <svg
            width="169"
            height="52"
            viewBox="0 0 169 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rotate-arrow"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140 52V39L84 39V13L140 13V1.83099V0L169 26L140 52ZM16 13L0 13L0 39H16L16 13ZM41 13L20 13L20 39L41 39L41 13ZM45 13L79 13V39H45L45 13Z"
              fill="url(#paint0_linear_28_9)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_28_9"
                x1="177.163"
                y1="18.6831"
                x2="1.99999"
                y2="18.6831"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0087E8" />
                <stop offset="1" stopColor="#0087E8" stopOpacity="0.15" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
      {/* arrow-main-yellow */}
      {color === 'boundary' && (
        <div
          className="arrow-container"
          style={{ transform: `rotate(${transform}deg)` }}
        >
          <div
            className="number-circle opacity"
            style={{ transform: `rotate(${-transform}deg)` }}
          >
            <p className="number yellow">{value}</p>
          </div>
          <svg
            width="169"
            height="52"
            viewBox="0 0 169 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rotate-arrow"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140 52V39L84 39V13L140 13V1.83099V0L169 26L140 52ZM16 13L0 13L0 39H16L16 13ZM41 13L20 13L20 39L41 39L41 13ZM45 13L79 13V39H45L45 13Z"
              fill="url(#paint0_linear_28_23)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_28_23"
                x1="177.163"
                y1="18.6831"
                x2="1.99999"
                y2="18.6831"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFC700" />
                <stop offset="1" stopColor="#FFC700" stopOpacity="0.15" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
      {/* arrow-main-orange */}
      {color === 'serious' && (
        <div
          className="arrow-container"
          style={{ transform: `rotate(${transform}deg)` }}
        >
          <div
            className="number-circle opacity"
            style={{ transform: `rotate(${-transform}deg)` }}
          >
            <p className="number orange">{value}</p>
          </div>
          <svg
            width="169"
            height="52"
            viewBox="0 0 169 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rotate-arrow"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140 52V39L84 39V13L140 13V1.83099V0L169 26L140 52ZM16 13L0 13L0 39H16L16 13ZM41 13L20 13L20 39L41 39L41 13ZM45 13L79 13V39H45L45 13Z"
              fill="url(#paint0_linear_28_30)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_28_30"
                x1="177.163"
                y1="18.6831"
                x2="1.99999"
                y2="18.6831"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F97B34" />
                <stop offset="1" stopColor="#F97B34" stopOpacity="0.15" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
      {/* arrow-main-red */}
      {color === 'danger' && (
        <div
          className="arrow-container"
          style={{ transform: `rotate(${transform}deg)` }}
        >
          <div
            className="number-circle opacity"
            style={{ transform: `rotate(${-transform}deg)` }}
          >
            <p className="number red">{value}</p>
          </div>
          <svg
            width="169"
            height="52"
            viewBox="0 0 169 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="rotate-arrow"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M140 52V39L84 39V13L140 13V1.83099V0L169 26L140 52ZM16 13L0 13L0 39H16L16 13ZM41 13L20 13L20 39L41 39L41 13ZM45 13L79 13V39H45L45 13Z"
              fill="url(#paint0_linear_28_16)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_28_16"
                x1="177.163"
                y1="18.6831"
                x2="1.99999"
                y2="18.6831"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FF0000" />
                <stop offset="1" stopColor="#FF0000" stopOpacity="0.15" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
    </div>
  );
}

export default ArrowMain;
