/* eslint-disable react/react-in-jsx-scope */
import './assets/css/arrow-three.css';

function ArrowThreeForList({ stroke }) {
  return (
    <div>
      <svg
        width="23"
        height="34"
        viewBox="0 0 23 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table-arrow"
      >
        <path d="M20 3L6 17L20 31" stroke={stroke} strokeWidth="8" />
      </svg>
      <svg
        width="23"
        height="34"
        viewBox="0 0 23 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table-arrow"
      >
        <path d="M20 3L6 17L20 31" stroke={stroke} strokeWidth="8" />
      </svg>

      <svg
        width="23"
        height="34"
        viewBox="0 0 23 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="table-arrow"
      >
        <path d="M20 3L6 17L20 31" stroke={stroke} strokeWidth="8" />
      </svg>
    </div>
  );
}

export default ArrowThreeForList;
