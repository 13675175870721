/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
// /* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Button,
  FormGroup,
  FormLabel,
  FormCheck,
  Image,
} from 'react-bootstrap';
import { FormikInput, CustomSwal } from '@components';
import { RESULT_CODE, RouterPath, Utils } from '@common';
import { images } from '@assets';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import { store } from '@common/redux/store';
import { authAction } from '@reducers/authSlice';
import classNames from 'classnames';
import { getAdmMenus, login } from './Rest';

// import { store } from '@common/redux/store';
// import { authAction } from '@reducers/authSlice';
// import { useSelector } from 'react-redux';
// import { postSignIn } from './crud';

export default React.memo(function SignIn(props) {
  //   // check member
  const [isMember, setIsMember] = useState(null);
  const [errorMessage, setErrorMessage] = useState();
  const [menuUrl, setMenuUrl] = useState();

  //   // Formik
  const SignInFormik = useFormik({
    initialValues: {
      admId: '',
      admPw: '',
    },
    validationSchema: Yup.object().shape({
      admId: Yup.string().required('아이디를 입력하지 않았습니다. 입력하세요.'),
      admPw: Yup.string().required(
        '비밀번호를 입력하지 않았습니다. 입력하세요.',
      ),
    }),
    onSubmit: values => {
      onLogin(values);
    },
  });

  // 로그인 함수
  const onLogin = async inputs => {
    const { admId, admPw } = inputs;
    const params = {
      loginId: Utils.encrypt(admId),
      password: Utils.encryptSHA256(admPw),
    };
    const { data } = await login(params);
    if (data.code === 200) {
      onLoginSuccess(data.data);
      setIsMember(true);
    } else {
      setErrorMessage(RESULT_CODE[`CODE_${data.code}`]);
      setErrorMessage(
        '아이디 또는 비밀번호를 잘못 입력했습니다.<br />입력하신 내용을 다시 확인해주세요.',
      );
      setIsMember(false);
    }

    return true;
  };

  const onLoginSuccess = async responseData => {
    const {
      accessToken,
      refreshToken,
      admNm,
      projCode,
      admType,
      admId,
      loginId,
    } = responseData;
    const auth = {
      accessToken,
      refreshToken,
      admNm: Utils.decrypt(loginId),
      admId,
      admType: Utils.encrypt(admType),
      projCode,
      firstMenuUrl: null,
    };
    store.dispatch(authAction.setAuth(auth));
    let firstMenuUrl = '';
    if (admType !== 'ADM-STORE') {
      firstMenuUrl = await getFirstMenuUrl();
    } else {
      firstMenuUrl = RouterPath.smbusiness;
    }

    if (firstMenuUrl) {
      auth.firstMenuUrl = firstMenuUrl;
      auth.isLogin = true;
      setMenuUrl(firstMenuUrl);
      await store.dispatch(authAction.setAuth(auth));
      props.history.replace(firstMenuUrl);
    } else {
      return CustomSwal.alertSwal({
        text: '로그인 시 이동할 메뉴 정보를 얻는데 실패했습니다.',
        confirmButtonText: '확인',
      });
    }
  };

  const getFirstMenuUrl = async () => {
    try {
      const { data } = await getAdmMenus();
      if (data.code === 200) {
        // 2deps
        if (Array.isArray(data.data.menus) && data.data.menus.length > 0) {
          const menu = data.data.menus[0];
          return menu &&
            menu.subMenuList.length > 0 &&
            menu.subMenuList[0].menuUrl
            ? menu.subMenuList[0].menuUrl
            : menu.menuUrl;
        }
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const { values, dirty, isValid } = SignInFormik;

  // 로그인실패 후 인풋 입력시 실패메세지 지우기
  useEffect(() => {
    if (isMember === false) setIsMember(null);
    return () => {
      setIsMember(null);
    };
  }, [values.admId, values.admPw]);

  useEffect(() => {
    store.dispatch(authAction.removeAuth());
  }, []);

  return (
    <main id="signin" className="auth">
      <Container>
        <div className="logo-area">
          {/* <Image src={images.logoDark} alt="logo" /> */}
          <div className="title-area flex-column">
            <p className="wfs-title">Wi-Fi ALL-IN-ONE SERVICE</p>
            <h2>
              <span>&#34;WI-FREE SQUARE&#34;</span>
              <br />
              platform
            </h2>
          </div>
          {/* <Image src={images.bgSignin} alt="bgSignin" /> */}
        </div>
        <div className="form-box">
          <div className="title-area">
            <h3>로그인</h3>
          </div>
          <FormikProvider value={SignInFormik}>
            <Form className="column">
              <FormGroup className="form-group">
                <FormLabel>아이디</FormLabel>
                <FormikInput
                  type="text"
                  id="admId"
                  name="admId"
                  placeholder="아이디을 입력하세요."
                  limitByte="100"
                />
              </FormGroup>
              <FormGroup className="form-group">
                <FormLabel>비밀번호</FormLabel>
                <FormikInput
                  type="password"
                  id="admPw"
                  name="admPw"
                  placeholder="비밀번호를 입력하세요."
                  limitByte="100"
                />
              </FormGroup>
              <FormGroup className="form-group mt-3">
                <FormCheck type="checkbox" label="아이디 저장" id="saveId" />
              </FormGroup>
              <p
                className={classNames('invalid-feedback mt-3', {
                  'd-block': isMember === false,
                })}
                dangerouslySetInnerHTML={{ __html: errorMessage }}
              />
              <div className="btn-area mt-5">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  className="w-100"
                  style={{
                    background: dirty && isValid ? '#FFD972' : '#cccccc',
                    border: 'none',
                    color: '#5A5A5A',
                  }}
                  disabled={!(dirty && isValid)}
                >
                  로그인
                </Button>
              </div>
              <div className="btn-area finds m-0">
                <Button
                  variant="text-dark"
                  onClick={() => props.history.push(RouterPath.findid)}
                >
                  아이디 찾기
                </Button>
                <span />
                <Button
                  variant="text-dark"
                  onClick={() => props.history.push(RouterPath.findpwd)}
                >
                  비밀번호 찾기
                </Button>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </Container>
    </main>
  );
});
