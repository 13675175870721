/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const updatedOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    datalabels: {
      display: false,
    },
  },
};
const backgroundColors = [
  'rgb(36 66 122)',
  'rgb(249 155 46)',
  'rgb(88 144 199)',
  'rgb(98 74 239)',
  'rgb(188 187 220)',
];
const labels = ['영양풍력단지', '일월산', '영양군청', '초등학교', '기타'];

export const dataObj = {
  labels,
  datasets: [
    {
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      data: [],
      backgroundColor: labels.map((_, index) => backgroundColors[index]),
      categoryPercentage: 1.0,
      barPercentage: 0.5,
    },
  ],
};

export function VerticalBarChart({ data = dataObj }) {
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
      }}
    >
      <Bar
        options={updatedOptions}
        data={data}
        style={{ flex: 1, width: '100%', alignSelf: 'flex-end' }}
      />
    </div>
  );
}
