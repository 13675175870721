/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import {
  deleteCoupon,
  getCoupons,
  getProfiles,
  getPromoArea,
} from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';

export default React.memo(function PrmCoupon(props) {
  const allData = {
    label: '전체',
    value: null,
  };

  const size = 10;

  const history = useHistory();
  const page = (history.location.state && history.location.state.page) || 1;
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const promoAreaId =
    (history.location.state && history.location.state.promoAreaId) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || '';
  const startDate =
    (history.location.state && history.location.state.startDate) || null;
  const endDate =
    (history.location.state && history.location.state.endDate) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [searchPrmArea, setSearchPrmArea] = useState({});
  const [searchProfile, setSearchProfile] = useState({});

  const [profileList, setProfileList] = useState([]);
  const [prmAreaList, setPrmAreaList] = useState([]);

  const [couponList, setCouponList] = useState([]);
  const [totalPage, setTotalPage] = useState();

  const [selectCoupon, setSelectCoupon] = useState();

  const [couponDeleteModalShow, setCouponDeleteModalShow] = useState(false);
  const handleClose = () => {
    setCouponDeleteModalShow(false);
    setSelectCoupon();
  };
  const removeCoupun = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      const { data } = await deleteCoupon(selectCoupon.couponId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            handleClose();
            getCouponList();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getCouponList = async () => {
    try {
      const params = {
        page,
        size,
        profileId,
        promoAreaId,
        keyword,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data } = await getCoupons(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setTotalPage(data.data.totalPages);
          setCouponList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const getPromoAreaList = async () => {
    try {
      const { data } = await getPromoArea();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPrmAreaList(tempList);
        if (promoAreaId) {
          setSearchPrmArea(tempList.find(v => v.value === promoAreaId));
        } else {
          setSearchPrmArea(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const setSearchState = () => {
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
    setSearchPrmArea(prmAreaList.find(v => v.value === promoAreaId) || allData);
    setSearchKeyword(keyword || '');
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      profileId: isSearch ? searchProfile.value : profileId,
      promoAreaId: isSearch ? searchPrmArea.value : promoAreaId,
      startDate: isSearch ? searchStartDate : startDate,
      endDate: isSearch ? searchEndDate : endDate,
    };

    props.history.push({
      pathname: RouterPath.prmCoupon,
      state,
    });
  };

  const getDiscountValue = (discountType, discountValue) => {
    switch (discountType) {
      case 'RATE':
        return Utils.calculatePercentage(discountValue, 1, 0);
      default:
        return `${Utils.changeNumberComma(discountValue)}원`;
    }
  };

  useEffect(() => {
    getPromoAreaList();
    getProfileList();
  }, []);

  useEffect(() => {
    setSearchState();
    getCouponList();
  }, [pageRefresh]);

  const [prmOptions, setPrmOptions] = useState();
  const [bsunitOptions, setBsUnitOptions] = useState();
  const [serchfilter, setSerchFilter] = useState();

  const [openDate, setOpenDate] = useState(); // 일정
  const [closeDate, setCloseDate] = useState(); // 일정

  return (
    <main id="prm-coupon-list">
      <Header title="쿠폰 관리" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보영역</Form.Label>
              <CustomSelect
                options={prmAreaList}
                value={searchPrmArea}
                onChange={setSearchPrmArea}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>생성일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="홍보 타이틀 검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      movePage(1, true);
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4 scrollbar">
          <Table className="table-hover text-start">
            <colgroup>
              <col width={80} />
              <col width={120} />
              <col width={100} />
              <col width={120} />
              <col width={80} />
              <col width={100} />
              <col width={100} />
              <col width={120} />
              <col width={140} />
              <col width={140} />
              <col width={120} />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>홍보일</th>
                <th>쿠폰</th>
                <th>적용기간</th>
                <th>적용상태</th>
                <th>작성자</th>
                <th>홍보구분</th>
                <th>홍보영역</th>
                <th>홍보 타이틀</th>
                <th>사업단위</th>
                <th className="text-sm-center">관리</th>
              </tr>
            </thead>

            <tbody>
              {couponList.length > 0 ? (
                couponList.map((v, i) => {
                  return (
                    <tr key={`coupon_${i}`}>
                      <td>{v.promoId}</td>
                      <td>
                        {v.promoStartDate ? (
                          <Moment
                            date={v.promoStartDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                        <span className="pd-lr">-</span>
                        {v.promoEndDate ? (
                          <Moment
                            date={v.promoEndDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        {v.couponId
                          ? getDiscountValue(v.discountType, v.discountValue)
                          : '-'}
                      </td>
                      <td>
                        {' '}
                        {v.couponStartDate ? (
                          <Moment
                            date={v.couponStartDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          ''
                        )}
                        <span className="pd-lr">-</span>
                        {v.couponEndDate ? (
                          <Moment
                            date={v.couponEndDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          ''
                        )}
                      </td>
                      <td>{v.showYn === 'Y' ? '적용' : '미적용'}</td>
                      <td>{Utils.decrypt(v.admNm) || '-'}</td>
                      <td>{v.promoCategory}</td>
                      <td>{v.promoArea}</td>
                      <td className="ellipsis">{v.title}</td>
                      <td className="ellipsis">{v.profileNm}</td>
                      <td>
                        <div className="flex-td">
                          {v.couponId ? (
                            <>
                              <Button
                                variant="outline-primary"
                                onClick={() => {
                                  history.push({
                                    pathname: `${RouterPath.prmCouponDetail}/${v.promoId}`,
                                  });
                                }}
                              >
                                수정
                              </Button>
                              <Button
                                variant="outline-primary"
                                onClick={() => {
                                  setCouponDeleteModalShow(true);
                                  setSelectCoupon(v);
                                }}
                              >
                                삭제
                              </Button>
                            </>
                          ) : (
                            <Button
                              variant="outline-primary"
                              onClick={() => {
                                history.push({
                                  pathname: `${RouterPath.prmCouponDetail}/${v.promoId}`,
                                });
                              }}
                            >
                              등록
                            </Button>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={11}>
                    홍보 내역이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
      {couponDeleteModalShow && selectCoupon && (
        <CustomModal
          title="쿠폰삭제"
          id="couponModal"
          show={couponDeleteModalShow}
          onHide={() => handleClose()}
        >
          <Modal.Body>
            <div>
              <p>{`${selectCoupon.couponNm}을 삭제하시겠습니까?`}</p>
            </div>
            <div className="btn-area">
              <Button
                onClick={e => {
                  removeCoupun(e);
                }}
              >
                삭제
              </Button>
            </div>
          </Modal.Body>
        </CustomModal>
      )}
    </main>
  );
});
