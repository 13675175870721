/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Form, Table } from 'react-bootstrap';
import { Header, CustomSwal } from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { debounce } from 'lodash';
import { getMenus, toggleMenu } from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function Menu(props) {
  const [menuList, setMenuList] = useState([]);
  const getMenuList = async reqAdmType => {
    try {
      const { data } = await getMenus(reqAdmType);
      if (data.code === 200) {
        setMenuList(createHierarchicalList(data.data));
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const createHierarchicalList = list => {
    // 매개변수로 받은 리스트를 기준으로 새로운 맵을 만듭니다.
    const menuMap = new Map();
    list
      .sort((a, b) => a.menuOrder - b.menuOrder)
      .forEach(menu => {
        menuMap.set(menu.admMenuId, { ...menu, children: [] });
      });

    // 계층적 구조를 위한 최종 메뉴 배열
    const hierarchicalList = [];

    // 각 메뉴를 해당하는 부모 메뉴의 children에 추가합니다.
    menuMap.forEach(menu => {
      if (menu.parentId === null) {
        hierarchicalList.push(menu);
      } else if (menuMap.has(menu.parentId)) {
        menuMap.get(menu.parentId).children.push(menu);
      }
    });

    return hierarchicalList;
  };

  const handleDebounced = useCallback(
    debounce(async value => {
      try {
        const params = {
          admMenuId: value.admMenuId,
          useYn: value.useYn === 'Y' ? 'N' : 'Y',
        };
        const { data } = await toggleMenu(params);
        if (data.code === 200) {
          getMenuList(value.admType);
        } else {
          throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
        }
      } catch (error) {
        Utils.handleError(error, customSwalGoError, false);
      }
    }, 200),
    [],
  );

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    getMenuList();
  }, []);

  return (
    <main id="sys-auth">
      <Header title="메뉴 관리" />
      <Container className="contents">
        <article className="mt-4">
          <Table className="table-hover text-start">
            <colgroup>
              <col width={80} />
              <col width={100} />
              <col width={50} />
            </colgroup>
            <thead>
              <tr>
                <th>대메뉴</th>
                <th>소메뉴</th>
                <th>사용여부</th>
              </tr>
            </thead>
            <tbody>
              {menuList.length > 0 &&
                menuList.map((v, i) => {
                  const { children } = v;
                  const hasChildren = children && children.length > 0;
                  const rowSpan = hasChildren ? children.length : 1;

                  return (
                    <React.Fragment key={i}>
                      {hasChildren ? (
                        children.map((child, idx) => (
                          <tr key={`c_menu_${child.admMenuId}`}>
                            {idx === 0 && (
                              <td rowSpan={rowSpan}>{v.admMenuNm}</td>
                            )}
                            <td>{child.admMenuNm}</td>
                            <td>
                              <Form>
                                {['checkbox'].map(type => (
                                  <div key={`checkp-${type}`}>
                                    <Form.Check
                                      type={type}
                                      id={`checkp-${type}`}
                                      checked={child.useYn === 'Y'}
                                      onChange={e => {
                                        handleDebounced(child);
                                      }}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr key={`menu_${v.admMenuId}`}>
                          <td rowSpan={rowSpan}>{v.admMenuNm}</td>
                          <td />
                          <td>
                            <Form>
                              {['checkbox'].map(type => (
                                <div key={`checkp-${type}`}>
                                  <Form.Check
                                    type={type}
                                    id={`checkp-${type}`}
                                    checked={v.useYn === 'Y'}
                                    onChange={e => {
                                      handleDebounced(v);
                                    }}
                                  />
                                </div>
                              ))}
                            </Form>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
        </article>
      </Container>
    </main>
  );
});
