/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Ratio,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Popover,
  Modal,
} from 'react-bootstrap';
import { Header, CustomTable, PrmScheduleModal } from '@components';
import { Utils, RouterPath, Options } from '@common';
import { images } from '@assets';
import Moment from 'react-moment';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';
import ReactDOMServer from 'react-dom/server';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import {
  deletePromotion,
  getApListByProfile,
  getProfileDetailById,
  getPromotionDetail,
} from '../../../common/crud';
import CustomSwal from '../../../components/CustomSwal';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';
import MainimagesShow from '../../../components/project/MainimagesShow';
import DetailimagesShow from '../../../components/project/DetailimagesShow';

export default React.memo(function PrmConfirmDetail(props) {
  const navermaps = useNavermaps();
  const naverMap = useRef();
  const history = useHistory();
  const { promoId } = useParams();

  const [prmDetail, setPrmDetail] = useState({});

  // 메인 미리보기 모달
  const [mainimagesShow, setMainImagesShow] = useState(false);
  const mainhandleClose = () => setMainImagesShow(false);

  // 상세 미리보기 모달
  const [detailimagesShow, setDetailImagesShow] = useState(false);
  const detailhandleClose = () => setDetailImagesShow(false);

  const gerPrmDetailData = async () => {
    try {
      const { data } = await getPromotionDetail(promoId);
      if (data.code === 200) {
        setPrmDetail(data.data);
        if (data.data.profileId) {
          getProfileDetail(data.data.profileId);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const [profileDetail, setProfileDetail] = useState({});
  const [apList, setApList] = useState([]);
  const [selectUnit, setSelectUnit] = useState();

  const getProfileDetail = async profileId => {
    try {
      const { data } = await getProfileDetailById(profileId);
      if (data.code === 200) {
        setProfileDetail(data.data);
        getApListByProfileData(profileId);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getApListByProfileData = async profileId => {
    try {
      const { data } = await getApListByProfile(profileId);
      if (data.code === 200) {
        setApList(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const deleteSwal = () => {
    Swal.fire({
      title: '삭제 확인',
      text: '정말로 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예, 삭제합니다.',
      cancelButtonText: '취소',
    }).then(e => {
      if (e.isConfirmed) {
        removePromo();
      }
    });
  };

  const removePromo = async () => {
    try {
      const { data } = await deletePromotion(promoId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            props.history.push(RouterPath.prmConfirmList);
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    if (Number(promoId) && Number.isInteger(Number(promoId))) {
      gerPrmDetailData();
    } else {
      CustomSwal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
        finally: () => {
          history.goBack();
        },
      });
    }
  }, [promoId]);

  // 행정동 사업 정보 - 주소
  const apColumns = useMemo(
    () => [
      {
        Header: '설치주소',
        accessor: 'apAddress',
        width: '',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 위도',
        accessor: 'latitude',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 경도',
        accessor: 'longitude',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 이름',
        accessor: 'apName',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: '원격 게이트웨이',
        accessor: 'apIp',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'MAC',
        accessor: 'apMacAddress',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
    ],
    [],
  );
  const handlePromoStateText = state => {
    const promoStateObj = Options.promoStateOptions.find(
      v => v.value === state,
    );
    return (
      <span className={classNames('signal', promoStateObj.className)}>
        {promoStateObj.label}
      </span>
    );
  };

  // 홍보 스케줄 관리
  const [prmScheduleModalShow, setPrmScheduleModalShow] = useState(false);
  const openScheduleModal = () => {
    setPrmScheduleModalShow(true);
  };
  const closePrmScheduleModal = () => {
    setPrmScheduleModalShow(false);
  };

  return (
    <main id="prm-confirm-detail" className="prm-detail">
      <Header title="홍보 리스트" />
      <Container className="contents">
        <article>
          <div className="title-area pb-2">
            <div className="flex-label">
              <h3>홍보 상세</h3>
              {prmDetail &&
                prmDetail.promoState &&
                handlePromoStateText(prmDetail.promoState)}
              {/* 종료 , 홍보중 ,취소 end , addon, cancel 추가
              <span className="signal end">종료</span>
              <span className="signal addon">홍보중</span> 
              */}
            </div>
            <Button
              variant="outline-primary"
              className="ms-2"
              onClick={openScheduleModal}
            >
              스케쥴 관리
            </Button>
          </div>
          <div className="dl-box align-start">
            <div className="grid-flex border-0">
              <dl>
                <dt>작성자</dt>
                <dd>{Utils.decrypt(prmDetail.reqAdmNm)}</dd>
              </dl>
              <dl className="border-0">
                <dt>요청일시</dt>
                <dd>
                  <Moment
                    date={prmDetail.createDate}
                    format="YYYY.MM.DD HH:mm:ss"
                  />
                </dd>
              </dl>
            </div>
            <div className="grid-flex border-bottom">
              <dl>
                <dt>홍보영역</dt>
                <dd>{prmDetail.promoArea}</dd>
              </dl>
              <dl>
                <dt>홍보타입</dt>
                <dd>{prmDetail.promoType}</dd>
              </dl>
            </div>
            <div className="grid-flex border-0">
              <dl>
                <dt>사업단위</dt>
                <dd>{prmDetail.profileNm}</dd>
              </dl>
              <dl className="border-0">
                <dt>광고비 구분</dt>
                <dd>
                  {[
                    {
                      yn: prmDetail.cpcYn,
                      label: 'CPC',
                      price: prmDetail.cpcPrice,
                    },
                    {
                      yn: prmDetail.cpvYn,
                      label: 'CPV',
                      price: prmDetail.cpvPrice,
                    },
                    {
                      yn: prmDetail.cpaYn,
                      label: 'CPA',
                      price: prmDetail.cpaPrice,
                    },
                  ]
                    .filter(v => v.yn === 'Y')
                    .map((v, i, arr) => {
                      return (
                        <p key={i} style={{ display: 'inline' }}>
                          {v.label}
                          <span
                            style={{
                              marginLeft: 8,
                              fontSize: 16,
                              fontWeight: 700,
                            }}
                          >
                            {Utils.changeNumberComma(v.price)}원
                          </span>
                          {i !== arr.length - 1 ? (
                            <span style={{ marginLeft: 8, marginRight: 8 }}>
                              |
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      );
                    })}
                </dd>
              </dl>
            </div>
            <div className="grid-flex border-bottom">
              <dl>
                <dt>홍보일</dt>
                <dd className="line">
                  <span>
                    {Utils.handleMomentStartEnd(
                      prmDetail.startDate,
                      prmDetail.endDate,
                    )}
                  </span>
                  <span>{`${Utils.timeFormatter(
                    prmDetail.startTime,
                  )} ~ ${Utils.timeFormatter(prmDetail.endTime)}`}</span>
                  {/* <span>{thisPrm.dayOfWeek}</span> */}
                </dd>
              </dl>
              <dl className="border-0">
                <dt>구분</dt>
                <dd>{prmDetail.promoCate}</dd>
              </dl>
            </div>
            <dl>
              <dt>타이틀</dt>
              <dd>{prmDetail.title}</dd>
            </dl>
            <dl>
              <dt>위치</dt>
              <dd>{prmDetail.address}</dd>
            </dl>
            <dl>
              <dt>연락처</dt>
              <dd>{prmDetail.tel}</dd>
            </dl>
            <dl>
              <dt>일정</dt>
              <dd>
                {Utils.handleMomentStartEnd(
                  prmDetail.openDate,
                  prmDetail.closeDate,
                )}
              </dd>
            </dl>
            <dl className="flex-line">
              <dt>링크</dt>
              <dd>{prmDetail.url}</dd>
              {['checkbox'].map(type => (
                <div
                  key="linkcheck"
                  style={{ width: '150px' }}
                  className="linkdiv"
                >
                  <Form.Check
                    type={type}
                    id="linkcheck"
                    readOnly
                    checked={prmDetail.urlMoveYn === 'Y'}
                    label="링크로 이동"
                  />
                </div>
              ))}
            </dl>
            <div className="grid-flex">
              <dl>
                <dt>노출수(CPV)</dt>
                <dd>{Utils.changeNumberComma(prmDetail.callCnt)}회</dd>
              </dl>
              <dl className="border-0">
                <dt>조회수(CPC)</dt>
                <dd>{Utils.changeNumberComma(prmDetail.viewCnt)}회</dd>
              </dl>
            </div>
            <div className="grid-flex">
              <dl>
                <dt>조회율</dt>
                <dd>
                  {Utils.calculatePercentage(
                    prmDetail.viewCnt,
                    prmDetail.callCnt,
                  )}
                </dd>
              </dl>
              {/* <dl className="border-0">
                <dt>평균단가</dt>
                <dd className="text-end">
                  {Utils.changeNumberComma(prmDetail.unitPrice)}원
                </dd>
              </dl> */}
            </div>
            <dl>
              <dt>상세 설명</dt>
              <dd
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {prmDetail.description}
              </dd>
            </dl>
            <dl>
              <dt>메인 이미지</dt>
              <dd>
                <ul className="att-list">
                  <li>
                    <div className="flex-start">
                      <Ratio aspectRatio="35x19">
                        <div
                          className="img-box"
                          style={{
                            backgroundImage: `url(${Utils.fileViewUrl(
                              prmDetail.bannerPath,
                            )})`,
                          }}
                        />
                      </Ratio>
                      <p>{prmDetail.bannerNm}</p>
                    </div>
                  </li>
                </ul>
              </dd>
              {prmDetail.bannerId && (
                <Button variant="outline-primary" onClick={setMainImagesShow}>
                  미리보기
                </Button>
              )}
            </dl>
            <dl>
              <dt>상세 이미지</dt>
              <dd>
                {prmDetail.images && prmDetail.images.length > 0 ? (
                  <ul className="att-list">
                    {prmDetail.images.map((item, idx) => {
                      return (
                        <li key={`att-${idx}`}>
                          <div className="flex-start">
                            <Ratio aspectRatio="35x19">
                              <div
                                className="img-box"
                                style={{
                                  backgroundImage: `url(${Utils.fileViewUrl(
                                    item.filePath,
                                  )})`,
                                }}
                              />
                            </Ratio>
                            <p>{item.fileNm}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="no-data justify-content-start text-start p-0">
                    -
                  </p>
                )}
              </dd>
              {prmDetail.images && prmDetail.images.length && (
                <Button variant="outline-primary" onClick={setDetailImagesShow}>
                  미리보기
                </Button>
              )}
            </dl>
          </div>
          <div className="btn-area justify-content-sm-between">
            <div>
              {prmDetail.reqState !== 'TEMP' && (
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    props.history.push({
                      pathname: RouterPath.prmRequestRegister,
                      state: { promoId: prmDetail.promoId },
                    });
                  }}
                >
                  복사
                </Button>
              )}
            </div>
            {prmDetail.useYn === 'Y' && (
              <Button variant="outline-primary" onClick={e => deleteSwal()}>
                삭제
              </Button>
            )}
          </div>
          {/* <div className="btn-area justify-content-end">
            <Button
              variant="outline-primary"
              onClick={() => props.history.push(RouterPath.prmConfirmList)}
            >
              삭제
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => props.history.push(RouterPath.prmConfirmList)}
            >
              수정
            </Button>
          </div> */}
        </article>

        <article className="mt-4">
          <div className="title-area">
            <h3>행정동 사업 정보</h3>
          </div>
          <div className="dl-box align-start">
            <dl>
              <dt>사업단위</dt>
              <dd>{profileDetail.profileNm}</dd>
            </dl>
            <dl>
              <dt>행정구역</dt>
              <dd>{profileDetail.gunNm}</dd>
            </dl>
            <dl>
              <dt>사업위도</dt>
              <dd>{profileDetail.latitude}</dd>
            </dl>
            <dl>
              <dt>사업경도</dt>
              <dd>{profileDetail.longitude}</dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>{profileDetail.address}</dd>
            </dl>
            <dl>
              <dd>
                {profileDetail.profileId && (
                  <MapDiv
                    className="map-box"
                    fallback={null}
                    onClick={() => {
                      setSelectUnit();
                    }}
                  >
                    <NaverMap
                      ref={naverMap}
                      defaultCenter={
                        new navermaps.LatLng(
                          profileDetail.latitude,
                          profileDetail.longitude,
                        )
                      }
                      maxZoom={13}
                      minZoom={13}
                    >
                      {apList &&
                        apList.length > 0 &&
                        apList.map((v, i) => {
                          let content = ReactDOMServer.renderToString(
                            <Button variant="map-unit-state" />,
                          );
                          if (selectUnit && v.apMacId === selectUnit.apMacId) {
                            content = ReactDOMServer.renderToString(
                              <div
                                className="test"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Button
                                  variant="map-unit-state"
                                  style={{ marginRight: 8 }}
                                />
                                <div
                                  className="popover unit-overlay-state custom-unit-overlay-state"
                                  style={{ position: 'relative !important' }}
                                >
                                  <div>
                                    <h3 className="popover-header">{v.apNm}</h3>
                                  </div>
                                  <div className="popover-body">
                                    <div className="dl-box">
                                      <dl>
                                        <dt>AP위도</dt>
                                        <dd className="dl-box">{v.latitude}</dd>
                                      </dl>
                                      <dl>
                                        <dt>AP경도</dt>
                                        <dd className="dl-box">
                                          {v.longitude}
                                        </dd>
                                      </dl>
                                      <dl>
                                        <dt>상태</dt>
                                        <dd className="dl-box">
                                          {v.deviceStateYn === 'Y'
                                            ? '정상'
                                            : '비정상'}
                                        </dd>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>,
                            );
                          }
                          const mkPosition = new navermaps.LatLng(
                            v.latitude,
                            v.longitude,
                          );
                          return (
                            <Marker
                              key={`marker-${i}`}
                              position={mkPosition}
                              icon={{
                                content,
                                origin: new navermaps.Point(0, 0),
                                anchor: new navermaps.Point(12, 37),
                              }}
                              onClick={() => {
                                setSelectUnit(v);
                              }}
                            />
                          );
                        })}
                    </NaverMap>
                  </MapDiv>
                )}
                <CustomTable
                  columns={apColumns}
                  data={apList}
                  className="sm mt-3"
                />
              </dd>
            </dl>
          </div>
        </article>
      </Container>
      {mainimagesShow && (
        <MainimagesShow
          show={mainimagesShow}
          prmDetail={prmDetail}
          noCloseBtn
          onHide={() => setMainImagesShow(false)}
          mainhandleClose={mainhandleClose}
        />
      )}

      {detailimagesShow && (
        <DetailimagesShow
          detailhandleClose={detailhandleClose}
          show={detailimagesShow}
          prmDetail={prmDetail}
          noCloseBtn
          onHide={() => setDetailImagesShow(false)}
        />
      )}
      {prmScheduleModalShow && (
        <PrmScheduleModal
          isUpdate
          profileId={profileDetail.profileId}
          prmDetail={prmDetail}
          show={prmScheduleModalShow}
          onHide={closePrmScheduleModal}
        />
      )}
    </main>
  );
});
