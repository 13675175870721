/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Badge,
  Table,
  Modal,
  Collapse,
} from 'react-bootstrap';
import { images } from '@assets';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { useHistory, useParams } from 'react-router-dom';
import SurveyItem from './SurveyItem';
import SurveyEdit from './SurveyEdit';
import CustomException from '../../../common/hooks/CustomException';
import { putSurvey } from '../../../common/crud';
import RESULT_CODE from '../../../common/ResultCode';

export default React.memo(function prmSurveyRegister(props) {
  const [queOpen, setQueOpen] = useState(false);
  const [surPopShow, setSurPopShow] = useState(false);
  const handleClose = () => setSurPopShow(false);
  const history = useHistory();
  const { promoId } = useParams();

  const [surveyNm, setSurveyNm] = useState('');

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDeta] = useState('');
  const [showYn, setShowYn] = useState('N');
  const defaultQuestions = useCallback(() => {
    return {
      uuid: uuidv4(),
      useYn: 'Y',
      options: [{ opUuid: uuidv4(), useYn: 'Y', selectYn: 'Y' }],
    };
  }, []);
  const [questions, setQuestions] = useState([defaultQuestions()]);

  const onSubmit = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;

      const tempList = questions.map(v => {
        const tempData = {
          ...v,
          options: v.options.map((op, i) => {
            if (!op.answer) {
              throw new CustomException('입력하지 않은 옵션이 존재 합니다.');
            }
            if (i === 0) {
              const tempOp = {
                ...op,
                selectYn: 'Y',
              };
              return tempOp;
            }
            return op;
          }),
        };

        return tempData;
      });

      const params = {
        promoId,
        surveyNm,
        showYn,
        startDate: format(startDate, 'yyyy-MM-dd').toString(),
        endDate: format(endDate, 'yyyy-MM-dd').toString(),
        questions: tempList,
      };

      const { data } = await putSurvey(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 등록 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            history.push(RouterPath.prmSurvey);
            // window.history.replaceState(null, '', RouterPath.prmSurvey);
            // window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      setSurPopShow(false);
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const checkSurvey = event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!surveyNm) {
        throw new CustomException('설문 제목을 입력해 주세요.');
      }

      if (!startDate || !endDate) {
        throw new CustomException('설문기간을 선택해 주세요.');
      }

      if (questions.length < 1) {
        throw new CustomException('질문을 1개 이상 작성해주세요.');
      }

      questions.forEach(v => {
        if (!v.question) {
          throw new CustomException('질문 내용이 없는 질문이 존재 합니다.');
        }
        if (!v.options) {
          throw new CustomException('옵션이 없는 질문이 존재 합니다.');
        }

        v.options.forEach((op, i) => {
          if (!op.answer) {
            throw new CustomException('입력하지 않은 옵션이 존재 합니다.');
          }
        });
      });

      setSurPopShow(true);
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const isDisable = useCallback(() => {
    try {
      if (!surveyNm) {
        throw new CustomException('설문 제목을 입력해 주세요.');
      }

      if (!startDate || !endDate) {
        throw new CustomException('설문기간을 선택해 주세요.');
      }

      if (questions.length < 1) {
        throw new CustomException('질문을 1개 이상 작성해주세요.');
      }

      questions.forEach(v => {
        if (!v.question) {
          throw new CustomException('질문 내용이 없는 질문이 존재 합니다.');
        }
        if (!v.options) {
          throw new CustomException('옵션이 없는 질문이 존재 합니다.');
        }

        v.options.forEach((op, i) => {
          if (!op.answer) {
            throw new CustomException('입력하지 않은 옵션이 존재 합니다.');
          }
        });
      });
      return false;
    } catch (error) {
      return true;
    }
  }, [surveyNm, startDate, endDate, questions]);

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    if (!Number(promoId) || !Number.isInteger(Number(promoId))) {
      CustomSwal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
        finally: () => {
          history.push(RouterPath.prmSurvey);
        },
      });
    }
  }, []);

  const [quemodalShow, setQueModalShow] = useState(false);
  const quemodalClose = () => setQueModalShow(false);
  return (
    <main id="prm-sur-detail">
      <Header title="설문조사 관리" />
      <Container className="contents container-m15">
        <article>
          <div className="result-title">
            <h6>설문조사 등록</h6>
          </div>
          <h6>미리보기</h6>
          <div className="grid-div">
            <section className="survey">
              <header>
                <h3>{surveyNm}</h3>
              </header>
              {questions.length > 0 &&
                questions.map((v, i) => {
                  return (
                    <SurveyItem
                      key={`SurveyItem_${i}`}
                      data={v}
                      index={i + 1}
                    />
                  );
                })}
              {/* 닫기 */}
              <section className="py-3">
                <Button size="lg" className="w-100 btn-connect-wifi btn-radius">
                  설문 완료
                </Button>
              </section>
            </section>
            <Form className="prm-request-input">
              <div className="que-box">
                <Form.Group className="form-group">
                  <Form.Label>설문제목</Form.Label>
                  <Form.Control
                    className="mw-100"
                    value={surveyNm}
                    onChange={e => {
                      setSurveyNm(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="form-group border-bottom-0 flex-check">
                  <Form.Label>설문기간</Form.Label>
                  <div className="datepicker-wrap mw-280">
                    <CustomDatePicker
                      value={startDate}
                      maxDate={endDate}
                      onChange={e => {
                        setStartDate(e);
                      }}
                      placeholderText="yyyy.mm.dd"
                    />
                    <span>-</span>
                    <CustomDatePicker
                      value={endDate}
                      minDate={startDate}
                      onChange={setEndDeta}
                      placeholderText="yyyy.mm.dd"
                    />
                  </div>
                  {['checkbox'].map(type => (
                    <div key={`inline-${type}`} className="ms-3">
                      <Form.Group className="flex-form">
                        <Form.Check
                          type={type}
                          id={`inline-${type}-1`}
                          name="group1"
                          checked={showYn === 'Y'}
                          onChange={e => {
                            setShowYn(e.target.checked ? 'Y' : 'N');
                          }}
                        />
                        <Form.Label>적용</Form.Label>
                      </Form.Group>
                    </div>
                  ))}
                </Form.Group>
              </div>
              {questions.map((v, i) => {
                return (
                  <SurveyEdit
                    key={`SurveyEdit_${i}`}
                    data={v}
                    index={i + 1}
                    setQuestions={e => {
                      setQuestions(e);
                    }}
                    isDelete={questions.length !== 1}
                  />
                );
              })}
              <div className="btn-area ">
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    setQuestions([...questions, defaultQuestions()])
                  }
                >
                  질문추가
                </Button>
              </div>
            </Form>
          </div>
          <div className="btn-area justify-btn-end">
            {/* <Button variant="outline-primary" onClick={setSurPopShow}>
              삭제
            </Button> */}
            <Button
              disabled={isDisable()}
              onClick={e => {
                checkSurvey(e);
              }}
            >
              등록
            </Button>
          </div>
        </article>
      </Container>
      <CustomModal
        title="설문조사 등록"
        id="surveyModal"
        show={surPopShow}
        onHide={() => setSurPopShow(false)}
      >
        <Modal.Body>
          <div>
            <p>설문 조사 등록 시 수정 및 삭제가 불가 합니다.</p>
          </div>
          <div className="btn-area">
            <Button
              onClick={e => {
                onSubmit(e);
              }}
            >
              등록
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>
    </main>
  );
});
