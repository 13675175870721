/* eslint-disable react/no-danger */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { FormText } from 'react-bootstrap';
import { Utils } from '@common';
import { css } from '@emotion/react';
import { Form, Field, useField } from 'formik';
import classNames from 'classnames';

function FormikInput({
  innerRef,
  isInvalid,
  isValid,
  noInvalid,
  limitByte,
  ...props
}) {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const handleFocus = () => setDidFocus(true);
  // const showFeedback =
  //   (!!didFocus && field.value.trim().length > 2) || meta.touched;
  const showFeedback =
    (!!didFocus && field.value.trim().length > 0) || meta.touched;
  // useEffect(() => {
  //   console.log('field', field);
  //   console.log('props', props);
  // }, []);

  return (
    <>
      <Field
        className={classNames('form-control', {
          'is-valid': isValid,
          'is-invalid': (showFeedback && meta.error) || isInvalid,
          'no-invalid': noInvalid,
        })}
        css={input}
        innerRef={innerRef}
        onFocus={handleFocus}
        {...props}
        {...field}
        onChange={e => {
          // 길이 제한 검사
          if (limitByte && !Utils.byteCheck(e.target.value, limitByte)) {
            return;
          }
          field.onChange(e);
        }}
      />
      {showFeedback && meta.error && (
        <small
          type="invalid"
          id={`${props.id}-feedback`}
          aria-live="polite"
          className="invalid-feedback"
        >
          {meta.error}
        </small>
      )}
    </>
  );
}

const input = css`
  &.no-invalid {
  }
  &.is-invalid {
    & + .invalid-feedback {
      order: 3;
    }
  }
`;

export default FormikInput;
