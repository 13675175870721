/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Form, Ratio, Badge } from 'react-bootstrap';
import { CustomModal, CustomSelect, CustomDatePicker } from '@components';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
import Utils from '../../common/Utils';
// swiper
import 'swiper/css';
import 'swiper/css/thumbs';

SwiperCore.use([Thumbs, FreeMode]);

function MainimagesShow({ mainhandleClose, prmDetail, ...rest }) {
  const [style, setStyle] = useState({});
  const [isFull, setIsFull] = useState(false);
  useEffect(() => {
    if (prmDetail) {
      const tempData = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${Utils.fileViewUrl(prmDetail.bannerPath)})`,
      };
      setIsFull(String(prmDetail.codeArea).includes('FULL'));
      setStyle(tempData);
    }
  }, []);
  return (
    <CustomModal title="메인이미지 미리보기" id="mainimagesModal" {...rest}>
      <Modal.Body>
        <section className="main-section" style={isFull ? { ...style } : {}}>
          <div
            className="main-box"
            style={
              isFull
                ? { opacity: 0 }
                : String(prmDetail.codeArea).includes('SLIDE')
                ? style
                : { border: 0, backgroundColor: '#aaa' }
            }
          />
          <div
            className="sec-mainbox"
            style={
              isFull
                ? { opacity: 0 }
                : String(prmDetail.codeArea).includes('BAR')
                ? style
                : { border: 0, backgroundColor: '#aaa' }
            }
          />
          <div className="grid-box" style={isFull ? { opacity: 0 } : {}}>
            <div
              className="ano-box"
              style={
                String(prmDetail.codeArea).includes('BOX')
                  ? style
                  : { border: 0, backgroundColor: '#aaa' }
              }
            />
            <div className="ano-box" />
            <div className="ano-box" />
          </div>
        </section>
        <div className="btn-area justify-content-end">
          <Button variant="outline-primary" onClick={mainhandleClose}>
            닫기
          </Button>
        </div>
      </Modal.Body>
    </CustomModal>
  );
}

export default MainimagesShow;
