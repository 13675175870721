/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Button,
  FormGroup,
  FormLabel,
  Image,
  InputGroup,
} from 'react-bootstrap';
import { FormikInput } from '@components';
import { RouterPath, Utils } from '@common';
import { images } from '@assets';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { authEmail, changePw, confirmEmail } from './Rest';
import RESULT_CODE from '../../common/ResultCode';
import CustomSwal from '../../components/CustomSwal';

export default React.memo(function FindPwd(props) {
  const [isSucceed, setIsSucceed] = useState(false);
  const [isFirstSend, setIsFirstSend] = useState(true);
  const [isMember, setIsMember] = useState(null);
  const [certId, setCertId] = useState();
  const verifyCodeRef = useRef(null);

  // 찾기 Formik
  const FindPwdFormik = useFormik({
    initialValues: {
      admId: '',
      email: '',
      verifyCode: '',
    },
    validationSchema: Yup.object().shape({
      admId: Yup.string().required(
        '아이디를 입력하지 않았습니다. 입력해 주세요.',
      ),
      email: Yup.string()
        .required('이메일을 입력하지 않았습니다. 입력하세요.')
        .email('이메일 형식에 맞게 입력하세요.'),

      verifyCode: Yup.number()
        .typeError('숫자 6자리를 입력하세요.')
        .required('인증코드를 입력하지 않았습니다. 입력하세요.')
        .test(
          'len',
          '숫자 6자리를 입력하세요.',
          val => val.toString().length === 6,
        ),
    }),
    onSubmit: async values => {
      const success = await confirmAuthEmail(values);
      if (success) {
        setIsSucceed(true);
      } else {
        CustomSwal.fire({
          html: '입력하신 인증코드가 올바르지 않습니다.<br />다시 확인해 주세요.',
          confirmButtonText: '확인',
        });
      }
    },
  });

  // 인증번호 요청
  const sendVerificationCode = async event => {
    const target = event.currentTarget;
    target.disabled = true;
    const { admId, email } = FindPwdFormik.values;
    const params = {
      email: Utils.encrypt(email),
      loginId: Utils.encrypt(admId),
      certGubun: 'FIND_PW',
    };
    const { data } = await authEmail(params);
    if (data.code === 200) {
      CustomSwal.fire({
        text: !isFirstSend
          ? '이메일로 발송된 인증코드를 입력해 주세요.'
          : '이메일로 재발송된 인증코드를 입력해 주세요.',
        confirmButtonText: '확인',
        returnFocus: false,
        finally: result => {
          setCertId(data.data.certId);
          setIsFirstSend(false);
          verifyCodeRef.current.focus();
        },
      });
    } else {
      CustomSwal.fire({
        text: RESULT_CODE[`CODE_${data.code}`],
        confirmButtonText: '확인',
        finally: result => {
          setIsMember(false);
        },
      });
    }
    target.disabled = false;
  };

  const confirmAuthEmail = async values => {
    const { verifyCode, email } = values;
    const params = {
      certId,
      email: Utils.encrypt(email),
      certCode: verifyCode,
    };
    const { data } = await confirmEmail(params);
    if (data.code === 200) {
      return true;
    }
    return false;
  };

  // -------------------------------------------------
  // -------------------------------------------------
  // 재설정 Formik
  const pwdRule = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
  const ResetPwdFormik = useFormik({
    initialValues: {
      newPwd: '',
      newPwdCheck: '',
    },
    validationSchema: Yup.object().shape({
      newPwd: Yup.string()
        .required('새 비밀번호를 입력하지 않았습니다. 입력하세요.')
        .test(
          'pwdRule',
          '영문 숫자 특수기호 조합 8자리 이상으로 입력하세요.',
          val => pwdRule.test(val),
        ),
      newPwdCheck: Yup.string()
        .required('새 비밀번호를 다시 입력하지 않았습니다. 입력하세요.')
        .test(
          'pwdCheckRule',
          '새 비밀번호와 일치하지 않습니다.',
          val => val === ResetPwdFormik.values.newPwd,
        ),
    }),
    onSubmit: values => {
      updatePwd(values);
      CustomSwal.fire({
        html: '입력한 비밀번호로 재설정되었습니다.<br />로그인 화면으로 이동합니다.',
        confirmButtonText: '확인',
        then: result => {
          if (result.isConfirmed) {
            props.history.push(RouterPath.signin);
          }
        },
      });
    },
  });

  const updatePwd = async values => {
    const { admId, email, verifyCode } = FindPwdFormik.values;
    const { newPwd, newPwdCheck } = values;
    const params = {
      loginId: Utils.encrypt(admId),
      email: Utils.encrypt(email),
      certId,
      certCode: verifyCode,
      newPassword: Utils.encrypt(newPwd),
      confirmPassword: Utils.encrypt(newPwdCheck),
    };
    const { data } = await changePw(params);
    if (data.code === 200) {
      CustomSwal.fire({
        html: '입력한 비밀번호로 재설정되었습니다.<br />로그인 화면으로 이동합니다.',
        confirmButtonText: '확인',
        finally: () => {
          props.history.push(RouterPath.signin);
        },
      });
    } else {
      CustomSwal.fire({
        text: RESULT_CODE[`CODE_${data.code}`],
        confirmButtonText: '확인',
      });
    }
  };

  return (
    <main id="find-pwd" className="auth">
      <Container fluid="sm">
        <Image
          src={images.logoDark}
          alt="logo"
          className="logo"
          onClick={() => props.history.push(RouterPath.signin)}
        />
        <div className="form-box">
          <div className="title-area">
            <h3>비밀번호 {!isSucceed ? '찾기' : '재설정'}</h3>
          </div>
          {!isSucceed ? (
            <FormikProvider value={FindPwdFormik}>
              <Form className="column">
                <FormGroup className="form-group mt-4">
                  <FormLabel>아이디</FormLabel>
                  <FormikInput
                    type="text"
                    id="admId"
                    name="admId"
                    disabled={!isFirstSend}
                    placeholder="아이디를 입력하세요."
                  />
                </FormGroup>
                <FormGroup className="form-group mt-4">
                  <FormLabel>이메일</FormLabel>
                  <InputGroup>
                    <FormikInput
                      type="email"
                      id="email"
                      name="email"
                      placeholder="이메일 주소를 입력하세요."
                      disabled={!isFirstSend}
                    />
                    <Button
                      variant="outline-primary"
                      disabled={
                        !FindPwdFormik.values.admId ||
                        FindPwdFormik.errors.admId ||
                        !FindPwdFormik.values.email ||
                        FindPwdFormik.errors.email
                      }
                      onClick={e => sendVerificationCode(e)}
                    >
                      인증코드 {isFirstSend ? '받기' : '재전송'}
                    </Button>
                  </InputGroup>
                </FormGroup>
                <FormGroup
                  className="form-group mt-2"
                  style={{ display: isFirstSend ? 'none' : 'block' }}
                >
                  <FormikInput
                    type="text"
                    id="verifyCode"
                    name="verifyCode"
                    placeholder="인증코드를 입력해주세요."
                    innerRef={verifyCodeRef}
                    disabled={isFirstSend}
                    limitByte="6"
                  />
                </FormGroup>
                <div className="btn-area mt-5 mb-0 column">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    className="w-100"
                    disabled={
                      !(
                        FindPwdFormik.values.verifyCode &&
                        !FindPwdFormik.errors.verifyCode
                      )
                    }
                  >
                    비밀번호 찾기
                  </Button>
                  <Button
                    variant="ts"
                    size="lg"
                    className="mt-2 w-100"
                    onClick={() => props.history.push(RouterPath.findid)}
                  >
                    아이디 찾기
                  </Button>
                </div>
              </Form>
            </FormikProvider>
          ) : (
            <FormikProvider value={ResetPwdFormik}>
              <Form className="column">
                <FormGroup className="form-group">
                  <FormLabel>새 비밀번호</FormLabel>
                  <FormikInput
                    type="password"
                    id="newPwd"
                    name="newPwd"
                    placeholder="영문, 숫자, 특수문자 조합으로 입력하세요."
                  />
                </FormGroup>
                <FormGroup className="form-group mt-2">
                  <FormikInput
                    type="password"
                    id="newPwdCheck"
                    name="newPwdCheck"
                    placeholder="새 비밀번호를 다시 입력하세요."
                  />
                </FormGroup>
                <div className="btn-area mt-5 mb-0 column">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    className="w-100"
                    disabled={ResetPwdFormik.errors.newPwdCheck}
                  >
                    완료
                  </Button>
                </div>
              </Form>
            </FormikProvider>
          )}
        </div>
      </Container>
    </main>
  );
});
