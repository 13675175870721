/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Modal,
  Form,
  Button,
  InputGroup,
  Table,
} from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {
  Header,
  CustomSelect,
  CustomModal,
  CustomDatePicker,
  CustomPagination,
  CustomSwal,
} from '@components';
import { Options, RouterPath, Utils } from '@common';
import Moment from 'react-moment';

import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { ko } from 'date-fns/locale';
import classNames from 'classnames';
import Calendar from './Calendar';
import {
  getMonthlySchedules,
  getProfiles,
  getPromoArea,
  getPromoAreaReq,
  getScheduleStatus,
} from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';

export default React.memo(function PrmSchedule(props) {
  // const allData = {
  //   label: '전체',
  //   value: null,
  // };

  const history = useHistory();

  // 슬라이드
  const [prmbanner, setPrmBanner] = useState(bannerOptions[0]);
  // 스케줄 상태
  const [scheduleStatus, setScheduleStatus] = useState({});
  const [scheduleList, setScheduleList] = useState([]);
  // const [eventList, setEventList] = useState([]);

  // 홍보 영역
  const [prmAreaReqList, setPrmAreaReqList] = useState([]); // 홍보 영역
  const [promoAreaId, setPromoAreaId] = useState(); // 선택한 홍보 영역 id
  const [profileId, setProfileId] = useState();
  const [profileList, setProfileList] = useState([]);

  const [yearMonth, setYearMonth] = useState(
    Utils.getYearMonthString(new Date()),
  );

  // 모달
  const [calenderPopShow, setCalenderPopShow] = useState(false);
  const [seeMoreList, setSeeMoreList] = useState([]);
  const [seeMoreDate, setSeeMoreDate] = useState();
  const handleClose = () => {
    setCalenderPopShow(false);
    setSeeMoreList([]);
    setSeeMoreDate('');
  };

  const handleDatesSet = eventInfo => {
    const calendarApi = eventInfo.view.calendar;
    setYearMonth(Utils.getYearMonthString(calendarApi.getDate()));
  };

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        setProfileList(tempProfileList);
        setProfileId(tempProfileList[0].value || null);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getScheduleStatusData = async () => {
    try {
      const params = { yearMonth, profileId };
      const { data } = await getScheduleStatus(params);
      if (data.code === 200) {
        setScheduleStatus(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaReqList = async () => {
    try {
      const { data } = await getPromoAreaReq();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        setPrmAreaReqList(tempList);
        setPromoAreaId(tempList[0].value);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const [listIndexList, setListIndexList] = useState([]);
  const getMonthlyScheduleList = async () => {
    try {
      const params = {
        yearMonth,
        profileId,
        promoAreaId,
      };
      const { data } = await getMonthlySchedules(params);
      if (data.code === 200) {
        setListIndexList(data.data.listIndex);
        setScheduleList(data.data.promotions);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Errors);
        }
      },
    });
  };

  const seeMoreEvent = (list, date) => {
    setSeeMoreList(list);
    const formattedDate = format(date, 'M월 d일 eeee', { locale: ko });
    setSeeMoreDate(formattedDate);
    setCalenderPopShow(true);
  };

  const getColor = codeType => {
    switch (codeType) {
      case 'LOCAL-GOV':
        return 'localgor';
      case 'STORE':
        return 'smbusiness';
      default:
        return '';
    }
  };

  useEffect(() => {
    getPromoAreaReqList();
    getProfileList();
  }, []);

  useEffect(() => {
    getScheduleStatusData();
  }, [yearMonth, profileId]);

  useEffect(() => {
    if (promoAreaId && yearMonth && profileId) {
      getMonthlyScheduleList();
    }
  }, [yearMonth, promoAreaId, profileId]);

  return (
    <main id="prm-schedule">
      <Header title="홍보 스케쥴 조회" />
      <Container className="contents">
        <div className="top-flex">
          <ul className="list-prmType">
            <li>
              <h6>ESG</h6>
              <strong className="text-success">
                {Utils.calculatePercentage(scheduleStatus.rateEsg, 1)}
              </strong>
            </li>
            <li>
              <h6>소상공인</h6>
              <strong className="text-warning">
                {Utils.calculatePercentage(scheduleStatus.rateBusiness, 1)}
              </strong>
            </li>
            <li>
              <h6>지자체</h6>
              <strong className="text-primary">
                {Utils.calculatePercentage(scheduleStatus.rateLocalGov, 1)}
              </strong>
            </li>
          </ul>
          <div className="grid-group" style={{ display: 'flex' }}>
            <CustomSelect
              options={profileList}
              value={profileList.find(v => v.value === profileId)}
              onChange={e => setProfileId(e.value)}
              className="mw-160"
            />
            <div style={{ marginLeft: 10 }}>
              <CustomSelect
                options={prmAreaReqList}
                value={prmAreaReqList.find(v => v.value === promoAreaId)}
                onChange={e => setPromoAreaId(e.value)}
                className="mw-160"
              />
            </div>
          </div>
        </div>
        <article>
          {/* <Button onClick={setCalenderPopShow}>모달</Button> */}
          <FullCalendar
            initialView="dayGridMonth"
            plugins={[dayGridPlugin]}
            dayCellContent={e => {
              return renderEventContent(
                scheduleList,
                listIndexList,
                e,
                seeMoreEvent,
              );
            }}
            datesSet={handleDatesSet}
            headerToolbar={header}
            titleFormat={title}
            locale="ko" // 한글
            eventColor="#FFFFFF" // background 컬러
            eventTimeFormat={DateFormatter}
            eventDisplay="list-item"
          />
        </article>
      </Container>
      <CustomModal
        title={seeMoreDate}
        id="calenderModal"
        show={calenderPopShow}
        noCloseBtn
        onHide={() => setCalenderPopShow(false)}
      >
        <Modal.Body>
          <div className="dl-box">
            {seeMoreList.map((v, i) => {
              return (
                <dl className="dl-flex" key={`see-more${i}`}>
                  <div>
                    <dt>{`${v.title} (${v.promoArea})`}</dt>
                    <dd>{`${Utils.timeFormatter(
                      v.startTime,
                    )} ~ ${Utils.timeFormatter(v.endTime)}`}</dd>
                  </div>
                  <div className="dl-baseline">
                    <dt className={classNames('base-dt', getColor(v.codeType))}>
                      {v.promoType}
                    </dt>
                  </div>
                </dl>
              );
            })}
          </div>
          <div className="btn-area justify-content-end">
            <Button variant="outline-primary" onClick={handleClose}>
              닫기
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>
    </main>
  );
});

// 사업단위
const bannerOptions = [
  { label: '슬라이드 배너', value: '0' },
  { label: '바 배너', value: '1' },
  { label: '박스 배너', value: '2' },
  { label: '통 배너', value: '3' },
];

function renderEventContent(
  scheduleList,
  listIndexList,
  eventInfo,
  seeMoreEvent,
) {
  const listIndex = listIndexList.find(v => {
    const indexDay = new Date(v.day).setHours(0, 0, 0, 0);
    const day = new Date(eventInfo.date).setHours(0, 0, 0, 0);
    return indexDay === day;
  });
  let remain = 0;
  let share = 0;
  if (listIndex) {
    if (1 - listIndex.index < 0) {
      remain = 0;
      share = 1;
    } else {
      remain = 1 - listIndex.index;
      share = listIndex.index;
    }
  }
  // console.log('listIndex :: ', listIndex);
  const eventList = scheduleList.filter(v =>
    Utils.isDateInRange(eventInfo.date, v.startDate, v.endDate),
  );

  const limit = 2;

  const getColor = codeType => {
    switch (codeType) {
      case 'ESG':
        return 'Green';
      case 'STORE':
        return 'Orange';
      default:
        return '';
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        minHeight: '100%',
        // backgroundColor: 'greenyellow',
      }}
    >
      <div className="grid-calender">
        <i className="title-color">
          {new Date(eventInfo.date).getDate().toString()}일
        </i>
        {eventList &&
          eventList.length > 0 &&
          eventList.slice(0, limit).map((v, i) => {
            return (
              <div className="caln-box" key={`event_${i}`}>
                {/** list 클래스안에 Green, Orange 클래스 추가시 색변경  */}
                <div className={classNames('list', getColor(v.codeType))}>
                  <b className="b-word">{`${v.title}`}</b>
                </div>
                <b className="me-sm-2 time-color">
                  {` (${v.promoArea}) ${Utils.timeFormatter(
                    v.startTime,
                  )} ~ ${Utils.timeFormatter(v.endTime)}`}
                </b>
              </div>
            );
          })}
        {/* {listIndex && (
        <p>{Utils.calculatePercentageInt(listIndex.index, 1, 0)}%</p>
      )} */}
        {eventList.length > limit && (
          <b
            className="seemore"
            onClick={() => {
              seeMoreEvent(eventList, eventInfo.date);
            }}
          >
            {eventList.length - limit}개 더 보기
          </b>
        )}
      </div>
      {listIndex && (
        <div
          style={{ display: 'flex', width: '100%', height: 21, marginTop: 13 }}
        >
          <div
            style={{
              flex: share,
              backgroundColor: '#FA4D56',
              fontSize: 14,
              fontWeight: '700',
              textAlign: 'end',
            }}
          />
          <div
            style={{
              flex: remain,
            }}
          />
          <div
            style={{
              position: 'absolute',
              right: 10,
              color: share === 1 ? '#FFFFFF' : '#525252',
              fontSize: 14,
              fontWeight: '700',
            }}
          >
            {Utils.calculatePercentageInt(listIndex.index, 1, 0)}%
          </div>
        </div>
      )}
    </div>
  );
}
const header = {
  start: 'prev',
  center: 'title',
  end: 'next',
};
const DateFormatter = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  meridiem: false,
};
const title = { year: 'numeric', month: 'long' };
