/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useCallback } from 'react';
import { Container, Form, Table } from 'react-bootstrap';
import { Header, CustomSelect, CustomSwal } from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { debounce, throttle } from 'lodash';
import { getAdmins, getPermissions, patchPermission } from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function Auth(props) {
  const [permissionList, setPermissionList] = useState([]);
  const [admType, setAdmType] = useState(Options.admTypeOptions[0]);

  const getPermissionList = async reqAdmType => {
    try {
      const { data } = await getPermissions(reqAdmType);
      if (data.code === 200) {
        setPermissionList(createHierarchicalList(data.data));
        console.log(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const createHierarchicalList = menuList => {
    // 매개변수로 받은 리스트를 기준으로 새로운 맵을 만듭니다.
    const menuMap = new Map();
    menuList
      .sort((a, b) => a.menuOrder - b.menuOrder)
      .forEach(menu => {
        menuMap.set(menu.admMenuId, { ...menu, children: [] });
      });

    // 계층적 구조를 위한 최종 메뉴 배열
    const hierarchicalList = [];

    // 각 메뉴를 해당하는 부모 메뉴의 children에 추가합니다.
    menuMap.forEach(menu => {
      if (menu.parentId === null) {
        hierarchicalList.push(menu);
      } else if (menuMap.has(menu.parentId)) {
        menuMap.get(menu.parentId).children.push(menu);
      }
    });

    return hierarchicalList;
  };

  const handleDebounced = useCallback(
    debounce(async (value, type) => {
      try {
        const params = {
          admMenuId: value.admMenuId,
          admType: value.admType,
        };
        switch (type) {
          case 'READ':
            params.readYn = value.readYn === 'Y' ? 'N' : 'Y';
            break;
          case 'CREATE':
            params.createYn = value.createYn === 'Y' ? 'N' : 'Y';
            break;
          default:
            break;
        }
        const { data } = await patchPermission(params);
        if (data.code === 200) {
          getPermissionList(value.admType);
        } else {
          throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
        }
      } catch (error) {
        Utils.handleError(error, customSwalGoError, false);
      }
    }, 200),
    [],
  );

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    if (admType) {
      getPermissionList(admType.value);
    }
  }, [admType]);

  return (
    <main id="sys-auth">
      <Header title="권한 관리" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>관리자타입</Form.Label>
              <CustomSelect
                options={Options.admTypeOptions}
                value={admType}
                onChange={e => {
                  setAdmType(e);
                }}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4">
          <Table className="table-hover text-start">
            <colgroup>
              <col width={80} />
              <col width={100} />
              <col width={50} />
              <col width={50} />
            </colgroup>
            <thead>
              <tr>
                <th>대메뉴</th>
                <th>소메뉴</th>
                <th>조회권한</th>
                <th>쓰기권한</th>
              </tr>
            </thead>
            <tbody>
              {permissionList.length > 0 &&
                permissionList.map((v, i) => {
                  const { children } = v;
                  const hasChildren = children && children.length > 0;
                  const rowSpan = hasChildren ? children.length : 1;

                  return (
                    <React.Fragment key={i}>
                      {hasChildren ? (
                        children.map((child, idx) => (
                          <tr key={`c_menu_${child.admMenuId}`}>
                            {idx === 0 && (
                              <td rowSpan={rowSpan}>{v.admMenuNm}</td>
                            )}
                            <td>{child.admMenuNm}</td>
                            <td>
                              <Form>
                                {['checkbox'].map(type => (
                                  <div key={`checkp-${type}`}>
                                    <Form.Check
                                      type={type}
                                      id={`checkp-${type}`}
                                      checked={child.readYn === 'Y'}
                                      onChange={e => {
                                        handleDebounced(child, 'READ');
                                      }}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </td>
                            <td>
                              <Form>
                                {['checkbox'].map(type => (
                                  <div key={`checkp-${type}`}>
                                    <Form.Check
                                      type={type}
                                      id={`checkp-${type}`}
                                      checked={child.createYn === 'Y'}
                                      onChange={e => {
                                        handleDebounced(child, 'CREATE');
                                      }}
                                    />
                                  </div>
                                ))}
                              </Form>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr key={`menu_${v.admMenuId}`}>
                          <td rowSpan={rowSpan}>{v.admMenuNm}</td>
                          <td />
                          <td>
                            <Form>
                              {['checkbox'].map(type => (
                                <div key={`checkp-${type}`}>
                                  <Form.Check
                                    type={type}
                                    id={`checkp-${type}`}
                                    checked={v.readYn === 'Y'}
                                    onChange={e => {
                                      handleDebounced(v, 'READ');
                                    }}
                                  />
                                </div>
                              ))}
                            </Form>
                          </td>
                          <td>
                            <Form>
                              {['checkbox'].map(type => (
                                <div key={`checkp-${type}`}>
                                  <Form.Check
                                    type={type}
                                    id={`checkp-${type}`}
                                    checked={v.createYn === 'Y'}
                                    onChange={e => {
                                      handleDebounced(v, 'CREATE');
                                    }}
                                  />
                                </div>
                              ))}
                            </Form>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
            </tbody>
          </Table>
        </article>
      </Container>
    </main>
  );
});
