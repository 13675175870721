/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
  FormGroup,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory, useParams } from 'react-router-dom';
import { Piechart } from './Piechart';
import { getSurvey } from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';
import ChartColorUtils from '../../../common/ChartColorUtils';

export default React.memo(function PrmResult(props) {
  const history = useHistory();
  const { surveyId } = useParams();
  const [surveyData, setSurveyData] = useState({});
  const [questions, setQuestions] = useState([]);

  const getSurveyData = async () => {
    try {
      const { data } = await getSurvey(surveyId);
      if (data.code === 200) {
        setSurveyData(data.data);
        setQuestions(data.data.questions);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    if (Number(surveyId) && Number.isInteger(Number(surveyId))) {
      getSurveyData();
    } else {
      CustomSwal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
        finally: () => {
          history.push(RouterPath.prmSurvey);
        },
      });
    }
  }, []);

  return (
    <main id="prm-sur-result">
      <Header title="설문조사 관리" />
      <Container className="contents">
        <article>
          <div className="result-title">
            <h6>설문조사 결과</h6>
          </div>
          <h5>{surveyData.surveyNm}</h5>
          <p>
            응답기간 :{' '}
            <Moment date={surveyData.surveyStartDate} format="YYYY.MM.DD" />
            ~
            <Moment date={surveyData.surveyEndDate} format="YYYY.MM.DD" />
          </p>
          {questions.map((v, i) => {
            const tempLabels = v.options.map(op => {
              return op.answer;
            });

            const colorList = ChartColorUtils.generateColorList(
              v.options.length,
            );
            const options = v.options.map((op, index) => {
              const tempOp = {
                ...op,
                chartData: Utils.calculatePercentageInt(
                  op.cnt,
                  v.responseCnt,
                  0,
                ),
                color: colorList[index],
              };
              return tempOp;
            });

            const chartData = {
              labels: tempLabels,
              datasets: [
                {
                  data: options.map(({ chartData }) => {
                    return chartData;
                  }),
                  backgroundColor: options.map(({ color }) => {
                    return color;
                  }),
                  hoverOffset: 4,
                },
              ],
            };
            return v.responseCnt !== 0 ? (
              <div className="result-box mt-3" key={`question_${i}`}>
                <h5>{v.question}</h5>
                <div className="grid-result mt-3">
                  <div className="pie-title">
                    <p>{`응답 ${v.responseCnt}개`}</p>
                    <Piechart chartData={chartData} />
                  </div>
                  <div>
                    <Form className="scroll-option">
                      {options.map(option => (
                        <Form.Group
                          className="form-group form-flex"
                          key={`option_${option.optionId}`}
                        >
                          <div className="oplabel-flex">
                            <span
                              className="option-boll"
                              style={{ backgroundColor: option.color }}
                            />
                            <Form.Label>{option.answer}</Form.Label>
                          </div>
                          <div className="opval-flex">
                            <p>{option.cnt}</p>
                            <p>{option.chartData}%</p>
                          </div>
                        </Form.Group>
                      ))}
                    </Form>
                  </div>
                </div>
              </div>
            ) : (
              <div className="noresult-box mt-3">
                <h5>{v.question}</h5>
                <div className="grid-noresult mt-3">
                  <div className="pie-title">
                    <p>응답 0개</p>
                  </div>
                  <div className="noresult-div mt-3">
                    <p>질문에 대한 응답이 없습니다.</p>
                  </div>
                </div>
              </div>
            );
          })}
        </article>
      </Container>
    </main>
  );
});
