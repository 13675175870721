import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { RouterPath } from '@common';
import { Aside } from '@components';
import SignIn from '@pages/Auth/SignIn';

function LayoutAuth({ component: Component, render, redirect, type, ...rest }) {
  // const isAuthorized = localStorage.getItem('auth') != null;
  const isAuthorized = true;

  return (
    <Container fluid className="app-container">
      <div className="app-contents">
        {type !== 'smbusiness' && type !== 'FullDashboard' && <Aside />}
        <Route {...rest} render={routeProps => <Component {...routeProps} />}>
          {!isAuthorized && <Redirect to={RouterPath.signin} />}
        </Route>
      </div>
    </Container>
  );
}
export default React.memo(LayoutAuth);
