/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Ratio,
  Row,
  Col,
  Form,
  Button,
  OverlayTrigger,
  Popover,
  Modal,
} from 'react-bootstrap';
import { Header, CustomTable, PrmScheduleModal } from '@components';
import { Utils, RouterPath, Options } from '@common';
import { images } from '@assets';
import Moment from 'react-moment';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';
import ReactDOMServer from 'react-dom/server';
import {
  getApListByProfile,
  getProfileDetailById,
  getPromotionAprvDetail,
  rejectPromoRequest,
} from '../../../common/crud';
import CustomSwal from '../../../components/CustomSwal';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';
import MainimagesShow from '../../../components/project/MainimagesShow';
import DetailimagesShow from '../../../components/project/DetailimagesShow';

export default React.memo(function PrmApprovalDetail(props) {
  const navermaps = useNavermaps();
  const naverMap = useRef();
  const history = useHistory();
  const { requestId } = useParams();

  // 메인 미리보기 모달
  const [mainimagesShow, setMainImagesShow] = useState(false);
  const mainhandleClose = () => setMainImagesShow(false);

  // 상세 미리보기 모달
  const [detailimagesShow, setDetailImagesShow] = useState(false);
  const detailhandleClose = () => setDetailImagesShow(false);

  const [prmDetail, setPrmDetail] = useState({});

  const gerPrmDetailData = async () => {
    try {
      const { data } = await getPromotionAprvDetail(requestId);
      if (data.code === 200) {
        setPrmDetail(data.data);
        if (data.data.profileId) {
          getProfileDetail(data.data.profileId);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const [profileDetail, setProfileDetail] = useState({});
  const [apList, setApList] = useState([]);
  const [selectUnit, setSelectUnit] = useState();

  const getProfileDetail = async profileId => {
    try {
      const { data } = await getProfileDetailById(profileId);
      if (data.code === 200) {
        setProfileDetail(data.data);
        getApListByProfileData(profileId);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getApListByProfileData = async profileId => {
    try {
      const { data } = await getApListByProfile(profileId);
      if (data.code === 200) {
        setApList(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  useEffect(() => {
    if (Number(requestId) && Number.isInteger(Number(requestId))) {
      gerPrmDetailData();
    } else {
      CustomSwal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
        finally: () => {
          history.goBack();
        },
      });
    }
  }, [requestId]);

  const rejectPrm = async event => {
    const target = event.currentTarget;
    try {
      const { data } = await rejectPromoRequest(requestId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: '정상적으로 반려되었습니다.',
          confirmButtonText: '확인',
          finally: () => {
            history.goBack();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
    target.disabled = false;
  };

  // 행정동 사업 정보 - 주소
  const apColumns = useMemo(
    () => [
      {
        Header: '설치주소',
        accessor: 'apAddress',
        width: '',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 위도',
        accessor: 'latitude',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 경도',
        accessor: 'longitude',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'AP 이름',
        accessor: 'apName',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: '원격 게이트웨이',
        accessor: 'apIp',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
      {
        Header: 'MAC',
        accessor: 'apMacAddress',
        width: '160px',
        align: 'left',
        Cell: td => {
          return <div className="text-start">{td.cell.value}</div>;
        },
      },
    ],
    [],
  );

  // 홍보 스케줄 관리
  const [prmScheduleModalShow, setPrmScheduleModalShow] = useState(false);
  const openScheduleModal = () => {
    setPrmScheduleModalShow(true);
  };
  const closePrmScheduleModal = () => {
    setPrmScheduleModalShow(false);
  };

  return (
    <main id="prm-approval-detail" className="prm-detail">
      <Header title="홍보 승인 관리" />
      <Container className="contents">
        <article>
          <div className="title-area pb-2">
            <h3>요청 정보</h3>
            <div>
              <strong className="me-3">
                {Utils.handleApprovalText(prmDetail.reqState)}
              </strong>
              {prmDetail.reqState === 'REQ' && (
                <>
                  <Button
                    variant="outline-primary"
                    className="ms-3"
                    onClick={e => {
                      rejectPrm(e);
                    }}
                  >
                    반려
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="ms-2"
                    onClick={openScheduleModal}
                  >
                    승인
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="dl-box align-start">
            <div className="grid-flex border-0">
              <dl>
                <dt>작성자</dt>
                <dd>{Utils.decrypt(prmDetail.reqAdmNm)}</dd>
              </dl>
              <dl className="border-0">
                <dt>요청일시</dt>
                <dd>
                  <Moment
                    date={prmDetail.createDate}
                    format="YYYY.MM.DD HH:mm:ss"
                  />
                </dd>
              </dl>
            </div>
            <div className="grid-flex border-bottom">
              <dl>
                <dt>홍보영역</dt>
                <dd>{prmDetail.promoArea}</dd>
              </dl>
              <dl>
                <dt>홍보타입</dt>
                <dd>{prmDetail.promoType}</dd>
              </dl>
            </div>
            <div className="grid-flex border-bottom">
              <dl>
                <dt>사업단위</dt>
                <dd>{prmDetail.profileNm}</dd>
              </dl>
              <dl className="border-0">
                <dt>과금적용 여부</dt>
                <dd>{prmDetail.chargeYn}</dd>
              </dl>
            </div>
            <div className="grid-flex border-bottom">
              <dl>
                <dt>희망 홍보일</dt>
                <dd>
                  {Utils.handleMomentStartEnd(
                    prmDetail.startDate,
                    prmDetail.endDate,
                  )}
                </dd>
              </dl>
              <dl>
                <dt>구분</dt>
                <dd>{prmDetail.promoCate}</dd>
              </dl>
            </div>
            <dl>
              <dt>타이틀</dt>
              <dd>{prmDetail.title}</dd>
            </dl>
            <dl>
              <dt>위치</dt>
              <dd>{prmDetail.address}</dd>
            </dl>
            <dl>
              <dt>연락처</dt>
              <dd>{prmDetail.tel}</dd>
            </dl>
            <dl>
              <dt>일정</dt>
              <dd>
                {Utils.handleMomentStartEnd(
                  prmDetail.openDate,
                  prmDetail.closeDate,
                )}
              </dd>
            </dl>
            <dl className="flex-line">
              <dt>링크</dt>
              <dd>{prmDetail.url}</dd>
              {['checkbox'].map(type => (
                <div key="linkcheck" style={{ width: '150px' }}>
                  <Form.Check
                    type={type}
                    id="linkcheck"
                    readOnly
                    checked={prmDetail.urlMoveYn === 'Y'}
                    label="링크로 이동"
                  />
                </div>
              ))}
            </dl>
            <dl>
              <dt>상세 설명</dt>
              <dd
                style={{
                  whiteSpace: 'pre-wrap',
                }}
              >
                {prmDetail.description}
              </dd>
            </dl>
            <dl>
              <dt>메인 이미지</dt>
              <dd>
                <ul className="att-list">
                  <li>
                    <div className="flex-start">
                      <Ratio aspectRatio="35x19">
                        <div
                          className="img-box"
                          style={{
                            backgroundImage: `url(${Utils.fileViewUrl(
                              prmDetail.bannerPath,
                            )})`,
                          }}
                        />
                      </Ratio>
                      <p>{prmDetail.bannerNm}</p>
                    </div>
                  </li>
                </ul>
              </dd>
              {prmDetail.bannerId && (
                <Button variant="outline-primary" onClick={setMainImagesShow}>
                  미리보기
                </Button>
              )}
            </dl>
            <dl>
              <dt>상세 이미지</dt>
              <dd>
                {prmDetail.images && prmDetail.images.length > 0 ? (
                  <ul className="att-list">
                    {prmDetail.images.map((item, idx) => {
                      return (
                        <li key={`att-${idx}`}>
                          <div className="flex-start">
                            <Ratio aspectRatio="35x19">
                              <div
                                className="img-box"
                                style={{
                                  backgroundImage: `url(${Utils.fileViewUrl(
                                    item.filePath,
                                  )})`,
                                }}
                              />
                            </Ratio>
                            <p>{item.fileNm}</p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="no-data justify-content-start text-start p-0">
                    -
                  </p>
                )}
              </dd>
              {prmDetail.images && prmDetail.images.length && (
                <Button variant="outline-primary" onClick={setDetailImagesShow}>
                  미리보기
                </Button>
              )}
            </dl>
          </div>
        </article>

        <article className="mt-4">
          <div className="title-area">
            <h3>행정동 사업 정보</h3>
          </div>
          <div className="dl-box align-start">
            <dl>
              <dt>사업단위</dt>
              <dd>{profileDetail.profileNm}</dd>
            </dl>
            <dl>
              <dt>행정구역</dt>
              <dd>{profileDetail.gunNm}</dd>
            </dl>
            <dl>
              <dt>사업위도</dt>
              <dd>{profileDetail.latitude}</dd>
            </dl>
            <dl>
              <dt>사업경도</dt>
              <dd>{profileDetail.longitude}</dd>
            </dl>
            <dl>
              <dt>주소</dt>
              <dd>{profileDetail.address}</dd>
            </dl>
            <dl>
              <dd>
                {profileDetail.profileId && (
                  <MapDiv
                    className="map-box"
                    fallback={null}
                    onClick={() => {
                      setSelectUnit();
                    }}
                  >
                    <NaverMap
                      ref={naverMap}
                      defaultCenter={
                        new navermaps.LatLng(
                          profileDetail.latitude,
                          profileDetail.longitude,
                        )
                      }
                      maxZoom={13}
                      minZoom={13}
                    >
                      {apList &&
                        apList.length > 0 &&
                        apList.map((v, i) => {
                          let content = ReactDOMServer.renderToString(
                            <Button variant="map-unit-state" />,
                          );
                          if (selectUnit && v.apMacId === selectUnit.apMacId) {
                            content = ReactDOMServer.renderToString(
                              <div
                                className="test"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Button
                                  variant="map-unit-state"
                                  style={{ marginRight: 8 }}
                                />
                                <div
                                  className="popover unit-overlay-state custom-unit-overlay-state"
                                  style={{ position: 'relative !important' }}
                                >
                                  <div>
                                    <h3 className="popover-header">{v.apNm}</h3>
                                  </div>
                                  <div className="popover-body">
                                    <div className="dl-box">
                                      <dl>
                                        <dt>AP위도</dt>
                                        <dd className="dl-box">{v.latitude}</dd>
                                      </dl>
                                      <dl>
                                        <dt>AP경도</dt>
                                        <dd className="dl-box">
                                          {v.longitude}
                                        </dd>
                                      </dl>
                                      <dl>
                                        <dt>상태</dt>
                                        <dd className="dl-box">
                                          {v.deviceStateYn === 'Y'
                                            ? '정상'
                                            : '비정상'}
                                        </dd>
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>,
                            );
                          }
                          const mkPosition = new navermaps.LatLng(
                            v.latitude,
                            v.longitude,
                          );
                          return (
                            <Marker
                              key={`marker-${i}`}
                              position={mkPosition}
                              icon={{
                                content,
                                origin: new navermaps.Point(0, 0),
                                anchor: new navermaps.Point(12, 37),
                              }}
                              onClick={() => {
                                setSelectUnit(v);
                              }}
                            />
                          );
                        })}
                    </NaverMap>
                  </MapDiv>
                )}
                <CustomTable
                  columns={apColumns}
                  data={apList}
                  className="sm mt-3"
                />
              </dd>
            </dl>
          </div>
        </article>
      </Container>

      {mainimagesShow && (
        <MainimagesShow
          show={mainimagesShow}
          prmDetail={prmDetail}
          noCloseBtn
          onHide={() => setMainImagesShow(false)}
          mainhandleClose={mainhandleClose}
        />
      )}

      {detailimagesShow && (
        <DetailimagesShow
          detailhandleClose={detailhandleClose}
          show={detailimagesShow}
          prmDetail={prmDetail}
          noCloseBtn
          onHide={() => setDetailImagesShow(false)}
        />
      )}

      {prmScheduleModalShow && (
        <PrmScheduleModal
          requestId={requestId}
          show={prmScheduleModalShow}
          profileId={profileDetail.profileId}
          // title={prmDetail.title}
          // startDate={prmDetail.startDate}
          // endDate={prmDetail.endDate}
          prmDetail={prmDetail}
          onHide={closePrmScheduleModal}
        />
      )}
    </main>
  );
});
