/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { deleteAp, getAps, getProfiles } from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import ApModal from './ApModal';

export default React.memo(function Apdevice(props) {
  const history = useHistory();

  const allData = {
    label: '전체',
    value: null,
  };

  const deviceStateOptions = [
    {
      label: '원할',
      value: 'Y',
    },
    {
      label: '비정상',
      value: 'N',
    },
  ];

  const size = 10;

  const page = (history.location.state && history.location.state.page) || 1;
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const deviceStateYn =
    (history.location.state && history.location.state.deviceStateYn) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || '';
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [profileList, setProfileList] = useState([]);
  const [searchProfile, setSearchProfile] = useState();
  const [searchDeviceState, setSearchDeviceState] = useState(allData.value);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [apTotalPageCnt, setApTotalPageCnt] = useState(0);
  const [apList, setApList] = useState([]);

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getApList = async () => {
    try {
      const params = {
        page,
        size,
        profileId,
        deviceStateYn,
        keyword,
      };
      const { data } = await getAps(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setApTotalPageCnt(data.data.totalPages);
          setApList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const deleteSwal = apMacId => {
    Swal.fire({
      title: '삭제 확인',
      text: '정말로 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예, 삭제합니다.',
      cancelButtonText: '취소',
    }).then(e => {
      if (e.isConfirmed) {
        removeAp(apMacId);
      }
    });
  };

  const removeAp = async apMacId => {
    try {
      const { data } = await deleteAp(apMacId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.apdevice);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      profileId: isSearch ? searchProfile.value : profileId,
      deviceStateYn: isSearch ? searchDeviceState : deviceStateYn,
    };

    props.history.push({
      pathname: RouterPath.apdevice,
      state,
    });
  };

  const setSearchState = () => {
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
    setSearchKeyword(keyword || '');
    setSearchDeviceState(deviceStateYn || allData.value);
  };

  useEffect(() => {
    getProfileList();
  }, []);

  useEffect(() => {
    setSearchState();
    getApList();
  }, [pageRefresh]);

  // 관리자 추가 모달
  const [sysAddPopShow, setAPAddPopShow] = useState(false);
  const [apModalType, setApModalType] = useState('ADD');
  const [selectApData, setSelectApData] = useState();
  const handleClose = () => {
    setAPAddPopShow(false);
    setApModalType('ADD');
    setSelectApData();
  };

  const modalHandleOpen = (type, admin) => {
    setAPAddPopShow(true);
    setApModalType(type);
    if (admin) {
      setSelectApData(admin);
    }
  };
  return (
    <main id="prm-coupon-list">
      <Header title="AP 기기 관리" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>통신상태</Form.Label>
              <div className="flex-start">
                {['radio'].map(type => (
                  <div key={`inline-${type}`} className="flex-start">
                    {[allData, ...deviceStateOptions].map((v, i) => {
                      return (
                        <Form.Check
                          key={`ds-${i}`}
                          type={type}
                          id={`device-state-${v.value}`}
                          name="group1"
                          value={v.value}
                          label={v.label}
                          onChange={() => {
                            setSearchDeviceState(v.value);
                          }}
                          checked={searchDeviceState === v.value}
                        />
                      );
                    })}
                  </div>
                ))}
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="장비명 검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      movePage(1, true);
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4 scrollbar">
          <div className="btn-area justify-content-end mt-0">
            <Button
              onClick={() => {
                modalHandleOpen('ADD');
              }}
            >
              AP 등록
            </Button>
          </div>
          <Table className="table-hover text-start">
            <colgroup>
              <col width={70} />
              <col width={80} />
              <col width={100} />
              <col width={80} />
              <col width={80} />
              <col width={140} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={140} />
              <col width={100} />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>통신상태</th>
                <th>AP장비명</th>
                <th>IP주소</th>
                <th>AP MAC주소</th>
                <th>설치주소</th>
                <th>AP위도</th>
                <th>AP경도</th>
                <th>등록일</th>
                <th>사업단위</th>
                <th className="text-sm-center">관리</th>
              </tr>
            </thead>

            <tbody>
              {apList.length > 0 ? (
                apList.map((v, i) => {
                  return (
                    <tr
                      key={`ap_${i}`}
                      onClick={() => {
                        modalHandleOpen('UPDATE', v);
                      }}
                    >
                      <td>{v.apMacId}</td>
                      <td>
                        {
                          deviceStateOptions.find(
                            ds => ds.value === v.deviceStateYn,
                          ).label
                        }
                      </td>
                      <td className="word-break">{v.apNm}</td>
                      <td className="word-break">{v.apIp}</td>
                      <td className="word-break">{v.apMacAddress}</td>
                      <td className="ellipsis">{v.apAddress}</td>
                      <td className="word-break">{v.latitude}</td>
                      <td className="word-break">{v.longitude}</td>
                      <td>
                        {v.date ? (
                          <Moment
                            date={v.createDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{v.profileNm}</td>
                      <td>
                        <div className="flex-td">
                          <Button
                            variant="outline-primary"
                            onClick={e => {
                              e.stopPropagation();
                              deleteSwal(v.apMacId);
                            }}
                          >
                            삭제
                          </Button>
                          <Button>수정</Button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={11}>
                    조회된 AP가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={apTotalPageCnt}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
      {sysAddPopShow &&
        (apModalType === 'UPDATE' ? selectApData : !selectApData) && (
          <ApModal
            show={sysAddPopShow}
            handleClose={handleClose}
            type={apModalType}
            apData={selectApData}
          />
        )}
    </main>
  );
});
const adminTableList = [
  {
    tno: '1',
    comm: '원활',
    apdev: 'XP-120-경북영양 1-1',
    ipaddress: '123.123.123',
    apmac: '1C:EC:72:52:42:1B',
    setaddress: '경상북도 영양군 영양읍 바들양지어쩌고저쩌고',
    apLi: '12.1234.45632',
    aphd: '12.1234.45632',
    date: new Date(),
    bsunit: '사업단위 표시',
    setting: (
      <>
        <Button variant="outline-primary">삭제</Button>
        <Button>수정</Button>
      </>
    ),
  },
  {
    tno: '2',
    comm: '원활',
    apdev: 'XP-120-경북영양 1-1',
    ipaddress: '123.123.123',
    apmac: '1C:EC:72:52:42:1B',
    setaddress: '경상북도 영양군 영양읍 바들양지어쩌고저쩌고',
    apLi: '12.1234.45632',
    aphd: '12.1234.45632',
    date: new Date(),
    bsunit: '사업단위 표시',
    setting: (
      <>
        <Button variant="outline-primary">삭제</Button>
        <Button>수정</Button>
      </>
    ),
  },
  {
    tno: '3',
    comm: '원활',
    apdev: 'XP-120-경북영양 1-1',
    ipaddress: '123.123.123',
    apmac: '1C:EC:72:52:42:1B',
    setaddress: '경상북도 영양군 영양읍 바들양지어쩌고저쩌고',
    apLi: '12.1234.45632',
    aphd: '12.1234.45632',
    date: new Date(),
    bsunit: '사업단위 표시',
    setting: (
      <>
        <Button variant="outline-primary">삭제</Button>
        <Button>수정</Button>
      </>
    ),
  },
];

const businessOptions = [
  { label: '사업단위1', value: '1' },
  { label: '사업단위2', value: '2' },
  { label: '사업단위3', value: '3' },
  { label: '사업단위4', value: '3' },
];
