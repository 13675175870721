/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Button, InputGroup, Table } from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomPagination,
  CustomSwal,
} from '@components';
import { Options, RouterPath, Utils } from '@common';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import {
  getProfiles,
  getPromoArea,
  getPromotionList,
  getPromoTypes,
  showOffPromo,
  showOnPromo,
} from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';

export default React.memo(function PrmConfirmList(props) {
  const history = useHistory();

  const prmConfirmPage =
    (history.location.state && history.location.state.page) || 1;
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const areaId =
    (history.location.state && history.location.state.areaId) || null;
  const typeId =
    (history.location.state && history.location.state.typeId) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || null;
  const promoState =
    (history.location.state && history.location.state.promoState) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const allData = {
    label: '전체',
    value: null,
  };

  const promoAllData = {
    label: '전체',
    value: 'ALL',
  };

  const allTime = {
    label: '종일',
    value: 'all',
    startTime: '00:00:00',
    endTime: '24:00:00',
  };

  // 검색
  const [searchBsnsUnit, setSearchBsnsUnit] = useState({});
  const [searchPrmArea, setSearchPrmArea] = useState({});
  const [searchPrmType, setSearchPrmType] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchPromoState, setSearchPromoState] = useState('ALL');

  // 테이블
  const [prmConfirmTotalPageCnt, setPrmConfirmTotalPageCnt] = useState();
  const [showPrmConfirmList, setShowPrmConfirmList] = useState([]);
  // const [prmConfirmPage, setPrmConfirmPage] = useState(1);
  const [prmConfirmSize, setPrmConfirmSize] = useState(10);

  const [profileList, setProfileList] = useState([]);
  const [prmAreaList, setPrmAreaList] = useState([]);
  const [promoTypeList, setPromoTypeList] = useState([]); // 홍보 유형 리스트

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchBsnsUnit(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchBsnsUnit(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaList = async () => {
    try {
      const { data } = await getPromoArea();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPrmAreaList(tempList);
        if (areaId) {
          setSearchPrmArea(tempList.find(v => v.value === areaId));
        } else {
          setSearchPrmArea(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoTypeList = async () => {
    try {
      const { data } = await getPromoTypes();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPromoTypeList(tempList);
        if (typeId) {
          setSearchPrmType(tempList.find(v => v.value === typeId));
        } else {
          setSearchPrmType(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const handlePromoStateText = state => {
    const promoStateObj = Options.promoStateOptions.find(
      v => v.value === state,
    );
    return (
      <span className={classNames('signal', promoStateObj.className)}>
        {promoStateObj.label}
      </span>
    );
  };

  useEffect(() => {
    getPromoTypeList();
    getPromoAreaList();
    getProfileList();
  }, []);

  const getPrmConfirmList = async () => {
    try {
      const params = {
        page: prmConfirmPage,
        size: prmConfirmSize,
        profileId,
        areaId,
        typeId,
        promoState: promoState === 'ALL' ? null : promoState,
        keyword,
      };
      const { data } = await getPromotionList(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && prmConfirmPage > 1) {
          movePage(1);
        } else {
          setPrmConfirmTotalPageCnt(data.data.totalPages);
          setShowPrmConfirmList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const setSearchState = () => {
    setSearchBsnsUnit(profileList.find(v => v.value === profileId) || allData);
    setSearchPrmArea(prmAreaList.find(v => v.value === areaId) || allData);
    setSearchPrmType(promoTypeList.find(v => v.value === typeId) || allData);
    setSearchPromoState(
      Options.promoStateOptions.find(v => v.value === promoState)?.value ||
        promoAllData.value,
    );
    setSearchKeyword(keyword || '');
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      profileId: isSearch ? searchBsnsUnit.value : profileId,
      areaId: isSearch ? searchPrmArea.value : areaId,
      typeId: isSearch ? searchPrmType.value : typeId,
      promoState: isSearch ? searchPromoState : promoState,
    };

    props.history.push({
      pathname: RouterPath.prmConfirmList,
      state,
    });
  };

  const showOnOffPromo = async (event, promoId, isShow) => {
    const target = event.currentTarget;
    try {
      const { data } = isShow
        ? await showOnPromo(promoId)
        : await showOffPromo(promoId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 ${isShow ? '공개' : '비공개'} 처리 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            getPrmConfirmList();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
    target.disabled = false;
  };

  useEffect(() => {
    setSearchState();
    getPrmConfirmList();
  }, [pageRefresh]);

  return (
    <main id="prm-confirm-list">
      <Header title="홍보 리스트" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <div className="grid-group" style={{ display: 'flex' }}>
              <Form.Group
                className="form-group"
                style={{ flex: 1, maxWidth: 800 }}
              >
                <Form.Label>사업단위</Form.Label>
                <CustomSelect
                  options={profileList}
                  value={searchBsnsUnit}
                  onChange={setSearchBsnsUnit}
                  className="mw-160"
                />
              </Form.Group>
              <Form.Group className="form-group mt-sm-0" style={{ flex: 1 }}>
                <Form.Label>홍보영역</Form.Label>
                <CustomSelect
                  options={prmAreaList}
                  value={searchPrmArea}
                  onChange={setSearchPrmArea}
                  className="mw-160"
                />
              </Form.Group>
            </div>
            <div className="grid-group" style={{ display: 'flex' }}>
              <Form.Group
                className="form-group"
                style={{ flex: 1, maxWidth: 800 }}
              >
                <Form.Label>광고상태</Form.Label>
                <div className="flex-start">
                  {[promoAllData, ...Options.promoStateOptions]
                    .filter(v => v.value !== 'NONE')
                    .map((item, i) => {
                      return (
                        <Form.Check
                          key={`promo-state-${i}`}
                          type="radio"
                          name="promo-state"
                          id={`promo-state-${item.value}`}
                          value={item.value}
                          label={item.label}
                          onChange={() => setSearchPromoState(item.value)}
                          checked={searchPromoState === item.value}
                        />
                      );
                    })}
                </div>
              </Form.Group>
              {/* <Form.Group className="form-group mt-sm-0" style={{ flex: 1 }}>
                <Form.Label>광고비 구분</Form.Label>
                <div className="flex-start">
                  {['radio'].map(type => (
                    <div key={`inline-${type}`} className="flex-start">
                      <Form.Check
                        type={type}
                        id={`inline-${type}-all`}
                        name="group1"
                        label="전체"
                      />
                      <Form.Check
                        type={type}
                        id={`inline-${type}-1`}
                        name="group1"
                        label="CPV"
                      />
                      <Form.Check
                        type={type}
                        id={`inline-${type}-2`}
                        name="group1"
                        label="CPC"
                      />
                      <Form.Check type={type} disabled label="CPA" />
                    </div>
                  ))}
                </div>
              </Form.Group> */}
              <Form.Group className="form-group mt-sm-0" style={{ flex: 1 }}>
                <Form.Label>홍보타입</Form.Label>
                <div className="flex-start">
                  {promoTypeList.map(item => {
                    return (
                      <Form.Check
                        key={`prmType-${item.value}`}
                        type="radio"
                        name="prmType"
                        id={`prmType-${item.value}`}
                        label={item.label}
                        onChange={() => setSearchPrmType(item)}
                        checked={searchPrmType.value === item.value}
                      />
                    );
                  })}
                </div>
              </Form.Group>
            </div>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="홍보 타이틀 검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      movePage(1, true);
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4 scrollbar">
          <Table className="table-hover text-start sm">
            <colgroup>
              <col width={60} />
              <col width={120} />
              <col width={100} />
              {/* <col width={80} /> */}
              <col width={120} />
              <col width={120} />
              <col width={80} />
              <col width={100} />
              <col width={140} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>홍보영역</th>
                <th>홍보타입</th>
                {/* <th>광고비 구분</th> */}
                <th>홍보일</th>
                <th>시간</th>
                <th>홍보구분</th>
                <th>홍보 타이틀</th>
                <th>사업단위</th>
                <th>작성자</th>
                <th>승인자</th>
                <th>광고상태</th>
              </tr>
            </thead>
            <tbody>
              {showPrmConfirmList.length > 0 ? (
                showPrmConfirmList.map((v, i) => {
                  return (
                    <tr
                      key={`prmConfirm-${i}`}
                      onClick={() =>
                        props.history.push({
                          pathname: `${RouterPath.prmConfirmDetail}/${v.promoId}`,
                        })
                      }
                    >
                      <td>{v.promoId}</td>
                      <td>{v.promoArea}</td>
                      <td>{v.promoType}</td>
                      {/* <td>
                        {[
                          { yn: v.cpcYn, label: 'CPC' },
                          { yn: v.cpvYn, label: 'CPV' },
                          { yn: v.cpaYn, label: 'CPA' },
                        ]
                          .filter(cp => cp.yn === 'Y')
                          .map(cp => cp.label)
                          .toString() || '-'}
                      </td> */}
                      <td>
                        {Utils.handleMomentStartEnd(v.startDate, v.endDate)}
                      </td>
                      <td>
                        {v.startTime === allTime.startTime &&
                        v.endTime === allTime.endTime
                          ? allTime.label
                          : `${Utils.timeFormatter(
                              v.startTime,
                            )} ~ ${Utils.timeFormatter(v.endTime)}`}
                      </td>
                      <td>{v.promoCate || '-'}</td>
                      <td>
                        <p className="ellipsis">{v.title || '-'}</p>
                      </td>
                      <td>
                        <p className="ellipsis">{v.profileNm}</p>
                      </td>
                      <td>
                        <p className="ellipsis">{Utils.decrypt(v.reqAdmNm)}</p>
                      </td>
                      <td>{Utils.decrypt(v.confirmAdmNm)}</td>
                      <td>
                        {handlePromoStateText(v.promoState)}
                        {/* 종료 , 홍보중 ,취소 end , addon, cancel 추가
                        <span className="signal end">종료</span>
                        <span className="signal addon">홍보중</span> 
                        */}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={11}>
                    홍보 데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={prmConfirmTotalPageCnt}
            pageNum={prmConfirmPage}
            setPage={e => movePage(e)}
          />
        </article>
      </Container>
    </main>
  );
});

// 사업단위
const bsnsUnitOptions = [
  { label: '전체', value: 'all' },
  { label: '영양산업단지', value: '1' },
  { label: '그들이설정하는', value: '2' },
  { label: '사업단위값들', value: '3' },
];

// 테이블 dummy
const prmConfirmList = [
  {
    no: 1,
    area: 'main',
    prmType: 'esg',
    startDate: new Date(),
    endDate: new Date(),
    dayOfWeek: '월 화 수 목',
    time: '종일',
    gubun: '축제',
    title:
      '영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너',
    bsnsUnit: '영양산업단지영양산업단지영양산업단지영양산업단지',

    writer: '아이디아이디아이디',
    approver: 'admin',
    displaystatus: <Form.Check type="switch" id="custom-switch" />,
  },
  {
    no: 2,
    area: 'box',
    prmType: 'esg',
    startDate: new Date(),
    endDate: new Date(),
    dayOfWeek: '월요일',
    time: '00:00 - 04:00',
    gubun: '관광지',
    title:
      '영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너',
    bsnsUnit: '영양산업단지영양산업단지영양산업단지영양산업단지',
    writer: '아이디아이디아이디',
    approver: 'admin',
    displaystatus: <Form.Check type="switch" id="custom-switch" />,
  },
  {
    no: 3,
    area: 'bar',
    prmType: 'smallBsns',
    startDate: new Date(),
    endDate: new Date(),
    dayOfWeek: '주말',
    time: '00:00 - 04:00',
    gubun: '관광지',
    title:
      '영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너',
    bsnsUnit: '영양산업단지영양산업단지영양산업단지영양산업단지',

    writer: '아이디아이디아이디',
    approver: 'admin',
    displaystatus: <Form.Check type="switch" id="custom-switch" />,
  },
  {
    no: 4,
    area: 'bar',
    prmType: 'smallBsns',
    startDate: new Date(),
    endDate: new Date(),
    dayOfWeek: '주중',
    time: '00:00 - 04:00',
    gubun: '관광지',
    title:
      '영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너',
    bsnsUnit: '영양산업단지영양산업단지영양산업단지영양산업단지',

    writer: '아이디아이디아이디',
    approver: 'admin',
    displaystatus: <Form.Check type="switch" id="custom-switch" />,
  },
];
