/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import ReactDOMServer from 'react-dom/server';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

// tooltip
const getOrCreateTooltip = (chart, tooltip) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    const content = ReactDOMServer.renderToString(
      <div
        style={{
          maxWidth: 100,
          backgroundColor: '#393939',
          borderRadius: 4,
          height: 22,
          padding: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span
          style={{
            width: 6,
            height: 6,
            display: 'flex',
            position: 'relative',
            backgroundColor: '#00974e',
            borderRadius: 10,
            marginRight: 4,
          }}
        />
        <span style={{ color: '#ffffff', fontSize: 10, fontWeight: '700' }}>
          %
        </span>
      </div>,
    );
    tooltipEl.innerHTML = content;
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = context => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const { isGap, backgroundColor } = tooltip.dataPoints[0].dataset;
  if (!isGap) {
    return;
  }
  const tooltipEl = getOrCreateTooltip(chart, tooltip);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  const spanElements = tooltipEl.getElementsByTagName('span');
  spanElements[0].style.backgroundColor = backgroundColor;
  const gap =
    tooltip.dataPoints[0].dataset.gaps[tooltip.dataPoints[0].dataIndex];
  spanElements[1].textContent = `${gap > 0 ? '+' : ''} ${gap}%`;

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
    },
    datalabels: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      max: 100,
      ticks: {
        callback(value) {
          return `${value}%`;
        },
      },
      // maxTicksLimit: 5, // specify the maximum number of ticks on the y-axis
      // ticks: {
      //   stepSize: 250, // specify the step size for ticks
      //   max: 1000, // specify the maximum value for ticks
      // },
    },
  },
};

const labels = [
  '1월',
  '2월',
  '3월',
  '4월',
  '5월',
  '6월',
  '7월',
  '8월',
  '9월',
  '10월',
  '11월',
  '12월',
];
export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: 'rgb(0, 151, 78)',
      borderWidth: 1,
      backgroundColor: 'rgb(0, 151, 78)',
    },
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: 'rgb(255, 133, 65)',
      borderWidth: 1,
      backgroundColor: 'rgb(255, 133, 65)',
    },
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: 'rgb(48, 83, 153)',
      borderWidth: 1,
      backgroundColor: 'rgb(48, 83, 153)',
    },
  ],
};

export const EsgScheduleLineChart = React.forwardRef(({ chartData }, ref) => {
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
        position: 'relative',
      }}
    >
      <Line
        ref={ref}
        options={options}
        data={chartData || data}
        style={{
          flex: 1,
          width: '100%',
        }}
      />
    </div>
  );
});
