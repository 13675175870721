/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { store } from '@common/redux/store';
import { authAction } from '@reducers/authSlice';
import {
  Container,
  Button,
  Ratio,
  Badge,
  InputGroup,
  Table,
  Modal,
  ToggleButtonGroup,
  ToggleButton,
  Form,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { css } from '@emotion/react';
import { RouterPath, Utils, Options } from '@common';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomTable,
  CustomPagination,
} from '@components';

import { images } from '@assets';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/thumbs';

// moment
import Moment from 'react-moment';
import 'moment/locale/ko'; // 한글 로케일 설정
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import DaumPostcode from 'react-daum-postcode';

import { useSelector } from 'react-redux';
import { useNavermaps } from 'react-naver-maps';
import { useHistory } from 'react-router-dom';
import {
  getPromoAreaReq,
  getPromoCategories,
  getPromotionByStore,
  getPromotionReqDetail,
  getPromoTypes,
  putPromoRequest,
  putPromoRequestByStore,
  savePromoRequest,
  savePromoRequestByStore,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import BusinessModal from '../Promotion/Request/BusinessModal';
import CustomSwal from '../../components/CustomSwal';
import MainimagesShowForSmBusiness from './MainimagesShowForSmBusiness';

SwiperCore.use([Thumbs, FreeMode]);

const header = css`
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--bs-white);
  border-bottom: 1px solid var(--bs-border);
  z-index: 10;
  transition: 0.25s ease-in-out;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    .header-logo {
      margin: 0;
    }
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      strong,
      a {
        display: block;
        margin-left: 1rem;
        color: var(--bs-gray);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    left: 60px;
    width: calc(100% - 60px);
  }
`;

export default React.memo(function SmBusiness(props) {
  const history = useHistory();

  const auth = useSelector(state => {
    return { ...state.auth, admType: Utils.decrypt(state.auth.admType) };
  });
  const navermaps = useNavermaps();
  // const requestId =
  //   (history.location.state && history.location.state.requestId) || null;

  // 기본 데이터
  const [requestId, setRequestId] = useState();
  const [reqState, setReqState] = useState();
  const [prmAreaReqList, setPrmAreaReqList] = useState([]); // 홍보 영역
  const [promoAreaId, setPromoAreaId] = useState(); // 선택한 홍보 영역 id
  const [codeArea, setCodeArea] = useState(); // 홍보 영역 code

  const [isArea, setIsArea] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const [promoCategoryList, setPromoCategoryList] = useState([]); // 홍보 구분 리스트
  const [promoCateId, setPromoCateId] = useState(); // 선택한 홍보 구분 id

  const [startDate, setStartDate] = useState(); // 홍보일
  const [endDate, setEndDate] = useState(); // 홍보일
  const [openDate, setOpenDate] = useState(); // 일정
  const [chargeYn, setChargeYn] = useState('Y'); // url 이동 유무
  const [closeDate, setCloseDate] = useState(); // 일정

  const [profileId, setProfileId] = useState();
  const [urlMoveYn, setUrlMoveYn] = useState('N'); // url 이동 유무
  const [url, setUrl] = useState(''); // url
  const [tel, setTel] = useState(''); // 전화 번호
  const [title, setTitle] = useState(''); // 제목
  const [description, setDescription] = useState(''); // 설명
  const [address, setAddress] = useState(''); // 주소
  const [coordinates, setCoordinates] = useState(); // 입력한 주소의 위경도

  // 메인 이미지
  const [banner, setBanner] = useState();
  const [isDelBanner, setIsDelBanner] = useState(false);
  const [deleteBannerYn, setDeleteBannerYn] = useState('N');

  // 서브 이미지
  const [subImageList, setSubImageList] = useState([]);
  const [newSubImageList, setNewSubImageList] = useState([]); // 추가된 이미지
  const [deleteSubImageList, setDeleteSubImageList] = useState([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const handleSubmit = e => {
    e.preventDefault();
  };
  // 모달
  const [businessShow, setBusinessShow] = useState(false);
  const handleClose = () => setBusinessShow(false);

  const [addressShow, setAddressShow] = useState(false);
  const handleAddressClose = () => setAddressShow(false);

  // file upload
  const mainImageInput = useRef();
  const onCickMainImageUpload = () => {
    mainImageInput.current.click();
  };

  const onChangeMainImageUpload = e => {
    try {
      if (!e.target.files[0]) {
        return;
      }
      const newFile = e.target.files[0];
      const res = Utils.handleFile(newFile);
      setBanner(res);
      mainImageInput.current.value = null;
      if (isDelBanner) {
        setDeleteBannerYn('Y');
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const deleteMainImage = () => {
    setBanner();
    if (isDelBanner) {
      setDeleteBannerYn('Y');
    }
  };

  const subImageInput = useRef();
  const onCickSubImageUpload = () => {
    subImageInput.current.click();
  };

  const onChangeSubImageUpload = e => {
    try {
      if (!e.target.files[0]) {
        return;
      }
      const newFile = e.target.files[0];
      const res = Utils.handleFile(newFile);
      setNewSubImageList([...newSubImageList, res]);
      subImageInput.current.value = null;
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const deleteNewSubImage = indexToRemove => {
    const tempList = newSubImageList.filter(
      (item, index) => index !== indexToRemove,
    );
    setNewSubImageList(tempList);
  };

  const deleteSubImage = fileId => {
    const tempList = subImageList.filter(
      (item, index) => item.fileId !== fileId,
    );
    setDeleteSubImageList([...deleteSubImageList, fileId]);
    setSubImageList(tempList);
  };

  const [businessOption, setBusinessOptions] = useState(); // 선택한 사업 단위

  const getPromoAreaReqList = async () => {
    try {
      const { data } = await getPromoAreaReq();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
            code: v.code,
          };
          return tempData;
        });
        setPrmAreaReqList(tempList);
        setPromoAreaId(tempList[0].value);
        setCodeArea(tempList[0].code);
        setIsArea(true);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoCategoryList = async () => {
    try {
      const { data } = await getPromoCategories();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        setPromoCategoryList(tempList);
        setPromoCateId(tempList[0].value);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const onPromoSave = async (event, isReq) => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      const orders = subImageList.map((v, i) => {
        const tempData = {
          fileId: v.fileId,
          orderNo: i + 1,
        };
        return tempData;
      });
      const params = {
        requestId,
        promoAreaId,
        promoCateId,
        title,
        description,
        startDate: Utils.dateToString(startDate),
        endDate: Utils.dateToString(endDate),
        tel,
        url,
        urlMoveYn,
        chargeYn,
        openDate: Utils.dateToString(openDate),
        closeDate: Utils.dateToString(closeDate),
        address,
        orders,
        profileId,
        deleteBannerYn,
        deleteImages: deleteSubImageList,
        ...coordinates,
      };
      const formData = new FormData();
      const json = JSON.stringify(params);
      const blob = new Blob([json], { type: 'application/json' });
      formData.append('dto', blob);
      if (banner && !banner.fileId) {
        formData.append('fileBanner', banner.file);
      }
      const files = newSubImageList.forEach((v, i) => {
        formData.append('files', v.file);
      });
      const { data } = isReq
        ? await putPromoRequestByStore(formData)
        : await savePromoRequestByStore(formData);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 ${
            isReq ? '요청' : requestId ? '수정' : '등록'
          } 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            // props.history.push(RouterPath.prmRequestList);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  // 드레그
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (event, index) => {
    setDraggedItem(subImageList[index]);
    setDraggedIndex(index);

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', event.currentTarget);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    if (draggedIndex === index) return;
  };

  const handleDrop = (event, index) => {
    if (!isReadOnly) {
      if (draggedIndex === null || draggedIndex === index) {
        setDraggedItem(null);
        setDraggedIndex(null);
        return;
      }

      const updatedBanners = [...subImageList];
      updatedBanners.splice(draggedIndex, 1);
      updatedBanners.splice(index, 0, draggedItem);
      setSubImageList(updatedBanners);
      setDraggedItem(null);
      setDraggedIndex(null);
    }
  };

  // 주소 검색 및 위경도
  const handleComplete = data => {
    setAddress(data.address);
    handleAddressClose();
    navermaps.Service.geocode(
      {
        query: data.address,
      },
      (status, response) => {
        if (status === navermaps.Service.Status.ERROR) {
          customSwalGoError('주소 검색 중 오류가 발생했습니다.', false);
          return;
        }
        const result = response.v2.addresses[0];

        setCoordinates({ latitude: result.y, longitude: result.x });
      },
    );
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const getPrmDetailData = async () => {
    try {
      const { data } = await getPromotionByStore();
      if (data.code === 200) {
        if (data.data) {
          setProfileId(data.data.profileId);
          setPromoCateId(data.data.promoCateId);
          setStartDate(Utils.stringToDate(data.data.startDate));
          setEndDate(Utils.stringToDate(data.data.endDate));
          setOpenDate(Utils.stringToDate(data.data.openDate));
          setCloseDate(Utils.stringToDate(data.data.closeDate));
          if (data.data.profileId) {
            const tempProfile = {
              value: data.data.profileId,
              label: data.data.profileNm,
            };
            setBusinessOptions(tempProfile);
          }

          setUrlMoveYn(data.data.urlMoveYn);
          setUrl(data.data.url);
          setTel(data.data.tel);
          setTitle(data.data.title);
          setAddress(data.data.address);
          setCodeArea(data.data.codeArea);
          setDescription(data.data.description);
          const tempCoordinates = {
            latitude: data.data.latitude,
            longitude: data.data.longitude,
          };

          setCoordinates(tempCoordinates);

          setIsDelBanner(true);
          if (data.data.promoId) {
            const tempArea = prmAreaReqList.find(v =>
              String(v.code).includes(String(data.data.codeArea.split('-')[0])),
            );
            setPromoAreaId(tempArea ? tempArea.value : prmAreaReqList[0].value);
            setIsReadOnly(true);
            setReqState('CON');
            settingFiles(data.data);
          } else {
            settingFiles(data.data, data.data.reqState === 'REJ');
            switch (data.data.reqState) {
              case 'REQ':
              case 'CON':
                setIsReadOnly(true);
                break;
              case 'TEMP':
                setRequestId(data.data.requestId);
                setIsReadOnly(false);
                break;
              default:
                setIsReadOnly(false);
                break;
            }
            setReqState(data.data.reqState);
            setPromoAreaId(data.data.promoAreaId);
          }
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const convertImageUrlToFile = async (imageUrl, fileName = 'image') => {
    try {
      const response = await fetch(Utils.fileViewUrl(imageUrl));
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const fileExtension = fileName.split('.').pop();
      const mimeType = `image/${fileExtension}`;
      const file = new File([blob], fileName, { type: mimeType });
      return file;
    } catch (error) {
      // console.error('There was a problem with the fetch operation: ', error);
      throw new CustomException(
        '이미지를 가져오는데 실패했습니다. 다시 이미지를 선택 하세요.',
      );
    }
  };

  const settingFiles = async (resData, isBlob) => {
    try {
      if (isBlob) {
        const bannerBlob = await convertImageUrlToFile(
          resData.bannerPath,
          resData.bannerNm,
        );
        const tempBanner = Utils.handleFile(bannerBlob);

        const tempSubImageList = resData.images.map(async (v, i) => {
          const subImgBlob = await convertImageUrlToFile(v.filePath);
          const tempSubData = Utils.handleFile(subImgBlob);
          return tempSubData;
        });
        setBanner(resData.bannerPath ? tempBanner : null);
        // setSubImageList(tempSubImageList);
      } else {
        const tempBanner = {
          fileId: resData.bannerId,
          file: { name: resData.bannerNm },
          preview: Utils.fileViewUrl(resData.bannerPath),
        };

        const tempSubImageList = resData.images.map((v, i) => {
          const tempSubData = {
            fileId: v.fileId,
            file: { name: v.fileNm },
            preview: Utils.fileViewUrl(v.filePath),
          };
          return tempSubData;
        });
        setBanner(resData.bannerPath ? tempBanner : null);
        setSubImageList(tempSubImageList);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  useEffect(() => {
    getPromoAreaReqList();
    getPromoCategoryList();
  }, []);

  useEffect(() => {
    if (isArea) {
      getPrmDetailData();
    }
  }, [isArea]);

  return (
    <main id="smbusiness-register">
      <header css={header}>
        <Container>
          <div className="header-logo">
            <img src={images.logoSm} />
          </div>
          <div>
            <strong>
              <Moment
                fromNow
                interval={1}
                local="ko"
                format="YYYY.MM.DD(ddd) HH:mm:ss"
              />
            </strong>
            {/* <p css={p}>{auth.admNm}</p> */}
            <Button
              variant="outline-primary"
              className="ms-3"
              onClick={() => {
                store.dispatch(authAction.removeAuth());
              }}
            >
              로그아웃
            </Button>
          </div>
        </Container>
      </header>
      <Container className="contents container-m15">
        <article className="scrollbar">
          <div className="title-area pb-xl-2">
            <h3>홍보 신규 등록</h3>
            <strong className="me-3">
              {/* <span className="text-info">승인요청</span> */}
              <strong className="me-3">
                {Utils.handleApprovalText(reqState)}
              </strong>
              {/* <span className="text-danger">승인반려</span> */}
            </strong>
          </div>
          <h6>미리보기</h6>
          {/* 메인미리보기 */}
          <div className="grid-box">
            <MainimagesShowForSmBusiness codeArea={codeArea} banner={banner} />
            {/* 프로모션미리보기 */}
            <div className="prm-request-detail">
              <div className="prm-detail-header">
                <Button variant="icon">
                  <i className="material-icons">arrow_back</i>
                </Button>
                <h3>{title}</h3>
                <Button variant="icon">
                  <i className="material-icons">share</i>
                </Button>
              </div>
              <section>
                <Ratio aspectRatio="35x19" className="img-top">
                  <div
                    className="img-box"
                    style={{
                      backgroundColor: 'var(--bs-gray-100)',
                      backgroundImage: thumbsSwiper
                        ? `url(${thumbsSwiper.preview})`
                        : [...subImageList, ...newSubImageList][0]
                        ? `url(${
                            [...subImageList, ...newSubImageList][0].preview
                          })`
                        : '',
                    }}
                  />
                </Ratio>
                <Swiper
                  spaceBetween={4}
                  slidesPerView={4}
                  className="swiper-box pt-1"
                >
                  {subImageList.length > 0 &&
                    subImageList.map((file, idx) => {
                      return (
                        <SwiperSlide
                          key={`sub-thumb-${idx}`}
                          onClick={e => {
                            setThumbsSwiper(file);
                          }}
                        >
                          <Ratio aspectRatio="35x19">
                            <div
                              className="img-box"
                              style={{
                                backgroundImage: `url(${file.preview})`,
                              }}
                            />
                          </Ratio>
                        </SwiperSlide>
                      );
                    })}
                  {newSubImageList.length > 0 &&
                    newSubImageList.map((file, idx) => {
                      return (
                        <SwiperSlide
                          key={`new-sub-thumb-${idx}`}
                          onClick={e => {
                            setThumbsSwiper(file);
                          }}
                        >
                          <Ratio aspectRatio="35x19">
                            <div
                              className="img-box"
                              style={{
                                backgroundImage: `url(${file.preview})`,
                              }}
                            />
                          </Ratio>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </section>
              <section className="info">
                <div className="title">
                  <div className="flex-between">
                    <h6>INFO</h6>
                    <Badge>
                      {promoCateId &&
                        promoCategoryList.find(v => v.value === promoCateId)
                          .label}
                    </Badge>
                  </div>
                </div>
                <div className="dl-box align-start">
                  <dl>
                    <dt>
                      <i className="material-icons">place</i>
                    </dt>
                    <dd>{address}</dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">call</i>
                    </dt>
                    <dd>{tel}</dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">calendar_month</i>
                    </dt>
                    <dd>
                      {openDate && closeDate && (
                        <>
                          <Moment date={openDate} format="YYYY-MM-DD" />
                          <span> ~ </span>
                          <Moment date={closeDate} format="YYYY-MM-DD" />
                        </>
                      )}
                      {openDate && !closeDate && (
                        <>
                          <Moment date={openDate} format="YYYY-MM-DD" />{' '}
                          <span> ~ </span>
                        </>
                      )}
                      {!openDate && closeDate && (
                        <>
                          <span> ~ </span>
                          <Moment date={closeDate} format="YYYY-MM-DD" />
                        </>
                      )}
                    </dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">home</i>
                    </dt>
                    <dd>{url}</dd>
                  </dl>
                  <dl>
                    <dt>
                      <i className="material-icons">description</i>
                    </dt>
                    <dd
                      className="scrollbody"
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      {description}
                    </dd>
                  </dl>
                </div>
              </section>
            </div>
            <div>
              <Form className="prm-request-input" onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                  <Form.Label className="required">홍보영역</Form.Label>
                  <CustomSelect
                    options={prmAreaReqList}
                    value={prmAreaReqList.find(v => v.value === promoAreaId)}
                    onChange={e => {
                      setPromoAreaId(e.value);
                      setCodeArea(e.code);
                    }}
                    className="mw-160"
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="required">사업단위</Form.Label>
                  {businessOption && businessOption.label ? (
                    <p>{businessOption.label}</p>
                  ) : (
                    <p />
                  )}
                  <div>
                    <Button
                      variant="outline-primary"
                      onClick={() => setBusinessShow(true)}
                      disabled={isReadOnly}
                    >
                      선택
                    </Button>
                  </div>
                  {['checkbox'].map(type => (
                    <div
                      key="linkcheck"
                      style={{ width: '150px', marginLeft: 10 }}
                    >
                      <Form.Check
                        type={type}
                        id="linkcheck-charge"
                        label="과금적용 여부"
                        checked={chargeYn === 'Y'}
                        onChange={e => {
                          if (e.target.checked) {
                            setChargeYn('Y');
                          } else {
                            setChargeYn('N');
                          }
                        }}
                      />
                    </div>
                  ))}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="required">홍보일</Form.Label>
                  <div className="datepicker-wrap mw-100">
                    <CustomDatePicker
                      value={startDate}
                      // minDate={Utils.getTomorrow()}
                      onChange={setStartDate}
                      disabled={isReadOnly}
                      maxDate={endDate}
                      placeholderText="yyyy.mm.dd"
                      // dateFormat="yyyy-mm-dd"
                      className="mw-100"
                    />
                    <span>-</span>
                    <CustomDatePicker
                      value={endDate}
                      minDate={startDate}
                      disabled={isReadOnly}
                      onChange={setEndDate}
                      placeholderText="yyyy.mm.dd"
                      // dateFormat="yyyy-mm-dd"
                      className="mw-100"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="required">구분</Form.Label>
                  <CustomSelect
                    options={promoCategoryList}
                    value={promoCategoryList.find(v => v.value === promoCateId)}
                    onChange={e => setPromoCateId(e.value)}
                    className="mw-160"
                    isDisabled={isReadOnly}
                  />
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="required">타이틀</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={title || ''}
                      maxLength={20}
                      disabled={isReadOnly}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>위치</Form.Label>
                  <div className="flex-gap" style={{ alignItems: 'center' }}>
                    <InputGroup>
                      <Form.Control
                        value={address || ''}
                        disabled={!!address}
                        className="position-rel"
                      />
                      {address && (
                        <Button
                          variant="icon"
                          className="icon-btn"
                          onClick={() => {
                            setAddress();
                            setCoordinates();
                          }}
                        >
                          <i className="material-icons cancel-position">
                            cancel
                          </i>
                        </Button>
                      )}
                    </InputGroup>

                    {/** 주소 검색창으로 연결 */}
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        setAddressShow(true);
                      }}
                      disabled={isReadOnly}
                    >
                      주소검색
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="required">연락처</Form.Label>
                  <InputGroup>
                    <Form.Control
                      value={tel}
                      // type="Number"
                      disabled={isReadOnly}
                      onChange={e => setTel(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>일정</Form.Label>
                  <div className="datepicker-wrap mw-100">
                    <CustomDatePicker
                      value={openDate}
                      // minDate={Utils.getTomorrow()}
                      maxDate={closeDate}
                      onChange={e => {
                        setOpenDate(e);
                      }}
                      disabled={isReadOnly}
                      placeholderText="yyyy.mm.dd"
                    />
                    <span>-</span>
                    <CustomDatePicker
                      value={closeDate}
                      minDate={openDate}
                      onChange={setCloseDate}
                      disabled={isReadOnly}
                      placeholderText="yyyy.mm.dd"
                    />
                  </div>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>링크</Form.Label>
                  <div className="flex-gap">
                    <InputGroup>
                      <Form.Control
                        disabled={isReadOnly}
                        value={url || ''}
                        onChange={e => setUrl(e.target.value)}
                      />
                    </InputGroup>
                    {['checkbox'].map(type => (
                      <div key="linkcheck" style={{ width: '150px' }}>
                        <Form.Check
                          type={type}
                          id="linkcheck"
                          label="링크로 이동"
                          checked={urlMoveYn === 'Y'}
                          onChange={e => {
                            if (!isReadOnly) {
                              if (e.target.checked) {
                                setUrlMoveYn('Y');
                              } else {
                                setUrlMoveYn('N');
                              }
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Form.Group>
                <Form.Group className="form-group align-start">
                  <Form.Label className="required">상세설명</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    maxLength={500}
                    disabled={isReadOnly}
                    value={description || ''}
                    onChange={e => setDescription(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="form-group align-start">
                  <Form.Label className="required">메인 이미지</Form.Label>
                  <div>
                    <div className="btn-area justify-content-start mt-0">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        disabled={isReadOnly}
                        onClick={onCickMainImageUpload}
                      >
                        이미지 첨부
                      </Button>
                      <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Control
                          type="file"
                          size="lg"
                          style={{ display: 'none' }}
                          ref={mainImageInput}
                          onChange={e => {
                            onChangeMainImageUpload(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                    {banner && (
                      <ul className="attatch-list">
                        <li>
                          <div className="flex-start">
                            <Ratio aspectRatio="35x19">
                              <div
                                className="img-box"
                                style={{
                                  backgroundImage: `url(${banner.preview})`,
                                }}
                              />
                            </Ratio>
                            <p>{banner.file.name}</p>
                          </div>
                          {!isReadOnly && (
                            <Button
                              variant="icon"
                              onClick={() => {
                                deleteMainImage();
                              }}
                            >
                              <i className="material-icons">cancel</i>
                            </Button>
                          )}
                        </li>
                      </ul>
                    )}
                  </div>
                </Form.Group>
                {/** 상세이미지 */}
                <Form.Group className="form-group align-start border-0">
                  <Form.Label className="required">상세 이미지</Form.Label>
                  <div>
                    <div className="btn-area justify-content-start mt-0">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={onCickSubImageUpload}
                        disabled={
                          [...subImageList, ...newSubImageList].length >= 4 ||
                          isReadOnly
                        }
                      >
                        이미지 첨부
                      </Button>
                      <Form.Group controlId="formFileLg" className="mb-3">
                        <Form.Control
                          type="file"
                          accept="image/jpg ,image/png , image/jpeg"
                          size="lg"
                          style={{ display: 'none' }}
                          ref={subImageInput}
                          onChange={e => {
                            onChangeSubImageUpload(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <ul className="attatch-list">
                      {subImageList.length > 0 &&
                        subImageList.map((v, index) => {
                          return (
                            <li
                              key={`sub-image${index}`}
                              draggable
                              onDragStart={event =>
                                handleDragStart(event, index)
                              }
                              onDragOver={event => handleDragOver(event, index)}
                              onDrop={event => handleDrop(event, index)}
                            >
                              <Button variant="icon">
                                <i className="material-icons">drag_handle</i>
                              </Button>
                              <div className="flex-start">
                                <Ratio aspectRatio="35x19">
                                  <div
                                    className="img-box"
                                    style={{
                                      backgroundImage: `url(${v.preview})`,
                                    }}
                                  />
                                </Ratio>
                                <p>{v.file.name}</p>
                              </div>
                              {!isReadOnly && (
                                <Button
                                  variant="icon"
                                  onClick={() => deleteSubImage(v.fileId)}
                                >
                                  <i className="material-icons">cancel</i>
                                </Button>
                              )}
                            </li>
                          );
                        })}
                      {newSubImageList.length > 0 &&
                        newSubImageList.map((v, index) => {
                          return (
                            <li key={`new-sub-image${index}`}>
                              <div className="flex-start">
                                <Ratio aspectRatio="35x19">
                                  <div
                                    className="img-box"
                                    style={{
                                      backgroundImage: `url(${v.preview})`,
                                    }}
                                  />
                                </Ratio>
                                <p>{v.file.name}</p>
                              </div>
                              <Button
                                variant="icon"
                                onClick={() => deleteNewSubImage(index)}
                              >
                                <i className="material-icons">cancel</i>
                              </Button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </Form.Group>
                {!isReadOnly && (
                  <div className="btn-area justify-content-end">
                    {/* <Button variant="outline-primary" size="sm">
                    취소
                  </Button> */}
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={e => {
                        onPromoSave(e);
                      }}
                    >
                      임시저장
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      type="submit"
                      disabled={
                        !!(
                          title === '' ||
                          tel === '' ||
                          description === '' ||
                          startDate === '' ||
                          !profileId ||
                          endDate === '' ||
                          !banner ||
                          (!subImageList && !newSubImageList) ||
                          subImageList.length + newSubImageList.length === 0
                        )
                      }
                      onClick={e =>
                        // props.history.push(RouterPath.prmRequestList)
                        onPromoSave(e, true)
                      }
                    >
                      승인요청
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
        </article>
      </Container>
      {businessShow && (
        <BusinessModal
          businessShow={businessShow}
          handleClose={handleClose}
          businessOption={businessOption}
          setBusinessOptions={e => {
            setBusinessOptions(e);
            setProfileId(e.value);
          }}
        />
      )}
      <CustomModal
        title="주소 검색"
        id="addrees-modal"
        size="lg"
        show={addressShow}
        onHide={() => handleAddressClose()}
        noCloseBtn
      >
        <Modal.Body>
          <DaumPostcode onComplete={handleComplete} />
        </Modal.Body>
      </CustomModal>
    </main>
  );
});
