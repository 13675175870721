/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      display: false, // 라인 차트에서 데이터 라벨을 숨깁니다.
    },
  },
};

const labels = ['일', '월', '화', '수', '목', '금', '토'];

export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: 'rgba(170, 206, 243, 0.5)',
    },
  ],
};

export default React.memo(function BarChart({ chartData }) {
  return <Bar options={options} data={chartData || data} />;
});
