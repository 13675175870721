import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { CustomModal, CustomSwal, CustomSelect } from '@components';
import Moment from 'react-moment';
import { RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useMap,
  useNavermaps,
} from 'react-naver-maps';
import ReactDOMServer from 'react-dom/server';
import Swal from 'sweetalert2';
import {
  deleteAdminStore,
  deleteAp,
  getProfileDetailById,
  getProfiles,
  patchAdminStore,
  patchAp,
  putAdminStore,
  putAp,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function ApModal({
  show,
  handleClose,
  type,
  apData,
}) {
  const navermaps = useNavermaps();
  const naverMap = useRef();
  const history = useHistory();

  const [profileList, setProfileList] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [profileDetail, setProfileDetail] = useState({});
  const [moveCenter, setMoveCenter] = useState();
  const [apMacId, setApMacId] = useState('');
  const [apmacAddress, setApmacAddress] = useState('');
  const [apAddress, setApAddress] = useState('');
  const [apNm, setApNm] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [apIp, setApIp] = useState('');
  const deviceStateYn = 'Y';
  const exceptYn = 'N';

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempList = data.data.map(v => {
          const tempProfile = {
            value: v.profileId,
            label: v.profileNm,
          };
          return tempProfile;
        });
        setProfileList(tempList);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getProfileDetail = async profileId => {
    try {
      const { data } = await getProfileDetailById(profileId);
      if (data.code === 200) {
        setProfileDetail(data.data);
        const center = new navermaps.LatLng(
          data.data.latitude,
          data.data.longitude,
        );
        setMoveCenter(center);
        naverMap.current.refresh();
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const onSubmit = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!selectedProfile) {
        throw new CustomException('사업단위를 선택해 주세요.');
      }
      if (!apNm) {
        throw new CustomException('AP명을 입력해 주세요.');
      }

      //   if (!apIp) {
      //     throw new CustomException('IP 주소를 입력해 주세요.');
      //   }

      if (!apmacAddress) {
        throw new CustomException('AP MAC 주소를 입력해 주세요.');
      }

      if (!latitude) {
        throw new CustomException('AP 위도를 입력해 주세요.');
      }
      if (!longitude) {
        throw new CustomException('AP 경도를 입력해 주세요.');
      }

      const params = {
        deviceStateYn,
        exceptYn,
        profileId: selectedProfile.value,
        apAddress,
        apIp,
        apNm,
        latitude,
        longitude,
        apmacAddress,
      };

      if (apMacId) {
        params.apMacId = apMacId;
      }
      const { data } = apMacId ? await patchAp(params) : await putAp(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 ${apMacId ? '수정' : '등록'} 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.apdevice);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const deleteSwal = () => {
    Swal.fire({
      title: '삭제 확인',
      text: '정말로 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예, 삭제합니다.',
      cancelButtonText: '취소',
    }).then(e => {
      if (e.isConfirmed) {
        removeAp();
      }
    });
  };

  const removeAp = async () => {
    try {
      const { data } = await deleteAp(apMacId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.apdevice);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Error);
        }
      },
    });
  };

  const handleNumberChange = event => {
    const input = event.target.value;
    const onlyNumbers = /^[0-9.]*$/; // 소수점을 포함한 숫자만 검사하는 정규식

    if (input === '' || onlyNumbers.test(input)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getProfileList();
    if (apData && type === 'UPDATE') {
      setApMacId(apData.apMacId);
      setApAddress(apData.apAddress);
      setApIp(apData.apIp);
      setApNm(apData.apNm);
      setApmacAddress(apData.apMacAddress);
      setLatitude(apData.latitude);
      setSelectedProfile({ label: apData.profileNm, value: apData.profileId });
      setLongitude(apData.longitude);
    }
  }, []);

  useEffect(() => {
    if (naverMap.current && moveCenter && selectedProfile) {
      naverMap.current.panTo(moveCenter);
      naverMap.current.refresh();
    }
  }, [moveCenter]);

  useEffect(() => {
    if (selectedProfile && selectedProfile.value) {
      getProfileDetail(selectedProfile.value);
    }
  }, [selectedProfile]);

  return (
    <CustomModal
      title={`AP ${type === 'ADD' ? '등록' : '수정'}`}
      id="apModal"
      show={show}
      noCloseBtn
      onHide={() => handleClose()}
    >
      <Modal.Body>
        <Form className="sys-area">
          <Form.Group className="form-group">
            <Form.Label>사업단위</Form.Label>
            <CustomSelect
              options={profileList}
              value={selectedProfile}
              onChange={e => setSelectedProfile(e)}
              className="mw-160"
              placeholder="선택"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>AP장비명</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="AP명을 입력하세요"
                className="mw-280"
                value={apNm}
                onChange={e => {
                  setApNm(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>IP 주소</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="IP주소를 입력하세요."
                className="mw-280"
                value={apIp}
                onChange={e => {
                  setApIp(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>AP MAC</Form.Label>
            <InputGroup>
              <Form.Control
                className="mw-280"
                placeholder="AP MAC 주소를 입력하세요"
                value={apmacAddress}
                onChange={e => {
                  setApmacAddress(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>설치주소</Form.Label>
            <InputGroup>
              <Form.Control
                className="mw-280"
                placeholder="설치 주소를 입력하세요"
                value={apAddress}
                onChange={e => {
                  setApAddress(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>AP위도</Form.Label>
            <InputGroup>
              <Form.Control
                className="mw-280"
                placeholder="AP위도를 입력하세요"
                value={latitude}
                onChange={e => {
                  if (handleNumberChange(e)) {
                    setLatitude(e.target.value);
                  }
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>AP경도</Form.Label>
            <InputGroup>
              <Form.Control
                className="mw-280"
                placeholder="AP경도를 입력하세요"
                value={longitude}
                onChange={e => {
                  if (handleNumberChange(e)) {
                    setLongitude(e.target.value);
                  }
                }}
              />
            </InputGroup>
          </Form.Group>
        </Form>
        {profileDetail.profileId && (
          <MapDiv className="map-box" fallback={null}>
            <NaverMap
              ref={naverMap}
              defaultCenter={
                new navermaps.LatLng(
                  profileDetail.latitude,
                  profileDetail.longitude,
                )
              }
              maxZoom={13}
              minZoom={13}
            >
              <Marker
                position={
                  new navermaps.LatLng(
                    profileDetail.latitude,
                    profileDetail.longitude,
                  )
                }
                icon={{
                  content: ReactDOMServer.renderToString(
                    <Button size="sm" variant="map-unit-state" />,
                  ),
                  origin: new navermaps.Point(0, 0),
                  anchor: new navermaps.Point(12, 37),
                }}
              />
            </NaverMap>
          </MapDiv>
        )}
        <div className="btn-area justify-btn-side">
          <Button
            variant="outline-primary"
            onClick={handleClose}
            className="md-3"
          >
            닫기
          </Button>
          <div className="flex-gap">
            {apMacId && (
              <Button
                variant="outline-primary"
                onClick={e => {
                  deleteSwal();
                }}
              >
                삭제
              </Button>
            )}
            <Button onClick={e => onSubmit(e)}>
              {apMacId ? '수정' : '등록'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
});
