/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PieController,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Annotation from 'chartjs-plugin-annotation';

ChartJS.register(
  PieController,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels,
  Annotation,
);

function createCustomComponent(text) {
  const container = document.createElement('div');
  container.style.display = 'flex';
  container.style.alignItems = 'center';
  container.style.justifyContent = 'center';
  container.style.position = 'absolute';
  container.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  container.style.borderRadius = '50%';
  container.style.width = '50px';
  container.style.height = '50px';

  const label = document.createElement('span');
  label.style.color = '#fff';
  label.textContent = text;

  container.appendChild(label);

  return container;
}

export const data = {
  labels: ['Red', 'Blue', 'Hohoit'],
  datasets: [
    {
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
      ],
      hoverOffset: 4,
    },
  ],
};

export function Piechart({ chartData }) {
  const pieChartOptions = {
    // Modify CSS properties of the pie chart
    // Example:
    plugins: {
      legend: {
        position: 'right',
        display: false, // 범례 숨기기
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
      },
      datalabels: {
        color: '#fff',
        font: {
          size: 14,
        },
        formatter: (value, context) => {
          // Pie 차트에 표시할 텍스트
          const labelText = data.labels[context.dataIndex];
          return ``;
        },
      },
    },
  };

  return (
    <div id="piechart">
      <div style={{ height: 234 }}>
        <Pie data={chartData || data} options={pieChartOptions} />
      </div>
    </div>
  );
}
