/* eslint-disable react/prefer-stateless-function */
import React, { Component, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import Utils from '../../../common/Utils';

class MyCalendar extends Component {
  render() {
    const handleDatesSet = eventInfo => {
      const { startStr, endStr } = eventInfo;
      console.log('Start Date:', startStr);
      console.log('End Date:', endStr);
    };

    return (
      <div className="App">
        <FullCalendar
          initialView="dayGridMonth"
          plugins={[dayGridPlugin]}
          // events={event}
          datesSet={handleDatesSet}
          // eventContent={e => {
          //   renderEventContent(e);
          // }}
          // dayCellDidMount={e => {
          //   const tempList = event.filter(v =>
          //     Utils.isDateInRange(e.date, v.startDate, v.endDate),
          //   );
          //   // console.log('date :: ', Utils.dateToString(e.date));
          //   // console.log('tempList :: ', tempList);
          // }}
          dayCellContent={e => {
            // console.log('eee :: ', e);
            return renderEventContent(e);
          }}
          // doCustomRendering={e => {
          //   console.log('elelelel', e);
          // }}
          headerToolbar={header}
          titleFormat={title}
          locale="ko" // 한글
          eventColor="#FFFFFF" // background 컬러
          eventTimeFormat={DateFormatter}
          eventDisplay="list-item"
        />
      </div>
    );
  }
}
const event = [
  {
    tilte: 'test1',
    scheduleId: 1,
    promoId: 4,
    startDate: '2023-03-30',
    endDate: '2023-04-20',
    startTime: '09:00:00',
    endTime: '18:00:00',
    createDate: '2023-03-23 16:30:29',
    updateDate: null,
  },
  {
    tilte: 'test2',
    scheduleId: 2,
    promoId: 1,
    startDate: '2023-03-30',
    endDate: '2023-04-05',
    startTime: '09:00:00',
    endTime: '20:00:00',
    createDate: '2023-03-23 16:40:56',
    updateDate: '2023-03-28 10:25:58',
  },
  {
    tilte: 'test3',
    scheduleId: 3,
    promoId: 2,
    startDate: '2023-03-30',
    endDate: '2023-04-20',
    startTime: '09:00:00',
    endTime: '18:00:00',
    createDate: '2023-03-23 16:50:19',
    updateDate: null,
  },
  {
    tilte: 'test4',
    scheduleId: 4,
    promoId: 3,
    startDate: '2023-03-30',
    endDate: '2023-04-20',
    startTime: '09:00:00',
    endTime: '18:00:00',
    createDate: '2023-03-23 16:58:19',
    updateDate: null,
  },
  {
    tilte: 'test5',
    scheduleId: 8,
    promoId: 8,
    startDate: '2023-03-30',
    endDate: '2023-04-20',
    startTime: '09:00:00',
    endTime: '09:00:00',
    createDate: '2023-03-27 14:44:08',
    updateDate: null,
  },
  {
    tilte: 'test6',
    scheduleId: 9,
    promoId: 9,
    startDate: '2023-03-30',
    endDate: '2023-04-20',
    startTime: '08:00:00',
    endTime: '18:00:00',
    createDate: '2023-03-27 15:11:26',
    updateDate: null,
  },
  {
    tilte: 'test7',
    scheduleId: 10,
    promoId: 10,
    startDate: '2023-03-29',
    endDate: '2023-03-31',
    startTime: '00:00:00',
    endTime: '04:00:00',
    createDate: '2023-03-28 10:49:55',
    updateDate: null,
  },
  {
    tilte: 'test8',
    scheduleId: 11,
    promoId: 11,
    startDate: '2023-03-29',
    endDate: '2023-03-29',
    startTime: '00:00:00',
    endTime: '24:00:00',
    createDate: '2023-03-28 11:08:07',
    updateDate: null,
  },
];
function renderEventContent(eventInfo) {
  const tempList = event.filter(v =>
    Utils.isDateInRange(eventInfo.date, v.startDate, v.endDate),
  );

  const limit = 2;

  return (
    <div className="grid-calender">
      <i className="title-color">
        {new Date(eventInfo.date).getDate().toString()}일
      </i>
      {tempList &&
        tempList.length > 0 &&
        tempList.slice(0, limit).map((v, i) => {
          return (
            <div className="caln-box">
              {/** list 클래스안에 Green, Orange 클래스 추가시 색변경  */}
              <div className="list">
                <b className="b-word">{v.tilte}</b>
              </div>
              <b className="me-sm-2 time-color">
                {v.startTime} - {v.endTime}
              </b>
            </div>
          );
        })}
      {tempList.length > limit && (
        <b className="seemore">{tempList.length - limit}개 더 보기</b>
      )}
    </div>
  );
}
const header = {
  start: 'prev',
  center: 'title',
  end: 'next',
};
const DateFormatter = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  meridiem: false,
};
const title = { year: 'numeric', month: 'long' };
export default MyCalendar;
