/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable new-cap */
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ReactDOMServer from 'react-dom/server';
import Moment from 'react-moment';
import { Table } from 'react-bootstrap';
import CustomSwal from '../components/CustomSwal';
import { Piechart } from '../pages/Promotion/Survey/Piechart';
import CustomException from './hooks/CustomException';

const PdfDownloader = async (
  images,
  orientation = 'p',
  filename = `wifreePdf${new Date().getTime()}.pdf`,
) => {
  try {
    // // 1. HTML 요소를 canvas로 변환
    // const canvas = await html2canvas(containerRef.current);

    // // 2. Canvas를 이미지로 변환
    // const imageFile = canvas.toDataURL('image/png');

    const marginLeft = 10; // 왼쪽 마진 값 (mm)
    const marginRight = 10; // 오른쪽 마진 값 (mm)
    const marginTop = 10; // 상단 마진 값 (mm)
    const marginBottom = 10; // 하단 마진 값 (mm)

    // 3. PDF 문서 준비
    const doc = new jsPDF(orientation, 'mm', 'a4', true);

    // PDF 페이지의 가로 세로 사이즈
    const pageWidth =
      doc.internal.pageSize.getWidth() - (marginLeft + marginRight);
    const pageHeight =
      doc.internal.pageSize.getHeight() - (marginTop + marginBottom);

    await images.reduce(async (promise, pdfObj, index, array) => {
      await promise; // Wait for the previous iteration to complete

      const { canvas, image } = pdfObj;

      // 이미지의 길이와 PDF 페이지의 가로 길이를 기준으로 비율을 구함
      const widthRatio = pageWidth / canvas.width;

      // 비율에 따른 이미지 높이
      const customHeight = canvas.height * widthRatio;

      // 이미지를 PDF 페이지에 추가
      doc.addImage(
        image,
        'jpeg',
        marginLeft,
        marginTop,
        pageWidth,
        customHeight,
        '',
        'FAST',
      );

      // 남은 이미지 높이와 이미지 자를 위치 변수 설정
      let heightLeft = customHeight;
      let heightAdd = -pageHeight;

      // 이미지가 한 페이지 이상일 경우
      while (heightLeft >= pageHeight) {
        doc.addPage();
        doc.addImage(
          image,
          'jpeg',
          marginLeft,
          heightAdd,
          pageWidth,
          customHeight,
          '',
          'FAST',
        );

        heightLeft -= pageHeight;
        heightAdd -= pageHeight;
      }

      // 리스트의 마지막 요소가 아닌 경우에만 페이지를 추가
      if (index !== array.length - 1) {
        doc.addPage();
      }
    }, Promise.resolve()); // Initial value for reduce()

    // PDF 문서 저장
    doc.save(filename);
  } catch (error) {
    console.log('error :: ', error);
    // CustomSwal.fire({
    //   text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
    //   confirmButtonText: '확인',
    // });
    throw error;
  }
};
export default PdfDownloader;
