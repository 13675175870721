/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false,
  accessToken: undefined,
  refreshToken: undefined,
  admNm: undefined,
  admId: undefined,
  admType: undefined,
  projCode: undefined,
  firstMenuUrl: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isLogin = !!action.payload.isLogin;
      state.admId = action.payload.admId;
      state.accessToken = action.payload.accessToken;
      state.admNm = action.payload.admNm;
      state.refreshToken = action.payload.refreshToken;
      state.admType = action.payload.admType;
      state.projCode = action.payload.projCode;
      state.firstMenuUrl = action.payload.firstMenuUrl;
    },
    getAuth: (state, action) => {
      const auth = {
        accessToken: state.accessToken,
        refreshToken: state.refreshToken,
        admId: state.admId,
        admNm: state.admNm,
        admType: state.admType,
        projCode: state.projCode,
        firstMenuUrl: state.firstMenuUrl,
      };
      action.payload = auth;
    },
    removeAuth: state => {
      state.isLogin = false;
      state.accessToken = initialState.accessToken;
      state.admNm = initialState.admNm;
      state.admId = initialState.admId;
      state.refreshToken = initialState.refreshToken;
      state.admType = initialState.admType;
      state.projCode = initialState.projCode;
      state.firstMenuUrl = initialState.firstMenuUrl;
    },
  },
});

// Action creators are generated for each case reducer function
export const authAction = authSlice.actions;

export default authSlice.reducer;
