/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Button, InputGroup, Table } from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomPagination,
  CustomSwal,
} from '@components';
import { Options, RouterPath, Utils } from '@common';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import {
  getProfiles,
  getPromoAreaReq,
  getPromotionAprvList,
  getPromoTypes,
} from '../../../common/crud';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';

export default React.memo(function PrmApprovalList(props) {
  const history = useHistory();

  const prmApprovalPage =
    (history.location.state && history.location.state.page) || 1;
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const areaId =
    (history.location.state && history.location.state.areaId) || null;
  const typeId =
    (history.location.state && history.location.state.typeId) || null;
  const reqState =
    (history.location.state && history.location.state.reqState) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const allData = {
    label: '전체',
    value: null,
  };

  // 검색
  const [searchBsnsUnit, setSearchBsnsUnit] = useState({});
  const [searchPrmArea, setSearchPrmArea] = useState({});
  const [searchApproval, setSearchApproval] = useState('ALL');
  const [searchPrmType, setSearchPrmType] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');

  const [profileList, setProfileList] = useState([]);
  const [prmAreaReqList, setPrmAreaReqList] = useState([]);
  const [promoTypeList, setPromoTypeList] = useState([]); // 홍보 유형 리스트

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchBsnsUnit(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchBsnsUnit(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoTypeList = async () => {
    try {
      const { data } = await getPromoTypes();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPromoTypeList(tempList);
        if (typeId) {
          setSearchPrmType(tempList.find(v => v.value === typeId));
        } else {
          setSearchPrmType(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaReqList = async () => {
    try {
      const { data } = await getPromoAreaReq();
      if (data.code === 200) {
        const tempPrmAreaReqList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempPrmAreaReqList.unshift(allData);
        setPrmAreaReqList(tempPrmAreaReqList);
        if (areaId) {
          setSearchPrmArea(tempPrmAreaReqList.find(v => v.value === areaId));
        } else {
          setSearchPrmArea(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    getPromoAreaReqList();
    getProfileList();
    getPromoTypeList();
  }, []);

  // 테이블
  const [prmApprovalTotalPageCnt, setPrmApprovalTotalPageCnt] = useState();
  const [showPrmApprovalList, setShowPrmApprovalList] = useState([]);
  const [prmApprovalSize, setPrmApprovalSize] = useState(10);

  const getPrmApprovalList = async () => {
    try {
      const params = {
        page: prmApprovalPage,
        size: prmApprovalSize,
        profileId,
        areaId,
        typeId,
        reqState: reqState === 'ALL' ? null : reqState,
        keyword,
      };
      const { data } = await getPromotionAprvList(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && prmApprovalPage > 1) {
          movePage(1);
        } else {
          setPrmApprovalTotalPageCnt(data.data.totalPages);
          setShowPrmApprovalList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      profileId: isSearch ? searchBsnsUnit.value : profileId,
      areaId: isSearch ? searchPrmArea.value : areaId,
      typeId: isSearch ? searchPrmType.value : typeId,
      reqState: isSearch ? searchApproval : reqState,
    };

    props.history.push({
      pathname: RouterPath.prmApprovalList,
      state,
    });
  };

  const setSearchState = () => {
    setSearchBsnsUnit(profileList.find(v => v.value === profileId) || allData);
    setSearchPrmArea(prmAreaReqList.find(v => v.value === areaId) || allData);
    setSearchPrmType(promoTypeList.find(v => v.value === typeId) || allData);
    setSearchApproval(reqState || 'ALL');
    setSearchKeyword(keyword || '');
  };

  useEffect(() => {
    setSearchState();
    getPrmApprovalList();
  }, [pageRefresh]);

  return (
    <main id="prm-request-list">
      <Header title="홍보 승인 리스트" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchBsnsUnit}
                onChange={setSearchBsnsUnit}
                className="mw-160"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보영역</Form.Label>
              <CustomSelect
                options={prmAreaReqList}
                value={searchPrmArea}
                onChange={setSearchPrmArea}
                className="mw-160"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>승인상태</Form.Label>
              <div className="flex-start">
                {Options.approvalStatus.map(item => {
                  return (
                    <Form.Check
                      key={`approval-${item.value}`}
                      type="radio"
                      name="approval"
                      id={`approval-${item.value}`}
                      label={item.label}
                      onChange={() => setSearchApproval(item.state)}
                      checked={searchApproval === item.state}
                    />
                  );
                })}
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보타입</Form.Label>
              <div className="flex-start">
                {promoTypeList.map(item => {
                  return (
                    <Form.Check
                      key={`prmType-${item.value}`}
                      type="radio"
                      name="prmType"
                      id={`prmType-${item.value}`}
                      label={item.label}
                      onChange={() => setSearchPrmType(item)}
                      checked={searchPrmType.value === item.value}
                    />
                  );
                })}
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="홍보 타이틀 검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      movePage(1, true);
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4 scrollbar">
          <Table className="table-hover text-start">
            <colgroup>
              <col width={60} />
              <col width={120} />
              <col width={100} />
              <col width={100} />
              <col width={120} />
              <col width={120} />
              <col width={100} />
              <col width={140} />
              <col width={160} />
              <col width={100} />
              <col width={100} />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>홍보영역</th>
                <th>홍보타입</th>
                <th>승인상태</th>
                <th>희망 홍보 시작일</th>
                <th>희망 홍보 종료일</th>
                <th>홍보구분</th>
                <th>홍보 타이틀</th>
                <th>사업단위</th>
                <th>작성자</th>
                <th>요청일시</th>
              </tr>
            </thead>
            <tbody>
              {showPrmApprovalList.length > 0 ? (
                showPrmApprovalList.map((v, i) => {
                  return (
                    <tr
                      key={`prmApproval-${i}`}
                      onClick={() =>
                        v.reqState === 'CON'
                          ? props.history.push({
                              pathname: `${RouterPath.prmConfirmDetail}/${v.promoId}`,
                            })
                          : props.history.push({
                              pathname: `${RouterPath.prmApprovalDetail}/${v.requestId}`,
                            })
                      }
                    >
                      <td>{v.requestId}</td>
                      <td>{v.promoArea}</td>
                      <td>{v.promoType}</td>
                      <td>{Utils.handleApprovalText(v.reqState)}</td>
                      <td>
                        {v.startDate ? (
                          <Moment
                            date={v.startDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>
                        {v.endDate ? (
                          <Moment
                            date={v.endDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{v.promoCate ? v.promoCate : '-'}</td>
                      <td>
                        <p className="ellipsis">{v.title ? v.title : '-'}</p>
                      </td>
                      <td>
                        <p className="ellipsis">{v.profileNm}</p>
                      </td>
                      <td>
                        <p className="ellipsis">{Utils.decrypt(v.reqAdmNm)}</p>
                      </td>
                      <td>
                        <Moment
                          date={v.createDate}
                          format="YYYY.MM.DD hh:mm:ss"
                          interval={0}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={11}>
                    요청 내역이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={prmApprovalTotalPageCnt}
            pageNum={prmApprovalPage}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
    </main>
  );
});

// 사업단위
const bsnsUnitOptions = [
  { label: '전체', value: 'all' },
  { label: '영양산업단지', value: '1' },
  { label: '그들이설정하는', value: '2' },
  { label: '사업단위값들', value: '3' },
];

// 테이블 dummy
const prmApprovalList = [
  {
    no: 1,
    prmId: 12,
    area: 'main',
    prmType: 'esg',
    approval: 'request',
    startDate: new Date(),
    endDate: new Date(),
    gubun: '축제',
    title:
      '영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너 영양군 홍보 배너',
    bsnsUnit: '영양산업단지영양산업단지영양산업단지영양산업단지',
    writer: '아이디아이디아이디',
    requestDate: new Date(),
  },
  {
    no: 2,
    prmId: 123,
    area: 'main',
    prmType: 'smallBsns',
    approval: 'withdraw',
    startDate: new Date(),
    endDate: new Date(),
    gubun: '축제',
    title: '영양군 홍보 배너',
    bsnsUnit: '영양산업단지',
    writer: '아이디',
    requestDate: new Date(),
  },
  {
    no: 3,
    prmId: 1234,
    area: 'bar',
    prmType: 'localGov',
    approval: 'reject',
    startDate: new Date(),
    endDate: new Date(),
    gubun: '축제',
    title: '영양군 홍보 배너',
    bsnsUnit: '영양산업단지',
    writer: '아이디',
    requestDate: new Date(),
  },
];
