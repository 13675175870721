/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const labels = ['ESG(70%)', '소상공인', '지자체'];

export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: 'rgb(238, 83, 124)',
      categoryPercentage: 1.0,
      barPercentage: 0.3,
      stack: 'Stack 0',
    },
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: 'rgb(235, 146, 123) ',
      categoryPercentage: 1.0,
      barPercentage: 0.3,
      stack: 'Stack 0',
    },
  ],
};

export const GroupBarChart = React.forwardRef(({ chartData }, ref) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
      },
      datalabels: {
        // display: false,
        color: '#fff',
        font: {
          size: 14,
        },
        formatter: (value, context) => {
          return ``;
        },
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        max: 100,
        min: 0,
        stacked: false,
        ticks: {
          callback(value) {
            return `${value}%`;
          },
        },
      },
    },
  };
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
      }}
    >
      <Bar
        ref={ref}
        options={options}
        data={chartData || data}
        style={{ flex: 1, width: '100%' }}
      />
    </div>
  );
});
