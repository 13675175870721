/* eslint-disable prefer-const */
/* eslint-disable no-alert */
/* eslint-disable no-shadow */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import Swal from 'sweetalert2';
import { store } from '@common/redux/store';
import { authAction } from '@reducers/authSlice';
import { useTranslation } from 'react-i18next';
import RouterPath from './RouterPath';
import NetworkException from './hooks/NetworkException';
import CustomSwal from '../components/CustomSwal';

const baseURL = process.env.REACT_APP_API_URL;

const REST = axios.create({
  baseURL,
  headers: {
    'Content-type': 'application/json',
  },
});

// 1. 요청 인터셉터 2개의 콜백함수
REST.interceptors.request.use(
  async config => {
    // 요청 성공 직전 호출됩니다.
    // axios 설정값을 넣습니다. (사용자 정의 설정도 추가 가능)
    const originalRequest = config;

    try {
      const auth = store.dispatch(authAction.getAuth()).payload;
      if (auth) {
        if (auth.accessToken) {
          originalRequest.headers.Authorization = `Bearer ${auth.accessToken}`;
        }
      }
    } catch (err) {
      console.log(err, 'err');
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// 2. 응답 인터셉터 2개의 콜백함수
REST.interceptors.response.use(
  response => {
    /*
        http status가 200인 경우
        응답 성공 직전 호출됩니다.
        .then() 으로 이어집니다.
        */
    return response;
  },
  async error => {
    try {
      const { config, response } = error;
      let result = response;

      // response이 Blob으로 왔을 경우  response data to json
      // [[
      if (
        response.data instanceof Blob &&
        response.data.type === 'application/json'
      ) {
        const responseData = await response.data.text();
        const responseJson =
          typeof responseData === 'string'
            ? JSON.parse(responseData)
            : responseData;
        result = responseJson;
      }
      // ]]

      const originalRequest = config;

      const errorMessage = result.data.message;

      // 토큰 유무 확인 > 로그인 이동
      if (result.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const auth = store.dispatch(authAction.getAuth()).payload;
        if (!auth) {
          store.dispatch(authAction.removeAuth());
          window.location.href = RouterPath.signin;
        }

        // 토큰 기간 만료 시 Refresh 토큰으로 Access 발급 후 api 재요청
        if (result.data.code === 1101) {
          // Refresh 토큰
          axios.defaults.headers.common.Authorization = `Bearer ${auth.refreshToken}`;

          // Access 토큰 재발급
          try {
            const { data } = await axios.get(
              `${baseURL}api/v1/auth/refresh-token`,
            );
            const { accessToken } = data.data;
            const newAuth = {
              ...auth,
              accessToken,
            };

            store.dispatch(authAction.setAuth(newAuth));
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            REST.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            return REST(originalRequest);
          } catch (err) {
            if (err.response) {
              // 로그아웃
              const {
                response: {
                  data: { code, msg },
                },
              } = err;
              store.dispatch(authAction.removeAuth());
              window.location.href = RouterPath.signin;
              delete axios.defaults.headers.common.Authorization;
              delete REST.defaults.headers.common.Authorization;
            } else {
              console.log('err', err);
            }
          }
        }
      }
      if (result.data.intended) {
        return result;
      }
      // 에러 메세지 Swal
      CustomSwal.fire({
        html: errorMessage,
        confirmButtonText: '확인',
        finally: () => {
          if (result.status === 401) {
            window.location.href = RouterPath.signin;
          }
        },
      });
      return Promise.reject(new NetworkException(errorMessage));
    } catch (err) {
      console.log(err, '-err');
      return Promise.reject(err);
    }
  },
);

export default REST;
