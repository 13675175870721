/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Marker, Polygon, useMap, useNavermaps } from 'react-naver-maps';
import { Utils } from '@common';
import ReactDOMServer from 'react-dom/server';

export default React.memo(function PolygonLine({ data }) {
  const navermaps = useNavermaps();
  const naverMap = useMap();
  const [paths, setPaths] = useState();
  const [isJoin, setIsJoin] = useState(false);
  useEffect(() => {
    if (data) {
      const tempPaths = data.paths.map((v, i) => {
        const tempData = new navermaps.LatLng(v.lat, v.lng);
        return tempData;
      });
      setPaths(tempPaths);
      // setPaths(data);
      setIsJoin(data.isJoin);
    }
  }, []);
  return (
    <Polygon
      paths={[paths]}
      fillColor={isJoin ? '#6DA0EC' : '#E95F67'}
      fillOpacity={0.4}
      strokeColor="white"
    />
  );
});
