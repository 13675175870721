/* eslint-disable react/react-in-jsx-scope */
import moment from 'moment';
import { format, setHours, setMinutes } from 'date-fns';
import crypto from 'crypto-js';
import Swal from 'sweetalert2';
import Moment from 'react-moment';
import RouterPath from './RouterPath';
import CustomException from './hooks/CustomException';
import RESULT_CODE from './ResultCode';
import NetworkException from './hooks/NetworkException';

const secretkey = process.env.REACT_APP_SECRET_KEY;
const action = process.env.REACT_APP_ENV_NAME;
const key = crypto.enc.Utf8.parse(secretkey.substring(0, 16));
const iv = crypto.enc.Utf8.parse(secretkey.substring(0, 16));
const kakaoKey = process.env.REACT_APP_SHARE_KAKAO_LINK_KEY;
const shareUrl = process.env.REACT_APP_SHARE_URL;
const fileViewUrl = '/image';

const utils = {
  logStyleErr: 'color:red',
  logStyleWarn: 'color:yellow',
  logStyleInfo: 'color:blue',
  logStyleSuccess: 'color:green',

  checkSpace: str => {
    if (str.search(/\s/) !== -1) {
      return true;
    }
    return false;
  },

  // 인풋 입력숫자 콤마표기
  changeNumberComma: str => {
    if (
      str === undefined ||
      str === null ||
      !utils.isIntegerIncludingNegative(str)
    ) {
      return '';
    }
    return Number(str).toLocaleString();
  },

  changeNumberCommaForZero: str => {
    if (!str || !utils.isIntegerIncludingNegative(str)) {
      return '';
    }
    return Number(str).toLocaleString();
  },

  // 숫자 콤마표기, 소수점 3자리까지
  numberComma: num => {
    const result = num.toLocaleString(undefined, {
      maximumFractionDigits: 3,
    });
    return result;
  },
  // 숫자 콤마제거, 소수점 3자리까지
  removeComma: str => {
    const result = str.toString().replace(/,/g, '').toLocaleString(undefined, {
      maximumFractionDigits: 3,
    });
    return Number(result);
  },

  // 가운데 ellipsis
  ellipsisCenter: (str, maxLength, startLength, endLength) => {
    if (str.length > maxLength) {
      return `${str.substr(0, startLength)}…${str.substr(
        str.length - endLength,
        str.length,
      )}`;
    }
    return str;
  },

  // get Byte
  getByteSize: str => {
    const result = new Blob([str]).size;
    return result;
  },

  calDurationDay: (start, end) => {
    const ms = moment(end, 'DD/MM/YYYY').diff(moment(start, 'DD/MM/YYYY'));
    const d = moment.duration(ms);
    const result = Number(d.format('DD'));
    return result;
  },
  calDuration: (start, end, reqFormat) => {
    const ms = moment(end, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(start, 'DD/MM/YYYY HH:mm:ss'),
    );
    const d = moment.duration(ms);
    const result = d.format(reqFormat);
    return result;
  },

  // HTML 태그 제거
  removeHTML: text => {
    const imgTag = /<IMG(.*?)>/gi;
    const result = text
      .replace(imgTag, '')
      .replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, ' ');
    return result;
  },

  // 날짜 하이픈 추가 (숫자만 입력)
  dateHypenFormat: date => {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  },

  // 날짜 닷 추가 (숫자만 입력)
  dateDotFormat: date => {
    return `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
  },

  // 날짜 년/월/일 텍스트 추가 (숫자만 입력)
  dateTextFormat: date => {
    return `${date.getFullYear()}년 ${
      date.getMonth() + 1
    }월 ${date.getDate()}일`;
  },

  // 이메일 유효성 검사
  emailTest: email => {
    const reg =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i; // eslint-disable-line
    return !!reg.test(email);
  },

  // 모든 전화번호 유효성 검사
  phoneNumberTest: phoneNumber => {
    let removeHyphen = phoneNumber.replace(/\-/g, ''); // eslint-disable-line
    const reg = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})?[0-9]{3,4}?[0-9]{4}$/;
    return !!reg.test(removeHyphen);
  },

  pwRuleCheck: pw => {
    const reg =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,15}$/;
    return !!reg.test(pw);
  },
  sleep: time => {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    });
  },
  idRuleCheck: id => {
    const reg = /[~!@#$%^&*()_+|<>?:{}.*\\//₩=-]/;
    return !!reg.test(id);
  },

  handleDestroySwiper: (swiper, length, limit) => {
    // console.log(limit, length);
    if (limit >= length) {
      swiper.destroy(false, true);
    }
  },

  byteCheck: (value, maxByte) => {
    const textVal = value; // 입력한 문자
    const textLen = value.length; // 입력한 문자수
    let totalByte = 0;
    for (let i = 0; i < textLen; i += 1) {
      const eachChar = textVal.charAt(i);
      const uniChar = escape(eachChar); // 유니코드 형식으로 변환
      if (uniChar.length > 4) {
        // 한글 : 2Byte
        totalByte += 2;
      } else {
        // 영문,숫자,특수문자 : 1Byte
        totalByte += 1;
      }
    }
    return maxByte >= totalByte;
  },

  getDateMinute: () => {
    return setHours(setMinutes(new Date(), 0), new Date().getHours() + 1);
  },
  getToday: () => {
    return new Date().setDate(new Date().getDate());
  },
  getTomorrow: () => {
    return new Date().setDate(new Date().getDate() + 1);
  },
  encrypt: text => {
    if (!text) return text;
    const cipher = crypto.AES.encrypt(text.toString(), key, {
      iv,
      padding: crypto.pad.Pkcs7,
      mode: crypto.mode.CBC,
    }).toString();
    return cipher;
  },

  encryptSHA256: text => {
    if (!text) return text;
    return crypto.SHA256(text).toString();
  },

  decrypt: ciphertext => {
    if (!ciphertext) return ciphertext;
    const decrypted = crypto.AES.decrypt(ciphertext, key, {
      iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    }).toString(crypto.enc.Utf8);
    return decrypted;
  },

  getDateAMonthAgo: dateObject => {
    const date = dateObject;
    return new Date(date.setMonth(date.getMonth() - 1));
  },
  shareKakao: (route, title, introduce, imageUrl) => {
    // url이 id값에 따라 변경되기 때문에 route를 인자값으로 받아줌
    if (window.Kakao) {
      const kakao = window.Kakao;
      if (!kakao.isInitialized()) {
        kakao.init(kakaoKey); // 카카오에서 제공받은 javascript key를 넣어줌 -> .env파일에서 호출시킴
      }

      kakao.Link.sendDefault({
        objectType: 'feed', // 카카오 링크 공유 여러 type들 중 feed라는 타입 -> 자세한 건 카카오에서 확인
        content: {
          title, // 인자값으로 받은 title
          description: introduce, // 인자값으로 받은 title
          imageUrl,
          link: {
            mobileWebUrl: route, // 인자값으로 받은 route(uri 형태)
            webUrl: route,
          },
        },
        buttons: [
          {
            title,
            link: {
              mobileWebUrl: route,
              webUrl: route,
            },
          },
        ],
      });
    }
  },
  handleCopyClipBoard: async (text, then) => {
    try {
      await navigator.clipboard.writeText(text);
      // alert('클립보드에 링크가 복사되었습니다.');
      then(true);
    } catch (e) {
      then(false);
      // alert('복사에 실패하였습니다');
    }
  },
  getPhoneNoHypen: phoneNumber => {
    if (!phoneNumber) return null;
    return phoneNumber.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  },
  isBeforeToday: date => {
    const today = new Date();
    const targetDate = new Date(date);
    return today > targetDate;
  },
  isAfterToday: date => {
    const today = new Date();
    const targetDate = new Date(date);
    return today < targetDate;
  },

  // 승인상태 TEMP(임시저장), REQ(승인요청), WTH(승인회수), REJ(승인반려), CON(승인완료)
  handleApprovalText: val => {
    // switch (val) {
    //   case 'tempSave':
    //     return <span className="text-gray">임시저장</span>;
    //   case 'request':
    //     return <span className="text-info">승인요청</span>;
    //   case 'withdraw':
    //     return <span className="text-secondary">승인회수</span>;
    //   case 'reject':
    //     return <span className="text-danger">승인반려</span>;
    //   case 'complete':
    //     return <span className="text-success">승인완료</span>;
    //   default:
    //     return '-';
    // }
    switch (val) {
      case 'TEMP':
        return <span className="text-gray">임시저장</span>;
      case 'REQ':
        return <span className="text-info">승인요청</span>;
      case 'WTH':
        return <span className="text-secondary">승인회수</span>;
      case 'REJ':
        return <span className="text-danger">승인반려</span>;
      case 'CON':
        return <span className="text-success">승인완료</span>;
      default:
        return '-';
    }
  },
  handleMomentStartEnd: (startDate, endDate) => {
    if (startDate && endDate) {
      return (
        <>
          <Moment date={startDate} format="YYYY.MM.DD" interval={0} />
          {' - '}
          <Moment date={endDate} format="YYYY.MM.DD" interval={0} />
        </>
      );
    }
    if (startDate || endDate) {
      return (
        <Moment date={startDate || endDate} format="YYYY.MM.DD" interval={0} />
      );
    }
    return null;
  },
  handleError: (error, process, isGo) => {
    switch (true) {
      case error instanceof CustomException:
      case error instanceof NetworkException:
        process(error.message, isGo);
        break;
      default:
        console.log('handleError error :: ', error);
        process(RESULT_CODE.CODE_500, isGo);
    }
  },
  handleFile: newFile => {
    const file = newFile;
    const preview = URL.createObjectURL(newFile);
    if (
      file.type.includes('image/jpeg') ||
      file.type.includes('image/jpg') ||
      file.type.includes('image/png')
    ) {
      return { file, preview };
    }
    throw new CustomException('.jpg, .jpeg, .png 이미지만 등록할 수 있습니다.');
  },
  dateToString: data => {
    if (data) {
      return format(new Date(data), 'yyyy-MM-dd');
    }
    return null;
  },
  stringToDate: data => {
    if (data) {
      return new Date(data);
    }
    return null;
  },
  dateToStringByFormat: (data, reqFormat) => {
    if (data) {
      return format(new Date(data), reqFormat);
    }
    return null;
  },
  timeFormatter: time => {
    if (time) {
      return time.slice(0, -3);
    }
    return '';
  },
  fileViewUrl: path => {
    if (!path) {
      return null;
    }
    const hostUrl =
      action === 'local' || action === 'dev'
        ? 'https://leo-wifree.com'
        : window.location.origin;
    // const hostUrl =
    //   action === 'local'
    //     ? 'https://dev-wifree.upchain.co.kr'
    //     : window.location.origin;
    return hostUrl + fileViewUrl + path;
  },
  isInteger: value => {
    if (typeof value === 'number' || typeof value === 'string') {
      if (value) {
        return /^\d+$/.test(value);
      }
      return true;
    }
    return false;
  },
  isIntegerIncludingNegative: value => {
    if (typeof value === 'number' || typeof value === 'string') {
      if (value) {
        return /^-?\d+$/.test(value);
      }
      return true;
    }
    return false;
  },
  isNumber: value => {
    const regex = /^\d+(\.\d+)?$/;
    return regex.test(value);
  },
  isDateInRange: (inputDate, startDate, endDate) => {
    const input = new Date(inputDate).setHours(0, 0, 0, 0);
    const start = new Date(startDate).setHours(0, 0, 0, 0);
    const end = new Date(endDate).setHours(0, 0, 0, 0);

    return input >= start && input <= end;
  },
  calculatePercentage: (viewCnt, callCnt, fixed) => {
    if (
      !viewCnt ||
      !callCnt ||
      !utils.isNumber(callCnt) ||
      !utils.isNumber(viewCnt)
    ) {
      return '0%';
    }

    const percentage = ((viewCnt / callCnt) * 100).toFixed(
      typeof fixed !== 'undefined' ? fixed : 2,
    );
    return `${percentage}%`;
  },
  calculatePercentageInt: (viewCnt, callCnt, fixed) => {
    if (
      !viewCnt ||
      !callCnt ||
      !utils.isNumber(callCnt) ||
      !utils.isNumber(viewCnt)
    ) {
      return 0;
    }

    const percentage = ((viewCnt / callCnt) * 100).toFixed(
      typeof fixed !== 'undefined' ? fixed : 2,
    );
    return percentage;
  },
  getYearMonthString: date => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() 메소드는 0부터 시작하므로 1을 더해줌
    const monthString = month >= 10 ? month.toString() : `0${month.toString()}`; // 달이 10 이상인 경우와 미만인 경우를 나누어 처리
    const yearMonthString = `${year.toString()}-${monthString}`;
    return yearMonthString;
  },
  getKoreanDayOfWeek: date => {
    const dayDate = new Date(date);
    const day = dayDate.getDay();
    const koreanDays = ['일', '월', '화', '수', '목', '금', '토'];
    return koreanDays[day];
  },
  getRandomInt: (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
  randomRGB: () => {
    const red = utils.getRandomInt(0, 255);
    const green = utils.getRandomInt(0, 255);
    const blue = utils.getRandomInt(0, 255);
    return `rgb(${red}, ${green}, ${blue})`;
  },
  randomBrightRGB: () => {
    let red;
    let green;
    let blue;
    let brightness;

    do {
      red = Math.floor(Math.random() * 256);
      green = Math.floor(Math.random() * 256);
      blue = Math.floor(Math.random() * 256);
      brightness = (red * 299 + green * 587 + blue * 114) / 1000;
    } while (brightness < 128); // 128 이상의 밝기를 가지는 배경색만 선택합니다.

    return `rgb(${red}, ${green}, ${blue})`;
  },
};

export default utils;
