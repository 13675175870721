/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Container, Form, Ratio, Badge } from 'react-bootstrap';
import { CustomModal, CustomSelect, CustomDatePicker } from '@components';
import { Options, RouterPath, Utils } from '@common';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { images } from '@assets';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
// swiper
import 'swiper/css';
import 'swiper/css/thumbs';

SwiperCore.use([Thumbs, FreeMode]);

function MainimagesShowForCoupon({
  mainhandleClose,
  prmDetail,
  couponData,
  ...rest
}) {
  const [style, setStyle] = useState({});
  const [isFull, setIsFull] = useState(false);
  const getDiscountValue = (discountType, discountValue) => {
    switch (discountType) {
      case 'RATE':
        return `${Utils.changeNumberCommaForZero(discountValue)}%`;
      default:
        return `${Utils.changeNumberCommaForZero(discountValue)}원`;
    }
  };

  const couponStr = useCallback(() => {
    return getDiscountValue(couponData.discountType, couponData.discountValue);
  }, [couponData]);

  useEffect(() => {
    if (prmDetail) {
      const tempData = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${Utils.fileViewUrl(prmDetail.bannerPath)})`,
      };
      setIsFull(String(prmDetail.codeArea).includes('FULL'));
      setStyle(tempData);
    }
  }, []);
  return (
    <section className="main-section" style={isFull ? { ...style } : {}}>
      <div
        className="main-box"
        style={
          isFull
            ? { opacity: 0 }
            : String(prmDetail.codeArea).includes('SLIDE')
            ? style
            : { border: 0, backgroundColor: '#aaa' }
        }
      >
        {String(prmDetail.codeArea).includes('SLIDE') && (
          <div className="grid-label">
            <p className="sale-won">{couponStr()}</p>
            <p className="sale-text">
              할인
              <br />
              쿠폰
            </p>
          </div>
        )}
      </div>
      <div
        className="sec-mainbox"
        style={
          isFull
            ? { opacity: 0 }
            : String(prmDetail.codeArea).includes('BAR')
            ? style
            : { border: 0, backgroundColor: '#aaa' }
        }
      >
        {String(prmDetail.codeArea).includes('BAR') && (
          <div className="grid-label">
            <p className="sale-won">{couponStr()}</p>
            <p className="sale-text">
              할인
              <br />
              쿠폰
            </p>
          </div>
        )}
      </div>
      <div className="grid-box" style={isFull ? { opacity: 0 } : {}}>
        <div
          className="ano-box"
          style={
            String(prmDetail.codeArea).includes('BOX')
              ? style
              : { border: 0, backgroundColor: '#aaa' }
          }
        >
          {String(prmDetail.codeArea).includes('BOX') && (
            <div className="grid-label">
              <p className="sale-won">{couponStr()}</p>
              <p className="sale-text">
                할인
                <br />
                쿠폰
              </p>
            </div>
          )}
        </div>
        <div className="ano-box" />
        <div className="ano-box" />
      </div>
    </section>
  );
}

export default MainimagesShowForCoupon;
