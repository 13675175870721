const RESULT_CODE = {
  CODE_SUCCESS: 'success',
  CODE_500: '확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
  CODE_401: '인증되지 않은 키 입니다.',
  CODE_403: '허용되지 않은 요청입니다.',
  CODE_404: 'Not Found!',
  CODE_9000: '필수값이 없습니다.',
  CODE_9001: '업로드한 파일을 확인 해주세요.',
  CODE_9002: '업로드한 파일의 용량을 확인 해주세요.',
  CODE_9003: '업로드한 파일의 확장자를 확인 해주세요.',
  CODE_9004: '입력값 길이가 초과하였습니다.',
  CODE_9005: '날짜 형식을 확인해주세요.',
  CODE_9006: '접근 권한이 없습니다.',
  CODE_9007: '삭제 권한이 없습니다.',
  CODE_9008: '시간 형식을 확인해주세요.',
  CODE_9009: '잘못된 검색유형입니다.',
  CODE_9010: '조회 가능한 데이터가 없습니다.',
  CODE_9100: '등록 중 오류가 발생했습니다.',
  CODE_9101: '수정 중 오류가 발생했습니다.',
  CODE_9102: '삭제 중 오류가 발생했습니다.',
  CODE_9103: '이메일 전송 중 오류가 발생했습니다.',
  CODE_9104: '데이터 변환 중 오류가 발생했습니다.',
  CODE_9200: '허용되지 않은 IP로 접속을 시도했습니다.',
  CODE_1000: '올바른 이메일 형식이 아닙니다.',
  CODE_1001: '휴대폰 번호를 입력해주세요.',
  CODE_1002: '인증구분이 올바르지 않습니다.',
  CODE_1003: '이메일 주소를 확인해주세요.',
  CODE_1005: '이메인 인증 요청 중 오류가 발생했습니다.',
  CODE_1006: '유효하지 않은 본인인증 ID 입니다.',
  CODE_1007: '요청시 입력한 이메일 주소와 다릅니다.',
  CODE_1008: '잘못된 인증코드 입니다.',
  CODE_1009: '만료된 인증코드 입니다.',
  CODE_1010: '이메일 인증처리에 실패했습니다.',
  CODE_1011: '가입하지 않은 아이디이거나, 잘못된 비밀번호입니다.',
  CODE_1012: '인증코드가 일치하지 않습니다.',
  CODE_1013: '가입하지 않은 아이디이거나, 잘못된 이메일입니다.',
  CODE_1014: '입력하신 두 비밀번호가 일치하지 않습니다.',
  CODE_1015: '올바른 비밀번호 형식이 아닙니다.',
  CODE_1090: '이미 가입되어 있는 아이디입니다.',
  CODE_1091: '이미 가입되어 있는 이메일입니다.',
  CODE_1092: '이미 가입되어 있는 휴대폰 번호입니다.',
  CODE_1101: '토큰이 만료되었습니다.',
  CODE_1103: '잘못된 요청입니다.',
  CODE_1104: '로그인이 필요합니다.',
  CODE_1105: '리프레시 토큰이 아닙니다.',
  CODE_1106: '토큰값이 맞지 않습니다.',
  CODE_1107: '억세스 토큰이 아닙니다.',
  CODE_1108: '토큰값을 찾을 수 없습니다.',
  CODE_1109: '토큰 변환 중 오류가 발생했습니다.',
  CODE_1110: '회원정보를 찾을 수 없습니다.',
  CODE_2000: '[2000] 조회 가능한 사업단위가 없습니다.',
  CODE_2001: '[2001] 조회 가능한 홍보가 없습니다.',
  CODE_2002: '[2002] 조회 가능한 AP가 없습니다.',
  CODE_2003: '[2003] 조회 가능한 홍보요청이 없습니다.',
  CODE_2004: '[2004] 조회 가능한 설문조사가 없습니다.',
  CODE_2100: '[2100] 홍보명은 최대 20자까지 입력 가능합니다.',
  CODE_2101: '[2101] 상세설명은 최대 500자까지 입력 가능합니다.',
  CODE_2102: '[2101] 홍보 시작일, 홍보 종료일을 확인해주세요.',
  CODE_2103: '[2103] 일정을 확인해주세요.',
  CODE_2104: '[2104] 홍보 유형을 선택해주세요.',
  CODE_2105: '[2105] 배너 이미지를 업로드해주세요.',
  CODE_2106: '[2106] 상세 이미지를 업로드해주세요.',
  CODE_2107: '[2107] 상세 이미지 최대 등록 개수는 4개 입니다.',
  CODE_2108: '[2108] 배너 이미지가 이미 등록되어 있습니다.',
  CODE_2109: '[2109] 광고 스케줄 등록중 오류가 발생했습니다.',
  CODE_2110: '[2110] 임시저장 상태의 홍보요청만 삭제할 수 없습니다.',
  CODE_2111: '[2111] 승인요청 상태의 홍보요청만 반려할 수 있습니다.',
  CODE_2112: '[2112] 삭제할 수 있는 홍보가 없습니다.',
  CODE_2113: '[2113] 수정 가능한 홍보가 없습니다.',
  CODE_2114: '[2114] 수정 가능한 홍보 일정이 없습니다.',
  CODE_2115: '[2115] 시작시간, 종료시간을 확인해주세요.',
  CODE_2116: '[2116] 이미 쿠폰이 등록되어 있습니다.',
  CODE_2117: '[2117] 이미 설문이 등록되어 있습니다.',
  CODE_2118: '[2118] 질문 등록에 필수 항목이 없습니다.',
  CODE_2119: '[2119] 답변 등록에 필수 항목이 없습니다.',
  CODE_2120: '[2120] 홍보 기준 코드 조회 중 오류가 발생했습니다.',
  CODE_3000: '[3000] 조회 가능한 관리자가 없습니다.',
  CODE_3001: '[3001] 삭제 가능한 관리자가 없습니다.',
  CODE_4000: '[4000] 조회 가능한 코드가 없습니다.',
  CODE_4001: '[4001] 이미 등록되어 있는 코드입니다.',
  CODE_4002: '[4002] 이미 등록되어 있는 Mac주소입니다.',
  CODE_4003: '[4003] 비율의 합이 100%가 되어야합니다.',
  CODE_5000: '[5000] 소상공인 관리자만 접근 가능합니다.',
  CODE_5001: '[5001] 이미 등록되어 있는 홍보가 있습니다.',
  CODE_5002: '[5002] 진행중인 홍보요청이 있습니다.',
};
export default RESULT_CODE;
