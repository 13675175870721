/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
  ListGroup,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import InfiniteScroll from 'react-infinite-scroll-component';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import {
  getBudgetDate,
  getBudgetList,
  getBudgetState,
  getChargeInvoices,
  getChargeRate,
  patchChargeRate,
  putBudget,
  putChargeInvoice,
} from '../../common/crud';

export default React.memo(function Billing(props) {
  const [chargeRateData, setChargeRateData] = useState({});
  const [updateRateData, setUpdateRateData] = useState({});

  const getChargeRateData = async () => {
    try {
      const { data } = await getChargeRate();
      if (data.code === 200) {
        setChargeRateData(data.data);
        setUpdateRateData(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const [invoiceList, setInvoiceList] = useState([]);
  const [isLast, setIsLast] = useState(false);
  const [selectInvoice, setSelectInvoice] = useState({});
  const [showInvoice, setShowInvoice] = useState({});
  const [createInvoiceData, setCreateInvoiceData] = useState({});
  // const [createInvoiceData, setCreateInvoiceData] = useState({});
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(30);

  const getChargeInvoiceList = async () => {
    try {
      const params = {
        page,
        size,
      };
      const { data } = await getChargeInvoices(params);
      if (data.code === 200) {
        if (page === 1) {
          setInvoiceList(data.data.list);
        } else {
          setInvoiceList([...invoiceList, ...data.data.list]);
        }
        if (
          data.data.list &&
          !showInvoice.invoiceId &&
          data.data.list.length > 0
        ) {
          onSelectInvoice(data.data.list[0]);
        }
        setIsLast(data.data.isLast);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const updateChargeRateData = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      // const checkRate = 0;
      Object.keys(updateRateData).forEach(v => {
        if (!updateRateData[`${v}`]) {
          throw new CustomException('누락된 홍보비율이 있습니다.');
        }
        // checkRate += updateRateData[`${v}`] * 100;
      });
      // if (checkRate !== 100) {
      //   throw new CustomException('입력한 홍보비율의 합이 1이 되야합니다.');
      // }
      const { data } = await patchChargeRate(updateRateData);
      if (data.code === 200) {
        setAPAddPopShow(false);
        getChargeRateData();
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
    target.disabled = false;
  };

  const createInvoice = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      const { data } = await putChargeInvoice(createInvoiceData);
      if (data.code === 200) {
        CustomSwal.fire({
          text: '정상적으로 등록 되었습니다.',
          confirmButtonText: '확인',
          finally: () => {
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
    target.disabled = false;
  };

  const handleCloseUpdateChargeRateModal = () => {
    setAPAddPopShow(false);
    setUpdateRateData(chargeRateData);
  };

  const handleCloseCreateInvoiceDataModal = () => {
    setTableEdit(false);
    setCreateInvoiceData(selectInvoice);
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const handleInputChange = (e, key) => {
    const { value } = e.target;
    const numOnly = value.replace(/[^0-9]/g, '');
    setCreateInvoiceData({ ...createInvoiceData, [key]: numOnly });
  };

  const onSelectInvoice = data => {
    const tempObj = cloneDeep(data);
    let tempList = [
      { area: 'Full', areaNm: '통광고', data: {} },
      { area: 'Slide', areaNm: '슬라이드 배너', data: {} },
      { area: 'Bar', areaNm: '바 배너', data: {} },
      { area: 'Box', areaNm: '박스 배너', data: {} },
    ];
    Object.keys(data).forEach(key => {
      tempList = tempList.map(v => {
        if (String(key).includes(v.area)) {
          const tempData = {
            ...v,
            data: { ...v.data, [key]: data[key] },
          };
          delete tempObj[key];
          return tempData;
        }
        return v;
      });
    });
    const result = { ...tempObj, list: tempList };
    setShowInvoice(result);
    setCreateInvoiceData(data);
    setSelectInvoice(data);
  };

  const getShowInvoiceValue = data => {
    return data === 0 ? data : Utils.changeNumberCommaForZero(data) || '-';
  };
  const getEditInvoiceValue = data => {
    return data === 0 ? data : Utils.changeNumberCommaForZero(data) || '';
  };

  const getCommaValue = (data, prefix = '', nullStr = '-') => {
    return data === 0
      ? `${prefix}${data}`
      : data
      ? `${prefix}${Utils.changeNumberCommaForZero(data)}`
      : nullStr;
  };

  const handleInputChange4Budget = e => {
    const { value } = e.target;
    const numOnly = value.replace(/[^0-9]/g, '');
    setCreateBudgetData({ ...createBudgetData, amount: numOnly });
  };

  const [budgetDate, setBudgetDate] = useState({});
  const [budgetList, setBudgetList] = useState([]);
  const [budgetState, setBudgetState] = useState({});
  const [createBudgetData, setCreateBudgetData] = useState({
    regDate: new Date(),
  });

  const getBudgetDateApi = async () => {
    try {
      const { data } = await getBudgetDate();
      if (data.code === 200) {
        setBudgetDate(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getBudgetListApi = async () => {
    try {
      const { data } = await getBudgetList();
      if (data.code === 200) {
        setBudgetList(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getBudgetStateApi = async () => {
    try {
      const { data } = await getBudgetState();
      if (data.code === 200) {
        setBudgetState(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  useEffect(() => {
    getBudgetDateApi();
    getBudgetListApi();
    getBudgetStateApi();
    getChargeRateData();
  }, []);

  const createBudget = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      const { data } = await putBudget({
        ...createBudgetData,
        regDate: format(createBudgetData.regDate, 'yyyy-MM-dd').toString(),
      });
      if (data.code === 200) {
        CustomSwal.fire({
          text: '정상적으로 등록 되었습니다.',
          confirmButtonText: '확인',
          finally: () => {
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
    target.disabled = false;
  };

  useEffect(() => {
    getChargeInvoiceList();
  }, [page]);

  // 홍보비율 추가 모달
  const [sysAddPopShow, setAPAddPopShow] = useState(false);
  const handleClose = () => setAPAddPopShow(false);
  // 관리자 추가 모달
  const [tableEdit, setTableEdit] = useState(false);
  const tableClose = () => setTableEdit(false);
  // 예산정책 추가 모달
  const [moneyPopShow, setMoneyPopShow] = useState(false);
  const MoneyModalClose = () => {
    setMoneyPopShow(false);
    setCreateBudgetData({
      regDate: new Date(),
    });
  };
  return (
    <main id="billing-list">
      <Header title="과금 관리" />
      <Container className="contents">
        <article>
          <h5 className="mb-xxl-3">홍보 비율</h5>
          <div className="btn-area justify-btn-side mb-0">
            <ul className="list-prmType">
              <li>
                <h6>ESG</h6>
                <strong className="text-success">
                  {Utils.calculatePercentage(chargeRateData.esgRate, 1, 0)}
                </strong>
              </li>
              <li>
                <h6>소상공인</h6>
                <strong className="text-warning">
                  {Utils.calculatePercentage(chargeRateData.storeRate, 1, 0)}
                </strong>
              </li>
              <li>
                <h6>지자체</h6>
                <strong className="text-primary">
                  {Utils.calculatePercentage(chargeRateData.govRate, 1, 0)}
                </strong>
              </li>
            </ul>
            <Button onClick={setAPAddPopShow}>수정</Button>
          </div>
        </article>
        <div className="article-grid">
          <article className="mt-4 b-day mi-ht" id="invoice-scroll">
            <h5 className="mb-xxl-3">과금설정일자</h5>
            {invoiceList.length > 0 ? (
              <InfiniteScroll
                scrollableTarget="invoice-scroll"
                dataLength={invoiceList && invoiceList.length}
                hasMore={!isLast}
                next={() => {
                  setPage(prev => prev + 1);
                }}
              >
                <ListGroup variant="flush">
                  {invoiceList.map((v, i) => {
                    return (
                      <div
                        key={`invoice_${v.invoiceId}`}
                        className="day-box"
                        onClick={e => {
                          onSelectInvoice(v);
                        }}
                      >
                        <p>
                          <Moment date={v.createDate} format="YYYY.MM.DD" />
                        </p>
                      </div>
                    );
                  })}
                </ListGroup>
              </InfiniteScroll>
            ) : null}
          </article>
          <article className="mt-4 scrollbar mx-ht">
            <h5 className="mb-xxl-3">과금정책</h5>
            <Table className="table-hover text-start">
              <colgroup>
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
                <col width={100} />
              </colgroup>
              <thead>
                <tr>
                  <th rowSpan={2} className="bd-ri">
                    홍보영역
                  </th>
                  <th colSpan={3} className="bd-bt bd-ri">
                    ESG
                  </th>
                  <th colSpan={3} className="bd-bt bd-ri">
                    소상공인
                  </th>
                  <th colSpan={3} className="bd-bt">
                    지자체
                  </th>
                </tr>
                <tr>
                  <th className="bd-ri">CPV</th>
                  <th className="bd-ri">CPC</th>
                  <th className="bd-ri">CPA</th>
                  <th className="bd-ri">CPV</th>
                  <th className="bd-ri">CPC</th>
                  <th className="bd-ri">CPA</th>
                  <th className="bd-ri">CPV</th>
                  <th className="bd-ri">CPC</th>
                  <th>CPA</th>
                </tr>
              </thead>

              <tbody>
                {showInvoice.invoiceId && showInvoice.list.length > 0 ? (
                  showInvoice.list.map((v, i) => {
                    return (
                      <tr>
                        <td>{v.areaNm}</td>
                        <td>
                          {getShowInvoiceValue(v.data[`esg${v.area}Cpv`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`esg${v.area}Cpc`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`esg${v.area}Cpa`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`store${v.area}Cpv`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`store${v.area}Cpc`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`store${v.area}Cpa`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`gov${v.area}Cpv`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`gov${v.area}Cpc`])}
                        </td>
                        <td>
                          {getShowInvoiceValue(v.data[`gov${v.area}Cpa`])}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={10}>
                      과금정책이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            <div className="btn-area justify-content-end mb-0">
              <Button variant="outline-primary" onClick={setTableEdit}>
                수정
              </Button>
            </div>
          </article>
        </div>
        <article className="mt-4 prmtable">
          <div className="btn-side-jus">
            <h5>예산정책</h5>
            <Button onClick={setMoneyPopShow}>등록</Button>
          </div>
          <div className="flex-title">
            <div className="prm-flex">
              <p>
                누적총액
                <span className="prm-title-color">{`${getCommaValue(
                  budgetState.sum,
                  '₩',
                )}`}</span>
              </p>
              <p>
                현재잔액
                <span className="prm-title-color">{`${getCommaValue(
                  budgetState.remain,
                  '₩',
                )}`}</span>
              </p>
              <p>
                과금기준일자
                <span className="prm-title-color">
                  <Moment
                    date={budgetDate.codeKo}
                    format="YYYY.MM.DD"
                    interval={0}
                  />
                </span>
              </p>
            </div>
          </div>
          <Table className="table-hover text-start mt-3">
            <colgroup>
              <col width={80} />
              <col width={80} />
              <col width={140} />
              <col width={140} />
              <col width={140} />
            </colgroup>
            <thead>
              <tr>
                <th>No</th>
                <th>등록일</th>
                <th>입금액</th>
                <th>누적 합산액</th>
                <th>비고</th>
              </tr>
            </thead>

            <tbody>
              {budgetList.map((v, i) => {
                return (
                  <tr key={`budget_${i}`}>
                    <td>{v.budgetId}</td>
                    <td>
                      <Moment
                        date={v.regDate}
                        format="YYYY-MM-DD"
                        interval={0}
                      />
                    </td>
                    <td>{getCommaValue(v.amount)}</td>
                    <td>{getCommaValue(v.sum)}</td>
                    <td>{v.memo}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </article>
      </Container>
      {/** 예산정책 모달 */}
      <CustomModal
        title="예산정책 등록"
        id="bsperModal"
        show={moneyPopShow}
        noCloseBtn
        onHide={() => {
          MoneyModalClose();
        }}
      >
        <Modal.Body>
          <Form className="sys-area">
            <Form.Group className="form-group">
              <Form.Label>등록일</Form.Label>
              <div className="flex-div">
                <InputGroup>
                  <CustomDatePicker
                    value={createBudgetData.regDate}
                    placeholderText="yyyy.mm.dd"
                    style={{ maxWidth: '100%' }}
                    onChange={e => {
                      setCreateBudgetData({ ...createBudgetData, regDate: e });
                    }}
                  />
                </InputGroup>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="align-label">입금액</Form.Label>
              <div className="flex-div">
                <InputGroup>
                  <Form.Control
                    className="mw-280"
                    value={getCommaValue(createBudgetData.amount, '', '') ?? ''}
                    onChange={e => {
                      handleInputChange4Budget(e);
                    }}
                  />
                </InputGroup>
                <span>원</span>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>비고</Form.Label>
              <div className="flex-div">
                <InputGroup>
                  <Form.Control
                    className="mw-280"
                    value={createBudgetData.memo}
                    onChange={e => {
                      setCreateBudgetData({
                        ...createBudgetData,
                        memo: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </div>
            </Form.Group>
          </Form>
          <div className="btn-area justify-btn-side">
            <Button variant="outline-primary" onClick={MoneyModalClose}>
              닫기
            </Button>
            <Button
              onClick={e => {
                createBudget(e);
              }}
            >
              등록
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>
      {/** 홍보비율수정 모달 */}
      <CustomModal
        title="홍보비율 수정"
        id="bsperModal"
        show={sysAddPopShow}
        noCloseBtn
        onHide={() => {
          handleCloseUpdateChargeRateModal();
        }}
      >
        <Modal.Body>
          <Form className="sys-area">
            <Form.Group className="form-group">
              <Form.Label>ESG</Form.Label>
              <div className="flex-div">
                <InputGroup>
                  <Form.Control
                    className="mw-280"
                    value={Utils.calculatePercentageInt(
                      updateRateData.esgRate,
                      1,
                      0,
                    )}
                    onChange={e => {
                      if (Utils.isInteger(e.target.value)) {
                        setUpdateRateData({
                          ...updateRateData,
                          esgRate: parseFloat(e.target.value) / 100,
                        });
                      }
                    }}
                  />
                </InputGroup>
                <span>%</span>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="align-label">소상공인</Form.Label>
              <div className="flex-div">
                <InputGroup>
                  <Form.Control
                    className="mw-280"
                    value={Utils.calculatePercentageInt(
                      updateRateData.storeRate,
                      1,
                      0,
                    )}
                    onChange={e => {
                      if (Utils.isInteger(e.target.value)) {
                        setUpdateRateData({
                          ...updateRateData,
                          storeRate: parseFloat(e.target.value) / 100,
                        });
                      }
                    }}
                  />
                </InputGroup>
                <span>%</span>
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>지자체</Form.Label>
              <div className="flex-div">
                <InputGroup>
                  <Form.Control
                    className="mw-280"
                    value={Utils.calculatePercentageInt(
                      updateRateData.govRate,
                      1,
                      0,
                    )}
                    onChange={e => {
                      if (Utils.isInteger(e.target.value)) {
                        setUpdateRateData({
                          ...updateRateData,
                          govRate: parseFloat(e.target.value) / 100,
                        });
                      }
                    }}
                  />
                </InputGroup>
                <span>%</span>
              </div>
            </Form.Group>
          </Form>
          <div className="btn-area justify-btn-side">
            <Button
              variant="outline-primary"
              onClick={handleCloseUpdateChargeRateModal}
            >
              닫기
            </Button>
            <Button
              onClick={e => {
                updateChargeRateData(e);
              }}
            >
              수정
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>
      {/** 과금정책 수정 모달 */}
      <CustomModal
        id="blEditModal"
        title="과금정책"
        show={tableEdit}
        noCloseBtn
        onHide={() => handleCloseCreateInvoiceDataModal()}
      >
        <Modal.Body>
          <Table className="table-hover text-start modal-table">
            <colgroup>
              <col width={110} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
              <col width={75} />
            </colgroup>
            <thead>
              <tr>
                <th rowSpan={2} className="bd-ri">
                  홍보영역
                </th>
                <th colSpan={3} className="bd-bt bd-ri">
                  ESG
                </th>
                <th colSpan={3} className="bd-bt bd-ri">
                  소상공인
                </th>
                <th colSpan={3} className="bd-bt">
                  지자체
                </th>
              </tr>
              <tr>
                <th className="bd-ri">CPV</th>
                <th className="bd-ri">CPC</th>
                <th className="bd-ri">CPA</th>
                <th className="bd-ri">CPV</th>
                <th className="bd-ri">CPC</th>
                <th className="bd-ri">CPA</th>
                <th className="bd-ri">CPV</th>
                <th className="bd-ri">CPC</th>
                <th>CPA</th>
              </tr>
            </thead>

            <tbody>
              {[
                { area: 'Full', areaNm: '통광고' },
                { area: 'Slide', areaNm: '슬라이드 배너' },
                { area: 'Bar', areaNm: '바 배너' },
                { area: 'Box', areaNm: '박스 배너' },
              ].map((v, i) => {
                return (
                  <tr>
                    <td>{v.areaNm}</td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          // value={Utils.changeNumberCommaForZero(
                          //   createInvoiceData[`esg${v.area}Cpv`],
                          // )}
                          value={getEditInvoiceValue(
                            createInvoiceData[`esg${v.area}Cpv`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `esg${v.area}Cpv`);
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          value={getEditInvoiceValue(
                            createInvoiceData[`esg${v.area}Cpc`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `esg${v.area}Cpc`);
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          placeholder="0"
                          value={getEditInvoiceValue(
                            createInvoiceData[`esg${v.area}Cpa`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `esg${v.area}Cpa`);
                          }}
                          disabled
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          value={getEditInvoiceValue(
                            createInvoiceData[`store${v.area}Cpv`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `store${v.area}Cpv`);
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          value={getEditInvoiceValue(
                            createInvoiceData[`store${v.area}Cpc`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `store${v.area}Cpc`);
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          placeholder="0"
                          value={getEditInvoiceValue(
                            createInvoiceData[`store${v.area}Cpa`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `store${v.area}Cpa`);
                          }}
                          disabled
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          value={getEditInvoiceValue(
                            createInvoiceData[`gov${v.area}Cpv`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `gov${v.area}Cpv`);
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          value={Utils.changeNumberCommaForZero(
                            createInvoiceData[`gov${v.area}Cpc`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `gov${v.area}Cpc`);
                          }}
                        />
                      </InputGroup>
                    </td>
                    <td>
                      <InputGroup>
                        <Form.Control
                          className="mw-280"
                          placeholder="0"
                          value={getEditInvoiceValue(
                            createInvoiceData[`gov${v.area}Cpa`],
                          )}
                          onChange={e => {
                            handleInputChange(e, `gov${v.area}Cpa`);
                          }}
                          disabled
                        />
                      </InputGroup>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div className="btn-area justify-btn-end">
            <Button
              variant="outline-primary"
              onClick={handleCloseCreateInvoiceDataModal}
            >
              취소
            </Button>
            <Button
              onClick={e => {
                createInvoice(e);
              }}
            >
              수정
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>
    </main>
  );
});
const adminTableList = [
  {
    banner: '통 배너',
    esgcpv: '300',
    esgcpc: '300',
    esgcpa: '-',
    smcpv: '300',
    smcpc: '300',
    smcpa: '-',
    bscpv: '300',
    bscpc: '300',
    bscpa: '-',
  },
  {
    banner: '슬라이드 배너',
    esgcpv: '300',
    esgcpc: '300',
    esgcpa: '-',
    smcpv: '300',
    smcpc: '300',
    smcpa: '-',
    bscpv: '300',
    bscpc: '300',
    bscpa: '-',
  },
  {
    banner: '바 배너',
    esgcpv: '300',
    esgcpc: '300',
    esgcpa: '-',
    smcpv: '300',
    smcpc: '300',
    smcpa: '-',
    bscpv: '300',
    bscpc: '300',
    bscpa: '-',
  },
  {
    banner: '박스 배너',
    esgcpv: '300',
    esgcpc: '300',
    esgcpa: '-',
    smcpv: '300',
    smcpc: '300',
    smcpa: '-',
    bscpv: '300',
    bscpc: '300',
    bscpa: '-',
  },
];
const adminTableEditList = [
  {
    banner: '통 배너',
    esgcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    smcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    bscpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
  },
  {
    banner: '슬라이드 배너',
    esgcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    smcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    bscpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
  },
  {
    banner: '바 배너',
    esgcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    smcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    bscpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
  },
  {
    banner: '박스 배너',
    esgcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    esgcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    smcpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    smcpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
    bscpv: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpc: (
      <InputGroup>
        <Form.Control className="mw-280" />
      </InputGroup>
    ),
    bscpa: (
      <InputGroup>
        <Form.Control className="mw-280" placeholder="0" disabled />
      </InputGroup>
    ),
  },
];

const billingdummy = [
  {
    day: '2023.03.03',
  },
  {
    day: '2023.03.02',
  },
  {
    day: '2023.03.02',
  },
  {
    day: '2023.03.02',
  },
];
const addtotalList = [
  {
    no: '1',
    day: '2023-12-12',
    money: '300,000',
    total: '1,000,000,000',
    add: '비고',
  },
  {
    no: '2',
    day: '2023-12-12',
    money: '300,000',
    total: '1,000,000,000',
    add: '비고',
  },
  {
    no: '3',
    day: '2023-12-12',
    money: '300,000',
    total: '1,000,000,000',
    add: '비고',
  },
];
