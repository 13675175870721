/* eslint-disable no-else-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/order */
import io from 'socket.io-client';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { CustomModal } from '@components';
import { Utils, Options } from '@common';
import { Container, Modal } from 'react-bootstrap';

// map
import ReactDOMServer from 'react-dom/server';
import { Container as MapDiv, NaverMap, useNavermaps } from 'react-naver-maps';
import PolygonMarker from './PolygonMarker';
import PolygonLine from './PolygonLine';
import HeatMapMarker from './HeatMapMarker';

import { dataObj as barData } from './VerticalBarChartFullDash';
import Chart from './Chart';

import Sig from './json/sig_100.json'; // 위경도 값

import * as RssiToCoordnates from './func/rssiToCoordnates'; // 분포별 데이터

import ArrowInfo from './json/arrow-info.json'; // 방향별 정보
import ProfileList from './json/profile-list.json'; // 방향별 정보

import BoardTitle from './components/BoardTitle';

// 커스텀 마커
import './assets/css/CustomMarker.css';
import './assets/css/full-dashboard.css';
import './assets/css/full-dashboard-barchart.scss';
import ArrowMain from './ArrowMain';
import UnitMarker from './components/UnitMarker';
import UnitArrowMarker from './components/UnitArrowMarker';

// 토스트 알람
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import Msg from './components/Msg';

// 전체보기 실시간 소켓 통신 데이터
const initialState = {
  alarmToastList: [{}],
  distribution: [],
  directData: [],
  directDataAll: [],
  equipState: { abnormal: 0, normal: 0, total: 0 },
  human: { count: '' },
  list: [{}],
  profileList: [{}],
  today: [{}],
  top5: [{}],
  visitor: {},
};

function reducer(state, action) {
  switch (action.type) {
    case 'DEPTH_0':
      return action.dataFromServer;
    default:
      return state;
  }
}

export default React.memo(function FullDashboard(props) {
  const navermaps = useNavermaps();

  const [mapTypeId, setMapTypeId] = useState(navermaps.MapTypeId.SATELLITE);
  const buttons = [
    {
      typeId: navermaps.MapTypeId.NORMAL,
      text: '일반지도',
    },
    {
      typeId: navermaps.MapTypeId.TERRAIN,
      text: '지형도',
    },
    {
      typeId: navermaps.MapTypeId.SATELLITE,
      text: '위성지도',
    },
    {
      typeId: navermaps.MapTypeId.HYBRID,
      text: '겹쳐보기',
    },
  ];
  // dropdown
  const [selectedTime, setSelectedTime] = useState(Options.time[0]); // 현황 기준

  // 가입 지역 리스트
  const [mapMarkerDepth, setMapMarkerDepth] = useState(0);
  const [socketDepth, setSocketDepth] = useState(0);
  const [selectedProfile, setSelectedProfile] = useState({ profile_id: 0 });

  const { profile_id: profileId, profile_nm: profileName } = selectedProfile;

  // 방향별 도보, 이동수단 구분
  const [directionType, setDirectionType] = useState('WALKING');

  const naverMap = useRef();

  // 상수
  const MIN_ZOOM_LEVEL = 11;
  const MID_ZOOM_LEVEL = 13;
  const MAX_ZOOM_LEVEL = 18;
  const DEFAULT_LATITUDE = 35.2538433;
  const DEFAULT_LONGITUDE = 128.6402609;
  const MAIN_OVERLAY_MARKER_LAT_LNG_LIST = [
    // 창원시
    {
      lat: 35.3278241,
      lng: 128.5695364,
    },
  ];
  const REGION_ID = 1;

  const [mapModeId, setMapModeId] = useState('NORMAL');
  const [heatmapDataList, setHeatmapDataList] = useState([]);
  const [polygonList, setPolygonList] = useState([]);
  const [moveCenter, setMoveCenter] = useState();
  const [polygonMarkerList, setPolygonMarkerList] = useState([]);
  const [selectUnit, setSelectUnit] = useState({});
  const [defaultCenter, setDefaultCenter] = useState();
  const [currentZoomLevel, setCurrentZoomLevel] = useState(MIN_ZOOM_LEVEL);
  // const [mapClickPoint, setMapClickPoint] = useState({}); // 임시 : 클릭 좌표 확인
  const [mapCentorPoint, setMapCentorPoint] = useState({});

  // console.log('currentZoomLevel : ', currentZoomLevel);
  // console.log('mapModeId : ', mapModeId);
  // console.log('directionType : ', directionType);

  const [floatPopShow, setFloatPopShow] = useState(false);

  // 유동 상황판 - 행정동 정보
  const [dashboardStateRegion, setDashboardStateRegion] = useState({});

  // 유동 상황판 - 데이터 가공
  const [directData, setDirectData] = useState([]); // 방향별 API 데이터
  const [directDataAll, setDirectDataAll] = useState([]); // 방향별 API 데이터
  const [directShow, setDirectShow] = useState('ONE');

  // 유동 상황판 - AP 정보
  const [unitListAP, setUnitListAP] = useState([]);
  const [apList, setAPList] = useState([]);
  const [noSignalAPList, setNoSignalAPList] = useState([]);

  // 토스트 위험 알림(알람) 메시지
  const [dangerList, setDangerList] = useState({});

  const bounce = cssTransition({
    enter: 'animate__animated animate__bounceIn',
    exit: 'animate__animated animate__bounceOut',
  });

  const [moveToastDangerPoint, setMoveToastDangerPoint] = useState({});
  const isClickedToast = useRef(false);

  function selectProfile(id) {
    return socketState?.profileList?.find(
      el => Number(el.profile_id) === Number(id),
    );
  }

  useEffect(() => {
    // 토스트 위험 알림(알람) 클릭시 해당 AP로 이동
    if (Object.keys(moveToastDangerPoint).length === 0) return;

    isClickedToast.current = true;
    // console.log('moveToastDangerPoint : ', moveToastDangerPoint);

    const { latitude, longitude, profileIdToast } = moveToastDangerPoint;

    setSelectedProfile(selectProfile(profileIdToast));

    naverMap.current.setZoom(MAX_ZOOM_LEVEL, true);
    setMoveCenter(new navermaps.LatLng(latitude, longitude));
  }, [moveToastDangerPoint]);

  useEffect(() => {
    // const CONTROL_POINT_LATITUDE = 35.2481658;
    const CONTROL_POINT_LONGITUDE = 128.6206057;

    if (currentZoomLevel > MID_ZOOM_LEVEL) {
      const id = mapCentorPoint.x > CONTROL_POINT_LONGITUDE ? 2 : 1; // 창원_1차: 2, 창원_2차: 1

      setSelectedProfile(selectProfile(id));
    }
  }, [mapCentorPoint]);

  useEffect(() => {
    if (profileId > 0) {
      setSocketDepth(2);
    } else {
      setSocketDepth(0);
    }
  }, [profileId]);

  useEffect(() => {
    // console.log('dangerList : ', dangerList);
    toast.dismiss();
    Object.keys(dangerList).forEach(el => {
      const { apNm, count, latitude, longitude, profileIdToast } =
        dangerList[el];

      toast(
        <Msg
          apNm={apNm}
          count={count}
          dangerPoint={{ latitude, longitude, profileIdToast }}
          moveToastDangerPoint={{ setMoveToastDangerPoint }}
        />,
        {
          transition: bounce,
          icon: ({ theme, type }) => (
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.55614 23.4V15.6C1.55614 12.8417 2.65647 10.1965 4.61507 8.24609C6.57368 6.29571 9.23011 5.2 12 5.2C14.7699 5.2 17.4263 6.29571 19.3849 8.24609C21.3435 10.1965 22.4439 12.8417 22.4439 15.6V23.4H23.7493V26H0.250653V23.4H1.55614ZM4.1671 23.4H19.8329V15.6C19.8329 13.5313 19.0077 11.5474 17.5387 10.0846C16.0697 8.62178 14.0774 7.8 12 7.8C9.92259 7.8 7.93026 8.62178 6.46131 10.0846C4.99235 11.5474 4.1671 13.5313 4.1671 15.6V23.4ZM10.6945 0H13.3055V3.9H10.6945V0ZM22.154 3.6504L24 5.4886L21.2324 8.2459L19.3851 6.4077L22.154 3.6504ZM0 5.4886L1.84595 3.6504L4.61488 6.4064L2.77023 8.2472L0 5.4886ZM5.47259 15.6C5.47259 13.8761 6.16029 12.2228 7.38442 11.0038C8.60855 9.78482 10.2688 9.1 12 9.1V11.7C10.9613 11.7 9.96513 12.1109 9.23065 12.8423C8.49618 13.5737 8.08355 14.5657 8.08355 15.6H5.47259Z"
                fill="white"
              />
            </svg>
          ),
          theme: 'colored',
          position: 'top-center',
          autoClose: false,
          className: 'toast-color-level-danger',
        },
      );
    });
  }, [dangerList]);

  // 전체보기 실시간 소켓 통신 데이터
  const [socketState, dispatch] = useReducer(reducer, initialState);
  // const [socketState, setSocketState] = useState(initialState);

  // 토스트 알람 데이터(위험)
  const makeToastAlarmDangerList = () => {
    const { alarmToastList } = socketState;

    // 토스트 알림 임계치 지정 관련
    // TODO: profile-list.json에 있는 threshold_count값을 DB에서 관리하도록 변경 요망됨
    // TODO: threshold_count값을 설정하는 UI 작업 요망됨
    const tempDangerList = alarmToastList
      .filter(alarmToastElement => {
        const resultObj = ProfileList.find(
          profileElement =>
            String(profileElement.apmac_id) ===
            String(alarmToastElement.apmac_id),
        );
        // console.log('resultObj : ', resultObj);
        return (
          resultObj !== undefined &&
          Number(alarmToastElement.count) >= Number(resultObj.threshold_count)
        );
      })
      .reduce((acc, curr) => {
        // console.log('토스트 알림 : ', curr);
        const {
          apmac_id: apmacId,
          profile_id: profileIdToast,
          ap_nm: apNm,
          latitude,
          longitude,
          count,
        } = curr;
        acc[apmacId] =
          { profileIdToast, apNm, latitude, longitude, count } || {};
        return acc;
      }, {});
    setDangerList(tempDangerList || {});
  };

  useEffect(() => {
    createChartDataTotal(); // 금일 유동인구 누계
    createBarChartData(); // 유동인구 상위지역
    makeToastAlarmDangerList(); // 토스트 위험 알람
    setAPList(socketState.list);

    const result = ProfileList.filter(
      pl =>
        !socketState.list.some(
          ul => Number(ul.apmac_id) === Number(pl.apmac_id),
        ),
    );
    setNoSignalAPList(result);
    // console.log('socketState : ', socketState);
    socketState.equipToday && createChartDataAP(socketState.equipToday);
  }, [socketState]);

  const [labels, setLabels] = useState([
    { label: '', color: 'first' },
    { label: '', color: 'second' },
    { label: '', color: 'third' },
    { label: '', color: 'fourth' },
    { label: '', color: 'etc' },
  ]);

  // 금일 유동인구 누계(라인 차트)
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  // 접속자수 상위지역(바 차트 데이터)
  const [barChartData, setBarChartData] = useState({
    labels: [],
    datasets: [{}],
  });

  // 금일 유동인구 누계(라인 차트)
  const createChartDataTotal = data => {
    const tempArr = [];
    const tempObj = {
      labels: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ],
      datasets: [
        {
          // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          data: [],
          borderColor: '#F3722C',
          borderWidth: 4,
          pointBackgroundColor: '#F3722C',
          pointBorderColor: '#fff',
          pointBorderWidth: 1,
          pointHoverBorderColor: '#F3722C',
          pointHoverBorderWidth: 1,
          yAxisID: 'y',
        },
      ],
    };
    socketState.today.forEach(item => tempArr.push(item.count));
    tempObj.datasets[0].data = tempArr;
    setChartData(tempObj);
  };

  const backgroundColors = [
    '#F94144',
    '#F3722C',
    '#F8961E',
    '#F9C74F',
    '#90BE6D',
  ];

  const createBarChartData = () => {
    const nameArr = [];
    const mainLabels = [];
    const tempLabels = [];

    const dataObj = {
      labels: [],
      datasets: [
        {
          label: [],
          data: [],
          backgroundColor: nameArr.map((_, index) => backgroundColors[index]),
          categoryPercentage: 1.0,
          barPercentage: 0.5,
        },
      ],
    };

    socketState.top5.forEach(el => {
      nameArr.push(el.ap_nm);
      mainLabels.push(el.ap_nm);
      dataObj.datasets[0].data.push(el.count);
      tempLabels.push(el.ap_nm);
    });

    dataObj.datasets[0].label = [...nameArr];

    const mergedArray = labels.map((obj, index) => {
      return { ...obj, label: tempLabels[index] };
    });

    setLabels(mergedArray);

    // 유동인구 접속자수 상위지역 TOP4(바)
    const top5DataArr = socketState.top5.map(el => el.count ?? el.sum);
    const tempBarChartData = {
      labels: tempLabels,
      datasets: [
        {
          data: [...top5DataArr.map(v => +v)],
          backgroundColor: barData.datasets[0].backgroundColor,
          categoryPercentage: 1.0,
          barPercentage: 0.5,
          label: [...nameArr],
        },
      ],
    };
    setBarChartData(tempBarChartData);
  };

  const [chartDataAP, setChartDataAP] = useState({
    labels: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23, 24,
    ],
    datasets: [
      {
        // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        data: [],
        borderColor: '#305399',
        borderWidth: 1,
        pointBackgroundColor: '#305399',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointHoverBorderColor: '#305399',
        pointHoverBorderWidth: 1,
        yAxisID: 'y',
      },
    ],
  });

  const createChartDataAP = data => {
    const tempObj = {
      labels: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24,
      ],
      datasets: [
        {
          // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
          data: data.map(el => el.count),
          borderColor: '#305399',
          borderWidth: 1,
          pointBackgroundColor: '#305399',
          pointBorderColor: '#fff',
          pointBorderWidth: 2,
          pointHoverBorderColor: '#305399',
          pointHoverBorderWidth: 1,
          yAxisID: 'y',
        },
      ],
    };

    setChartDataAP(tempObj);
  };

  // 방향별 API 데이터 유동 인구 합계
  const sumDirectionCount = (apmacID, data) => {
    // console.log('data : ', data);
    const tempObj = ArrowInfo.find(
      el => Number(el.apmac_id) === Number(apmacID),
    )?.direction;

    // ArrowInfo에 등록되지 않은 AP 생성시 발생하는 경우에 대비한 코드
    if (tempObj === undefined) {
      console.error(`apmac_id ${apmacID}는(은) ArrowInfo에 등록되지 않음!`);
      return {};
    }

    // console.log('data2: ', tempObj);
    const tempArr = Object.keys(tempObj);

    const result = {};

    // console.log('tempObj : ', tempObj);

    tempArr?.forEach((v, i) => {
      result[v] = { count: 0, deg: tempObj[v].deg, colorLevel: '' };
      result[v].count = data.reduce(
        (a, b) =>
          tempObj[v].apmacIds.includes(Number(b.toAP_id))
            ? a + Number(b.count)
            : a,
        0,
      );
      result[v].colorLevel =
        result[v].count >= tempObj[v].colorLevel.danger
          ? 'danger'
          : result[v].count >= tempObj[v].colorLevel.serious
          ? 'serious'
          : result[v].count >= tempObj[v].colorLevel.boundary
          ? 'boundary'
          : 'normal';
    });
    return result;
  };

  let selectedTimeValue =
    selectedTime.value === '1h'
      ? 1
      : selectedTime.value === '6h'
      ? 6
      : selectedTime.value === '12h'
      ? 12
      : selectedTime.value === '24h'
      ? 24
      : 0;

  const currentUnitMarker = useRef(); // 방향별 현재 선택된 apmac_id, 시간별 조회 리렌더링용

  // 전체보기 실시간 처리용 소켓 통신
  useEffect(() => {
    const socket = io('wss://wss.leo-wifree.com');
    // const socket = io('ws://localhost:7080');
    // const socket = io('ws://121.174.87.208:7080');

    // const tempId =
    //   socketDepth === 3 && mapModeId === 'DIRECTION'
    //     ? selectUnit.apmac_id
    //     : profileId;

    let payload = {
      time: selectedTimeValue,
      depth: socketDepth,
      profileId: Number(selectedProfile.profile_id),
      regionId: REGION_ID,
      apmacId: null,
      mapModeId,
      directionType,
      directShow,
    };

    if (Object.keys(selectUnit).length !== 0) {
      const { apmac_id: apmacId } = selectUnit;
      payload.apmacId = Number(apmacId);
    }
    // console.log('payload : ', payload);

    socket.emit('equip', payload);
    socket.on('response', dataFromServer => {
      dispatch({
        type: 'DEPTH_0',
        dataFromServer,
      });
    });
    return () => {
      socket.off('response');
    };
  }, [selectedTimeValue, socketDepth, selectedProfile.profile_id, mapModeId, selectUnit, directionType, directShow]);

  // 가입 지역, 미가입 지역 위경도 정보 가공
  function initJoinLatLng(joinTrueOrFalse, sigCd) {
    const tempObj = { isJoin: joinTrueOrFalse, paths: [] };
    const tempArr = [];

    tempArr.push(
      Sig.features
        .find(item => item.properties.SIG_CD === sigCd)
        .geometry.coordinates.flat()
        .flat(),
    );

    tempObj.paths = tempArr[0]?.map(el =>
      JSON.parse(`{"lat": ${el[1]}, "lng": ${el[0]}}`),
    );

    return tempObj;
  }

  // 가입 지역, 미가입 지역 표시
  useEffect(() => {
    setDefaultCenter(new navermaps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE)); // 창원 위경도
    setPolygonMarkerList(MAIN_OVERLAY_MARKER_LAT_LNG_LIST);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(true, '48121'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48123'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48129'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48127'),
    ]);
    setPolygonList(prevPolygonList => [
      ...prevPolygonList,
      initJoinLatLng(false, '48125'),
    ]);
    return () => {
      setPolygonList([]);
    };
  }, []);

  useEffect(() => {
    if (mapModeId === 'HEATMAP') {
      // RSSI 정보로 위경도 계산
      const rssiDataState = socketState.distribution;

      // console.log('rssiDataState : ', rssiDataState);

      setHeatmapDataList([]);
      rssiDataState &&
        rssiDataState.forEach(el1 => {
          const toAPCoord = ProfileList.find(
            el2 => +el1.toAP_id === el2.apmac_id,
          );
          const fromAPCoord = ProfileList.find(
            el2 => +el1.fromAP_id === el2.apmac_id,
          );
          const { rssi } = el1;

          // ProfileList에 등록되지 않은 AP 존재할 경우에 대한 코드
          if (toAPCoord === undefined || fromAPCoord === undefined) {
            console.error(
              `apmac_id ${el1.fromAP_id}는(은) ProfileList에 등록되지 않음!`,
            );
          } else {
            const resultCoord = RssiToCoordnates.calPoint(
              toAPCoord.latitude,
              toAPCoord.longitude,
              fromAPCoord.latitude,
              fromAPCoord.longitude,
              rssi,
            );

            setHeatmapDataList(prev => [...prev, resultCoord]);
          }
        });
    }

    if (mapModeId === 'DIRECTION') {
      setDirectData([]);
      setDirectDataAll([]);

      // 방향별 데이터 세팅(개별)
      if (
        'directData' in socketState &&
        socketState.directData.length !== 0 &&
        directShow === 'ONE' &&
        selectUnit.apmac_id
      ) {
        setDirectData(
          sumDirectionCount(selectUnit.apmac_id, socketState.directData),
        );
      }
      // 방향별 데이터 세팅(전체)
      if (
        'directDataAll' in socketState &&
        socketState.directDataAll.length !== 0 &&
        directShow === 'ALL'
      ) {
        const directDataAllObj = socketState.list.reduce((acc, curr) => {
          // console.log('sumDirectionCount : ', curr.apmac_id);
          // console.log(
          //   'sumDirectionCount.directDataAll : ',
          //   socketState.directDataAll,
          // );
          acc[curr.apmac_id] = sumDirectionCount(
            curr.apmac_id,
            socketState.directDataAll.filter(el => {
              return Number(curr.apmac_id) === Number(el.fromAP_id);
            }),
          );
          return acc;
        }, {});
        setDirectDataAll(directDataAllObj);
      }
    }
  }, [socketState, directShow, selectedTimeValue]);

  // 가입 지역 선택시 타이틀 변경
  useEffect(() => {
    const tempProfileList =
      selectedTimeValue > 0
        ? dashboardStateRegion.batch_hour_profileDTOList
        : dashboardStateRegion.batch_now_profileDTOList;
    if (naverMap && mapMarkerDepth === 2) {
      const selectData = tempProfileList?.find(
        el => el.profile_id === profileId,
      );
      const center = new navermaps.LatLng(
        selectData?.latitude,
        selectData?.longitude,
      );

      !isClickedToast.current && setMoveCenter(center);
      isClickedToast.current = false;

      // setMaxMinZoom(18);
      naverMap.current.setZoom(18, true);
    }
  }, [profileId, mapMarkerDepth]);

  useEffect(() => {
    if (naverMap && moveCenter) {
      naverMap.current.panTo(moveCenter);
      naverMap.current.refresh();
    }
  }, [moveCenter]);

  // * 지도 클릭 위경도 정보
  // const naverUseMap = useMap();
  // function ClickLngLat({ mapClickPoint, setMapClickPoint }) {
  //   useEffect(() => {
  //     const mouseClick = naverUseMap.addListener('click', e => {
  //       setMapClickPoint({ lat: e.coord.y, lng: e.coord.x });
  //     });
  //     const center = naverUseMap.getCenter();
  //     console.log('center : ', center);
  //     return () => {
  //       naverUseMap.removeListener(mouseClick);
  //     };
  //   }, [mapClickPoint]);
  //   console.log('클릭 좌표확인: ', mapClickPoint);
  // }

  useEffect(() => {
    if (
      currentZoomLevel <= MID_ZOOM_LEVEL &&
      selectedProfile.profile_id !== 0
    ) {
      setSelectedProfile({ profile_id: 0 });
    }
  }, [currentZoomLevel]);

  return (
    <main id="dashboard" className="auth">
      <Container fluid>
        <MapDiv
          style={{
            width: '100%',
            height: '100%',
          }}
          className="map-box"
          fallback={null}
        >
          <NaverMap
            ref={naverMap}
            defaultCenter={defaultCenter}
            disableKineticPan={false}
            defaultZoom={currentZoomLevel}
            minZoom={MIN_ZOOM_LEVEL}
            maxZoom={MAX_ZOOM_LEVEL}
            mapTypeId={mapTypeId}
            onZoomChanged={level => {
              setCurrentZoomLevel(level);
            }}
            onCenterChanged={latLng => setMapCentorPoint(latLng)}
          >
            {/* <ClickLngLat
              mapClickPoint={mapClickPoint}
              setMapClickPoint={setMapClickPoint}
            /> */}
            {mapModeId === 'HEATMAP' && (
              <HeatMapMarker heatmapDataList={heatmapDataList} />
            )}
            {/* 행정구역별 */}
            {currentZoomLevel === MIN_ZOOM_LEVEL &&
              profileId === 0 &&
              mapMarkerDepth === 0 &&
              !!socketState.profileList &&
              polygonMarkerList.map((v, i) => {
                const content = ReactDOMServer.renderToString(
                  // 행정동 마커
                  <div>
                    <div className="custom-circle-marker">
                      <div className="custom-circle-marker-text">
                        {socketState.profileList.length}
                      </div>
                      <div className="custom-circle-market-text-2">창원시</div>
                    </div>
                  </div>,
                );

                const { latitude, longitude } = socketState.profileList[0];

                return (
                  <PolygonMarker
                    key={`pm-${i}`}
                    data={v}
                    content={content}
                    onClick={() => {
                      setMoveCenter(new navermaps.LatLng(latitude, longitude));
                      naverMap.current.setZoom(MID_ZOOM_LEVEL, true);
                      isClickedToast.current = false;
                    }}
                  />
                );
              })}
            {/* 가입지역, 미가입 지역 표시 */}
            {currentZoomLevel === MIN_ZOOM_LEVEL &&
              profileId === 0 &&
              mapMarkerDepth === 0 &&
              polygonList.map((v, i) => {
                return <PolygonLine data={v} />;
              })}
            {/* 프로파일 오버레이 마커 */}
            {currentZoomLevel <= MID_ZOOM_LEVEL &&
              currentZoomLevel > MIN_ZOOM_LEVEL &&
              // mapMarkerDepth === 0 &&
              // {mapMarkerDepth === 1 &&
              !!socketState.profileList &&
              socketState.profileList.map((v, i) => {
                const {
                  count,
                  latitude,
                  longitude,
                  profile_id: profileId,
                  profile_nm: profileName,
                } = v;
                const content = ReactDOMServer.renderToString(
                  <div
                    className="custom-marker wide"
                    style={{ display: 'grid', paddingTop: '10px' }}
                  >
                    <div className="custom-marker-title">{profileName}</div>
                    <div className="custom-marker-icon">
                      <svg
                        width="13"
                        height="22"
                        viewBox="0 0 13 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="custom-marker-value">
                      <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                        rel="stylesheet"
                      />
                      {Utils.numberComma(count)}
                    </div>
                  </div>,
                );

                return (
                  <PolygonMarker
                    data={{ lat: latitude, lng: longitude }}
                    content={content}
                    onClick={() => {
                      setSelectedProfile(selectProfile(profileId));
                      naverMap.current.setZoom(MAX_ZOOM_LEVEL, true);
                      setMoveCenter(new navermaps.LatLng(latitude, longitude));
                    }}
                  />
                );
              })}
            {/* AP 오버레이 마커(신호 무) */}
            {currentZoomLevel <= MAX_ZOOM_LEVEL &&
              currentZoomLevel > MID_ZOOM_LEVEL &&
              noSignalAPList.map((v, i) => {
                const { ap_nm: apNm } = v;

                const content = ReactDOMServer.renderToString(
                  <div
                    className="custom-marker nosign"
                    style={{
                      background: 'rgba(80, 80, 80, 0.5)',
                      display: 'grid',
                      paddingTop: '10px',
                    }}
                  >
                    <div className="custom-marker-title-nosign">{apNm}</div>
                    <div className="custom-marker-icon-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                      >
                        <path
                          d="M790-56 414-434q-47 11-87.5 33T254-346l-84-86q32-32 69-56t79-42l-90-90q-41 21-76.5 46.5T84-516L0-602q32-32 66.5-57.5T140-708l-84-84 56-56 736 736-58 56Zm-310-64q-42 0-71-29.5T380-220q0-42 29-71t71-29q42 0 71 29t29 71q0 41-29 70.5T480-120Zm236-238-29-29-29-29-144-144q81 8 151.5 41T790-432l-74 74Zm160-158q-77-77-178.5-120.5T480-680q-21 0-40.5 1.5T400-674L298-776q44-12 89.5-18t92.5-6q142 0 265 53t215 145l-84 86Z"
                          fill="rgba(255, 255, 255, 0.5)"
                        />
                      </svg>
                    </div>
                  </div>,
                );
                return (
                  <UnitMarker data={v} content={content} onClick={e => {}} />
                );
              })}
            {/* AP 오버레이 마커(신호 유) */}
            {currentZoomLevel <= MAX_ZOOM_LEVEL &&
              currentZoomLevel > MID_ZOOM_LEVEL &&
              socketState.list.map((v, i) => {
                // let arrowShow = false;
                let content = ReactDOMServer.renderToString(
                  <div
                    // className="custom-marker"
                    className={`custom-marker ${
                      directionType === 'RIDING' && mapModeId === 'DIRECTION'
                        ? 'pink'
                        : null
                    }`}
                    style={
                      !(
                        directionType !== 'WALKING' && mapModeId === 'DIRECTION'
                      )
                        ? {
                            background: '#142286',
                            display: 'grid',
                            paddingTop: '10px',
                          }
                        : {
                            background: '#f6960a',
                            display: 'grid',
                            paddingTop: '10px',
                          }
                    }
                  >
                    <div className="custom-marker-title">{v.ap_nm}</div>
                    <div className="custom-marker-icon">
                      {!(
                        directionType !== 'WALKING' && mapModeId === 'DIRECTION'
                      ) ? (
                        <svg
                          width="13"
                          height="22"
                          viewBox="0 0 13 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                            fill="white"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="20"
                          height="16"
                          viewBox="0 0 20 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.92478 15.787H2.88321C3.48519 15.787 3.95252 15.3197 3.95252 14.7177V13.3474C5.71887 13.4583 8.32484 13.5454 10.4239 13.5454C12.5229 13.5454 15.1289 13.4662 16.8873 13.3474V14.7177C16.8873 15.3197 17.3546 15.787 17.9645 15.787H18.915C19.5249 15.787 19.9923 15.3197 19.9923 14.7177V10.0207C19.9923 8.61866 19.7309 7.84242 18.9942 6.884L18.2893 5.99686C17.9962 4.57903 17.4734 3.08199 17.1962 2.49584C16.7526 1.55326 15.8972 0.998801 14.8041 0.848305C14.2259 0.769096 12.5308 0.729492 10.4239 0.729492C8.31692 0.729492 6.61393 0.777017 6.04363 0.848305C4.95055 0.98296 4.08718 1.55326 3.65153 2.49584C3.3743 3.08199 2.85152 4.57903 2.55845 5.99686L1.8535 6.884C1.10894 7.84242 0.855469 8.61866 0.855469 10.0207V14.7177C0.855469 15.3197 1.3228 15.787 1.92478 15.787ZM4.33272 5.26814C4.5149 4.48398 4.86342 3.43842 5.10105 3.01862C5.33075 2.59881 5.59214 2.42456 6.08323 2.36119C6.64562 2.28198 8.00008 2.23445 10.4239 2.23445C12.8397 2.23445 14.2021 2.26614 14.7645 2.36119C15.2556 2.43248 15.5091 2.59881 15.7467 3.01862C15.9922 3.4305 16.317 4.48398 16.5071 5.26814C16.5942 5.60874 16.4437 5.76716 16.0873 5.75131C14.8675 5.68003 13.3308 5.60082 10.4239 5.60082C7.51691 5.60082 5.98026 5.68003 4.76045 5.75131C4.39609 5.76716 4.26144 5.60874 4.33272 5.26814ZM4.61788 11.3276C3.86539 11.3276 3.28717 10.7573 3.28717 10.0048C3.28717 9.24441 3.86539 8.67411 4.61788 8.67411C5.37036 8.67411 5.94066 9.24441 5.94066 10.0048C5.94066 10.7573 5.37036 11.3276 4.61788 11.3276ZM16.2299 11.3276C15.4694 11.3276 14.8991 10.7573 14.8991 10.0048C14.8991 9.24441 15.4694 8.67411 16.2299 8.67411C16.9823 8.67411 17.5526 9.24441 17.5526 10.0048C17.5526 10.7573 16.9823 11.3276 16.2299 11.3276ZM8.28523 10.9949C7.71493 10.9949 7.31889 10.5989 7.31889 10.0365C7.31889 9.47412 7.71493 9.07807 8.28523 9.07807H12.5625C13.1249 9.07807 13.5209 9.47412 13.5209 10.0365C13.5209 10.5989 13.1249 10.9949 12.5625 10.9949H8.28523Z"
                            fill="#002060"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="custom-marker-value">
                      <link
                        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                        rel="stylesheet"
                      />
                      {Utils.numberComma(v.count)}
                    </div>
                  </div>,
                );
                if (selectUnit && selectUnit.apmac_id === v.apmac_id) {
                  switch (mapModeId) {
                    case 'DIRECTION':
                      // arrowShow = true;
                      content = ReactDOMServer.renderToString(
                        <div style={{ display: 'inline-block' }}>
                          <div
                            className={`custom-marker ${
                              directionType === 'RIDING' &&
                              mapModeId === 'DIRECTION'
                                ? 'pink'
                                : null
                            }`}
                            style={
                              directionType === 'WALKING'
                                ? {
                                    // background:
                                    //   'linear-gradient(to bottom, #2787cc, #195078)',
                                    background: '#142286',
                                    display: 'grid',
                                    paddingTop: '10px',
                                  }
                                : {
                                    // background:
                                    //   'linear-gradient(to bottom, #F85F8B, #FF2564)',
                                    background: '#f6960a',
                                    display: 'grid',
                                    paddingTop: '10px',
                                  }
                            }
                          >
                            <div className="custom-marker-title">{v.ap_nm}</div>
                            <div className="custom-marker-icon">
                              {directionType === 'WALKING' ? (
                                <svg
                                  width="13"
                                  height="22"
                                  viewBox="0 0 13 22"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                    fill="white"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  width="20"
                                  height="16"
                                  viewBox="0 0 20 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.92478 15.787H2.88321C3.48519 15.787 3.95252 15.3197 3.95252 14.7177V13.3474C5.71887 13.4583 8.32484 13.5454 10.4239 13.5454C12.5229 13.5454 15.1289 13.4662 16.8873 13.3474V14.7177C16.8873 15.3197 17.3546 15.787 17.9645 15.787H18.915C19.5249 15.787 19.9923 15.3197 19.9923 14.7177V10.0207C19.9923 8.61866 19.7309 7.84242 18.9942 6.884L18.2893 5.99686C17.9962 4.57903 17.4734 3.08199 17.1962 2.49584C16.7526 1.55326 15.8972 0.998801 14.8041 0.848305C14.2259 0.769096 12.5308 0.729492 10.4239 0.729492C8.31692 0.729492 6.61393 0.777017 6.04363 0.848305C4.95055 0.98296 4.08718 1.55326 3.65153 2.49584C3.3743 3.08199 2.85152 4.57903 2.55845 5.99686L1.8535 6.884C1.10894 7.84242 0.855469 8.61866 0.855469 10.0207V14.7177C0.855469 15.3197 1.3228 15.787 1.92478 15.787ZM4.33272 5.26814C4.5149 4.48398 4.86342 3.43842 5.10105 3.01862C5.33075 2.59881 5.59214 2.42456 6.08323 2.36119C6.64562 2.28198 8.00008 2.23445 10.4239 2.23445C12.8397 2.23445 14.2021 2.26614 14.7645 2.36119C15.2556 2.43248 15.5091 2.59881 15.7467 3.01862C15.9922 3.4305 16.317 4.48398 16.5071 5.26814C16.5942 5.60874 16.4437 5.76716 16.0873 5.75131C14.8675 5.68003 13.3308 5.60082 10.4239 5.60082C7.51691 5.60082 5.98026 5.68003 4.76045 5.75131C4.39609 5.76716 4.26144 5.60874 4.33272 5.26814ZM4.61788 11.3276C3.86539 11.3276 3.28717 10.7573 3.28717 10.0048C3.28717 9.24441 3.86539 8.67411 4.61788 8.67411C5.37036 8.67411 5.94066 9.24441 5.94066 10.0048C5.94066 10.7573 5.37036 11.3276 4.61788 11.3276ZM16.2299 11.3276C15.4694 11.3276 14.8991 10.7573 14.8991 10.0048C14.8991 9.24441 15.4694 8.67411 16.2299 8.67411C16.9823 8.67411 17.5526 9.24441 17.5526 10.0048C17.5526 10.7573 16.9823 11.3276 16.2299 11.3276ZM8.28523 10.9949C7.71493 10.9949 7.31889 10.5989 7.31889 10.0365C7.31889 9.47412 7.71493 9.07807 8.28523 9.07807H12.5625C13.1249 9.07807 13.5209 9.47412 13.5209 10.0365C13.5209 10.5989 13.1249 10.9949 12.5625 10.9949H8.28523Z"
                                    fill="#002060"
                                  />
                                </svg>
                              )}
                            </div>
                            <div className="custom-marker-value">
                              <link
                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                                rel="stylesheet"
                              />
                              {Utils.numberComma(v.count)}
                            </div>
                          </div>
                        </div>,
                      );
                      break;
                    default:
                      content = ReactDOMServer.renderToString(
                        // 커스텀 마커
                        <div style={{ display: 'inline-block' }}>
                          <div
                            className="custom-marker"
                            style={{ display: 'grid', paddingTop: '10px' }}
                          >
                            <div className="custom-marker-title">{v.ap_nm}</div>
                            <div className="custom-marker-icon">
                              <svg
                                width="13"
                                height="22"
                                viewBox="0 0 13 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.12 9.8H13V8H9.38L7.38 4.67C7.08 4.17 6.54 3.83 5.92 3.83C5.74 3.83 5.58 3.86 5.42 3.91L0 5.6V10.8H1.8V7.13L3.91 6.47L0 21.8H1.8L4.67 13.69L7 16.8V21.8H8.8V15.39L6.31 10.85L7.04 7.98M8 3.6C9 3.6 9.8 2.8 9.8 1.8C9.8 0.8 9 0 8 0C7 0 6.2 0.8 6.2 1.8C6.2 2.8 7 3.6 8 3.6Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <div className="custom-marker-value">
                              <link
                                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap"
                                rel="stylesheet"
                              />
                              {Utils.numberComma(v.count)}
                            </div>
                          </div>
                        </div>,
                      );
                      break;
                  }
                }

                const apmacId = Number(v.apmac_id);
                const selectedApmacId = Number(selectUnit.apmac_id);
                const directionValuesObj = directDataAll[apmacId];

                return (
                  <>
                    <UnitMarker
                      data={v}
                      key={i}
                      content={content}
                      onClick={e => {
                        setDirectData({});
                        if (e.apmac_id === selectUnit.apmac_id) {
                          setSelectUnit({});
                        } else {
                          setSelectUnit(e);
                        }
                        setFloatPopShow(mapModeId !== 'DIRECTION' && true);
                      }}
                    />
                    {mapModeId === 'DIRECTION' &&
                      directShow === 'ONE' &&
                      apmacId === selectedApmacId &&
                      Object.keys(selectUnit).length > 0 &&
                      Object.keys(directData).map((w, j) => (
                        <UnitArrowMarker
                          data={v}
                          content={ReactDOMServer.renderToString(
                            <ArrowMain
                              transform={directData[w].deg}
                              color={directData[w].colorLevel}
                              value={directData[w].count}
                            />,
                          )}
                          clickable={false}
                        />
                      ))}
                    {mapModeId === 'DIRECTION' &&
                      directShow === 'ALL' &&
                      !!directionValuesObj &&
                      Object.keys(directionValuesObj).map(
                        (w, j) =>
                          (
                            <UnitArrowMarker
                              data={v}
                              content={ReactDOMServer.renderToString(
                                <ArrowMain
                                  transform={directionValuesObj[w].deg}
                                  color={directionValuesObj[w].colorLevel}
                                  value={directionValuesObj[w].count}
                                />,
                              )}
                              clickable={false}
                            />
                          ) || null,
                      )}
                  </>
                );
              })}
            {/* 좌측 상단 breadcrumb */}
            <ul className="map-breadcrumb">
              <li
                onClick={() => {
                  isClickedToast.current = false;
                  setMapModeId('NORMAL');
                  naverMap.current.setZoom(MIN_ZOOM_LEVEL, true);
                  setMoveCenter(
                    new navermaps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE),
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                경상남도
              </li>
              {currentZoomLevel > MIN_ZOOM_LEVEL && (
                <li
                  onClick={() => {
                    isClickedToast.current = false;
                    setMapModeId('NORMAL');
                    naverMap.current.setZoom(MID_ZOOM_LEVEL, true);
                    !!socketState.profileList &&
                      setMoveCenter(
                        new navermaps.LatLng(
                          socketState.profileList.latitude,
                          socketState.profileList.longitude,
                        ),
                      );
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  창원시
                </li>
              )}
              {currentZoomLevel > MID_ZOOM_LEVEL && <li>{profileName}</li>}
            </ul>
            {/* 우측 상단 고정영역 */}
            <div className="map-fix-right map-permission">
              {currentZoomLevel === MIN_ZOOM_LEVEL && (
                // 가입지역, 미가입지역
                <div className="map-help">
                  <span>가입 지역</span>
                  <span>미가입 지역</span>
                </div>
              )}
            </div>
            <div className="map-fix-right map-btn-right">
              {buttons.map(btn => {
                return (
                  <button
                    type="button"
                    key={btn.typeId}
                    className="btn-wide-view"
                    style={{
                      backgroundColor:
                        btn.typeId === mapTypeId ? '#0070d7' : 'white',
                      color: btn.typeId === mapTypeId ? 'white' : 'black',
                    }}
                    onClick={() => {
                      setMapTypeId(btn.typeId);
                    }}
                  >
                    {btn.text}
                  </button>
                );
              })}
            </div>
            <BoardTitle
              mapMarkerDepth={mapMarkerDepth}
              chartData={chartData}
              barChartData={barChartData}
              mapModeId={mapModeId}
              setMapModeId={setMapModeId}
              setSocketDepth={setSocketDepth}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              directionType={directionType}
              setDirectionType={setDirectionType}
              socketDepth={socketDepth}
              socketState={socketState}
              labels={labels}
              mapTypeId={mapTypeId}
              currentZoomLevel={currentZoomLevel}
              maxZoomLevel={MAX_ZOOM_LEVEL}
              directShow={directShow}
              setDirectShow={setDirectShow}
            />
          </NaverMap>
        </MapDiv>
      </Container>
      <CustomModal
        title={`${selectUnit?.ap_nm || ''} 유동인구`}
        show={floatPopShow}
        onHide={() => {
          setFloatPopShow(false);
          setSelectUnit({});
        }}
      >
        <Modal.Body>
          <div className="chart-area">
            <Chart data={chartDataAP} />
          </div>
        </Modal.Body>
      </CustomModal>
      <ToastContainer />
    </main>
  );
});
