import REST from '@common/rest';

const baseURL = process.env.REACT_APP_API_URL;
const API_VERSION = 'api/v1';

export const DOMAIN_AUTH = `${API_VERSION}/auth`;

// WSAD_IF_001 ::  로그인
export const login = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/login`, data);
};

// WSAD_IF_002 ::  아이디 찾기
export const findId = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/find-id`, data);
};

// WSAD_IF_003 ::  Refresh Token 갱신
export const getRefreshToken = data => {
  return REST.get(`${baseURL + DOMAIN_AUTH}/refresh-token`, {
    params: data,
  });
};

// WSAD_IF_004 ::  Access Token 재발행
export const getAccessToken = data => {
  return REST.get(`${baseURL + DOMAIN_AUTH}/access-token`, {
    params: data,
  });
};

// WSAD_IF_005 ::  메일 인증 요청
export const authEmail = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/auth-email`, data);
};

// WSAD_IF_006 ::  메일 인증 확인
export const confirmEmail = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/confirm-email`, data);
};

// WSAD_IF_007 ::  패스워드 재설정
export const changePw = data => {
  return REST.patch(`${baseURL + DOMAIN_AUTH}/change-pw`, data);
};

// WSAD_IF_007 ::  메뉴 리스트 및 권한 조회
export const getAdmMenus = () => {
  return REST.get(`${baseURL + DOMAIN_AUTH}/adm-menus`);
};
