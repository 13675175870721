/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Form } from 'react-bootstrap';
import { CustomModal, CustomSelect, CustomDatePicker } from '@components';
import { Options } from '@common';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import {
  confirmPromoRequest,
  getProfiles,
  getPromoArea,
  getPromoManage,
  getScheduleStatus,
  getUnitPriceByPromo,
  getUnitPriceByPromoReq,
  patchPromotion,
  patchSchedule,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import Utils from '../../common/Utils';
import CustomSwal from '../CustomSwal';
import RouterPath from '../../common/RouterPath';

function PrmScheduleModal({ isUpdate, profileId, prmDetail, ...rest }) {
  const allTime = {
    label: '00:00 - 24:00',
    value: 'all',
    startTime: '00:00:00',
    endTime: '24:00:00',
  };
  const history = useHistory();

  const {
    promoId,
    startDate,
    endDate,
    requestId,
    unitPrice: prevUnitPrice,
    title,
    chargeYn,
    cpaYn,
    cpcYn,
    cpvYn,
    cpaPrice,
    cpcPrice,
    cpvPrice,
    startTime,
    endTime,
    codeArea,
  } = prmDetail;

  // 사업단위
  const [profileList, setProfileList] = useState([]);
  const [selectProfile, setSelectProfile] = useState();
  const [expTitle, setExpTitle] = useState(title);
  const [unitPrice, setUnitPrice] = useState(prevUnitPrice || '');
  const [unitPriceAddComma, setnitPriceAddComma] = useState(
    Utils.changeNumberComma(prevUnitPrice) || '',
  );

  const [unitPriceData, setUnitPriceData] = useState({});

  const [expCpvPrice, setExpCpvPrice] = useState(cpvPrice);
  const [expCpcPrice, setExpCpcPrice] = useState(cpcPrice);
  const [expCpaPrice, setExpCpaPrice] = useState(cpaPrice);
  const [expCpvYn, setExpCpvYn] = useState(
    chargeYn === 'Y' ? 'Y' : cpvYn || 'N',
  );
  const [expCpcYn, setExpCpcYn] = useState(
    chargeYn === 'Y' ? 'Y' : cpcYn || 'N',
  );
  const [expCpaYn, setExpCpaYn] = useState(
    chargeYn === 'Y' ? 'Y' : cpaYn || 'N',
  );

  // 스케줄 상태
  const [scheduleStatus, setScheduleStatus] = useState({});

  // 홍보 영역
  const [prmAreaList, setPrmAreaList] = useState([]);
  const [selectPrmArea, setSelectPrmArea] = useState({});
  // 노출일시
  const [expStartDate, setExpStartDate] = useState(
    Utils.stringToDate(startDate),
  );
  const [expEndDate, setExpEndDate] = useState(Utils.stringToDate(endDate));
  const [expTime, setExpTime] = useState({});
  const [expAllTime, setExpAllTime] = useState(false);
  const [expTimeList, setExpTimeList] = useState([]);
  const [expTimeForFullList, setExpTimeForFullList] = useState([
    {
      label: '00:00 ~ 01:00',
      value: 1,
      startTime: '00:00:00',
      endTime: '01:00:00',
    },
    {
      label: '01:00 ~ 02:00',
      value: 2,
      startTime: '01:00:00',
      endTime: '02:00:00',
    },
    {
      label: '02:00 ~ 03:00',
      value: 3,
      startTime: '02:00:00',
      endTime: '03:00:00',
    },
    {
      label: '03:00 ~ 04:00',
      value: 3,
      startTime: '03:00:00',
      endTime: '04:00:00',
    },
    {
      label: '04:00 ~ 05:00',
      value: 4,
      startTime: '04:00:00',
      endTime: '05:00:00',
    },
    {
      label: '05:00 ~ 06:00',
      value: 5,
      startTime: '05:00:00',
      endTime: '06:00:00',
    },
    {
      label: '06:00 ~ 07:00',
      value: 6,
      startTime: '06:00:00',
      endTime: '07:00:00',
    },
    {
      label: '07:00 ~ 08:00',
      value: 7,
      startTime: '07:00:00',
      endTime: '08:00:00',
    },
    {
      label: '08:00 ~ 09:00',
      value: 8,
      startTime: '08:00:00',
      endTime: '09:00:00',
    },
    {
      label: '09:00 ~ 10:00',
      value: 9,
      startTime: '09:00:00',
      endTime: '10:00:00',
    },
    {
      label: '10:00 ~ 11:00',
      value: 10,
      startTime: '10:00:00',
      endTime: '11:00:00',
    },
    {
      label: '11:00 ~ 12:00',
      value: 11,
      startTime: '11:00:00',
      endTime: '12:00:00',
    },
    {
      label: '12:00 ~ 13:00',
      value: 12,
      startTime: '12:00:00',
      endTime: '13:00:00',
    },
    {
      label: '13:00 ~ 14:00',
      value: 13,
      startTime: '13:00:00',
      endTime: '14:00:00',
    },
    {
      label: '14:00 ~15:00',
      value: 13,
      startTime: '14:00:00',
      endTime: '15:00:00',
    },
    {
      label: '15:00 ~ 16:00',
      value: 15,
      startTime: '15:00:00',
      endTime: '16:00:00',
    },
    {
      label: '16:00 ~ 17:00',
      value: 16,
      startTime: '16:00:00',
      endTime: '17:00:00',
    },
    {
      label: '17:00 ~ 18:00',
      value: 17,
      startTime: '17:00:00',
      endTime: '18:00:00',
    },
    {
      label: '18:00 ~ 19:00',
      value: 18,
      startTime: '18:00:00',
      endTime: '19:00:00',
    },
    {
      label: '19:00 ~ 20:00',
      value: 19,
      startTime: '19:00:00',
      endTime: '20:00:00',
    },
    {
      label: '20:00 ~ 21:00',
      value: 20,
      startTime: '20:00:00',
      endTime: '21:00:00',
    },
    {
      label: '21:00 ~ 22:00',
      value: 21,
      startTime: '21:00:00',
      endTime: '22:00:00',
    },
    {
      label: '22:00 ~ 23:00',
      value: 22,
      startTime: '22:00:00',
      endTime: '23:00:00',
    },
    {
      label: '23:00 ~ 00:00',
      value: 23,
      startTime: '23:00:00',
      endTime: '00:00:00',
    },
  ]);
  const [checkedDays, setCheckedDays] = useState([]); // 요일

  const getExpTimeList = async () => {
    try {
      const { data } = await getPromoManage();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempDate = {
            label: v.manageNm,
            value: v.promoManageId,
            startTime: v.startTime,
            endTime: v.endTime,
          };
          return tempDate;
        });
        setExpTimeList(tempList);
        if (startTime && endTime) {
          if (allTime.startTime === startTime && allTime.endTime === endTime) {
            setExpTime(allTime);
            setExpAllTime(true);
          } else {
            const tempData = {
              label: `${Utils.timeFormatter(startTime)} - ${Utils.timeFormatter(
                endTime,
              )}`,
              value: null,
              startTime,
              endTime,
            };
            setExpTime(
              tempList.find(
                v => v.startTime === startTime && v.endTime === endTime,
              ) || tempData,
            );
          }
        } else if (String(codeArea).includes('FULL')) {
          setExpTime(expTimeForFullList[0]);
        } else {
          setExpTime(tempList[0]);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getScheduleStatusData = async () => {
    try {
      const yearMonth = Utils.getYearMonthString(new Date());
      const params = {
        yearMonth,
        profileId: selectProfile.value,
      };
      const { data } = await getScheduleStatus(params);
      if (data.code === 200) {
        setScheduleStatus(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const types = [
    { type: 'Cpc', setter: e => setExpCpcPrice(e), value: cpcPrice },
    { type: 'Cpv', setter: e => setExpCpvPrice(e), value: cpvPrice },
    { type: 'Cpa', setter: e => setExpCpaPrice(e), value: cpaPrice },
  ];

  const settingCpPrice = () => {
    types.forEach(v => {
      const tempPrice =
        unitPriceData[
          `${String(selectPrmArea.code.split('-')[0]).toLocaleLowerCase()}${
            v.type
          }`
        ];
      v.setter(tempPrice);
    });
  };

  const getReqUnitPriceData = async () => {
    try {
      const { data } = await getUnitPriceByPromoReq(requestId);
      if (data.code === 200) {
        setUnitPriceData(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getUnitPriceData = async () => {
    try {
      const { data } = await getUnitPriceByPromo(promoId);
      if (data.code === 200) {
        types.forEach(v => {
          data.data[
            `${String(codeArea.split('-')[0]).toLocaleLowerCase()}${v.type}`
          ] = v.value;
        });
        setUnitPriceData(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        setProfileList(tempProfileList);
        setSelectProfile(tempProfileList.find(v => v.value === profileId));
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaList = async () => {
    try {
      const { data } = await getPromoArea();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
            code: v.code,
          };
          return tempData;
        });
        let tempSelect = null;
        if (promoId) {
          tempSelect = tempList.find(v => v.code === codeArea);
        } else {
          tempSelect = tempList.find(v =>
            String(v.code).includes(String(codeArea.split('-')[0])),
          );
        }
        setPrmAreaList(tempList);
        setSelectPrmArea(tempSelect || tempList[0]);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Errors);
        }
      },
    });
  };

  const confirmPrm = async event => {
    const target = event.currentTarget;
    try {
      const params = {
        promoId,
        requestId,
        title: expTitle,
        promoAreaId: selectPrmArea.value,
        profileId: selectProfile.value,
        startDate: Utils.dateToString(expStartDate),
        endDate: Utils.dateToString(expEndDate),
        startTime: expTime.startTime,
        endTime: expTime.endTime,
        cpvYn: expCpvYn,
        cpcYn: expCpcYn,
        cpaYn: expCpaYn,
        cpvPrice: expCpvPrice ?? 0,
        cpcPrice: expCpcPrice ?? 0,
        cpaPrice: expCpaPrice,
        unitPrice,
      };
      const { data } = isUpdate
        ? await patchSchedule(params)
        : await confirmPromoRequest(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `성공적으로 ${isUpdate ? '수정' : '승인'}되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            if (isUpdate) {
              // history.push(RouterPath.prmConfirmList);
              window.location.reload();
            } else {
              history.push(RouterPath.prmApprovalList);
            }
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const handleInputChange = e => {
    const { value } = e.target;
    const numOnly = value.replace(/[^0-9]/g, '');
    const formattedNum = Utils.changeNumberComma(numOnly);
    setnitPriceAddComma(formattedNum);
    setUnitPrice(numOnly);
  };

  const defaultApi = async () => {
    await getPromoAreaList();
    await getExpTimeList();
    await getProfileList();
    if (!isUpdate) {
      await getReqUnitPriceData();
    } else {
      await getUnitPriceData();
    }
  };

  const [isDefaultApi, setIsDefaultApi] = useState(false);

  useEffect(() => {
    defaultApi().then(() => {
      setIsDefaultApi(true);
    });
  }, []);

  useEffect(() => {
    if (selectProfile && selectProfile.value) {
      getScheduleStatusData();
    }
  }, [selectProfile]);

  useEffect(() => {
    if (unitPriceData && selectPrmArea && selectPrmArea.code) {
      settingCpPrice();
    }
  }, [selectPrmArea, unitPriceData]);

  useEffect(() => {
    if (isDefaultApi && selectPrmArea && selectPrmArea.code === 'FULL') {
      setExpTime(expTimeForFullList[0]);
    } else {
      setExpTime(expTimeList[0]);
    }
    setExpAllTime(false);
  }, [selectPrmArea]);

  return (
    <CustomModal
      title="홍보 스케줄 관리"
      size="lg"
      id="modal-prm-schedule"
      {...rest}
    >
      <Modal.Body>
        <ul className="list-prmType">
          <li>
            <h6>ESG</h6>
            <strong className="text-success">
              {Utils.calculatePercentage(scheduleStatus.rateEsg, 1)}
            </strong>
          </li>
          <li>
            <h6>소상공인</h6>
            <strong className="text-warning">
              {Utils.calculatePercentage(scheduleStatus.rateBusiness, 1)}
            </strong>
          </li>
          <li>
            <h6>지자체</h6>
            <strong className="text-primary">
              {Utils.calculatePercentage(scheduleStatus.rateLocalGov, 1)}
            </strong>
          </li>
        </ul>
        <Form>
          <Form.Group className="form-group">
            <Form.Label>타이틀</Form.Label>
            <Form.Control
              value={expTitle}
              onChange={e => {
                setExpTitle(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>홍보영역</Form.Label>
            <CustomSelect
              options={prmAreaList}
              value={selectPrmArea}
              onChange={setSelectPrmArea}
              className="mw-160"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>사업단위</Form.Label>
            <CustomSelect
              options={profileList}
              value={selectProfile}
              onChange={setSelectProfile}
              className="mw-160"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>사업단위</Form.Label>
            {selectPrmArea &&
              selectPrmArea.code &&
              ['checkbox'].map(type => (
                <div key={`inline-${type}`} className="flex-start grid-line">
                  <div>
                    <Form.Check
                      type={type}
                      id={`inline-${type}-1`}
                      name="group1"
                      label="CPV"
                      checked={expCpvYn === 'Y'}
                      onChange={e => {
                        setExpCpvYn(e.target.checked ? 'Y' : 'N');
                      }}
                    />
                  </div>
                  <p>{Utils.changeNumberComma(expCpvPrice)}</p>
                  <div>
                    <Form.Check
                      type={type}
                      id={`inline-${type}-2`}
                      name="group1"
                      label="CPC"
                      checked={expCpcYn === 'Y'}
                      onChange={e => {
                        setExpCpcYn(e.target.checked ? 'Y' : 'N');
                      }}
                    />
                  </div>
                  <p>{Utils.changeNumberComma(expCpcPrice)}</p>
                  <div>
                    <Form.Check
                      type={type}
                      disabled
                      label="CPA"
                      checked={expCpaYn === 'Y'}
                      onChange={e => {
                        setExpCpaYn(e.target.checked ? 'Y' : 'N');
                      }}
                    />
                  </div>
                  <p>{Utils.changeNumberComma(expCpaPrice)}</p>
                  {/* </div> */}
                </div>
              ))}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>노출일시</Form.Label>
            <div className="datepicker-wrap mw-100">
              <CustomDatePicker
                value={expStartDate}
                // minDate={Utils.getTomorrow()}
                onChange={setExpStartDate}
                maxDate={expEndDate}
                placeholderText="yyyy.mm.dd"
                // dateFormat="yyyy-mm-dd"
                className="mw-100"
              />
              <span>-</span>
              <CustomDatePicker
                value={expEndDate}
                minDate={expStartDate}
                onChange={setExpEndDate}
                placeholderText="yyyy.mm.dd"
                // dateFormat="yyyy-mm-dd"
                className="mw-100"
              />
              <CustomSelect
                options={
                  selectPrmArea.code === 'FULL'
                    ? expTimeForFullList
                    : expTimeList
                }
                className="ms-2 mw-160"
                value={expTime}
                onChange={e => {
                  setExpAllTime(false);
                  setExpTime(e);
                }}
              />
              <Form.Check
                type="checkbox"
                id="time-all"
                value="time-all"
                label="종일"
                onChange={e => {
                  setExpAllTime(e.target.checked);
                  if (e.target.checked) {
                    setExpTime(allTime);
                  } else {
                    setExpTime(expTimeList[0]);
                  }
                }}
                checked={expAllTime}
              />
            </div>
          </Form.Group>
          {/* <Form.Group className="form-group">
            <Form.Label>요일</Form.Label>
            <div className="flex-start">
              {Options.dayOfWeek.map(item => {
                return (
                  <Form.Check
                    key={`day-${item.value}`}
                    type="checkbox"
                    id={`day-${item.value}`}
                    value={item.value}
                    label={item.label}
                    onChange={() =>
                      setCheckedDays([...checkedDays, item.value])
                    }
                    checked={
                      checkedDays.length > 0 &&
                      checkedDays.find(el => el === item.value)
                    }
                  />
                );
              })}
            </div>
          </Form.Group> */}
          {/* 평균 단가 제거
          <Form.Group className="form-group">
            <Form.Label>평균단가</Form.Label>
            <Form.Control
              className="mw-280 text-end"
              placeholder="0"
              value={unitPriceAddComma}
              maxLength={10}
              onChange={e => {
                handleInputChange(e);
              }}
            />
          </Form.Group> 
          */}
        </Form>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <Button variant="outline-primary" onClick={() => rest.onHide()}>
          닫기
        </Button>
        <Button
          onClick={event => {
            confirmPrm(event);
          }}
        >
          저장
        </Button>
      </Modal.Footer>
    </CustomModal>
  );
}
// 사업단위
export default PrmScheduleModal;
