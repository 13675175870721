import logoDark from './logo_dark.png';
import logoSm from './logo_sm.png';
import bgSignin from './bg_signin.png';
import SignInBg from './signin-bg.png';

// icon
import icUnChecked from './icon/checkbox_unchecked.png';
import icChecked from './icon/checkbox_checked.png';
import icDropdown from './icon/dropdown.png';
import icDropup from './icon/dropup.png';
import icCalendar from './icon/calendar.png';
import icClose from './icon/close_gray.png';
import icExcel from './icon/excel.png';

// dummy
import land1 from './dummy/land1.jpg';
import land2 from './dummy/land2.jpg';
import land3 from './dummy/land3.jpg';
import land4 from './dummy/land4.jpg';
import promotionimg from './dummy/promotionimg.png';
import wildgreens from './dummy/wildgreens.png';
import banner from './dummy/banner.png';

// full-mode-icon
import max from './max.png';
import min from './min.png';

export default {
  logoDark,
  logoSm,
  bgSignin,
  SignInBg,

  icCalendar,
  icClose,
  icDropdown,
  icDropup,
  icUnChecked,
  icChecked,
  icExcel,

  land1,
  land2,
  land3,
  land4,
  promotionimg,
  wildgreens,
  banner,
  min,
  max,
};
