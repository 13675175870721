/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-array-index-key */
import './assets/css/ap-monitor.css';
import io from 'socket.io-client';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Modal, Form, Table, Collapse, Card } from 'react-bootstrap';
import { Header, CustomSelect, CustomModal } from '@components';
import { Utils } from '@common';

// 스피너
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

// map
import ReactDOMServer from 'react-dom/server';
import {
  Container as MapDiv,
  Marker,
  NaverMap,
  useNavermaps,
} from 'react-naver-maps';

// AP 리스트 오름차순 정렬
const sortAPList = arr => {
  return arr?.sort((a, b) => {
    const nameA = a.ap_nm.toUpperCase();
    const nameB = b.ap_nm.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

export default React.memo(function APDashboard(props) {
  const [isLoading, setIsLoading] = useState(false);

  const navermaps = useNavermaps();
  const [mapTypeId, setMapTypeId] = useState(navermaps.MapTypeId.HYBRID);
  const buttons = [
    {
      typeId: navermaps.MapTypeId.NORMAL,
      text: '일반지도',
    },
    {
      typeId: navermaps.MapTypeId.TERRAIN,
      text: '지형도',
    },
    {
      typeId: navermaps.MapTypeId.SATELLITE,
      text: '위성지도',
    },
    {
      typeId: navermaps.MapTypeId.HYBRID,
      text: '겹쳐보기',
    },
  ];

  // 브레드크럼 정보
  // const [currentProfile, setCurrentProfile] = useState({});
  // const { profile_nm: profileName } = currentProfile;

  const apStateOptions = [
    { label: '전체', value: 0 },
    { label: '정상', value: 1 },
    { label: '장애', value: 2 },
  ];

  const [apUnitOptions, setApUnitOptions] = useState(apStateOptions[0]);
  const [apStatusList, setApStatusList] = useState([]);
  const [apStatusListGroup, setApStatusListGroup] = useState([]);

  // 상수
  const MIN_ZOOM_LEVEL = 12;
  const MID_ZOOM_LEVEL = 14;
  const MAX_ZOOM_LEVEL = 20;
  const DEFAULT_ZOOM_LEVEL = 18;
  const DEFAULT_LATITUDE = 35.5246;
  const DEFAULT_LONGITUDE = 129.4308;
  const SELECTED_PROFILE = { profile_ids: [7] }; // 울산 동구 profile_id 7
  const REGION_ID = 1;

  const naverMap = useRef();
  const [moveCenter, setMoveCenter] = useState();
  const [selectUnit, setSelectUnit] = useState({});
  const [currentZoomLevel, setCurrentZoomLevel] = useState(DEFAULT_ZOOM_LEVEL);

  // console.log('currentZoomLevel : ', currentZoomLevel);

  const [floatPopShow, setFloatPopShow] = useState(false);

  // AP 리스트 > 장비 현황 > 개별 AP 클릭 지도 이동
  const [moveAPPoint, setMoveAPPoint] = useState({});
  const isClickedAP = useRef(false);

  // 전체보기 실시간 소켓 통신 데이터
  const [socketState, setSocketState] = useState({});
  const [selectGroup, setSelectGroup] = useState({});
  const { apList } = selectGroup;

  const [collapseOpen, setCollapseOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(socketState).length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [socketState]);

  // 전체보기 실시간 처리용 소켓 통신
  useEffect(() => {
    const socket = io('wss://wss.leo-wifree.com');
    // const socket = io('ws://localhost:7080');
    // const socket = io('ws://121.174.87.208:7080');

    const payload = {
      profileIds: SELECTED_PROFILE.profile_ids,
      regionId: REGION_ID,
    };

    socket.emit('dashboard-ap', payload);
    socket.on('response', dataFromServer => {
      // console.log('dataFromServer : ', dataFromServer);

      setSocketState(dataFromServer);
    });
    return () => {
      socket.off('response');
    };
  }, []);

  useEffect(() => {
    const apStatusObj = socketState?.apInfo?.reduce((acc, curr) => {
      const groupKey = curr.ap_nm.split('_')[0];
      const groupObj = {
        status: 'run',
        apList: [],
      };
      if (curr.ap_status !== 'run') {
        groupObj.status = curr.ap_status;
      }
      if (!(groupKey in acc)) {
        groupObj.apList = [curr];
        groupObj.latitude = curr.latitude;
        groupObj.longitude = curr.longitude;
        groupObj.groupName = groupKey;
        acc[groupKey] = { ...groupObj };
      } else {
        groupObj.apList = [...acc[groupKey].apList, curr];
        if (acc[groupKey].status !== 'run') {
          groupObj.status = acc[groupKey].status;
        }
        acc[groupKey] = { ...acc[groupKey], ...groupObj };
      }
      return acc;
    }, {});

    setApStatusListGroup(apStatusObj);

    switch (apUnitOptions.value) {
      case 1:
        setApStatusList(
          sortAPList(socketState.apInfo.filter(el => el.ap_status === 'run')),
        );
        break;
      case 2:
        setApStatusList(
          sortAPList(socketState.apInfo.filter(el => el.ap_status !== 'run')),
        );
        break;
      default:
        setApStatusList(sortAPList(socketState.apInfo));
    }
  }, [apUnitOptions, socketState]);

  useEffect(() => {
    if (Object.keys(moveAPPoint).length === 0) return;

    isClickedAP.current = true;

    const { latitude, longitude } = moveAPPoint;

    naverMap.current.setZoom(MAX_ZOOM_LEVEL, true);
    setMoveCenter(new navermaps.LatLng(latitude, longitude));
  }, [moveAPPoint]);

  useEffect(() => {
    if (naverMap && moveCenter) {
      naverMap.current.panTo(moveCenter);
      naverMap.current.refresh();
    }
  }, [moveCenter]);

  return (
    <main id="dashboard" className="prevent-user-select">
      {isLoading && <Loading />}
      <Header title="AP 상황판" />
      <Container fluid>
        <MapDiv
          style={{
            width: '100%',
            height: '100%',
          }}
          className="map-box"
          fallback={null}
        >
          <NaverMap
            ref={naverMap}
            defaultCenter={
              new navermaps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE)
            }
            disableKineticPan={false}
            defaultZoom={currentZoomLevel}
            minZoom={MIN_ZOOM_LEVEL}
            maxZoom={MAX_ZOOM_LEVEL}
            mapTypeId={mapTypeId}
            onZoomChanged={level => {
              setCurrentZoomLevel(level);
            }}
          >
            {/* AP 마커 */}
            {!!apStatusListGroup &&
              Object.keys(apStatusListGroup)?.map((key, idx) => {
                const group = apStatusListGroup[key];
                if (group.status !== 'run') {
                  const content = ReactDOMServer.renderToString(
                    <div className="custom-marker-status-abnormal">
                      <div className="custom-marker-title-status">{key}</div>
                      <div className="custom-marker-icon-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="28"
                          viewBox="0 -960 960 960"
                          width="28"
                        >
                          <path
                            d="M790-56 414-434q-47 11-87.5 33T254-346l-84-86q32-32 69-56t79-42l-90-90q-41 21-76.5 46.5T84-516L0-602q32-32 66.5-57.5T140-708l-84-84 56-56 736 736-58 56Zm-310-64q-42 0-71-29.5T380-220q0-42 29-71t71-29q42 0 71 29t29 71q0 41-29 70.5T480-120Zm236-238-29-29-29-29-144-144q81 8 151.5 41T790-432l-74 74Zm160-158q-77-77-178.5-120.5T480-680q-21 0-40.5 1.5T400-674L298-776q44-12 89.5-18t92.5-6q142 0 265 53t215 145l-84 86Z"
                            fill="rgba(255, 255, 255, 0.7)"
                          />
                        </svg>
                      </div>
                    </div>,
                  );
                  return (
                    <UnitMarker
                      key={`unjoin-${idx}`}
                      data={group.apList[0]}
                      content={content}
                      onClick={e => {
                        setSelectGroup(group);
                        setFloatPopShow(true);
                        // AP 리스트 그룹에 항목이 1개만 존재할 경우
                        if (group.apList.length === 1) {
                          setCollapseOpen(true);
                          setSelectUnit(group.apList[0]);
                        }
                      }}
                    />
                  );
                }
                const content = ReactDOMServer.renderToString(
                  <div className="custom-marker-status-normal">
                    <div className="custom-marker-title-status">{key}</div>
                    <div className="custom-marker-icon-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="28"
                        viewBox="0 -960 960 960"
                        width="28"
                      >
                        <path
                          d="M480-120q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM254-346l-84-86q59-59 138.5-93.5T480-560q92 0 171.5 35T790-430l-84 84q-44-44-102-69t-124-25q-66 0-124 25t-102 69ZM84-516 0-600q92-94 215-147t265-53q142 0 265 53t215 147l-84 84q-77-77-178.5-120.5T480-680q-116 0-217.5 43.5T84-516Z"
                          fill="rgba(255, 255, 255, 1)"
                        />
                      </svg>
                    </div>
                  </div>,
                );
                return (
                  <UnitMarker
                    key={`run-${idx}`}
                    data={group.apList[0]}
                    content={content}
                    onClick={e => {
                      setSelectGroup(group);
                      setFloatPopShow(true);
                      // AP 리스트 그룹에 항목이 1개만 존재할 경우
                      if (group.apList.length === 1) {
                        setCollapseOpen(true);
                        setSelectUnit(group.apList[0]);
                      }
                    }}
                  />
                );
              })}
            {/* 앵커 위경도 확인용 일반 마커 */}
            {/* {Object.keys(apStatusListGroup)?.map((key, idx) => {
              const group = apStatusListGroup[key];

              const { latitude, longitude } = group.apList[0];

              return (
                <Marker position={new navermaps.LatLng(latitude, longitude)} />
              );
            })} */}
            {/* 좌측 상단 breadcrumb */}
            <ul className="map-breadcrumb">
              <li
                onClick={() => {
                  naverMap.current.setZoom(MIN_ZOOM_LEVEL, true);
                  setMoveCenter(
                    new navermaps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE),
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                <span
                  className={
                    currentZoomLevel < MID_ZOOM_LEVEL
                      ? 'breadcrumb-selected'
                      : 'breadcrumb-non-selected'
                  }
                >
                  울산시
                </span>
              </li>
              <li
                onClick={() => {
                  naverMap.current.setZoom(MID_ZOOM_LEVEL, true);
                  setMoveCenter(
                    new navermaps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE),
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                <span
                  className={
                    currentZoomLevel < DEFAULT_ZOOM_LEVEL &&
                    currentZoomLevel >= MID_ZOOM_LEVEL
                      ? 'breadcrumb-selected'
                      : 'breadcrumb-non-selected'
                  }
                >
                  동구
                </span>
              </li>
              {/* currentZoomLevel >= MID_ZOOM_LEVEL && */}
              {/* {currentZoomLevel > MID_ZOOM_LEVEL && <li>{profileName}</li>} */}
              <li
                onClick={() => {
                  naverMap.current.setZoom(DEFAULT_ZOOM_LEVEL, true);
                  setMoveCenter(
                    new navermaps.LatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE),
                  );
                }}
                style={{ cursor: 'pointer' }}
              >
                <span
                  className={
                    currentZoomLevel >= DEFAULT_ZOOM_LEVEL
                      ? 'breadcrumb-selected'
                      : 'breadcrumb-non-selected'
                  }
                >
                  명덕 마을
                </span>
              </li>
            </ul>
            {/* 지도 유형 선택 */}
            <div className="map-fix-right map-btn-right">
              {buttons.map(btn => {
                return (
                  <button
                    type="button"
                    key={btn.typeId}
                    className="btn-wide-view"
                    style={{
                      backgroundColor:
                        btn.typeId === mapTypeId ? '#0070d7' : 'white',
                      color: btn.typeId === mapTypeId ? 'white' : 'black',
                    }}
                    onClick={() => {
                      setMapTypeId(btn.typeId);
                    }}
                  >
                    {btn.text}
                  </button>
                );
              })}
            </div>
          </NaverMap>
        </MapDiv>

        {/* 좌측 */}
        <div className="map-aside add-overflow-hidden">
          <section className="py-3">
            <h5 className="mb-0">
              울산시
              {currentZoomLevel >= MID_ZOOM_LEVEL && ' 동구'}
              {currentZoomLevel >= DEFAULT_ZOOM_LEVEL && ' 명덕 마을'}
            </h5>
          </section>
          <section>
            <h6>구축센서 장비 현황</h6>
            <div className="dl-box-ap-status column dl-horizontal">
              {/* getFloatingAPC 호출 오류 :: 오류 해결 후 디폴트 값 0으로 수정 요망 */}
              <dl>
                <dt>전체</dt>
                <dd>
                  <strong>
                    {Utils.numberComma(socketState.equipState?.total ?? 0)}
                  </strong>
                </dd>
              </dl>
              <dl>
                <dt>정상</dt>
                <dd>
                  <strong>
                    {Utils.numberComma(socketState.equipState?.normal ?? 0)}
                  </strong>
                </dd>
              </dl>
              <dl>
                <dt>장애</dt>
                <dd>
                  <strong>
                    {Utils.numberComma(socketState.equipState?.abnormal ?? 0)}
                  </strong>
                </dd>
              </dl>
            </div>
          </section>
          <section>
            <h6>AP 리스트</h6>
            <Form className="search-area">
              <Form.Group className="form-group">
                <Form.Label>장비 현황</Form.Label>
                <CustomSelect
                  options={apStateOptions}
                  value={apUnitOptions}
                  onChange={setApUnitOptions}
                  className="mw-160"
                  placeholder="선택"
                />
              </Form.Group>
            </Form>
            <div className="table-container mt-3">
              <Table className="table-hover text-start">
                <colgroup>
                  <col width="40%" />
                  <col width="40%" />
                  <col width="20%" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="table-fixed-header">Name</th>
                    <th className="table-fixed-header">Mac Address</th>
                    <th className="table-fixed-header">Status</th>
                  </tr>
                </thead>
                <tbody className="aside-ap-list">
                  {apStatusList?.length > 0 ? (
                    apStatusList.map((v, i) => {
                      return (
                        <tr
                          key={v.apmac_id}
                          onClick={e => setMoveAPPoint({ ...v })}
                        >
                          <td>{v.ap_nm}</td>
                          <td>{v.apmac_address}</td>
                          <td>
                            {v.ap_status === 'run' ? (
                              <div className="status-wrapper">
                                <div className="normal-circle" />
                                <div>정상</div>
                              </div>
                            ) : (
                              <div className="status-wrapper">
                                <div className="abnormal-circle" />
                                장애
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td className="no-data" colSpan={8}>
                        요청 내역이 없습니다.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </section>
        </div>
      </Container>
      <CustomModal
        title={`${selectGroup?.groupName || ''} 장비 현황`}
        show={floatPopShow}
        onHide={() => {
          setFloatPopShow(false);
          setCollapseOpen(false);
          setSelectUnit({});
        }}
      >
        <Modal.Body>
          <div>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                {/* <col width={50} /> */}
                <col width={100} />
                <col width={100} />
                <col width={50} />
              </colgroup>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  <th>Mac Address</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody className="ap-list">
                {!!apList ? (
                  sortAPList(apList).map((v, i) => {
                    return (
                      <tr
                        className={
                          selectUnit.apmac_address === v.apmac_address
                            ? 'tr-ap-list-selected'
                            : ''
                        }
                        onClick={e => {
                          setCollapseOpen(true);
                          setSelectUnit(v);
                        }}
                      >
                        <td>{v.ap_nm}</td>
                        {selectUnit.apmac_address === v.apmac_address ? (
                          <td style={{ color: 'rgb(0, 112, 215)' }}>
                            {v.apmac_address}
                          </td>
                        ) : (
                          <td>{v.apmac_address}</td>
                        )}

                        <td>
                          {v.ap_status === 'run' ? (
                            <div className="status-wrapper">
                              <div className="normal-circle" />
                              <div>정상</div>
                            </div>
                          ) : (
                            <div className="status-wrapper">
                              <div className="abnormal-circle" />
                              장애
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-data" colSpan={8}>
                      요청 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Collapse in={collapseOpen}>
            <Card>
              <ul class="list-group">
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key"> AP Group</div>
                    <div className="value">{selectUnit.ap_nm}</div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">AP Name</div>
                    <div className="value">{selectUnit.ap_name}</div>
                  </div>
                </li>
                <li className="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">AP Mac Address</div>
                    <div
                      className="value"
                      style={{ color: 'rgb(0, 112, 215)' }}
                    >
                      {selectUnit.ap_mac}
                    </div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">AP Serial</div>
                    <div className="value">{selectUnit.ap_serial}</div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">AP Model</div>
                    <div className="value">{selectUnit.ap_model}</div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">AP Status</div>
                    <div className="value">{selectUnit.ap_status}</div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">Latitude</div>
                    <div className="value">{selectUnit.latitude}</div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">Longitude</div>
                    <div className="value">{selectUnit.longitude}</div>
                  </div>
                </li>
                <li class="list-group-item">
                  <div className="ap-detail-item">
                    <div className="key">Address</div>
                    <div className="value">{selectUnit.ap_address}</div>
                  </div>
                </li>
              </ul>
            </Card>
          </Collapse>
        </Modal.Body>
      </CustomModal>
    </main>
  );
});

function UnitMarker({ data, content, onClick }) {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(11, 35),
        anchor: new navermaps.Point(40, 58),
      }}
      onClick={() => onClick(data)}
    />
  );
}
