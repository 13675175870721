import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';

function Layout({ component: Component, render, ...rest }) {
  return (
    <Container fluid className="app-container">
      <div className="app-contents">
        <Route {...rest} render={routeProps => <Component {...routeProps} />} />
      </div>
    </Container>
  );
}

export default React.memo(Layout);
