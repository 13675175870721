/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Marker, useMap, useNavermaps } from 'react-naver-maps';
import { Utils } from '@common';
import ReactDOMServer from 'react-dom/server';

export default React.memo(function PolygonMarker({ data, content, onClick }) {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.lat, data.lng)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(11, 35),
        anchor: new navermaps.Point(40, 58),
      }}
      onClick={() => onClick(true)}
    />
  );
});
