import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { CustomModal, CustomSwal } from '@components';
import Moment from 'react-moment';
import { RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteAdminStore,
  patchAdminStore,
  putAdminStore,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function AdminStoreModal({
  show,
  handleClose,
  type,
  adminData,
}) {
  const history = useHistory();
  const [admId, setAdmId] = useState();
  const [loginId, setLoginId] = useState('');
  const [businessNm, setBusinessNm] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [memo, setMemo] = useState('');

  const onSubmit = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!loginId) {
        throw new CustomException('아이디(사업자번호)를 입력해 주세요.');
      }

      if (!businessNm) {
        throw new CustomException('사업자명을 입력해 주세요.');
      }

      if (!phoneNo) {
        throw new CustomException('휴대폰 번호를 입력해 주세요.');
      }

      const params = {
        loginId: Utils.encrypt(loginId),
        phoneNo: Utils.encrypt(phoneNo),
        businessNm,
        memo,
      };

      if (admId) {
        params.admId = admId;
      }
      const { data } = admId
        ? await patchAdminStore(params)
        : await putAdminStore(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 ${admId ? '수정' : '추가'} 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.memberaccount);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const deleteSwal = () => {
    Swal.fire({
      title: '삭제 확인',
      text: '정말로 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예, 삭제합니다.',
      cancelButtonText: '취소',
    }).then(e => {
      if (e.isConfirmed) {
        removeAdminStore();
      }
    });
  };

  const removeAdminStore = async () => {
    try {
      const { data } = await deleteAdminStore(admId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.memberaccount);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    if (adminData && type === 'UPDATE') {
      setLoginId(Utils.decrypt(adminData.loginId));
      setPhoneNo(Utils.decrypt(adminData.phoneNo));
      setBusinessNm(adminData.businessNm);
      setMemo(adminData.memo);
      setAdmId(adminData.admId);
    }
  }, []);
  return (
    <CustomModal
      title={`관리자 ${type === 'ADD' ? '추가' : '수정'}`}
      id="sysModal"
      show={show}
      noCloseBtn
      onHide={() => handleClose()}
    >
      <Modal.Body>
        <Form className="sys-area">
          <Form.Group className="form-group">
            <Form.Label>사업자명</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="사업자명을 입력 하세요."
                className="mw-280"
                value={businessNm || ''}
                onChange={e => {
                  setBusinessNm(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>아이디</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="아이디(사업자번호)를 입력 하세요."
                className="mw-280"
                value={loginId}
                disabled={admId || ''}
                onChange={e => {
                  setLoginId(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>휴대폰번호</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="휴대폰번호를 입력 하세요."
                className="mw-280"
                value={phoneNo || ''}
                onChange={e => {
                  setPhoneNo(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>비고</Form.Label>
            <InputGroup>
              <Form.Control
                className="mw-280"
                value={memo || ''}
                onChange={e => {
                  setMemo(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
        </Form>
        <div className="btn-area justify-btn-side">
          <Button variant="outline-primary" onClick={handleClose}>
            닫기
          </Button>
          <div className="flex-gap">
            {admId && (
              <Button
                variant="outline-primary"
                onClick={e => {
                  deleteSwal();
                }}
              >
                삭제
              </Button>
            )}
            <Button
              onClick={e => {
                onSubmit(e);
              }}
            >
              {admId ? '수정' : '추가'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
});
