/* eslint-disable no-shadow */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

export default React.memo(function SurveyEdit({
  data,
  index,
  setQuestions,
  isDelete,
}) {
  return (
    <div className="que-box mt-3">
      <div className="que-title">
        <h6>질문 {index}</h6>
        <div className="btn-flex">
          {isDelete && (
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => {
                setQuestions(prev => prev.filter(v => v.uuid !== data.uuid));
              }}
            >
              삭제
            </Button>
          )}
        </div>
      </div>
      <Form.Group className="form-group">
        <Form.Label>질문내용</Form.Label>
        <Form.Control
          className="mw-100"
          value={data.question ?? ''}
          onChange={e => {
            setQuestions(prev =>
              prev.map((v, i) => {
                if (v.uuid === data.uuid) {
                  const tempData = {
                    ...v,
                    question: e.target.value,
                  };
                  return tempData;
                }
                return v;
              }),
            );
          }}
        />
      </Form.Group>
      <div className="add-option">
        {data.options.map((v, i) => {
          return (
            <div key={`option-${v.opUuid}`} className="mb-3">
              <Form.Group className="flex-form">
                <Form.Check
                  type="radio"
                  id={`inline-radio-${v.opUuid}`}
                  checked={i === 0}
                  readOnly
                />
                <Form.Control
                  className="mw-100"
                  placeholder={`옵션${i + 1}`}
                  value={v.answer ?? ''}
                  onChange={e => {
                    setQuestions(prev =>
                      prev.map((q, i) => {
                        if (q.uuid === data.uuid) {
                          const tempData = {
                            ...q,
                            options: q.options.map((o, oi) => {
                              if (o.opUuid === v.opUuid) {
                                const tempData = {
                                  ...o,
                                  answer: e.target.value,
                                };
                                return tempData;
                              }
                              return o;
                            }),
                          };
                          return tempData;
                        }
                        return q;
                      }),
                    );
                  }}
                />
                {data.options.length !== 1 && (
                  <i
                    className="material-icons"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setQuestions(prev =>
                        prev.map((q, i) => {
                          if (q.uuid === data.uuid) {
                            const tempData = {
                              ...q,
                              options: q.options.filter(
                                o => o.opUuid !== v.opUuid,
                              ),
                            };
                            return tempData;
                          }
                          return q;
                        }),
                      );
                    }}
                  >
                    close
                  </i>
                )}
              </Form.Group>
            </div>
          );
        })}
      </div>
      <Button
        variant="outline-primary mt-3 "
        onClick={() => {
          setQuestions(prev =>
            prev.map(v => {
              if (v.uuid === data.uuid) {
                const tempData = {
                  ...v,
                  options: [
                    ...(v.options || []),
                    { opUuid: uuidv4(), useYn: 'Y' },
                  ],
                };
                return tempData;
              }
              return v;
            }),
          );
        }}
      >
        옵션추가
      </Button>
    </div>
  );
});
