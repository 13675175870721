/* eslint-disable no-param-reassign */
/* eslint-disable default-case */
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import Select from 'react-select';
import { images } from '@assets';

// 참고 https://react-select.com/home
function CustomCheckboxSelect({ value, options, onChange, ...rest }) {
  // console.log(value);
  return (
    <Select
      options={options}
      value={value}
      onChange={onChange}
      styles={customStyles}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      noOptionsMessage={() => '선택 옵션이 없습니다.'}
      isClearable={value.some(v => !v.isFixed)}
      maxMenuHeight={400}
      {...rest}
    />
  );
}

const customStyles = {
  // select box
  container: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  // input box
  control: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    borderRadius: '0.25rem',
    border: state.isFocused
      ? '1px solid var(--bs-dark) !important'
      : '1px solid var(--bs-border)  !important',
    boxShadow: state.isFocused
      ? '0px 0px 0px 4px rgba(25, 25, 25, 0.2)'
      : 'none',
  }),
  // input
  valueContainer: (provided, state) => ({
    ...provided,
    flex: 'auto',
    padding: '0.25rem 2rem 0.25rem 1rem',
    minHeight: 45,
    border: 0,
    fontWeight: 400,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    margin: 0,
    fontWeight: 500,
    fontSize: 'var(--fs-16)',
  }),
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    padding: 0,
    fontWeight: 500,
    fontSize: 'var(--fs-16)',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontWeight: 400,
    color: 'var(--bs-dark)',
  }),
  // 우측
  indicatorsContainer: (provided, state) => ({
    ...provided,
    position: 'absolute',
    top: 0,
    right: 0,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    margin: '0 0.65rem',
    width: 24,
    height: 55,
    backgroundImage: state.selectProps.menuIsOpen
      ? `url(${images.icDropup})`
      : `url(${images.icDropdown})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    svg: {
      display: 'none',
    },
  }),
  // multi value label
  multiValue: (provided, state) => ({
    ...provided,
    padding: '0.4rem 0.5rem 0.3rem',
    margin: '0.25rem 0.5rem 0.25rem 0',
    borderRadius: '0.25rem',
    backgroundColor: state.data.isFixed
      ? 'var(--bs-primary)'
      : 'var(--bs-blue-bg)',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontWeight: 500,
    fontSize: 'var(--fs-16)',
    padding: '0 !important',
    color: state.data.isFixed ? 'var(--bs-white)' : 'var(--bs-dark)',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: state.data.isFixed ? 'none' : 'block',
    background: `transparent url(${images.icClose}) no-repeat center !important`,
    width: 24,
    height: 24,
    marginLeft: '0.5rem',
    svg: { display: 'none' },
  }),
  // options
  menu: provided => ({
    ...provided,
    padding: 0,
    borderColor: 'var(--bs-border)',
    borderRadius: '0.25rem',
    zIndex: 2,
    overflow: 'hidden',
  }),
  menuList: provided => ({
    ...provided,
    padding: '0.5rem',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    padding: '0.75rem 1rem 0.65rem 3rem',
    color: 'var(--bs-dark) !important',
    fontWeight: 600,
    backgroundImage: state.isSelected
      ? `url(${images.icChecked})`
      : `url(${images.icUnChecked})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left 0.65rem center',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: '#e6e8ef',
    },
    ':active': {
      backgroundColor: '#e6e8ef',
    },
  }),
};

export default CustomCheckboxSelect;
