/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Container, Form, Ratio, Badge } from 'react-bootstrap';
import { CustomModal, CustomSelect, CustomDatePicker } from '@components';
import { images } from '@assets';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Moment from 'react-moment';
import Utils from '../../common/Utils';
// swiper
import 'swiper/css';
import 'swiper/css/thumbs';

SwiperCore.use([Thumbs, FreeMode]);

function DetailimagesShow({ detailhandleClose, prmDetail, ...rest }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <CustomModal
      title="상세 이미지 미리보기  "
      id="detailimagesModal"
      {...rest}
    >
      <Modal.Body>
        <div className="prm-request-detail">
          <div className="prm-detail-header">
            <Button variant="icon">
              <i className="material-icons">arrow_back</i>
            </Button>
            <h3>{prmDetail.title}</h3>
            <Button variant="icon">
              <i className="material-icons">share</i>
            </Button>
          </div>
          <section>
            <Swiper
              speed={400}
              spaceBetween={0}
              threshold={30}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Thumbs]}
              className="swiper-box"
            >
              {prmDetail.images.map((item, idx) => {
                return (
                  <SwiperSlide key={`visual-${idx}`}>
                    <Ratio aspectRatio="35x19">
                      <div
                        className="img-box"
                        style={{
                          backgroundImage: `url(${Utils.fileViewUrl(
                            item.filePath,
                          )})`,
                        }}
                      />
                    </Ratio>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={4}
              slidesPerView={4}
              freeMode
              watchSlidesProgress
              modules={[FreeMode, Thumbs]}
              className="swiper-box pt-1"
            >
              {prmDetail.images.map((item, idx) => {
                return (
                  <SwiperSlide key={`thumb-${idx}`}>
                    <Ratio aspectRatio="35x19">
                      <div
                        className="img-box"
                        style={{
                          backgroundImage: `url(${Utils.fileViewUrl(
                            item.filePath,
                          )})`,
                        }}
                      />
                    </Ratio>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </section>
          <section className="info">
            <div className="title">
              <div className="flex-between">
                <h6>INFO</h6>
                <Badge>
                  {/* {promoCateId &&
                    promoCategoryList.find(v => v.value === promoCateId).label} */}
                </Badge>
              </div>
            </div>
            <div className="dl-box align-start">
              <dl>
                <dt>
                  <i className="material-icons">place</i>
                </dt>
                <dd>{prmDetail.address}</dd>
              </dl>
              <dl>
                <dt>
                  <i className="material-icons">call</i>
                </dt>
                <dd>{prmDetail.tel}</dd>
              </dl>
              <dl>
                <dt>
                  <i className="material-icons">calendar_month</i>
                </dt>
                <dd>
                  {prmDetail.openDate && prmDetail.closeDate && (
                    <>
                      <Moment date={prmDetail.openDate} format="YYYY-MM-DD" />
                      <span> ~ </span>
                      <Moment date={prmDetail.closeDate} format="YYYY-MM-DD" />
                    </>
                  )}
                  {prmDetail.openDate && !prmDetail.closeDate && (
                    <>
                      <Moment date={prmDetail.openDate} format="YYYY-MM-DD" />{' '}
                      <span> ~ </span>
                    </>
                  )}
                  {!prmDetail.openDate && prmDetail.closeDate && (
                    <>
                      <span> ~ </span>
                      <Moment date={prmDetail.closeDate} format="YYYY-MM-DD" />
                    </>
                  )}
                </dd>
              </dl>
              <dl>
                <dt>
                  <i className="material-icons">home</i>
                </dt>
                <dd>{prmDetail.url}</dd>
              </dl>
              <dl>
                <dt>
                  <i className="material-icons">description</i>
                </dt>
                <dd className="scrollbody" style={{ whiteSpace: 'pre-wrap' }}>
                  {prmDetail.description}
                </dd>
              </dl>
            </div>
          </section>
        </div>
        <div className="btn-area justify-content-end">
          <Button variant="outline-primary" onClick={detailhandleClose}>
            닫기
          </Button>
        </div>
      </Modal.Body>
    </CustomModal>
  );
}
const dummyBanners = [images.land1, images.land2, images.land3, images.land4];

export default DetailimagesShow;
