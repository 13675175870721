/* eslint-disable react/react-in-jsx-scope */
export default function Msg({
  apNm,
  count,
  dangerPoint,
  moveToastDangerPoint,
}) {
  const { setMoveToastDangerPoint } = moveToastDangerPoint;
  return (
    <div
      onClick={() => {
        setMoveToastDangerPoint(dangerPoint);
      }}
      className="prevent-user-select cursor-pointer"
    >
      <div className="msg-container">
        <p className="toast-ap-level">위 험</p>
        <p className="toast-ap-level">|</p>
        <p className="toast-ap-name">{apNm}</p>
        <p className="toast-ap-count">{count}명</p>
      </div>
    </div>
  );
}
