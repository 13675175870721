/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { store } from '@common/redux/store';
import { authAction } from '@reducers/authSlice';
import Moment from 'react-moment';
import 'moment/locale/ko'; // 한글 로케일 설정

export default React.memo(function Header({ title }) {
  const auth = useSelector(state => state.auth);

  return (
    <header css={header}>
      <Container>
        <h2>{title}</h2>
        <div>
          <strong>
            <Moment
              fromNow
              interval={1}
              local="ko"
              format="YYYY.MM.DD(ddd) HH:mm:ss"
            />
          </strong>
          {/* <p css={p}>{auth.admNm}</p> */}
          <Button
            variant="outline-primary"
            className="ms-3"
            onClick={() => {
              store.dispatch(authAction.removeAuth());
            }}
          >
            로그아웃
          </Button>
        </div>
      </Container>
    </header>
  );
});

const p = css`
  display: block;
  margin-left: 1rem;
  color: var(--bs-gray);
`;

const header = css`
  position: fixed;
  top: 0;
  left: 200px;
  width: calc(100% - 200px);
  background: var(--bs-white);
  border-bottom: 1px solid var(--bs-border);
  z-index: 10;
  transition: 0.25s ease-in-out;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    h2 {
      margin: 0;
      font-size: var(--fs-20);
    }
    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      strong,
      a {
        display: block;
        margin-left: 1rem;
        color: var(--bs-gray);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    left: 60px;
    width: calc(100% - 60px);
  }
`;
