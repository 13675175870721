import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouterPath } from '@common';

// 유동 상황판
import Dashboard from '@pages/Dashboard';

// 홍보
import PrmRequestList from '@pages/Promotion/Request/List';
import PrmRequestDetail from '@pages/Promotion/Request/Detail';
import PrmRequestRegister from '@pages/Promotion/Request/Register';
import PrmApprovalList from '@pages/Promotion/Approval/List';
import PrmApprovalDetail from '@pages/Promotion/Approval/Detail';
import PrmSchedule from '@pages/Promotion/Schedule';
import PrmConfirmList from '@pages/Promotion/Confirm/List';
import PrmConfirmDetail from '@pages/Promotion/Confirm/Detail';
import PrmDashoboard from '@pages/Promotion/Dashboard';
import PrmCoupon from '@pages/Promotion/Coupon/List';
import PrmCouponDetail from '@pages/Promotion/Coupon/Detail';
import PrmSurvey from '@pages/Promotion/Survey/List';
import PrmSurveyRegister from '@pages/Promotion/Survey/Register';
import PrmResult from '@pages/Promotion/Survey/Result';
// 데이터랩
import TopData from '@pages/DataLab/TopData';
import Population from '@pages/DataLab/Population';
import PrmStatistics from '@pages/DataLab/PrmStatistics';
import EsgPrm from '@pages/DataLab/EsgPrm';
import EsgSchedule from '@pages/DataLab/EsgSchedule';
import Monitoring from '@pages/DataLab/Monitoring';
// 시스템 관리
import Admin from '@pages/System/Admin';
import Auth from '@pages/System/Auth';
import Menu from '@pages/System/Menu';
import AdmAccesLog from '@pages/System/AdmAccesLog';
import AdmActionLog from '@pages/System/AdmActionLog';
import Code from '@pages/System/Code';
import MemberAccount from '@pages/System/MemberAccount';
import Apdevice from '@pages/System/Apdevice';
import Billing from '@pages/System/Billing';

import Errors from '@pages/Errors';

// 소상공인 홍보 신규등록
import SmBusiness from '@pages/SmBusiness/Register';

import SignIn from '@pages/Auth/SignIn';
import FindId from '@pages/Auth/FindId';
import FindPwd from '@pages/Auth/FindPwd';

// 레이아웃
import { useSelector } from 'react-redux';
import Layout from './Layout';
import LayoutAuth from './LayoutAuth';
import AdminRouters from './AdminRouters';
import utils from '../common/Utils';
import SmbusinessRouters from './SmbusinessRouters';

function Router() {
  const isAuthorized = useSelector(state => {
    return { ...state.auth, admType: utils.decrypt(state.auth.admType) };
  });

  return !isAuthorized.isLogin ? (
    <Switch>
      <Layout exact path={RouterPath.slash} component={SignIn} />
      <Layout exact path={RouterPath.signin} component={SignIn} />
      <Layout exact path={RouterPath.findid} component={FindId} />
      <Layout exact path={RouterPath.findpwd} component={FindPwd} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  ) : isAuthorized.admType === 'ADM-STORE' ? (
    <SmbusinessRouters firstMenuUrl={isAuthorized.firstMenuUrl} />
  ) : (
    <AdminRouters firstMenuUrl={isAuthorized.firstMenuUrl} />
  );
}

export default Router;
