/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
  Collapse,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import {
  getCodeParents,
  patchCodeParent,
  putCodeParent,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import CodeChildEdit from './CodeChildEdit';

export default React.memo(function Code(props) {
  const history = useHistory();

  const allData = {
    label: '전체',
    value: null,
  };

  const selectData = {
    label: '선택',
    value: null,
  };

  const size = 10;

  const searchTypeOptions = [
    {
      value: 'CODE',
      label: '코드',
    },
    {
      value: 'CODE_NM',
      label: '코드명',
    },
  ];

  const useStateOptions = [
    {
      value: 'Y',
      label: '사용',
    },
    {
      value: 'N',
      label: '미사용',
    },
  ];

  const page = (history.location.state && history.location.state.page) || 1;
  const useYn =
    (history.location.state && history.location.state.useYn) || null;
  const searchType =
    (history.location.state && history.location.state.searchType) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || '';
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [selectSearchType, setSelectSearchType] = useState(selectData);
  const [searchUseYn, setSearchUseYn] = useState(allData.value);

  const [codeParentList, setCodeParentList] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [selectParentCode, setSelectParentCode] = useState();
  const [updateParentCodeData, setUpdateParentCodeData] = useState();
  const [createParentCodeData, setCreateParentCodeData] = useState({
    useYn: 'Y',
  });

  const getCodeParentList = async () => {
    try {
      const params = {
        page,
        size,
        searchType,
        keyword,
        useYn,
      };
      const { data } = await getCodeParents(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setTotalPage(data.data.totalPages);
          setCodeParentList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const createParentCode = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!createParentCodeData.code) {
        throw new CustomException('대분류 코드를 입력해 주세요.');
      }

      if (!createParentCodeData.codeKo) {
        throw new CustomException('대분류 코드명(한글)을 입력해 주세요.');
      }

      if (!createParentCodeData.useYn) {
        throw new CustomException('사용여부를 선택해 주세요.');
      }
      const { data } = await putCodeParent(createParentCodeData);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 추가 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.code);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const updateParentCode = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!updateParentCodeData.codeKo) {
        throw new CustomException('대분류 코드명(한글)을 입력해 주세요.');
      }

      if (!updateParentCodeData.useYn) {
        throw new CustomException('사용여부를 선택해 주세요.');
      }

      const { data } = await patchCodeParent(updateParentCodeData);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 수정 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            setUpdateParentCodeData();
            getCodeParentList();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const setSearchState = () => {
    setSelectSearchType(
      searchTypeOptions.find(v => v.value === searchType) || selectData,
    );
    setSearchUseYn(
      useStateOptions.find(v => v.value === useYn) || allData.value,
    );
    setSearchKeyword(keyword || '');
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      useYn: isSearch ? searchUseYn : useYn,
      searchType: isSearch ? selectSearchType.value : searchType,
    };

    props.history.push({
      pathname: RouterPath.code,
      state,
    });
  };

  useEffect(() => {
    setSearchState();
    getCodeParentList();
  }, [pageRefresh]);

  const [usecheck, setUseCheck] = useState();
  const [serchfilter, setSerchFilter] = useState();
  const [open, setOpen] = useState(false);

  return (
    <main id="sys-code">
      <Header title="코드 관리" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <div className="form-grid-f">
              <Form.Group className="form-group block-flex">
                <Form.Label className="mb-md-1">대분류 코드</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="코드 입력"
                    className="mw-280"
                    value={createParentCodeData.code || ''}
                    onChange={e => {
                      setCreateParentCodeData({
                        ...createParentCodeData,
                        code: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="form-group block-flex">
                <Form.Label className="mb-md-1">대분류 코드명(한글)</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="코드명(한글) 입력"
                    className="mw-280"
                    value={createParentCodeData.codeKo || ''}
                    onChange={e => {
                      setCreateParentCodeData({
                        ...createParentCodeData,
                        codeKo: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="form-group block-flex">
                <Form.Label className="mb-md-1">대분류 코드명(영문)</Form.Label>
                <InputGroup>
                  <Form.Control
                    placeholder="코드명(영문) 입력"
                    className="mw-280"
                    value={createParentCodeData.codeEn || ''}
                    onChange={e => {
                      setCreateParentCodeData({
                        ...createParentCodeData,
                        codeEn: e.target.value,
                      });
                    }}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="form-group block-flex">
                <Form.Label className="mb-md-1">사용여부</Form.Label>
                <CustomSelect
                  options={useStateOptions}
                  className="mw-280"
                  placeholder="선택"
                  value={useStateOptions.find(
                    v => v.value === createParentCodeData.useYn,
                  )}
                  onChange={e => {
                    setCreateParentCodeData({
                      ...createParentCodeData,
                      useYn: e.value,
                    });
                  }}
                />
              </Form.Group>
            </div>
            <Form.Group className="form-group blcok-self">
              <Form.Label className="mb-md-1">메모</Form.Label>
              <InputGroup>
                <Form.Control
                  placeholder="내용 입력"
                  className="w-100"
                  value={createParentCodeData.memo || ''}
                  onChange={e => {
                    setCreateParentCodeData({
                      ...createParentCodeData,
                      memo: e.target.value,
                    });
                  }}
                />
              </InputGroup>
            </Form.Group>
            <div className="btn-area justify-content-end">
              <Button
                onClick={e => {
                  createParentCode(e);
                }}
              >
                코드추가
              </Button>
            </div>
          </Form>
        </article>
        <article className="mt-4">
          <Form className="mb-3">
            <Form.Group className="form-group">
              <Form.Label>사용여부</Form.Label>
              {['radio'].map(type => (
                <div key={`inline-${type}`} className="flex-start">
                  {[allData, ...useStateOptions].map((v, i) => {
                    return (
                      <Form.Check
                        key={`ds-${i}`}
                        type={type}
                        id={`use-state-${v.value}`}
                        name="group1"
                        value={v.value}
                        label={v.label}
                        onChange={() => {
                          setSearchUseYn(v.value);
                        }}
                        checked={searchUseYn === v.value}
                      />
                    );
                  })}
                </div>
              ))}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <CustomSelect
                  options={[selectData, ...searchTypeOptions]}
                  value={selectSearchType}
                  onChange={setSelectSearchType}
                  className="mw-160"
                  placeholder="선택"
                />
                <Form.Control
                  placeholder="검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      if (searchKeyword && !selectSearchType.value) {
                        CustomSwal.fire({
                          text: '검색어 타입을 선택해주세요.',
                          allowEnterKey: false,
                          confirmButtonText: '확인',
                        });
                      } else {
                        movePage(1, true, e);
                      }
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
          <Table className="table-hover table-auto text-start">
            <colgroup>
              <col width={80} />
              <col width={100} />
              <col width={100} />
              <col width={100} />
              <col width={200} />
              <col width={100} />
            </colgroup>
            <thead>
              <tr>
                <th>대분류 코드</th>
                <th>대분류 코드명(한글)</th>
                <th>대분류 코드명(영문)</th>
                <th>사용여부</th>
                <th>메모</th>
                <th className="th-center">관리</th>
              </tr>
            </thead>
            <tbody>
              {codeParentList.length > 0 ? (
                codeParentList.map((v, i) => {
                  return (
                    <>
                      <tr key={`parent-${i}`}>
                        <td>{v.code}</td>
                        <td>
                          {updateParentCodeData &&
                          updateParentCodeData.codeId === v.codeId ? (
                            <InputGroup>
                              <Form.Control
                                placeholder="상세코드명(한글)"
                                className="mw-200"
                                value={updateParentCodeData.codeKo || ''}
                                onChange={e => {
                                  setUpdateParentCodeData({
                                    ...updateParentCodeData,
                                    codeKo: e.target.value,
                                  });
                                }}
                              />
                            </InputGroup>
                          ) : (
                            v.codeKo
                          )}
                        </td>
                        <td>
                          {updateParentCodeData &&
                          updateParentCodeData.codeId === v.codeId ? (
                            <InputGroup>
                              <Form.Control
                                placeholder="상세코드명(영문)"
                                className="mw-200"
                                value={updateParentCodeData.codeEn || ''}
                                onChange={e => {
                                  setUpdateParentCodeData({
                                    ...updateParentCodeData,
                                    codeEn: e.target.value,
                                  });
                                }}
                              />
                            </InputGroup>
                          ) : (
                            v.codeEn
                          )}
                        </td>
                        <td>
                          {updateParentCodeData &&
                          updateParentCodeData.codeId === v.codeId ? (
                            <CustomSelect
                              options={useStateOptions}
                              className="mw-80"
                              placeholder="선택"
                              value={useStateOptions.find(
                                us => us.value === updateParentCodeData.useYn,
                              )}
                              onChange={e => {
                                setUpdateParentCodeData({
                                  ...updateParentCodeData,
                                  useYn: e.value,
                                });
                              }}
                            />
                          ) : (
                            useStateOptions.find(us => us.value === v.useYn)
                              .label
                          )}
                        </td>
                        <td>
                          {updateParentCodeData &&
                          updateParentCodeData.codeId === v.codeId ? (
                            <InputGroup>
                              <Form.Control
                                placeholder="내용 입력"
                                className="mw-200"
                                value={updateParentCodeData.memo}
                                onChange={e => {
                                  setUpdateParentCodeData({
                                    ...updateParentCodeData,
                                    memo: e.target.value,
                                  });
                                }}
                              />
                            </InputGroup>
                          ) : (
                            v.memo
                          )}
                        </td>

                        <td>
                          <div className="code-table">
                            {updateParentCodeData &&
                            updateParentCodeData.codeId === v.codeId ? (
                              <>
                                <Button
                                  variant="outline-primary"
                                  onClick={() => {
                                    setUpdateParentCodeData();
                                  }}
                                >
                                  취소
                                </Button>
                                <Button
                                  onClick={e => {
                                    updateParentCode(e);
                                  }}
                                >
                                  저장
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={() => {
                                  setUpdateParentCodeData(v);
                                  setSelectParentCode();
                                }}
                              >
                                수정
                              </Button>
                            )}
                            {v.useYn === 'Y' &&
                              (selectParentCode &&
                              selectParentCode.codeId === v.codeId ? (
                                <i
                                  className="material-icons"
                                  onClick={() => {
                                    setSelectParentCode();
                                  }}
                                  aria-controls="table-hover text-start"
                                  // aria-expanded={open}
                                >
                                  expand_less
                                </i>
                              ) : (
                                <i
                                  className="material-icons"
                                  aria-controls="table-hover text-start"
                                  // aria-expanded={open}
                                  onClick={() => {
                                    setSelectParentCode(v);
                                  }}
                                >
                                  expand_more
                                </i>
                              ))}
                          </div>
                        </td>
                      </tr>
                      {selectParentCode &&
                        selectParentCode.codeId === v.codeId && (
                          <CodeChildEdit
                            key={`codeChildEdit${i}`}
                            show={selectParentCode.codeId === v.codeId}
                            parentId={v.codeId}
                            parentCode={v.code}
                          />
                        )}
                    </>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={7}>
                    조회된 코드가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
    </main>
  );
});
