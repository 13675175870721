/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { Container, Form, Button, Modal } from 'react-bootstrap';
import { images } from '@assets';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import CustomSwal from '../../../components/CustomSwal';
import CustomException from '../../../common/hooks/CustomException';
import RESULT_CODE from '../../../common/ResultCode';
import {
  deleteCoupon,
  getCoupon,
  getPromotionDetail,
  patchCoupon,
  putCoupon,
} from '../../../common/crud';
import MainimagesShowForCoupon from './MainimagesShowForCoupon';

export default React.memo(function prmCouponDetail(props) {
  const history = useHistory();
  const { promoId } = useParams();

  const discountTypeOptions = [
    { value: 'PRICE', label: '금액' },
    {
      value: 'RATE',
      label: '할인율',
    },
  ];

  const [couponData, setCouponData] = useState({});
  const [prmDetail, setPrmDetail] = useState({});
  const [discountValueAddComma, setDiscountValueAddComma] = useState();
  const [type, setType] = useState('ADD');

  const getDiscountValue = (discountType, discountValue) => {
    switch (discountType) {
      case 'RATE':
        return `${Utils.changeNumberCommaForZero(discountValue)}%`;
      default:
        return `${Utils.changeNumberCommaForZero(discountValue)}원`;
    }
  };

  // const gerPrmDetailData = async () => {
  //   try {
  //     const { data } = await getPromotionDetail(promoId);
  //     if (data.code === 200) {
  //       setPrmDetail(data.data);
  //       getCouponData();
  //     } else {
  //       throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
  //     }
  //   } catch (error) {
  //     Utils.handleError(error, customSwalGoError, true);
  //   }
  // };

  const removeCoupun = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      const { data } = await deleteCoupon(couponData.couponId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            history.push(RouterPath.prmCoupon);
            // window.history.replaceState(null, '', RouterPath.prmCoupon);
            // window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const onSubmit = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!couponData.couponNm) {
        throw new CustomException('쿠폰 이름을 입력해 주세요.');
      }

      if (!couponData.discountType) {
        throw new CustomException('쿠폰 타입을 선택해 주세요.');
      }

      if (!couponData.discountValue) {
        throw new CustomException('할인값을 입력해 주세요.');
      }

      if (!couponData.couponStartDate || !couponData.couponEndDate) {
        throw new CustomException('생성일을 선택해 주세요.');
      }

      const params = {
        ...couponData,
        promoId,
        startDate: format(couponData.couponStartDate, 'yyyy-MM-dd').toString(),
        endDate: format(couponData.couponEndDate, 'yyyy-MM-dd').toString(),
        discountValue:
          couponData.discountType === 'RATE'
            ? parseFloat(couponData.discountValue) / 100
            : couponData.discountValue,
      };

      const { data } = couponData.couponId
        ? await patchCoupon(params)
        : await putCoupon(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 ${
            couponData.couponId ? '수정' : '추가'
          } 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            history.push(RouterPath.prmCoupon);
            // window.history.replaceState(null, '', RouterPath.prmCoupon);
            // window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const getCouponData = async () => {
    try {
      const { data } = await getCoupon(promoId);
      if (data.code === 200) {
        const { coupon, promotion } = data.data;
        setPrmDetail(promotion);
        if (coupon) {
          const tempDiscountValue =
            coupon.discountType === 'RATE'
              ? Utils.calculatePercentage(coupon.discountValue, 1, 0).replace(
                  '%',
                  '',
                )
              : coupon.discountValue;
          setCouponData({
            ...coupon,
            couponStartDate: new Date(coupon.couponStartDate),
            couponEndDate: new Date(coupon.couponEndDate),
            discountValue: tempDiscountValue,
          });
          setDiscountValueAddComma(
            Utils.changeNumberCommaForZero(tempDiscountValue),
          );
          setType('UPDATE');
        } else {
          setType('ADD');
          setCouponData({ showYn: 'N' });
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const handleInputChange = e => {
    const { value } = e.target;
    const numOnly = value.replace(/[^0-9]/g, '');
    const formattedNum = Utils.changeNumberCommaForZero(numOnly);
    setDiscountValueAddComma(formattedNum);
    setCouponData({ ...couponData, discountValue: numOnly });
  };

  useEffect(() => {
    if (Number(promoId) && Number.isInteger(Number(promoId))) {
      // gerPrmDetailData();
      getCouponData();
    } else {
      CustomSwal.fire({
        text: '잘못된 접근 입니다.',
        confirmButtonText: '확인',
        finally: () => {
          history.goBack();
        },
      });
    }
  }, []);

  const [couponDeleteModalShow, setCouponDeleteModalShow] = useState(false);
  const handleClose = () => setCouponDeleteModalShow(false);

  return (
    <main id="prm-coupon-detail">
      <Header title="쿠폰 관리" />
      <Container className="contents">
        <article className="mi-wd">
          <h6>미리보기</h6>
          <div className="grid-div">
            {prmDetail.promoId && (
              <MainimagesShowForCoupon
                prmDetail={prmDetail}
                couponData={couponData}
              />
            )}
            <section className="info">
              <div className="prm-detail-header">
                <Button variant="icon">
                  <i className="material-icons">arrow_back</i>
                </Button>
                <h3>{prmDetail.title}</h3>
                <Button variant="icon">
                  <i className="material-icons">share</i>
                </Button>
              </div>
              <div className="coupon-section">
                <h4>할인쿠폰</h4>
                <div className="coupon-box">
                  <div className="won-top" />
                  <div className="coupon-title">
                    <p>{couponData.couponNm || ''}</p>
                  </div>
                  <div className="grid-down">
                    <p>
                      {getDiscountValue(
                        couponData.discountType,
                        couponData.discountValue,
                      )}
                    </p>
                    <i className="material-icons right-icons">download</i>
                  </div>
                  <div className="flex-day">
                    {/* <p>23.03.01</p>~<p>23.03.10</p> */}
                    {/* couponData.couponStartDate */}
                    {couponData.couponStartDate && couponData.couponEndDate && (
                      <>
                        <p>
                          <Moment
                            date={couponData.couponStartDate}
                            format="YYYY.MM.DD"
                          />
                        </p>
                        <p> ~ </p>
                        <p>
                          <Moment
                            date={couponData.couponEndDate}
                            format="YYYY.MM.DD"
                          />
                        </p>
                      </>
                    )}
                    {couponData.couponStartDate &&
                      !couponData.couponEndDate && (
                        <>
                          <p>
                            <Moment
                              date={couponData.couponStartDate}
                              format="YYYY.MM.DD"
                            />{' '}
                          </p>
                          <p> ~ </p>
                        </>
                      )}
                    {!couponData.couponStartDate &&
                      couponData.couponEndDate && (
                        <>
                          <p> ~ </p>
                          <p>
                            <Moment
                              date={couponData.couponEndDate}
                              format="YYYY.MM.DD"
                            />
                          </p>
                        </>
                      )}
                  </div>
                  <div className="won-bot" />
                </div>
                <p className="coupon-text">
                  결제 시 해당 이미지를 가맹점에 제시하면,
                  <br />
                  해당 쿠폰 금액만큼 차감 결제됩니다.
                </p>
              </div>
            </section>
            <Form className="prm-request-input">
              <Form.Group className="form-group">
                <Form.Label>쿠폰이름</Form.Label>
                <Form.Control
                  placeholder="쿠폰명"
                  className="mw-100"
                  value={couponData?.couponNm || ''}
                  onChange={e => {
                    setCouponData({ ...couponData, couponNm: e.target.value });
                  }}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>쿠폰타입</Form.Label>
                <CustomSelect
                  options={discountTypeOptions}
                  value={discountTypeOptions.find(
                    v => v.value === couponData?.discountType,
                  )}
                  onChange={e => {
                    setCouponData({
                      ...couponData,
                      discountType: e.value,
                    });
                  }}
                  className="mw-280"
                  placeholder="선택"
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>할인값</Form.Label>
                <Form.Control
                  className="mw-100"
                  value={discountValueAddComma}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                />
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>적용 기간</Form.Label>
                <div className="datepicker-wrap mw-280">
                  <CustomDatePicker
                    value={couponData.couponStartDate}
                    maxDate={couponData.couponEndDate}
                    onChange={e => {
                      setCouponData({
                        ...couponData,
                        couponStartDate: e,
                      });
                    }}
                    placeholderText="yyyy.mm.dd"
                  />
                  <span>-</span>
                  <CustomDatePicker
                    value={couponData.couponEndDate}
                    minDate={couponData.couponStartDate}
                    onChange={e => {
                      setCouponData({
                        ...couponData,
                        couponEndDate: e,
                      });
                    }}
                    placeholderText="yyyy.mm.dd"
                  />
                </div>

                <div key="checkp-checkbox" className="check-div">
                  <Form.Check
                    type="checkbox"
                    id="checkp-checkbox"
                    checked={couponData.showYn === 'Y'}
                    onChange={e => {
                      setCouponData({
                        ...couponData,
                        showYn: e.target.checked ? 'Y' : 'N',
                      });
                    }}
                  />
                  <Form.Label>적용</Form.Label>
                </div>
              </Form.Group>
            </Form>
          </div>
          <div className="btn-area justify-btn-end">
            {type === 'UPDATE' && (
              <Button
                variant="outline-primary"
                onClick={setCouponDeleteModalShow}
              >
                삭제
              </Button>
            )}
            <Button
              onClick={e => {
                onSubmit(e);
              }}
            >
              {`${type === 'UPDATE' ? '수정' : '등록'}`}
            </Button>
          </div>
        </article>
      </Container>
      <CustomModal
        title="쿠폰삭제"
        id="couponModal"
        show={couponDeleteModalShow}
        onHide={() => handleClose()}
      >
        <Modal.Body>
          <div>
            <p>{`${couponData.couponNm}을 삭제하시겠습니까?`}</p>
          </div>
          <div className="btn-area">
            <Button
              onClick={e => {
                removeCoupun(e);
              }}
            >
              삭제
            </Button>
          </div>
        </Modal.Body>
      </CustomModal>
    </main>
  );
});
