/* eslint-disable no-shadow */
/* eslint-disable no-promise-executor-return */
/* eslint-disable new-cap */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-useless-catch */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { images } from '@assets';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import ReactDOMServer from 'react-dom/server';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Piechart } from '../Promotion/Survey/Piechart';
import { PrmBarChart } from './PrmBarChart';
import { MonitorBarChart } from './MonitorBarChart';
import {
  getBudgetState,
  getPriceCharts,
  getPriceExcel,
  getProfiles,
  getPromoArea,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import { DataLabPieChart } from './DataLabPieChart';
import PdfDownloader from '../../common/PdfDownloader';
import PdfTitleModal from './PdfTitleModal';

export default React.memo(function Monitoring(props) {
  const allData = {
    label: '전체',
    value: null,
  };

  const labels = [
    {
      label: 'CPV',
      color: 'first',
      rgb: 'rgb(238, 83, 124)',
      rgba: 'rgba(238,83,124,0.5)',
      sort: 1,
    },
    {
      label: 'CPC',
      color: 'second',
      rgb: 'rgb(235, 146, 123)',
      rgba: 'rgba(235, 146, 123,0.5)',
      sort: 2,
    },
    { label: 'CPA', color: 'third', rgb: '#fcc729', sort: 3 },
  ];
  const schlabels = [
    { label: 'ESG', color: 'first', category: 'ESG', rgb: '#00974e' },
    { label: '소상공인', color: 'second', category: 'STORE', rgb: '#ff8541' },
    { label: '지자체', color: 'third', category: 'LOCAL-GOV', rgb: '#305399' },
  ];

  const defaultPieDataLabels = {
    labels: {
      name: {
        align: 'center',
        // padding: 3,
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          if (value !== 0) {
            const total = ctx.dataset.data.reduce((sum, item) => sum + item, 0);
            return Utils.calculatePercentage(value, total, 1);
          }
          return '';
        },
      },
      value: {
        align: 'center',
        font: { size: 10, weight: '400' },
        formatter(value, ctx) {
          if (value !== 0) {
            // return `${Utils.changeNumberComma(value)}`;
            return '';
          }
          return '';
        },
        padding: 4,
      },
    },
  };

  const history = useHistory();

  const orderBy = (a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  };

  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const startDate =
    (history.location.state && history.location.state.startDate) ||
    new Date().setDate(new Date().getDate() - 30);
  const endDate =
    (history.location.state && history.location.state.endDate) || new Date();
  const promoAreaId =
    (history.location.state && history.location.state.promoAreaId) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [searchProfile, setSearchProfile] = useState({});
  const [searchPrmArea, setSearchPrmArea] = useState({});
  const [profileList, setProfileList] = useState([]);
  const [prmAreaList, setPrmAreaList] = useState([]);

  const [priceChartData, setPriceChartData] = useState();
  const [priceList, setPriceList] = useState([]);
  const [typeBarChartData, setTypeBarChartData] = useState();

  const [storeCharge, setStoreCharge] = useState();
  const [storeCnt, setStoreCnt] = useState();
  const [esgCharge, setEsgCharge] = useState();
  const [esgCnt, setEsgCnt] = useState();
  const [govCharge, setGovCharge] = useState();
  const [govCnt, setGovCnt] = useState();

  const [cpcCharge, setCpcCharge] = useState();
  const [cpvCharge, setCpvCharge] = useState();

  const [totalCharge, setTotalCharge] = useState();
  const [totalCnt, setTotalCnt] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [pdfModalShow, setPdfModalShow] = useState(false);

  const pdfRef = useRef();

  const [budgetState, setBudgetState] = useState({});

  const getBudgetStateApi = async () => {
    try {
      const { data } = await getBudgetState();
      if (data.code === 200) {
        setBudgetState(data.data);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getCommaValue = (data, suffix = '', nullStr = '-') => {
    return data === 0
      ? `${data}${suffix}`
      : data
      ? `${Utils.changeNumberCommaForZero(data)}${suffix}`
      : nullStr;
  };

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaList = async () => {
    try {
      const { data } = await getPromoArea();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPrmAreaList(tempList);
        if (promoAreaId) {
          setSearchPrmArea(tempList.find(v => v.value === promoAreaId));
        } else {
          setSearchPrmArea(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const [byPriceData, setByPriceData] = useState([]);
  const [byTypeData, setByTypeData] = useState([]);

  const getPriceChartData = async () => {
    try {
      const params = {
        profileId,
        promoAreaId,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data } = await getPriceCharts(params);
      if (data.code === 200) {
        setPriceList(data.data.list);
        setStoreCharge(data.data.storeCharge);
        setStoreCnt(data.data.storeCnt);

        setEsgCharge(data.data.esgCharge);
        setEsgCnt(data.data.esgCnt);

        setGovCharge(data.data.govCharge);
        setGovCnt(data.data.govCnt);

        setCpcCharge(data.data.cpcCharge);
        setCpvCharge(data.data.cpvCharge);

        setTotalCharge(data.data.totalCharge);
        setTotalCnt(data.data.totalCnt);
        const { byPrice, byType } = data.data;

        setByPriceData(byPrice);
        setByTypeData(byType);
        const tempPriceChartObj = {};
        const tempTypeBarChartObj = {};
        const chartTypeLabels = [];
        const chartPriceLabels = [];

        byPrice.forEach(item => {
          if (!tempPriceChartObj[item.category]) {
            tempPriceChartObj[item.category] = [];
          }

          tempPriceChartObj[item.category].push(item);
          if (!chartPriceLabels.find(v => v.category === item.category)) {
            chartPriceLabels.push(
              labels.find(lb => lb.label === item.category),
            );
          }
        });

        chartPriceLabels.sort((a, b) => {
          return orderBy(a.sort, b.sort);
        });

        const tempPriceChartData = {
          labels: chartPriceLabels.map(v => {
            return v.label;
          }),
          datasets: [
            {
              data: Object.keys(tempPriceChartObj)
                .sort((a, b) => {
                  return orderBy(
                    labels.find(v => v.label === a).sort,
                    labels.find(v => v.label === b).sort,
                  );
                })
                .map(key =>
                  tempPriceChartObj[key].reduce(
                    (sum, item) => sum + item.value,
                    0,
                  ),
                ),
              datalabels: defaultPieDataLabels,
              backgroundColor: labels
                .sort((a, b) => {
                  return orderBy(a.category, b.category);
                })
                .map(label => {
                  return label.rgb;
                }),
              hoverOffset: 4,
            },
          ],
        };

        byType.forEach(item => {
          if (!tempTypeBarChartObj[item.category]) {
            tempTypeBarChartObj[item.category] = [];
          }

          tempTypeBarChartObj[item.category].push(item);

          if (!chartTypeLabels.find(v => v.category === item.category)) {
            chartTypeLabels.push(
              schlabels.find(lb => lb.category === item.category),
            );
          }
        });

        chartTypeLabels.sort((a, b) => {
          return orderBy(a.category, b.category);
        });

        const tempTypeBarChartData = {
          labels: chartTypeLabels.map(v => {
            return v.label;
          }),
          datasets: [
            {
              data: Object.keys(tempTypeBarChartObj)
                .sort()
                .map(key =>
                  tempTypeBarChartObj[key].reduce(
                    (sum, item) => sum + item.value,
                    0,
                  ),
                ),
              backgroundColor: schlabels
                .sort((a, b) => {
                  return orderBy(a.category, b.category);
                })
                .map(label => {
                  return label.rgb;
                }),
              hoverOffset: 4,
            },
          ],
        };

        setTypeBarChartData(tempTypeBarChartData);
        setPriceChartData(tempPriceChartData);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  // 페이지 이동
  const movePage = () => {
    const state = {
      pageRefresh: !pageRefresh,
      profileId: searchProfile.value,
      startDate: searchStartDate,
      endDate: searchEndDate,
      promoAreaId: searchPrmArea.value,
    };

    props.history.push({
      pathname: RouterPath.monitoring,
      state,
    });
  };

  const setSearchState = () => {
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
    setSearchPrmArea(prmAreaList.find(v => v.value === promoAreaId) || allData);
  };

  useEffect(() => {
    getProfileList();
    getPromoAreaList();
    getBudgetStateApi();
  }, []);

  useEffect(() => {
    setSearchState();
    getPriceChartData();
  }, [pageRefresh]);

  // pdf
  const createPdfElement = (tableList, type) => {
    console.log('type :: ', type === 'FRIST' || type === 'ALL');
    return (
      <article className="mt-4 scrollbar prmtable">
        {(type === 'FIRST' || type === 'ALL') && (
          <div className="flex-title">
            <h5>홍보단위별 과금 현황 리스트</h5>
            <div className="prm-flex">
              {[
                {
                  title: '건수총계',
                  count: `${Utils.changeNumberComma(totalCnt)}건`,
                  total: 'total',
                },
                {
                  title: 'ESG',
                  count: `${Utils.changeNumberComma(esgCnt)}건`,
                  value: `₩${Utils.changeNumberComma(esgCharge)}`,
                  total: 'ESG',
                  style: 'bd-right',
                },
                {
                  title: '소상공인',
                  count: `${Utils.changeNumberComma(storeCnt)}건`,
                  value: `₩${Utils.changeNumberComma(esgCharge)}`,
                  total: 'smbusiness',
                  style: 'bd-right',
                },
                {
                  title: '지자체',
                  count: `${Utils.changeNumberComma(govCnt)}건`,
                  value: `₩${Utils.changeNumberComma(govCharge)}`,
                  total: 'local',
                  style: 'bd-right',
                },
              ].map(option => (
                <p>
                  <span className="bd-none">{option.title}</span>
                  <span
                    className={`prm-title-color ${option.total} ${option.style}`}
                  >
                    {option.count}
                  </span>
                  <span className={`prm-title-color ${option.total}`}>
                    {option.value}
                  </span>
                </p>
              ))}
            </div>
          </div>
        )}
        <Table className="table-hover text-start mt-3">
          <colgroup>
            <col width={70} />
            <col width={80} />
            <col width={100} />
            <col width={120} />
            <col width={100} />
            <col width={120} />
            <col width={90} />
            <col width={120} />
            <col width={90} />
            <col width={120} />
            <col width={120} />
            <col width={120} />
          </colgroup>
          <thead>
            <tr>
              <th className="td-center">No</th>
              <th>홍보단위</th>
              <th>홍보영역</th>
              <th>홍보 타이틀</th>
              <th>홍보 조회기간</th>
              <th>CPV 단가</th>
              <th>CPV 노출수</th>
              <th>CPC 단가</th>
              <th>CPC 노출수</th>
              <th>CPV 과금</th>
              <th>CPC 과금</th>
              <th>총 금액</th>
            </tr>
          </thead>
          <tbody>
            {tableList.map((v, i) => {
              return (
                <tr key={`price_${i}`}>
                  <td className="td-center">{v.promoId}</td>
                  <td>{v.codeType}</td>
                  <td>{v.promoArea}</td>
                  <td>{v.title}</td>
                  <td>
                    {' '}
                    {v.startDate ? (
                      <Moment
                        date={v.startDate}
                        format="YYYY.MM.DD"
                        interval={0}
                      />
                    ) : (
                      '-'
                    )}
                    <span className="pd-lr"> - </span>
                    {v.endDate ? (
                      <Moment
                        date={v.endDate}
                        format="YYYY.MM.DD"
                        interval={0}
                      />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{Utils.changeNumberComma(v.cpvPrice)}</td>
                  <td>{Utils.changeNumberComma(v.cpvCnt)}</td>
                  <td>{Utils.changeNumberComma(v.cpcPrice)}</td>
                  <td>{Utils.changeNumberComma(v.cpcCnt)}</td>
                  <td>{Utils.changeNumberComma(v.cpvCharge)}</td>
                  <td>{Utils.changeNumberComma(v.cpcCharge)}</td>
                  <td>{Utils.changeNumberComma(v.totalCharge)}</td>
                </tr>
              );
            })}
            {(type === 'LAST' || type === 'ALL') && (
              <tr>
                <td colSpan={9} className="totalcount">
                  총계
                </td>
                <td>{Utils.changeNumberComma(cpvCharge)}</td>
                <td>{Utils.changeNumberComma(cpcCharge)}</td>
                <td>{Utils.changeNumberComma(totalCharge)}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </article>
    );
  };

  const splitArray = (arr, chunkSize) => {
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  };

  const createSearchPdfElement = ({ pdfTitle }) => {
    const prmAreaObj =
      prmAreaList.find(v => v.value === promoAreaId) || allData;
    const profileObj = profileList.find(v => v.value === profileId) || allData;
    return (
      <>
        <Form className="search-area">
          <h1>{pdfTitle}</h1>
          <Form.Group className="form-group">
            <Form.Label>사업단위</Form.Label>
            <Form.Label>{profileObj.label}</Form.Label>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>홍보영역</Form.Label>
            <Form.Label>{prmAreaObj.label}</Form.Label>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>조회일</Form.Label>
            <div className="datepicker-wrap mw-280">
              <Moment date={startDate} format="YYYY.MM.DD" interval={0} />
              <span>-</span>
              <Moment date={endDate} format="YYYY.MM.DD" interval={0} />
            </div>
          </Form.Group>
        </Form>
        <div className="grid-table">
          <article className="mt-4 prmtable">
            <h5>광고비별 종합 총계</h5>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={80} />
                <col width={140} />
                <col width={140} />
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>노출수</th>
                  <th>비율</th>
                </tr>
              </thead>

              <tbody>
                {byPriceData
                  .filter(v => v.category !== 'CPA')
                  .map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{v.category}</td>
                        <td>{Utils.changeNumberComma(v.value)}</td>
                        <td>{Utils.calculatePercentage(v.rateResult, 1, 1)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </article>
          <article className="mt-4 prmtable">
            <h5>홍보단위별 종합 총계</h5>
            <Table className="table-hover text-start mt-3 table-center">
              <colgroup>
                <col width={100} />
                <col width={140} />
                <col width={140} />
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>노출수</th>
                  <th>총 금액</th>
                </tr>
              </thead>

              <tbody>
                {[
                  {
                    title: 'ESG',
                    count: `${Utils.changeNumberComma(esgCnt)}`,
                    value: `${Utils.changeNumberComma(esgCharge)}원`,
                    code: 'ESG',
                    style: 'bd-right',
                  },
                  {
                    title: '소상공인',
                    count: `${Utils.changeNumberComma(storeCnt)}`,
                    value: `${Utils.changeNumberComma(storeCharge)}원`,
                    code: 'smbusiness',
                    style: 'bd-right',
                  },
                  {
                    title: '지자체',
                    count: `${Utils.changeNumberComma(govCnt)}`,
                    value: `${Utils.changeNumberComma(govCharge)}원`,
                    code: 'local',
                    style: 'bd-right',
                  },
                  {
                    title: '총계',
                    count: `${Utils.changeNumberComma(totalCnt)}`,
                    value: `${Utils.changeNumberComma(totalCharge)}원`,
                    className: 'fw-700',
                  },
                ].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className={v.className || ''}>{v.title}</td>
                      <td className={v.className || ''}>{v.count}</td>
                      <td className={v.className || ''}>{v.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </article>
        </div>
      </>
    );
  };

  const createElementWithContent = (tag, content) => {
    const element = document.createElement(tag);
    element.innerHTML = content;
    return element;
  };

  const renderToString = (element, props = {}) =>
    ReactDOMServer.renderToString(React.createElement(element, props));

  const createPdfImages = async pdfTitle => {
    setIsLoading(true);
    const tempPdfImages = [];

    let i = 0; // priceList의 시작 인덱스
    let chunkSize = 30; // 초기 청크 크기

    const originalStyle = pdfRef.current.style.cssText;
    pdfRef.current.style.cssText += 'max-width: 1920px;';
    await new Promise(resolve => setTimeout(resolve, 100));

    const chartCanvas = await html2canvas(pdfRef.current);

    pdfRef.current.style.cssText = originalStyle;

    const chartImageUrl = chartCanvas.toDataURL('image/png');
    const chartImg = document.createElement('img');
    chartImg.src = chartImageUrl;

    while (i < priceList.length) {
      const pdfObj = priceList.slice(i, i + chunkSize); // priceList에서 청크 크기만큼 잘라냄
      let type = '';

      if (pdfObj.length === priceList.length) {
        type = 'ALL';
      } else if (i === 0) {
        type = 'FIRST';
      } else if (i + chunkSize >= priceList.length) {
        type = 'LAST';
      }

      const firstDiv = document.createElement('div');
      firstDiv.style.position = 'fixed'; // Add this line
      firstDiv.style.top = '-10000px'; // Add this line
      firstDiv.style.maxWidth = '1920px';
      firstDiv.id = 'datalab-monitor';

      const searchDiv = createElementWithContent(
        'div',
        renderToString(createSearchPdfElement, { pdfTitle }),
      );

      const tempDiv = document.createElement('div');
      tempDiv.id = 'datalab-monitor';
      tempDiv.style.maxWidth = '1920px';

      if (type === 'ALL' || type === 'FIRST') {
        firstDiv.appendChild(searchDiv);
        firstDiv.appendChild(chartImg);
      }

      console.log('type :: ', type);
      const tempEle = createPdfElement(pdfObj, type);
      const content = ReactDOMServer.renderToString(tempEle);
      tempDiv.innerHTML = content;
      firstDiv.appendChild(tempDiv);

      document.body.appendChild(firstDiv);

      const pageWidth = 190;
      const pageHeight = 237;

      const widthRatio = pageWidth / (firstDiv.offsetWidth * 2);
      const customHeight = firstDiv.offsetHeight * 2 * widthRatio;

      if (pageHeight >= customHeight) {
        const addPdfCanvas = await html2canvas(firstDiv);

        const widthRatioReal = pageWidth / addPdfCanvas.width;
        const customHeightReal = addPdfCanvas.height * widthRatioReal;

        if (pageHeight >= customHeightReal) {
          const addPdfImage = addPdfCanvas.toDataURL('image/png');

          const addPdfObj = {
            canvas: addPdfCanvas,
            image: addPdfImage,
          };

          // A4 크기에 맞으면 tempPdfImages에 추가하고 다음 청크로 이동
          tempPdfImages.push(addPdfObj);
          i += chunkSize;
          chunkSize = 30;
        } else if (chunkSize > 1) {
          // A4 크기보다 크면 청크 크기를 하나 줄임
          chunkSize--;
        } else {
          throw new Error('Element size exceeds A4 page size.');
        }
      } else if (chunkSize > 1) {
        // A4 크기보다 크면 청크 크기를 하나 줄임
        chunkSize--;
      } else {
        throw new Error('Element size exceeds A4 page size.');
      }

      document.body.removeChild(firstDiv);
    }

    if (priceList.length === 0) {
      const tempDiv = document.createElement('div');

      const searchDiv = createElementWithContent(
        'div',
        renderToString(createSearchPdfElement, { pdfTitle }),
      );

      const firstDiv = document.createElement('div');
      firstDiv.style.position = 'fixed'; // Add this line
      firstDiv.style.top = '-10000px'; // Add this line
      firstDiv.style.maxWidth = '1920px';
      firstDiv.id = 'datalab-monitor';

      firstDiv.appendChild(searchDiv);
      firstDiv.appendChild(chartImg);

      const tempEle = createPdfElement(priceList, 'ALL');
      const content = ReactDOMServer.renderToString(tempEle);
      tempDiv.innerHTML = content;
      firstDiv.appendChild(tempDiv);

      document.body.appendChild(firstDiv);

      const addPdfCanvas = await html2canvas(firstDiv);
      const addPdfImage = addPdfCanvas.toDataURL('image/png');

      const addPdfObj = {
        canvas: addPdfCanvas,
        image: addPdfImage,
      };
      tempPdfImages.push(addPdfObj);
    }
    setIsLoading(false);
    return tempPdfImages;
  };

  const downloadExel = async () => {
    setIsLoading(true);
    try {
      const params = {
        profileId,
        promoAreaId,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
      };
      const { data, headers } = await getPriceExcel(params);

      const contentDisposition = headers['content-disposition'];
      let fileName = 'datalab_promotions_by_area.xlsx';
      try {
        if (contentDisposition) {
          fileName = contentDisposition.split(';')[1].split('=')[1].trim();
        }
      } catch (error) {
        //
      }

      const blob = new Blob([data]);
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = fileName;
      link.style.display = 'none';
      link.click();
      link.remove();
    } catch (error) {
      throw error;
    }
  };

  return (
    <main id="datalab-monitor">
      {isLoading && <Loading />}
      <Header title="과금 모니터링 조회" />
      <Container className="contents container-128">
        <div className="grid-article">
          <article className="total-box">
            <p className="total-p">예산 누적 총액</p>
            <p className="total-money">
              {getCommaValue(budgetState.sum, '원', '-')}
            </p>
          </article>
          <article className="total-box">
            <p className="total-p">예산 현재 잔액</p>
            <p className="total-money">
              {getCommaValue(budgetState.remain, '원', '-')}
            </p>
          </article>
        </div>
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보영역</Form.Label>
              <CustomSelect
                options={prmAreaList}
                value={searchPrmArea}
                onChange={setSearchPrmArea}
                className="mw-160"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>조회일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  maxDate={new Date()}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
          </Form>
          <div className="btn-center">
            <Button
              onClick={() => {
                movePage();
              }}
            >
              검색
            </Button>
          </div>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              downloadExel()
                .catch(() => {
                  CustomSwal.fire({
                    text: 'XLSX 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                    confirmButtonText: '확인',
                  });
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          >
            <i
              style={{ backgroundImage: `url(${images.icExcel})` }}
              className="xlsx me-2"
            />
            XLSX
          </Button>
          <Button
            onClick={() => {
              setPdfModalShow(true);
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>

        <div className="grid-table">
          <article className="mt-4 prmtable">
            <h5>광고비별 종합 총계</h5>
            <Table className="table-hover text-start mt-3">
              <colgroup>
                <col width={80} />
                <col width={140} />
                <col width={140} />
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>노출수</th>
                  <th>비율</th>
                </tr>
              </thead>

              <tbody>
                {byPriceData
                  .filter(v => v.category !== 'CPA')
                  .map((v, i) => {
                    return (
                      <tr key={i}>
                        <td>{v.category}</td>
                        <td>{Utils.changeNumberComma(v.value)}</td>
                        <td>{Utils.calculatePercentage(v.rateResult, 1, 1)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </article>
          <article className="mt-4 prmtable">
            <h5>홍보단위별 종합 총계</h5>
            <Table className="table-hover text-start mt-3 table-center">
              <colgroup>
                <col width={100} />
                <col width={140} />
                <col width={140} />
              </colgroup>
              <thead>
                <tr>
                  <th />
                  <th>노출수</th>
                  <th>총 금액</th>
                </tr>
              </thead>

              <tbody>
                {[
                  {
                    title: 'ESG',
                    count: `${Utils.changeNumberComma(esgCnt)}`,
                    value: `${Utils.changeNumberComma(esgCharge)}원`,
                    code: 'ESG',
                    style: 'bd-right',
                  },
                  {
                    title: '소상공인',
                    count: `${Utils.changeNumberComma(storeCnt)}`,
                    value: `${Utils.changeNumberComma(storeCharge)}원`,
                    code: 'smbusiness',
                    style: 'bd-right',
                  },
                  {
                    title: '지자체',
                    count: `${Utils.changeNumberComma(govCnt)}`,
                    value: `${Utils.changeNumberComma(govCharge)}원`,
                    code: 'local',
                    style: 'bd-right',
                  },
                  {
                    title: '총계',
                    count: `${Utils.changeNumberComma(totalCnt)}`,
                    value: `${Utils.changeNumberComma(totalCharge)}원`,
                    className: 'fw-700',
                  },
                ].map((v, i) => {
                  return (
                    <tr key={i}>
                      <td className={v.className || ''}>{v.title}</td>
                      <td className={v.className || ''}>{v.count}</td>
                      <td className={v.className || ''}>{v.value}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </article>
        </div>
        <div className="grid-section" ref={pdfRef}>
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>광고비별 과금 집계 현황</h5>
                <p>단위 : 원</p>
              </div>
              <div className="bar-height">
                {priceChartData && (
                  <MonitorBarChart chartData={priceChartData} />
                )}
              </div>
            </div>
          </article>
          <article className="mt-4">
            <div className="bar-section">
              <div className="flex-title">
                <h5>홍보단위별 과금 집계 현황</h5>
                <p>단위 : 원</p>
              </div>
              <div className="bar-height">
                {typeBarChartData && (
                  <MonitorBarChart chartData={typeBarChartData} />
                )}
              </div>
            </div>
          </article>
          <article className="mt-4">
            <div className="pie-section">
              <div className="flex-title">
                <h5>광고비별 과금 비율</h5>
                <div className="title-labels">
                  {labels.map((option, i) => (
                    <div className="oplabel-flex" key={`label_${i}`}>
                      <span className={`option-boll ${option.color}`} />
                      <Form.Label>{option.label}</Form.Label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pie-height">
                {priceChartData && (
                  <DataLabPieChart
                    style={{ flex: 1, width: '100%' }}
                    chartData={priceChartData}
                  />
                )}
              </div>
            </div>
          </article>
        </div>

        <article className="mt-4 scrollbar prmtable">
          <div className="flex-title">
            <h5>홍보단위별 과금 현황 리스트</h5>
            <div className="prm-flex">
              {[
                {
                  title: '건수총계',
                  count: `${Utils.changeNumberComma(totalCnt)}건`,
                  code: 'total',
                },
                {
                  title: '총 노출수',
                  count: `${Utils.changeNumberComma(priceList.length)}건`,
                  code: 'total',
                },
                {
                  title: 'ESG',
                  count: `${Utils.changeNumberComma(esgCnt)}건`,
                  value: `₩${Utils.changeNumberComma(esgCharge)}`,
                  code: 'ESG',
                  style: 'bd-right',
                },
                {
                  title: '소상공인',
                  count: `${Utils.changeNumberComma(storeCnt)}건`,
                  value: `₩${Utils.changeNumberComma(storeCharge)}`,
                  code: 'smbusiness',
                  style: 'bd-right',
                },
                {
                  title: '지자체',
                  count: `${Utils.changeNumberComma(govCnt)}건`,
                  value: `₩${Utils.changeNumberComma(govCharge)}`,
                  code: 'local',
                  style: 'bd-right',
                },
              ].map((option, i) => (
                <p key={i}>
                  <span className="bd-none">{option.title}</span>
                  <span
                    className={`prm-title-color ${option.code} ${option.style}`}
                  >
                    {option.count}
                  </span>
                  <span className={`prm-title-color ${option.code}`}>
                    {option.value}
                  </span>
                </p>
              ))}
            </div>
          </div>
          <Table className="table-hover text-start mt-3">
            <colgroup>
              <col width={70} />
              <col width={80} />
              <col width={100} />
              <col width={120} />
              <col width={100} />
              <col width={120} />
              <col width={90} />
              <col width={120} />
              <col width={90} />
              <col width={120} />
              <col width={120} />
              <col width={120} />
            </colgroup>
            <thead>
              <tr>
                <th className="td-center">No</th>
                <th>홍보단위</th>
                <th>홍보영역</th>
                <th>홍보 타이틀</th>
                <th>홍보 조회기간</th>
                <th>CPV 단가</th>
                <th>CPV 노출수</th>
                <th>CPC 단가</th>
                <th>CPC 노출수</th>
                <th>CPV 과금</th>
                <th>CPC 과금</th>
                <th>총 금액</th>
              </tr>
            </thead>

            <tbody>
              {priceList.length > 0 ? (
                <>
                  {priceList.map((v, i) => {
                    return (
                      <tr key={`price_${i}`}>
                        <td className="td-center">{v.promoId}</td>
                        <td>{v.codeType}</td>
                        <td>{v.promoArea}</td>
                        <td>{v.title}</td>
                        <td>
                          {' '}
                          {v.startDate ? (
                            <Moment
                              date={v.startDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                          <span className="pd-lr"> - </span>
                          {v.endDate ? (
                            <Moment
                              date={v.endDate}
                              format="YYYY.MM.DD"
                              interval={0}
                            />
                          ) : (
                            '-'
                          )}
                        </td>
                        <td>{Utils.changeNumberComma(v.cpvPrice)}</td>
                        <td>{Utils.changeNumberComma(v.cpvCnt)}</td>
                        <td>{Utils.changeNumberComma(v.cpcPrice)}</td>
                        <td>{Utils.changeNumberComma(v.cpcCnt)}</td>
                        <td>{Utils.changeNumberComma(v.cpvCharge)}</td>
                        <td>{Utils.changeNumberComma(v.cpcCharge)}</td>
                        <td>{Utils.changeNumberComma(v.totalCharge)}</td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={9} className="totalcount">
                      총계
                    </td>
                    <td>{Utils.changeNumberComma(cpvCharge)}</td>
                    <td>{Utils.changeNumberComma(cpcCharge)}</td>
                    <td>{Utils.changeNumberComma(totalCharge)}</td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td className="no-data" colSpan={12}>
                    홍보단위별 과금 현황이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </article>
      </Container>
      {pdfModalShow && (
        <PdfTitleModal
          show={pdfModalShow}
          handleClose={() => {
            setPdfModalShow(false);
          }}
          onDownload={pdfTitle => {
            createPdfImages(pdfTitle)
              .then(e => {
                return PdfDownloader(e);
              })
              .catch(e => {
                CustomSwal.fire({
                  text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                  confirmButtonText: '확인',
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
    </main>
  );
});

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const businessOptions = [
  { label: '사업단위1', value: '1' },
  { label: '사업단위2', value: '2' },
  { label: '사업단위3', value: '3' },
  { label: '사업단위4', value: '3' },
];
const adminTableList = [
  {
    tno: '1',
    prmset: '소상공인',
    prm: '슬라이드 배너',
    title: '홍보 타이틀이 나타납니다.',
    startDate: new Date(),
    endDate: new Date(),
    cpvdan: '100,000',
    cpvno: '111',
    cpcdan: '100,000',
    cpcno: '111',
    cpvmo: '11,100,000',
    cpcmo: '25,000',
    total: '11,100,000',
  },
];
const promotionOptions = [
  { label: '슬라이드 배너', value: '1' },
  { label: '바 배너', value: '2' },
  { label: '박스 배너', value: '3' },
  { label: '통 배너', value: '3' },
];
const addtotalList = [
  {
    adve: 'CPV',
    count: '10,000',
    per: '50%',
  },
  {
    adve: 'CPC',
    count: '10,000',
    per: '50%',
  },
];
const prmtotalList = [
  {
    prm: 'ESG',
    count: '10,000',
    adver: '100',
    pay: '12,121원',
  },
  {
    prm: '소상공인',
    count: '10,000',
    adver: '100',
    pay: '12,121원',
  },
  {
    prm: '지자체',
    count: '10,000',
    adver: '100',
    pay: '12,121원',
  },
];
