const ChartColorUtils = {
  // 두 색상 간의 색상 거리를 계산합니다.
  colorDistance: (color1, color2) => {
    const rDiff = color1[0] - color2[0];
    const gDiff = color1[1] - color2[1];
    const bDiff = color1[2] - color2[2];
    return Math.sqrt(rDiff * rDiff + gDiff * gDiff + bDiff * bDiff);
  },

  // 주어진 색상의 밝기를 계산하고 충분히 밝은지 확인합니다.
  isBrightEnough: color => {
    const brightness =
      (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;
    return brightness >= 80;
  },

  isDarkEnough: color => {
    const brightness =
      (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;
    return brightness < 180;
  },

  // 랜덤한 RGB 색상을 생성합니다.
  randomColor: () => {
    return [
      Math.floor(Math.random() * 256),
      Math.floor(Math.random() * 256),
      Math.floor(Math.random() * 256),
    ];
  },

  randomDarkRGB: (existingColors = [], minDistance = 50) => {
    let newColor;
    let tooClose;

    const checkColor = color => {
      return ChartColorUtils.colorDistance(color, newColor) < minDistance;
    };

    do {
      newColor = ChartColorUtils.randomColor();
      tooClose = existingColors.some(checkColor);
    } while (!ChartColorUtils.isDarkEnough(newColor) || tooClose);

    return `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
  },

  // 기존 색상과의 최소 거리를 고려하여 밝은 랜덤 RGB 색상을 생성합니다.
  randomBrightRGB: (existingColors = [], minDistance = 50) => {
    let newColor;
    let tooClose;

    const checkColor = color => {
      return ChartColorUtils.colorDistance(color, newColor) < minDistance;
    };

    do {
      newColor = ChartColorUtils.randomColor();
      tooClose = existingColors.some(checkColor);
    } while (!ChartColorUtils.isBrightEnough(newColor) || tooClose);

    return `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
  },

  // 주어진 길이와 최소 거리를 사용하여 색상 목록을 생성합니다.

  // 밝은색 리스트
  generateColorList(length, minDistance = 1000) {
    const colorList = [];

    while (colorList.length < length) {
      const newColor = ChartColorUtils.randomBrightRGB(colorList, minDistance);
      colorList.push(newColor);
    }

    return colorList;
  },
  // 어두운 색 리스트
  generateDarkColorList(length, minDistance = 1000) {
    const colorList = [];

    while (colorList.length < length) {
      const newColor = ChartColorUtils.randomDarkRGB(colorList, minDistance);
      colorList.push(newColor);
    }

    return colorList;
  },
};

export default ChartColorUtils;
