/* eslint-disable react/react-in-jsx-scope */
import './assets/css/arrow-three.css';

function ArrowThree({ transform, color }) {
  return (
    <div>
      {/* arrow-container-navy */}
      {color === 'first' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#24427a" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#788AAB" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#B7C0D1" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-orange */}
      {color === 'second' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#F99B2E" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#F7BF7E" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#F7DABA" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-skyblue */}
      {color === 'third' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#5890C7" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#97B8D9" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#C6D7E8" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-purple */}
      {color === 'fourth' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#624AEF" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#9D8EF1" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#C9C2F4" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-grey */}
      {color === 'fifth' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#2C8A3E" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#6EA779" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#A0BCA6" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-grey */}
      {color === 'sixth' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#FF97D6" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#EDAFD4" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#DFC0D3" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-grey */}
      {color === 'seventh' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#00DEB5" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#54D9C1" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#93D6C9" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-grey */}
      {color === 'eighth' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#EB440F" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#E17D5D" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#D9A797" strokeWidth="8" />
          </svg>
        </div>
      )}
      {/* arrow-container-grey */}
      {color === 'etc' && (
        <div className="animation-arrows" style={{ transform }}>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#BCBBDC" strokeWidth="8" />
          </svg>
          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#D3D2E6" strokeWidth="8" />
          </svg>

          <svg
            width="23"
            height="34"
            viewBox="0 0 23 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="ani"
          >
            <path d="M20 3L6 17L20 31" stroke="#E4E4EE" strokeWidth="8" />
          </svg>
        </div>
      )}
    </div>
  );
}

export default ArrowThree;
