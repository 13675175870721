import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, Link, NavLink } from 'react-router-dom';
import { Accordion, Nav, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { CustomSelect, CustomSwal } from '@components';
import { RouterPath } from '@common';
import { useSelector } from 'react-redux';
import { getAdmMenus } from '../../pages/Auth/Rest';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function Aside() {
  const history = useHistory();
  const location = useLocation();
  // 메뉴 조회
  const [menuList, setMenuList] = useState([]);

  const auth = useSelector(state => {
    return state.auth;
  });

  const getMenus = async () => {
    const { data } = await getAdmMenus();
    if (data.code === 200) {
      setMenuList(data.data.menus);
    } else {
      CustomSwal.alertSwal({
        text: RESULT_CODE[`CODE_${data.code}`],
        confirmButtonText: '확인',
      });
    }
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <aside>
      <h1 className="logo-box">
        <Link className="logo" to={RouterPath.slash} />
      </h1>

      <Accordion as="nav">
        {Array.isArray(menuList) &&
          menuList.length > 0 &&
          menuList.map((v, i) => {
            return Array.isArray(v.subMenuList) && v.subMenuList.length > 0 ? (
              <Accordion.Item
                className="nav-item"
                eventKey={`nav-item-${i + 1}`}
                key={`nav-item-${i + 1}`}
              >
                <div
                  className={classNames('nav-link', {
                    active: location.pathname.match(v.menuUrl),
                  })}
                >
                  <Accordion.Header>
                    <i className="material-icons-outlined">{v.iconNm}</i>
                    <span>{v.admMenuNm}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {Array.isArray(v.subMenuList) &&
                        v.subMenuList.length > 0 &&
                        v.subMenuList.map((s, j) => {
                          return (
                            <li key={`sub-item-${i + 1}-${j + 1}`}>
                              <NavLink to={s.menuUrl}>{s.admMenuNm}</NavLink>
                            </li>
                          );
                        })}
                    </ul>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            ) : (
              <Nav.Item key={`nav-item-${i + 1}`} className="accordion-item">
                <div
                  className={classNames('nav-link', {
                    active: location.pathname === v.menuUrl,
                  })}
                >
                  <h2 className="accordion-header">
                    <button
                      type="button"
                      className="accordion-button bg-none"
                      onClick={() => history.push(v.menuUrl)}
                    >
                      <i className="material-icons-outlined">{v.iconNm}</i>
                      <span>{v.admMenuNm}</span>
                    </button>
                  </h2>
                </div>
              </Nav.Item>
            );
          })}
      </Accordion>
    </aside>
  );
});
