import React from 'react';
import Router from './router/Router';

function App() {
  // const isAuthorized = localStorage.getItem('auth') != null;

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default React.memo(App);
