import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
  Alert,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteAdmin,
  getAdmins,
  patchAdmin,
  putAdmin,
  validateEmail,
  validateId,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function AdminModal({
  show,
  handleClose,
  type,
  adminData,
}) {
  const history = useHistory();
  const [admId, setAdmId] = useState();
  const [admType, setAdmType] = useState();
  const [loginId, setLoginId] = useState('');
  const [loginPw, setLoginPw] = useState('');
  const [checkloginPw, setCheckLoginPw] = useState('');
  const [email, setEmail] = useState('');
  const [memo, setMemo] = useState('');

  const [isLoginId, setIsLoginId] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isLoginPw, setIsLoginPw] = useState(false);

  const onSubmit = async event => {
    const target = event.currentTarget;
    try {
      target.disabled = true;
      if (!admType) {
        throw new CustomException('관리자 타입을 선택해 주세요.');
      }
      if (!loginId) {
        throw new CustomException('아이디를 입력해 주세요.');
      }
      if (!isLoginId) {
        throw new CustomException('아이디 중복 확인을 해주세요.');
      }
      if (!email) {
        throw new CustomException('이메일을 입력해 주세요.');
      }
      if (!isEmail) {
        throw new CustomException('이메일 중복 확인을 해주세요.');
      }
      if (!isLoginPw) {
        if (!loginPw) {
          throw new CustomException('비밀번호를 입력해 주세요.');
        }
        if (!checkloginPw) {
          throw new CustomException('비밀번호 확인을 입력해 주세요.');
        }
        if (!Utils.pwRuleCheck(loginPw)) {
          throw new CustomException('비밀번호 형식을 확인해 주세요.');
        }
        if (loginPw !== checkloginPw) {
          throw new CustomException('비밀번호 확인을 입력해 주세요.');
        }
      }

      const params = {
        loginId: Utils.encrypt(loginId),
        admType,
        email: Utils.encrypt(email),
        loginPw: Utils.encryptSHA256(loginPw),
        memo,
      };

      if (admId) {
        params.admId = admId;
      }
      const { data } = admId
        ? await patchAdmin(params)
        : await putAdmin(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 ${admId ? '수정' : '추가'} 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.admin);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
    target.disabled = false;
  };

  const checkValidateId = async () => {
    try {
      if (!loginId) {
        throw new CustomException('아이디를 입력해주세요.');
      }
      const params = {
        loginId,
      };
      const { data } = await validateId(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: '사용 가능한 아이디 입니다.',
          confirmButtonText: '확인',
          finally: () => {
            setIsLoginId(true);
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const checkValidateEmail = async () => {
    try {
      if (!email) {
        throw new CustomException('이메일을 입력해 주세요.');
      }
      const params = {
        email,
      };
      const { data } = await validateEmail(params);
      if (data.code === 200) {
        CustomSwal.fire({
          text: '사용 가능한 이메일 입니다.',
          confirmButtonText: '확인',
          finally: () => {
            setIsEmail(true);
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const deleteSwal = () => {
    Swal.fire({
      title: '삭제 확인',
      text: '정말로 삭제하시겠습니까? 삭제한 데이터는 복구할 수 없습니다.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '예, 삭제합니다.',
      cancelButtonText: '취소',
    }).then(e => {
      if (e.isConfirmed) {
        removeAdmin();
      }
    });
  };

  const removeAdmin = async () => {
    try {
      const { data } = await deleteAdmin(admId);
      if (data.code === 200) {
        CustomSwal.fire({
          text: `정상적으로 삭제 되었습니다.`,
          confirmButtonText: '확인',
          finally: () => {
            window.history.replaceState(null, '', RouterPath.admin);
            window.location.reload();
          },
        });
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  // 공통 에러 Swal
  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          history.push(RouterPath.Error);
        }
      },
    });
  };

  useEffect(() => {
    if (adminData && type === 'UPDATE') {
      setAdmType(adminData.admType || null);
      setEmail(Utils.decrypt(adminData.email) || '');
      setLoginId(Utils.decrypt(adminData.loginId) || '');
      setMemo(adminData.memo || '');
      setAdmId(adminData.admId);
      setIsEmail(true);
      setIsLoginId(true);
      setIsLoginPw(true);
    }
  }, []);
  return (
    <CustomModal
      title={`관리자 ${type === 'ADD' ? '추가' : '수정'}`}
      id="sysModal"
      show={show}
      noCloseBtn
      onHide={() => handleClose()}
    >
      <Modal.Body>
        <Form className="sys-area">
          <Form.Group className="form-group">
            <Form.Label>관리자타입</Form.Label>
            <CustomSelect
              options={Options.admTypeOptions.filter(
                v => v.value !== 'ADM-STORE',
              )}
              value={Options.admTypeOptions.find(v => v.value === admType)}
              onChange={e => {
                setAdmType(e.value);
              }}
              className="mw-200"
              placeholder="선택"
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>아이디</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="아이디를 입력하세요."
                className="mw-280"
                disabled={admId}
                value={loginId}
                onChange={e => {
                  setLoginId(e.target.value);
                  setIsLoginId(false);
                }}
              />
              {!admId && (
                <Button
                  variant="outline-primary"
                  className="btn-size"
                  disabled={isLoginId}
                  onClick={() => {
                    checkValidateId();
                  }}
                >
                  중복확인
                </Button>
              )}
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label className="align-label">비밀번호</Form.Label>
            <div className="password-box">
              <InputGroup>
                <Form.Control
                  type="password"
                  placeholder="영문,숫자,특수문자 조합으로 입력하세요"
                  className="mw-280"
                  value={loginPw}
                  onChange={e => {
                    setLoginPw(e.target.value);
                    setIsLoginPw(false);
                  }}
                />
              </InputGroup>
              <InputGroup>
                <Form.Control
                  type="password"
                  placeholder="비밀번호를 다시 입력하세요"
                  className="mw-280"
                  value={checkloginPw}
                  onChange={e => {
                    setCheckLoginPw(e.target.value);
                    setIsLoginPw(false);
                  }}
                />
              </InputGroup>
            </div>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>이메일</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="이메일을 입력하세요"
                className="mw-280"
                value={email}
                onChange={e => {
                  setEmail(e.target.value);
                  setIsEmail(false);
                }}
              />
              <Button
                variant="outline-primary"
                className="btn-size"
                disabled={isEmail}
                onClick={() => {
                  checkValidateEmail();
                }}
              >
                중복확인
              </Button>
            </InputGroup>
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>비고</Form.Label>
            <InputGroup>
              <Form.Control
                className="mw-280"
                value={memo}
                onChange={e => {
                  setMemo(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
        </Form>
        <div className="btn-area justify-btn-side">
          <Button variant="outline-primary" onClick={handleClose}>
            닫기
          </Button>
          <div className="flex-gap">
            {admId && (
              <Button
                variant="outline-primary"
                onClick={e => {
                  deleteSwal();
                }}
              >
                삭제
              </Button>
            )}
            <Button
              onClick={e => {
                onSubmit(e);
              }}
            >
              {admId ? '수정' : '추가'}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </CustomModal>
  );
});
