const RouterPath = {
  slash: '/',
  signin: '/auth/signin',
  findid: '/auth/findId',
  findpwd: '/auth/findPwd',

  dashboard: '/dashboard',
  fulldashboard: '/dashboard/fulldashboard',
  prmRequestList: '/promotion/request',
  prmRequestDetail: '/promotion/request/detail',
  prmRequestRegister: '/promotion/request/register',
  prmApprovalList: '/promotion/approval',
  prmApprovalDetail: '/promotion/approval/detail',
  prmSchedule: '/promotion/schedule',
  prmConfirmList: '/promotion/confirm',
  prmConfirmDetail: '/promotion/confirm/detail',
  prmDashboard: '/situation-dashboard',
  prmCoupon: '/promotion/coupon',
  prmCouponDetail: '/promotion/coupon/detail',
  prmSurvey: '/promotion/survey',
  prmSurveyRegister: '/promotion/survey/register',
  prmResult: '/promotion/survey/result',
  // 데이터랩
  // 상위지역 데이터
  topdata: '/datalab/topdata',
  // 경영지수 : 데이터 비용 환산
  datatocost: '/datalab/datatocost',
  // 유동인구
  population: '/datalab/population',
  // 홍보 노출수 통계
  prmstatistics: '/datalab/prmstatistics',
  // 홍보
  esgprm: '/datalab/esgprm',
  // 스케줄
  esgschedule: '/datalab/esgschedule',
  // 과금 모니터링
  monitoring: '/datalab/monitoring',

  // 시스템 관리
  // 관리자 계정관리
  admin: '/system/admin',
  // 권한 관리
  auth: '/system/auth',
  // 메뉴 관리
  memu: '/system/menu',
  // 관리자 접속로그
  admacceslog: '/system/admacceslog',
  // 관리자 액션 로그
  admactionlog: '/system/admactionlog',
  // 코드관리
  code: '/system/code',
  // 소상공인 계정관리
  memberaccount: '/system/memberaccount',
  // ap기기관리
  apdevice: '/system/apdevice',
  // 과금관리
  billing: '/system/billing',
  // 소상공인 신규등록
  smbusiness: '/smbusiness/register',
  // AP 상황판
  APMonitor: '/dashboard-ap',

  Errors: '/errors',
};
export default RouterPath;
