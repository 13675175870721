/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { getAdmins } from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import AdminModal from './AdminModal';

export default React.memo(function Admin(props) {
  const history = useHistory();

  const allData = {
    label: '전체',
    value: null,
  };

  const selectData = {
    label: '선택',
    value: null,
  };

  const size = 10;

  // 일반 관리자     :: ADM_EMAIL, ADM_LOGIN_ID
  // 소상공인 관리자  :: ADM_LOGIN_ID, BUSINESS_NM, PHONE_NO
  const searchTypeOptions = [
    {
      value: 'ADM_EMAIL',
      label: '이메일',
      isSearch: ['NORMAL'],
    },
    {
      value: 'ADM_LOGIN_ID',
      label: '아이디',
      isSearch: ['NORMAL', 'STORE'],
    },
    {
      value: 'BUSINESS_NM',
      label: '사업자명',
      isSearch: ['STORE'],
    },
    {
      value: 'PHONE_NO',
      label: '휴대폰 번호',
      isSearch: ['STORE'],
    },
  ];

  const page = (history.location.state && history.location.state.page) || 1;
  const admType =
    (history.location.state && history.location.state.admType) || null;
  const searchType =
    (history.location.state && history.location.state.searchType) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || '';
  const startDate =
    (history.location.state && history.location.state.startDate) || null;
  const endDate =
    (history.location.state && history.location.state.endDate) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [searchAdmType, setSearchAdmType] = useState(allData);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [selectSearchType, setSelectSearchType] = useState(selectData);

  const [adminModalShow, setAdminModalShow] = useState(false);
  const [adminModalType, setAdminModalType] = useState('ADD');
  const [selectAdminData, setSelectAdminData] = useState();
  const modalHandleOpen = (type, admin) => {
    setAdminModalShow(true);
    setAdminModalType(type);
    if (admin) {
      setSelectAdminData(admin);
    }
  };
  const modalHandleClose = () => {
    setAdminModalShow(false);
    setAdminModalType('ADD');
    setSelectAdminData();
  };

  const [adminList, setAdminList] = useState([]);
  const [adminTotalPage, setAdminTotalPage] = useState(0);

  const getAdminList = async () => {
    try {
      const params = {
        page,
        size,
        searchType,
        admType,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
        keyword,
      };
      const { data } = await getAdmins(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setAdminTotalPage(data.data.totalPages);
          setAdminList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const setSearchState = () => {
    setSearchAdmType(
      Options.admTypeOptions.find(v => v.state === admType) || allData,
    );
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSelectSearchType(
      searchTypeOptions.find(v => v.value === searchType) || selectData,
    );
    setSearchKeyword(keyword || '');
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      admType: isSearch ? searchAdmType.state : admType,
      searchType: isSearch ? selectSearchType.value : searchType,
      startDate: isSearch ? searchStartDate : startDate,
      endDate: isSearch ? searchEndDate : endDate,
    };

    props.history.push({
      pathname: RouterPath.admin,
      state,
    });
  };

  useEffect(() => {
    setSearchState();
    getAdminList();
  }, [pageRefresh]);

  return (
    <main id="prm-request-list">
      <Header title="관리자 계정 관리" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>생성일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  // minDate={Utils.getTomorrow()}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>관리자타입</Form.Label>
              <CustomSelect
                options={[
                  allData,
                  ...Options.admTypeOptions.filter(
                    v => v.value !== 'ADM-STORE',
                  ),
                ]}
                value={searchAdmType}
                onChange={e => {
                  setSearchAdmType(e);
                  setSelectSearchType(selectData);
                }}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <CustomSelect
                  options={[
                    selectData,
                    ...searchTypeOptions.filter(
                      v =>
                        !!v.isSearch.find(
                          f => f === (searchAdmType.type || 'NORMAL'),
                        ),
                    ),
                  ]}
                  value={selectSearchType}
                  onChange={setSelectSearchType}
                  className="mw-160"
                  placeholder="선택"
                />
                <Form.Control
                  placeholder="검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      if (searchKeyword && !selectSearchType.value) {
                        CustomSwal.fire({
                          text: '검색어 타입을 선택해주세요.',
                          allowEnterKey: false,
                          confirmButtonText: '확인',
                        });
                      } else {
                        movePage(1, true, e);
                      }
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4">
          <div className="btn-area justify-content-end">
            <Button onClick={() => modalHandleOpen('ADD')}>관리자 추가</Button>
          </div>
          <Table className="table-hover text-start">
            <colgroup>
              <col width={80} />
              <col width={120} />
              <col width={100} />
              <col width={100} />
              <col width={120} />
              <col width={120} />
            </colgroup>
            <thead>
              <tr>
                <th>관리자타입</th>
                <th>아이디</th>
                <th>이메일</th>
                <th>사용여부</th>
                <th>생성일시</th>
                <th>비고</th>
              </tr>
            </thead>

            <tbody>
              {adminList.length > 0 ? (
                adminList.map((v, i) => {
                  return (
                    <tr
                      key={`admin_${i}`}
                      onClick={() => {
                        modalHandleOpen('UPDATE', v);
                      }}
                    >
                      <td>
                        {
                          Options.admTypeOptions.find(
                            at => at.value === v.admType,
                          ).label
                        }
                      </td>
                      <td>{Utils.decrypt(v.loginId)}</td>
                      <td>{Utils.decrypt(v.email)}</td>
                      <td>{v.useYn}</td>
                      <td>
                        {v.createDate ? (
                          <Moment
                            date={v.createDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                      <td>{v.memo}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={6}>
                    조회된 관리자가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={adminTotalPage}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
      {/** 관리자 추가 ,수정 모달 */}
      {adminModalShow &&
        (adminModalType === 'UPDATE' ? selectAdminData : !selectAdminData) && (
          <AdminModal
            show={adminModalShow}
            handleClose={modalHandleClose}
            type={adminModalType}
            adminData={selectAdminData}
          />
        )}
    </main>
  );
});
