import REST from '@common/rest';
import Swal from 'sweetalert2';
import axios from 'axios';
import CustomSwal from '../components/CustomSwal';

const baseURL = process.env.REACT_APP_API_URL;
const API_VERSION = 'api/v1';

export const DOMAIN_COMMON = `${API_VERSION}/common`;
export const DOMAIN_AUTH = `${API_VERSION}/auth`;
export const DOMAIN_PROMO_REQ = `${API_VERSION}/promo-req`;
export const DOMAIN_PROMO_MANAGE = `${API_VERSION}/promo-manage`;
export const DOMAIN_PROMO_CON = `${API_VERSION}/promo-con`;
export const DOMAIN_PROMO_SCHEDULE = `${API_VERSION}/promo-schedule`;
export const DOMAIN_PROMO_COUPON = `${API_VERSION}/promo-coupon`;
export const DOMAIN_PROMO_SURVEY = `${API_VERSION}/promo-survey`;
export const DOMAIN_DASHBOARD_PROMO = `${API_VERSION}/dashboard-promo`;
export const DOMAIN_ADM_ACCOUNT = `${API_VERSION}/adm-account`;
export const DOMAIN_ADM_STORE = `${API_VERSION}/adm-store`;
export const DOMAIN_ADM_GRANT = `${API_VERSION}/adm-grant`;
export const DOMAIN_ADM_ACCESS = `${API_VERSION}/adm-access`;
export const DOMAIN_ADM_ACTION = `${API_VERSION}/adm-action`;
export const DOMAIN_CONFIG_MENU = `${API_VERSION}/config-menu`;
export const DOMAIN_CONFIG_CODE = `${API_VERSION}/config-code`;
export const DOMAIN_CONFIG_AP = `${API_VERSION}/config-ap`;
export const DOMAIN_CONFIG_PRICE = `${API_VERSION}/config-price`;
export const DOMAIN_CONFIG_CHARGE = `${API_VERSION}/config-charge`;
export const DOMAIN_PROMO_STORE = `${API_VERSION}/promo-store`;
export const DOMAIN_DATALAB_PROMO = `${API_VERSION}/datalab-promo`;
export const DOMAIN_DATALAB_ESG = `${API_VERSION}/datalab-esg`;
export const DOMAIN_DATALAB_SCHEDULE = `${API_VERSION}/datalab-schedule`;
export const DOMAIN_DATALAB_PRICE = `${API_VERSION}/datalab-price`;

const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

/*
 유동
 */
// 유동 상황판 조회
export const getFloating = (url, data) => {
  // const response = REST.get(
  //   `http://192.168.10.93:8081/api/v1/dashboard/state?depth=0&time=1`,
  // );
  const response = REST.get(`${baseURL + API_VERSION}/dashboard/${url}`, {
    params: data,
  });
  return response;
};

// 유동 상황판 구축센서 장비현황 조회
export const getFloatingAPC = url => {
  const response = REST.get(`${baseURL + API_VERSION}/dashboard/${url}`);
  return response;
};
// https://dev-wifree-admin.upchain.co.kr/api/v1/flowDataLab/direct?startDate=2023-06-1&endDate=2023-06-05&fromApId=10

// 유동 데이터랩 조회
export const getFlowDataLab = (url, data) => {
  const response = REST.get(`${baseURL + API_VERSION}/flowDataLab/${url}`, {
    params: data,
  });
  return response;
};

// 유동 데이터랩 엑셀 다운
export const getFlowDataLabExcel = (url, data) => {
  return REST.get(`${baseURL + API_VERSION}/flowDataLab/${url}/xlsx`, {
    params: data,
  });
};

/*
 공통
 */

// WSAD_IF_010 ::  홍보 영역 조회
export const getPromoArea = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promo-area`);
};

// WSAD_IF_011 ::  요청시 홍보 영역 조회
export const getPromoAreaReq = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promo-area-req`);
};

// WSAD_IF_012 ::  홍보 구분 조회
export const getPromoCategories = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promo-categories`);
};

// WSAD_IF_013 ::  사업단위 조회
export const getProfiles = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/profiles`);
};

// WSAD_IF_014 ::  홍보 유형 조회
export const getPromoTypes = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promo-types`);
};

// WSAD_IF_015 ::  사업단위 상세 조회 (ID)
export const getProfileDetailById = profileId => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/profile-id/${profileId}`);
};

// WSAD_IF_016 ::  사업단위 상세 조회 (Name)
export const getProfileDetailByName = profileNm => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/profile-nm/${profileNm}`);
};

// WSAD_IF_017 ::  사업단위별 AP 리스트 조회
export const getApListByProfile = profileId => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/ap-list/${profileId}`);
};

// WSAD_IF_020 ::  이번달 홍보 스케줄 현황 조회
export const getScheduleStatus = data => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/check-schedule-status`, {
    params: data,
  });
};

// WSAD_IF_021 ::  홍보 상품 조회
export const getPromoManage = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promo-manage`);
};

// WSAD_IF_022 ::  현재 과금 단가 조회
export const getCurrentUnitPrice = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/current-unit-price`);
};

// WSAD_IF_023 ::  홍보요청별 과금 단과 조회
export const getUnitPriceByPromoReq = requestId => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/req-unit-price/${requestId}`);
};

// WSAD_IF_023 ::  홍보요청별 과금 단과 조회
export const getUnitPriceByPromo = promoId => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/promo-unit-price/${promoId}`);
};

// WSAD_IF_030 :: 아이디 중복 확인
export const validateId = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/validate-id`, data);
};
// WSAD_IF_031 :: 이메일 중복 확인
export const validateEmail = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/validate-email`, data);
};
// WSAD_IF_032 :: 휴대폰 번호 중복 확인
export const validatePhone = data => {
  return REST.post(`${baseURL + DOMAIN_AUTH}/validate-phone`, data);
};

/*
 홍보 관리 -> 요청
 */

// WSAD_IF_201 ::  홍보요청 리스트 조회
export const getPromotionReqList = data => {
  return REST.get(`${baseURL + DOMAIN_PROMO_REQ}/read/requests`, {
    params: data,
  });
};

// WSAD_IF_202 ::  홍보요청 상세 조회
export const getPromotionReqDetail = requestId => {
  return REST.get(`${baseURL + DOMAIN_PROMO_REQ}/read/request/${requestId}`);
};

// WSAD_IF_203 ::  홍보요청 임시저장
export const savePromoRequest = data => {
  return REST.put(
    `${baseURL + DOMAIN_PROMO_REQ}/write/request/save`,
    data,
    formDataConfig,
  );
};

// WSAD_IF_204 ::  홍보요청 승인요청
export const putPromoRequest = data => {
  return REST.put(
    `${baseURL + DOMAIN_PROMO_REQ}/write/request/req`,
    data,
    formDataConfig,
  );
};

// WSAD_IF_205 ::  홍보요청 회수
export const withdrawPromoRequest = requestId => {
  return REST.patch(
    `${baseURL + DOMAIN_PROMO_REQ}/write/request/wth/${requestId}`,
  );
};

// WSAD_IF_206 :: 홍보요청 삭제
export const deletePromoRequest = requestId => {
  return REST.delete(
    `${baseURL + DOMAIN_PROMO_REQ}/write/request/${requestId}`,
  );
};

/*
 홍보 관리 -> 승인
 */

// WSAD_IF_211 :: 승인요청 리스트 조회
export const getPromotionAprvList = data => {
  return REST.get(`${baseURL + DOMAIN_PROMO_MANAGE}/read/requests`, {
    params: data,
  });
};

// WSAD_IF_212 :: 승인요청 상세 조회
export const getPromotionAprvDetail = requestId => {
  return REST.get(`${baseURL + DOMAIN_PROMO_MANAGE}/read/request/${requestId}`);
};

// WSAD_IF_213 :: 홍보요청 승인
export const confirmPromoRequest = data => {
  return REST.put(`${baseURL + DOMAIN_PROMO_MANAGE}/write/confirm`, data);
};

// WSAD_IF_214 :: 홍보요청 반려
export const rejectPromoRequest = requestId => {
  return REST.patch(
    `${baseURL + DOMAIN_PROMO_MANAGE}/write/reject/${requestId}`,
  );
};

/*
 홍보 관리 -> 리스트
 */

//  WSAD_IF_221 :: 홍보 리스트 조회
export const getPromotionList = data => {
  return REST.get(`${baseURL + DOMAIN_PROMO_CON}/read/promotions`, {
    params: data,
  });
};

// WSAD_IF_222 :: 홍보 상세 조회
export const getPromotionDetail = promoId => {
  return REST.get(`${baseURL + DOMAIN_PROMO_CON}/read/promotion/${promoId}`);
};

// WSAD_IF_223 :: 홍보 수정
export const patchPromotion = data => {
  return REST.patch(`${baseURL + DOMAIN_PROMO_CON}/write/promotion`, data);
};

// WSAD_IF_224 :: 홍보 삭제
export const deletePromotion = promoId => {
  return REST.delete(
    `${baseURL + DOMAIN_PROMO_CON}/write/promotion/${promoId}`,
  );
};

// WSAD_IF_225 :: 스케줄 수정
export const patchSchedule = data => {
  return REST.patch(`${baseURL + DOMAIN_PROMO_CON}/write/promo-schedule`, data);
};

// WSAD_IF_226 :: 홍보 노출 ON
export const showOnPromo = promoId => {
  return REST.patch(`${baseURL + DOMAIN_PROMO_CON}/write/promo-on/${promoId}`);
};

// WSAD_IF_227 :: 홍보 노출 OFF
export const showOffPromo = promoId => {
  return REST.patch(`${baseURL + DOMAIN_PROMO_CON}/write/promo-off/${promoId}`);
};

/*
 홍보 관리 -> 스케줄
*/

// WSAD_IF_230 :: 월별 광고 스케줄 조회
export const getMonthlySchedules = data => {
  return REST.post(`${baseURL + DOMAIN_PROMO_SCHEDULE}/read/monthly`, data);
};

/*
 홍보 관리 -> 쿠폰
*/

// WSAD_IF_240 :: 쿠폰 리스트 조회
export const getCoupons = data => {
  return REST.get(`${baseURL + DOMAIN_PROMO_COUPON}/read/coupons`, {
    params: data,
  });
};

// WSAD_IF_244 :: 쿠폰 상세
export const getCoupon = promoId => {
  return REST.get(`${baseURL + DOMAIN_PROMO_COUPON}/read/coupon/${promoId}`);
};

// WSAD_IF_241 :: 쿠폰 등록
export const putCoupon = data => {
  return REST.put(`${baseURL + DOMAIN_PROMO_COUPON}/write/coupon`, data);
};

// WSAD_IF_242 :: 쿠폰 수정
export const patchCoupon = data => {
  return REST.patch(`${baseURL + DOMAIN_PROMO_COUPON}/write/coupon`, data);
};

// WSAD_IF_243 :: 쿠폰 삭제
export const deleteCoupon = couponId => {
  return REST.delete(
    `${baseURL + DOMAIN_PROMO_COUPON}/write/coupon/${couponId}`,
  );
};

/*
 홍보 관리 -> 설문 조사 관리
*/

// WSAD_IF_250 :: 설문 리스트 조회
export const getSurveys = data => {
  return REST.get(`${baseURL + DOMAIN_PROMO_SURVEY}/read/surveys`, {
    params: data,
  });
};

// WSAD_IF_251 :: 설문 상세 조회
export const getSurvey = surveyId => {
  return REST.get(`${baseURL + DOMAIN_PROMO_SURVEY}/read/survey/${surveyId}`);
};

// WSAD_IF_252 :: 설문 등록
export const putSurvey = data => {
  return REST.put(`${baseURL + DOMAIN_PROMO_SURVEY}/write/survey`, data);
};

// WSAD_IF_253 :: 설문 수정
export const patchSurvey = data => {
  return REST.patch(`${baseURL + DOMAIN_PROMO_SURVEY}/write/survey`, data);
};

// WSAD_IF_254 :: 설문 삭제
export const deleteSurvey = surveyId => {
  return REST.delete(
    `${baseURL + DOMAIN_PROMO_SURVEY}/write/survey/${surveyId}`,
  );
};

// WSAD_IF_258 :: 설문 적용 여부 수정
export const toggleSurveyShowYn = surveyId => {
  return REST.patch(
    `${baseURL + DOMAIN_PROMO_SURVEY}/write/show-toggle/${surveyId}`,
  );
};

/*
 홍보 관리 -> 홍보 상황판
*/

// WSAD_IF_101 :: 전체 홍보 리스트 조회
export const getPromotionsListAll = page => {
  return REST.get(
    `${baseURL + DOMAIN_DASHBOARD_PROMO}/read/promotions-all/${page}`,
  );
};

// WSAD_IF_102 :: 사업단위별 홍보 리스트 조회
export const getPromotionsListByProfile = data => {
  return REST.get(`${baseURL + DOMAIN_DASHBOARD_PROMO}/read/promotions`, {
    params: data,
  });
};

// WSAD_IF_103 :: 홍보 상세 조회
export const getPromotionDetailForDashboard = promoId => {
  return REST.get(
    `${baseURL + DOMAIN_DASHBOARD_PROMO}/read/promotion/${promoId}`,
  );
};

// WSAD_IF_104 :: 홍보별 최근 방문객 수 추이 조회
export const getPromoStatistics = promoId => {
  return REST.get(
    `${baseURL + DOMAIN_DASHBOARD_PROMO}/read/promo-statistics/${promoId}`,
  );
};
/*
 데이터랩 > 홍보노출수 통계
*/

// WSAD_IF_301 :: 홍보노출수 데이터 조회
export const getPromoCharts = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_PROMO}/read/charts`, {
    params: data,
  });
};

// WSAD_IF_302 :: 홍보 영역별 노출 현황 리스트 조회
export const getPromoList = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_PROMO}/read/list`, {
    params: data,
  });
};

// WSAD_IF_303 :: 홍보 영역별 노출 현황 엑셀 다운로드
export const getPromoExcel = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_PROMO}/read/excel`, {
    params: data,
    responseType: 'blob',
  });
};

// WSAD_IF_310 :: 홍보 통계 데이터 조회
export const getEsgCharts = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_ESG}/read/charts`, {
    params: data,
  });
};

// WSAD_IF_320 :: 과금 모니터링 데이터 조회
export const getPriceCharts = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_PRICE}/read/charts`, {
    params: data,
  });
};

// WSAD_IF_322 :: 홍보단위별 과금 현황 엑셀 다운로드
export const getPriceExcel = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_PRICE}/read/excel`, {
    params: data,
    responseType: 'blob',
  });
};

// WSAD_IF_330 :: ESG 스케줄링/노출추이 데이터 조회
export const getScheduleCharts = data => {
  return REST.get(`${baseURL + DOMAIN_DATALAB_SCHEDULE}/read/charts`, {
    params: data,
  });
};

/*
 시스템 관리 -> 관리자 계정 관리
*/

// WSAD_IF_801 :: 관리자 계정 리스트 조회
export const getAdmins = data => {
  return REST.get(`${baseURL + DOMAIN_ADM_ACCOUNT}/read/admins`, {
    params: data,
  });
};

// WSAD_IF_802 :: 관리자 추가
export const putAdmin = data => {
  return REST.put(`${baseURL + DOMAIN_ADM_ACCOUNT}/write/admin`, data);
};

// WSAD_IF_803 :: 관리자 수정
export const patchAdmin = data => {
  return REST.patch(`${baseURL + DOMAIN_ADM_ACCOUNT}/write/admin`, data);
};

// WSAD_IF_804 :: 관리자 삭제
export const deleteAdmin = admId => {
  return REST.delete(`${baseURL + DOMAIN_ADM_ACCOUNT}/write/admin/${admId}`);
};

/*
 시스템 관리 -> 관리자 계정 관리
*/

// WSAD_IF_811 :: 소상공인 계정 리스트 조회
export const getAdminsByStore = data => {
  return REST.get(`${baseURL + DOMAIN_ADM_STORE}/read/admins`, {
    params: data,
  });
};

// WSAD_IF_812 :: 소상공인 계정 추가
export const putAdminStore = data => {
  return REST.put(`${baseURL + DOMAIN_ADM_STORE}/write/admin`, data);
};

// WSAD_IF_813 :: 소상공인 계정 수정
export const patchAdminStore = data => {
  return REST.patch(`${baseURL + DOMAIN_ADM_STORE}/write/admin`, data);
};

// WSAD_IF_814 :: 소상공인 계정 삭제
export const deleteAdminStore = admId => {
  return REST.delete(`${baseURL + DOMAIN_ADM_STORE}/write/admin/${admId}`);
};

/*
 시스템 관리 -> 권한 관리
*/

// WSAD_IF_821 :: 권한 조회
export const getPermissions = admType => {
  return REST.get(`${baseURL + DOMAIN_ADM_GRANT}/read/permissions/${admType}`);
};

// WSAD_IF_822 :: 권한 수정
export const patchPermission = data => {
  return REST.patch(`${baseURL + DOMAIN_ADM_GRANT}/write/permission`, data);
};

/*
 시스템 관리 -> 관리자 접속 로그
*/

// WSAD_IF_831 :: 관리자 접속 로그 리스트 조회
export const getAccessLogs = data => {
  return REST.get(`${baseURL + DOMAIN_ADM_ACCESS}/read/logs`, { params: data });
};

// WSAD_IF_832 :: 관리자 액션 로그 리스트 조회
export const getActionLogs = data => {
  return REST.get(`${baseURL + DOMAIN_ADM_ACTION}/read/logs`, { params: data });
};

/*
 시스템 관리 -> 메뉴 관리
*/

// WSAD_IF_901 :: 메뉴 조회
export const getMenus = () => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_MENU}/read/menus`);
};

// WSAD_IF_902 :: 메뉴 사용 여부 수정
export const toggleMenu = data => {
  return REST.patch(`${baseURL + DOMAIN_CONFIG_MENU}/write/menu`, data);
};

/*
 시스템 관리 -> 코드 관리
*/

// WSAD_IF_911 :: 코드 리스트 조회
export const getCodeParents = data => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_CODE}/read/parents`, {
    params: data,
  });
};

// WSAD_IF_912 :: 코드 추가
export const putCodeParent = data => {
  return REST.put(`${baseURL + DOMAIN_CONFIG_CODE}/write/parent`, data);
};

// WSAD_IF_913 :: 코드 수정
export const patchCodeParent = data => {
  return REST.patch(`${baseURL + DOMAIN_CONFIG_CODE}/write/parent`, data);
};

// WSAD_IF_915 :: 상세코드 리스트 조회
export const getCodeChilds = parentId => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_CODE}/read/children/${parentId}`);
};

// WSAD_IF_916 :: 상세코드 추가
export const putCodeChild = data => {
  return REST.put(`${baseURL + DOMAIN_CONFIG_CODE}/write/child`, data);
};

// WSAD_IF_917 :: 상세코드 수정
export const patchCodeChild = data => {
  return REST.patch(`${baseURL + DOMAIN_CONFIG_CODE}/write/child`, data);
};

// WSAD_IF_920 :: AP 리스트 조회
export const getAps = data => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_AP}/read/aps`, {
    params: data,
  });
};

// WSAD_IF_921 :: AP 등록
export const putAp = data => {
  return REST.put(`${baseURL + DOMAIN_CONFIG_AP}/write/ap`, data);
};

// WSAD_IF_922 :: 상세코드 수정
export const patchAp = data => {
  return REST.patch(`${baseURL + DOMAIN_CONFIG_AP}/write/ap`, data);
};

// WSAD_IF_923 :: AP 삭제
export const deleteAp = apmacId => {
  return REST.delete(`${baseURL + DOMAIN_CONFIG_AP}/write/ap/${apmacId}`);
};

// WSAD_IF_930 :: 현재 홍보 설정 비율 조회
export const getChargeRate = data => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_CHARGE}/read/rate`);
};

// WSAD_IF_931 :: 홍보 비율 수정
export const patchChargeRate = data => {
  return REST.patch(`${baseURL + DOMAIN_CONFIG_CHARGE}/write/rate`, data);
};

// WSAD_IF_932 :: 과금설정 리스트 조회
export const getChargeInvoices = data => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_CHARGE}/read/invoices`, {
    params: data,
  });
};

// WSAD_IF_933 :: 과금설정 상세 조회
export const getChargeInvoice = invoiceId => {
  return REST.get(
    `${baseURL + DOMAIN_CONFIG_CHARGE}/read/invoice/${invoiceId}`,
  );
};

// WSAD_IF_934 :: 과금설정 등록
export const putChargeInvoice = data => {
  return REST.put(`${baseURL + DOMAIN_CONFIG_CHARGE}/write/invoice`, data);
};

// WSAD_IF_940 :: 예산 리스트 조회
export const getBudgetList = () => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_CHARGE}/read/budgets`);
};

// WSAD_IF_941 :: 예산 등록
export const putBudget = data => {
  return REST.put(`${baseURL + DOMAIN_CONFIG_CHARGE}/write/budget`, data);
};

// WSAD_IF_942 :: 예산 현황 조회
export const getBudgetState = () => {
  return REST.get(`${baseURL + DOMAIN_CONFIG_CHARGE}/read/budget-state`);
};

/*
 소상공인 관리자
*/

// WSAD_IF_401 :: 홍보 요청 현황 조회
export const getPromotionByStore = () => {
  return REST.get(`${baseURL + DOMAIN_PROMO_STORE}/request`);
};
// WSAD_IF_402 :: 홍보 요청 임시저장
export const savePromoRequestByStore = data => {
  return REST.put(`${baseURL + DOMAIN_PROMO_STORE}/request/save`, data);
};
// WSAD_IF_403 :: 홍보 요청 승인요청
export const putPromoRequestByStore = data => {
  return REST.put(`${baseURL + DOMAIN_PROMO_STORE}/request/req`, data);
};

// WSAD_IF_025 :: 과금 기준일자 조회
export const getBudgetDate = () => {
  return REST.get(`${baseURL + DOMAIN_COMMON}/budget-date`);
};
