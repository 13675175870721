/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const updatedOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    datalabels: {
      display: false,
    },
  },
};
const backgroundColors = ['rgb(0 151 78)', 'rgb(255 133 65)', 'rgb(48 83 153)'];
const labels = ['ESG', '소상공인', '지자체'];

export const data = {
  labels,
  datasets: [
    {
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      backgroundColor: backgroundColors,
      categoryPercentage: 1.0,
      barPercentage: 0.5,
    },
  ],
};

export function MonitorBarChart({ chartData }) {
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        display: 'flex',
      }}
    >
      <Bar
        options={updatedOptions}
        data={chartData || data}
        style={{ flex: 1, width: '100%' }}
      />
    </div>
  );
}
