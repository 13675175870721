/* eslint-disable no-promise-executor-return */
/* eslint-disable import/named */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { images } from '@assets';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Piechart } from '../Promotion/Survey/Piechart';
import { PrmBarChart } from './PrmBarChart';
import { MonitorBarChart } from './MonitorBarChart';
import { PoPLineChart } from './PoPLineChart';
import { EsgScheduleLineChart } from './EsgScheduleLineChart';
import { DataLabPieChart } from './DataLabPieChart';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';
import {
  getProfiles,
  getPromoArea,
  getScheduleCharts,
} from '../../common/crud';
import PdfDownloader from '../../common/PdfDownloader';
import PdfTitleModal from './PdfTitleModal';

export default React.memo(function EsgSchedule(props) {
  const allData = {
    label: '전체',
    value: null,
  };

  const periodTypes = [
    { value: 'MONTHLY', label: '월별' },
    { value: 'QUARTERLY', label: '분기별' },
  ];

  const labels = [
    {
      label: 'ESG',
      color: 'first',
      category: 'ESG',
      rgb: '#00974e',
      rgba: 'rgba(0,151,78,0.5)',
    },
    {
      label: '소상공인',
      color: 'second',
      category: 'STORE',
      rgb: '#ff8541',
      rgba: 'rgba(255,133,65,0.5)',
    },
    {
      label: '지자체',
      color: 'third',
      category: 'LOCAL-GOV',
      rgb: '#305399',
      rgba: 'rgba(48,83,153,0.5)',
    },
  ];

  const defaultPieDataLabels = {
    labels: {
      name: {
        align: 'top',
        padding: 3,
        font: { size: 14, weight: '700' },
        formatter(value, ctx) {
          if (value !== 0) {
            const total = ctx.dataset.data.reduce((sum, item) => sum + item, 0);
            return Utils.calculatePercentage(value, total, 1);
          }
          return '';
        },
      },
      value: {
        align: 'center',
        font: { size: 10, weight: '400' },
        formatter(value, ctx) {
          if (value !== 0) {
            return `${Utils.changeNumberComma(value)}건`;
          }
          return '';
        },
        padding: 4,
      },
    },
  };

  const currentYear = new Date().getFullYear();

  const defaultYear = {
    label: `${currentYear}년`,
    value: currentYear,
  };

  const years = Array.from({ length: currentYear - 1899 }, (_, i) => {
    return { label: `${currentYear - i}년`, value: currentYear - i };
  });

  const history = useHistory();
  const profileId =
    (history.location.state && history.location.state.profileId) || null;
  const year =
    (history.location.state && history.location.state.year) ||
    defaultYear.value;
  const period =
    (history.location.state && history.location.state.period) ||
    periodTypes[0].value;
  const promoAreaId =
    (history.location.state && history.location.state.promoAreaId) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const [searchProfile, setSearchProfile] = useState({});
  const [searchPrmArea, setSearchPrmArea] = useState({});
  const [searchYear, setSearchYear] = useState(defaultYear);
  const [searchPeriod, setSearchPeriod] = useState(periodTypes[0]);
  const [profileList, setProfileList] = useState([]);
  const [prmAreaList, setPrmAreaList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [cpvGraphData, setCpvGraphData] = useState();
  const [cpcGraphData, setCpcGraphData] = useState();
  const [cpvChartData, setCpvChartData] = useState();
  const [cpcChartData, setCpcChartData] = useState();

  const cpvGraphRef = useRef();
  const cpcGraphRef = useRef();
  const cpvChartRef = useRef();
  const cpcChartRef = useRef();

  const pdfRef = useRef();

  const getProfileList = async () => {
    try {
      const { data } = await getProfiles();
      if (data.code === 200) {
        const tempProfileList = data.data.map((v, i) => {
          const tempData = {
            label: v.profileNm,
            value: v.profileId,
          };
          return tempData;
        });
        tempProfileList.unshift(allData);
        setProfileList(tempProfileList);
        if (profileId) {
          setSearchProfile(tempProfileList.find(v => v.value === profileId));
        } else {
          setSearchProfile(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getPromoAreaList = async () => {
    try {
      const { data } = await getPromoArea();
      if (data.code === 200) {
        const tempList = data.data.map((v, i) => {
          const tempData = {
            label: v.codeKo,
            value: v.codeId,
          };
          return tempData;
        });
        tempList.unshift(allData);
        setPrmAreaList(tempList);
        if (promoAreaId) {
          setSearchPrmArea(tempList.find(v => v.value === promoAreaId));
        } else {
          setSearchPrmArea(allData);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const getScheduleChartData = async () => {
    try {
      const params = {
        profileId,
        promoAreaId,
        period,
        year,
      };
      const { data } = await getScheduleCharts(params);
      if (data.code === 200) {
        // eslint-disable-next-line prefer-const
        let { cpvGraph, cpcGraph, cpvChart, cpcChart } = data.data;
        const graphLabels = [];
        const getChartData = list => {
          return {
            labels: list.map(item => {
              return item.label;
            }),
            datasets: [
              {
                data: list.map(item => {
                  return item.value;
                }),
                backgroundColor: list.map(item => {
                  return item.backgroundColor;
                }),
                datalabels: defaultPieDataLabels,
                hoverOffset: 4,
              },
            ],
          };
        };
        const updateCharts = list => {
          return list.map(item => {
            return {
              ...item,
              label: labels.find(v => v.category === item.legend).label,
              backgroundColor: labels.find(v => v.category === item.legend).rgb,
            };
          });
        };

        const getGraphObj = list => {
          const obj = {};
          list.forEach(item => {
            if (!obj[item.legend]) {
              obj[item.legend] = [];
            }
            obj[item.legend].push(item);

            if (!graphLabels.find(v => v === item.category)) {
              graphLabels.push(item.category);
            }
          });
          return obj;
        };

        const getGraphData = obj => {
          return {
            labels: graphLabels,
            datasets: [
              ...Object.keys(obj).map(key => {
                return {
                  data: obj[key].map(v => {
                    return Utils.calculatePercentageInt(v.rateResult, 1, 0);
                  }),
                  isGap: true,
                  gaps: obj[key].map(v => {
                    const gap =
                      Utils.calculatePercentageInt(v.rateResult, 1, 0) -
                      Utils.calculatePercentageInt(v.rateExpect, 1, 0);
                    return gap;
                  }),
                  borderWidth: 1,
                  borderColor: labels.find(lb => lb.category === key).rgb,
                  backgroundColor: labels.find(lb => lb.category === key).rgb,
                };
              }),
              ...Object.keys(obj).map(key => {
                return {
                  data: obj[key].map(v => {
                    return Utils.calculatePercentageInt(v.rateExpect, 1, 0);
                  }),
                  isGap: false,
                  borderWidth: 1,
                  borderColor: labels.find(lb => lb.category === key).rgba,
                  backgroundColor: labels.find(lb => lb.category === key).rgba,
                };
              }),
            ],
          };
        };

        cpvChart = updateCharts(cpvChart);
        cpcChart = updateCharts(cpcChart);

        const tempCpvChart = getChartData(cpvChart);
        const tempCpcChart = getChartData(cpcChart);

        const tempCpvGraphObj = getGraphObj(cpvGraph);
        const tempCpcGraphObj = getGraphObj(cpcGraph);

        const tempCpvGraph = getGraphData(tempCpvGraphObj);
        const tempCpcGraph = getGraphData(tempCpcGraphObj);

        setCpvGraphData(tempCpvGraph);
        setCpcGraphData(tempCpcGraph);
        setCpvChartData(tempCpvChart);
        setCpcChartData(tempCpcChart);
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, true);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  // 페이지 이동
  const movePage = () => {
    const state = {
      pageRefresh: !pageRefresh,
      profileId: searchProfile.value,
      period: searchPeriod.value,
      year: searchYear.value,
      promoAreaId: searchPrmArea.value,
    };

    props.history.push({
      pathname: RouterPath.esgschedule,
      state,
    });
  };

  const setSearchState = () => {
    setSearchYear(years.find(v => v.value === year) || defaultYear);
    setSearchPeriod(
      periodTypes.find(v => v.value === period) || periodTypes[0],
    );
    setSearchProfile(profileList.find(v => v.value === profileId) || allData);
    setSearchPrmArea(prmAreaList.find(v => v.value === promoAreaId) || allData);
  };

  useEffect(() => {
    getProfileList();
    getPromoAreaList();
  }, []);

  useEffect(() => {
    setSearchState();
    getScheduleChartData();
  }, [pageRefresh]);

  const createPdfImage = async pdfTitle => {
    setIsLoading(true);
    const tempPdfImages = [];
    const originalStyle = pdfRef.current.style.cssText;
    pdfRef.current.style.cssText += 'max-width: 1920px; box-shadow : unset;';
    await new Promise(resolve => setTimeout(resolve, 100));

    const chartCanvas = await html2canvas(pdfRef.current);

    pdfRef.current.style.cssText = originalStyle;

    const chartImageUrl = chartCanvas.toDataURL('image/png');
    const img = document.createElement('img');
    img.src = chartImageUrl;

    const searchDiv = document.createElement('div');
    const searchEl = createSearchPdfElement(pdfTitle);
    const searchContent = ReactDOMServer.renderToString(searchEl);
    searchDiv.innerHTML = searchContent;

    const firstDiv = document.createElement('div');
    firstDiv.appendChild(searchDiv);
    firstDiv.appendChild(img);
    firstDiv.style.position = 'fixed'; // Add this line
    firstDiv.style.top = '-10000px'; // Add this line
    firstDiv.style.maxWidth = '1920px';
    firstDiv.style.minWidth = '1280px';

    document.body.appendChild(firstDiv);

    const addPdfCanvas = await html2canvas(firstDiv);
    const addPdfImage = addPdfCanvas.toDataURL('image/png');

    const addPdfObj = {
      canvas: addPdfCanvas,
      image: addPdfImage,
    };

    tempPdfImages.push(addPdfObj);
    return tempPdfImages;
  };
  const [pdfModalShow, setPdfModalShow] = useState(false);

  const createSearchPdfElement = pdfTitle => {
    const profileObj = profileList.find(v => v.value === profileId) || allData;
    const prmAreaObj =
      prmAreaList.find(v => v.value === promoAreaId) || allData;
    const yearObj = years.find(v => v.value === year) || defaultYear;
    const periodObj =
      periodTypes.find(v => v.value === period) || periodTypes[0];
    return (
      <Form className="search-area">
        <h1>{pdfTitle}</h1>
        <Form.Group className="form-group">
          <Form.Label>기간</Form.Label>
          <Form.Label>{`${yearObj.label}  ${periodObj.label}`}</Form.Label>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>사업단위</Form.Label>
          <Form.Label>{profileObj.label}</Form.Label>
        </Form.Group>
        <Form.Group className="form-group">
          <Form.Label>홍보영역</Form.Label>
          <Form.Label>{prmAreaObj.label}</Form.Label>
        </Form.Group>
      </Form>
    );
  };

  return (
    <main id="datalab-esgschedule">
      {isLoading && <Loading />}
      <Header title="ESG 스케줄링/노출추이" />
      <Container className="contents container-128">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>기간</Form.Label>
              <CustomSelect
                options={years}
                value={searchYear}
                onChange={setSearchYear}
                className="mw-200"
                placeholder="선택"
              />
              <CustomSelect
                options={periodTypes}
                value={searchPeriod}
                onChange={setSearchPeriod}
                className="mw-200 ms-3"
                placeholder="선택"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>사업단위</Form.Label>
              <CustomSelect
                options={profileList}
                value={searchProfile}
                onChange={setSearchProfile}
                className="mw-200"
                placeholder="전체"
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>홍보영역</Form.Label>
              <CustomSelect
                options={prmAreaList}
                value={searchPrmArea}
                onChange={setSearchPrmArea}
                className="mw-160"
              />
            </Form.Group>
          </Form>
          <div className="btn-center">
            <Button
              onClick={() => {
                movePage();
              }}
            >
              검색
            </Button>
          </div>
        </article>
        <div className="btn-end">
          <Button
            onClick={() => {
              setPdfModalShow(true);
            }}
          >
            <i className="material-icons me-2">picture_as_pdf</i>
            PDF
          </Button>
        </div>
        <article className="mt-4" ref={pdfRef}>
          <div className="title-labels">
            {labels.map((option, i) => (
              <div className="oplabel-flex" key={`label_${i}`}>
                <span className={`option-boll ${option.color}`} />
                <Form.Label>{option.label}</Form.Label>
              </div>
            ))}
          </div>
          <section className="mt-4">
            <div>
              <h5>[CPV 전체] 스케줄링 대비 노출 비율</h5>
            </div>
            <div className="mt-4">
              {cpvGraphData && (
                <EsgScheduleLineChart
                  chartData={cpvGraphData}
                  ref={cpvGraphRef}
                />
              )}
            </div>
          </section>
          <section className="mt-5">
            <div>
              <h5>[CPC 전체] 스케줄링 대비 노출 비율</h5>
            </div>
            <div className="mt-4">
              {cpcGraphData && (
                <EsgScheduleLineChart
                  chartData={cpcGraphData}
                  ref={cpcGraphRef}
                />
              )}
            </div>
          </section>
          <div className="section-grid mt-5">
            <section>
              <div>
                <h5>CPV 점유율</h5>
              </div>
              <div className="pie-height">
                {cpvChartData && (
                  <DataLabPieChart
                    style={{ flex: 1, width: '100%' }}
                    chartData={cpvChartData}
                    ref={cpvChartRef}
                  />
                )}
              </div>
            </section>
            <section>
              <div>
                <h5>CPC 점유율</h5>
              </div>
              <div className="pie-height">
                {cpcChartData && (
                  <DataLabPieChart
                    style={{ flex: 1, width: '100%' }}
                    chartData={cpcChartData}
                    ref={cpcChartRef}
                  />
                )}
              </div>
            </section>
          </div>
        </article>
      </Container>
      {pdfModalShow && (
        <PdfTitleModal
          show={pdfModalShow}
          handleClose={() => {
            setPdfModalShow(false);
          }}
          onDownload={pdfTitle => {
            createPdfImage(pdfTitle)
              .then(e => {
                return PdfDownloader(e);
              })
              .catch(e => {
                CustomSwal.fire({
                  text: 'PDF 다운로드 중 확인되지 않은 오류입니다. 잠시 후 다시 시도해주세요.',
                  confirmButtonText: '확인',
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
        />
      )}
    </main>
  );
});

function Loading() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000,
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="7x" />
    </div>
  );
}

const businessOptions = [
  { label: '사업단위1', value: '1' },
  { label: '사업단위2', value: '2' },
  { label: '사업단위3', value: '3' },
  { label: '사업단위4', value: '3' },
];
const yearoption = [
  { label: '2023년', value: '1' },
  { label: '2022년', value: '2' },
];
const dayoption = [
  { label: '월별', value: '1' },
  { label: '분기별', value: '2' },
];
const adminTableList = [
  {
    tno: '1',
    prmset: '소상공인',
    prm: '슬라이드 배너',
    title: '홍보 타이틀이 나타납니다.',
    startDate: new Date(),
    endDate: new Date(),
    cpvdan: '100,000',
    cpvno: '111',
    cpcdan: '100,000',
    cpcno: '111',
    cpvmo: '11,100,000',
    cpcmo: '25,000',
    total: '11,100,000',
  },
];
const promotionOptions = [
  { label: '슬라이드 배너', value: '1' },
  { label: '바 배너', value: '2' },
  { label: '박스 배너', value: '3' },
  { label: '통 배너', value: '3' },
];
