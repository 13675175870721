import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { CustomModal, CustomSwal } from '@components';
import Moment from 'react-moment';
import { RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  deleteAdminStore,
  patchAdminStore,
  putAdminStore,
} from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function PdfTitleModal({
  show,
  handleClose,
  onDownload,
}) {
  const [title, setTitle] = useState('');
  return (
    <CustomModal
      title="리포트 제목"
      id="sysModal"
      show={show}
      noCloseBtn
      onHide={() => handleClose()}
    >
      <Modal.Body>
        <Form className="sys-area" as="div">
          <Form.Group className="form-group">
            <InputGroup>
              <Form.Control
                placeholder="리포트 제목을 입력 하세요. 최대 30자"
                className="mw-280"
                value={title}
                maxLength={30}
                onChange={e => {
                  setTitle(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
        </Form>
        <div className="btn-area justify-btn-side">
          <Button variant="outline-primary" onClick={handleClose}>
            닫기
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              handleClose();
              onDownload(title);
            }}
          >
            다운
          </Button>
        </div>
      </Modal.Body>
    </CustomModal>
  );
});
