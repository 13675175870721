/* eslint-disable react/no-array-index-key */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Container, Form, Ratio, Badge } from 'react-bootstrap';
import { CustomModal, CustomSelect, CustomDatePicker } from '@components';
import { Options, RouterPath, Utils } from '@common';
import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { images } from '@assets';
import SwiperCore, { Thumbs, FreeMode } from 'swiper';
// swiper
import 'swiper/css';
import 'swiper/css/thumbs';

SwiperCore.use([Thumbs, FreeMode]);

function MainimagesShowForSmBusiness({ codeArea, banner, ...rest }) {
  const [style, setStyle] = useState({});
  const [isFull, setIsFull] = useState(false);

  useEffect(() => {
    if (banner) {
      const tempData = {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: `url(${banner.preview})`,
      };
      setIsFull(String(codeArea).includes('FULL'));
      setStyle(tempData);
    }
  }, [banner, codeArea]);
  return (
    <section className="main-section" style={isFull ? { ...style } : {}}>
      <div
        className="main-box"
        style={
          isFull
            ? { opacity: 0 }
            : String(codeArea).includes('SLIDE')
            ? style
            : { border: 0, backgroundColor: '#aaa' }
        }
      />
      <div
        className="sec-mainbox"
        style={
          isFull
            ? { opacity: 0 }
            : String(codeArea).includes('BAR')
            ? style
            : { border: 0, backgroundColor: '#aaa' }
        }
      />
      <div className="grid-box" style={isFull ? { opacity: 0 } : {}}>
        <div
          className="ano-box"
          style={
            String(codeArea).includes('BOX')
              ? style
              : { border: 0, backgroundColor: '#aaa' }
          }
        />
        <div className="ano-box" />
        <div className="ano-box" />
      </div>
    </section>
  );
}

export default MainimagesShowForSmBusiness;
