import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Utils } from '@common';

ChartJS.register(ArcElement, Tooltip, Legend);
export const updatedOptions = {
  // responsive: true,
  // maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
    },
    datalabels: {
      display: false,
    },
  },
};

export const dataObj = {
  datasets: [
    {
      data: [12, 19, 3, 5, 2],
      backgroundColor: [
        'rgb(36 66 122)',
        'rgb(249 155 46)',
        'rgb(88 144 199)',
        'rgb(98 74 239)',
        'rgb(188 187 220)',
      ],
      borderWidth: 1,
      // categoryPercentage: 1.0,
      // barPercentage: 0.5,
    },
  ],
};

export function TopDataPieChart({ data = dataObj }) {
  return (
    <div id="piechart">
      {/* <div style={{ height: '234px;', position: 'relative' }}> */}
      <div style={{ position: 'relative' }}>
        <Doughnut data={data} options={updatedOptions} />
        <div className="doughnut-text">
          <p>총 접속자 수</p>
          <h6>{data.sum && Utils.numberComma(data.sum)}</h6>
        </div>
      </div>
    </div>
  );
}
