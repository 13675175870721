/* eslint-disable consistent-return */
import React, { useState } from 'react';
import {
  Container,
  Button,
  FormGroup,
  FormLabel,
  Image,
} from 'react-bootstrap';
import { FormikInput } from '@components';
import { RouterPath, Utils } from '@common';
import { images } from '@assets';
import { FormikProvider, Form, useFormik } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { findId } from './Rest';
import CustomSwal from '../../components/CustomSwal';

export default React.memo(function FindId(props) {
  const [isSucceed, setIsSucceed] = useState(false);
  const [loginId, setLoginId] = useState('');

  // Formik
  const FindIdFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('이메일을 입력하세요.')
        .required('이메일을 입력하지 않았습니다. 입력하세요.'),
    }),
    onSubmit: async values => {
      const success = await onSubmitFindId(values);
      if (success) {
        setIsSucceed(true);
      } else {
        CustomSwal.fire({
          html: '입력하신 정보와 일치하는 회원이 없습니다.<br />해당 정보를 다시 확인하시거나 회원가입을 해주세요.',
          confirmButtonText: '확인',
        });
      }
    },
  });

  const onSubmitFindId = async values => {
    const { email } = values;
    const params = {
      email: Utils.encrypt(email),
    };
    const { data } = await findId(params);
    if (data.code === 200) {
      setLoginId(Utils.decrypt(data.data.loginId));
      return true;
    }
    return false;
  };

  const { values, errors, handleReset, dirty, isValid } = FindIdFormik;

  return (
    <main id="find-id" className="auth">
      <Container fluid="sm">
        <Image
          src={images.logoDark}
          alt="logo"
          className="logo"
          onClick={() => props.history.push(RouterPath.signin)}
        />
        <div className="form-box">
          <div className="title-area">
            <h3>아이디 찾기</h3>
          </div>
          {!isSucceed ? (
            <FormikProvider value={FindIdFormik}>
              <Form className="column">
                <FormGroup className="form-group mt-4">
                  <FormLabel>이메일</FormLabel>
                  <FormikInput
                    type="email"
                    id="email"
                    name="email"
                    placeholder="이메일 주소를 입력하세요."
                  />
                </FormGroup>
                <div className="btn-area mt-5 mb-0 column">
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    className="w-100"
                    disabled={!(dirty && isValid)}
                  >
                    아이디 찾기
                  </Button>
                  <Button
                    variant="ts"
                    size="lg"
                    className="mt-2 w-100"
                    onClick={() => props.history.push(RouterPath.findpwd)}
                  >
                    비밀번호 찾기
                  </Button>
                </div>
              </Form>
            </FormikProvider>
          ) : (
            <div className="complete-wrap">
              <p>
                입력하신 이메일과 일치하는 아이디는
                <br />
                <strong>{loginId}</strong>입니다.
              </p>
              <div className="btn-area mt-5 mb-0 column">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  className="w-100"
                  onClick={() => props.history.push(RouterPath.signin)}
                >
                  로그인
                </Button>
                <Button
                  variant="ts"
                  size="lg"
                  className="w-100"
                  onClick={() => props.history.push(RouterPath.findpwd)}
                >
                  비밀번호 찾기
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </main>
  );
});
