/* eslint-disable react/function-component-definition */
import React from 'react';
import { Marker, useNavermaps } from 'react-naver-maps';

const UnitArrowMarker = ({ data, content, onClick, clickable }) => {
  const navermaps = useNavermaps();
  return (
    <Marker
      position={new navermaps.LatLng(data.latitude, data.longitude)}
      icon={{
        content,
        origin: new navermaps.Point(0, 0),
        // anchor: new navermaps.Point(120, 50),
        anchor: new navermaps.Point(-25, 50),
      }}
      onClick={() => onClick(data)}
      clickable={clickable}
    />
  );
};

export default UnitArrowMarker;
