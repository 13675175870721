/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from 'react-table';
import { CustomPagination } from '@components';

function CustomTable({
  columns,
  data,
  name,
  size,
  pageData,
  pageNum,
  setPage,
  pageCount,
  typeHover,
  onClickEvent,
  ...rest
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        height: 100,
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const history = useHistory();
  return (
    <>
      <table
        {...getTableProps()}
        className={`table ${typeHover && 'table-hover'} ${size || ''} ${
          rest.className || ''
        }`}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    width: column.width,
                    cursor: column.clickEvent ? 'pointer' : 'default',
                  }}
                  onClick={() => {
                    if (column.clickEvent) column.clickEvent();
                  }}
                >
                  <div
                    style={{
                      textAlign: column.align ? column.align : 'center',
                    }}
                  >
                    {column.render('Header')}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {pageData ? (
            page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={row.original.disabled ? 'disabled' : ''}
                    onClick={e =>
                      !row.original.href
                        ? !onClickEvent
                          ? null
                          : // : row.original.modal(row)
                            onClickEvent(row)
                        : !history.push({ pathname: row.original.href })
                    }
                  >
                    {row.cells.map(cell => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          onClick={() => {
                            cell.column.onClick &&
                              cell.column.onClick(row.original);
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={headerGroups[0].headers.length}
                  className="no-data"
                >
                  {name}가(이) 없습니다.
                </td>
              </tr>
            )
          ) : rows.length > 0 ? (
            rows.map(row => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className={row.original.disabled ? 'disabled' : ''}
                  onClick={e => (!onClickEvent ? null : onClickEvent(row))}
                >
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={headerGroups[0].headers.length} className="no-data">
                {name}이(가) 없습니다.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pageData && (
        <CustomPagination
          pageCount={pageCount}
          pageNum={pageNum}
          setPage={setPage}
        />
      )}
    </>
  );
}

export default React.memo(CustomTable);
