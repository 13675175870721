/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Container,
  Form,
  Button,
  InputGroup,
  Table,
  Modal,
} from 'react-bootstrap';
import {
  Header,
  CustomSelect,
  CustomDatePicker,
  CustomModal,
  CustomPagination,
  CustomSwal,
} from '@components';
import Moment from 'react-moment';
import { Options, RouterPath, Utils } from '@common';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { getAccessLogs } from '../../common/crud';
import CustomException from '../../common/hooks/CustomException';
import RESULT_CODE from '../../common/ResultCode';

export default React.memo(function AdmAccesLog(props) {
  const history = useHistory();
  const page = (history.location.state && history.location.state.page) || 1;
  const searchType =
    (history.location.state && history.location.state.searchType) || null;
  const keyword =
    (history.location.state && history.location.state.keyword) || '';
  const startDate =
    (history.location.state && history.location.state.startDate) || null;
  const endDate =
    (history.location.state && history.location.state.endDate) || null;
  const pageRefresh =
    (history.location.state && history.location.state.pageRefresh) || false;

  const selectData = {
    label: '선택',
    value: null,
  };

  const searchTypeOptions = [
    {
      value: 'ADM_LOGIN_ID',
      label: '아이디',
    },
    {
      value: 'ACCESS_IP',
      label: '접속 IP',
    },
    {
      value: 'ACCESS_INFO',
      label: '접속 정보',
    },
  ];

  const size = 10;

  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchStartDate, setSearchStartDate] = useState();
  const [searchEndDate, setSearchEndDate] = useState();
  const [selectSearchType, setSelectSearchType] = useState(selectData);

  const [accessLogList, setAccessLogList] = useState([]);
  const [totalPage, setTotalPage] = useState();

  const getAccessLogList = async () => {
    try {
      const params = {
        page,
        size,
        searchType,
        startDate: startDate
          ? format(startDate, 'yyyy-MM-dd').toString()
          : startDate,
        endDate: endDate ? format(endDate, 'yyyy-MM-dd').toString() : endDate,
        keyword,
      };
      const { data } = await getAccessLogs(params);
      if (data.code === 200) {
        if (data.data.list.length === 0 && page > 1) {
          movePage(1);
        } else {
          setTotalPage(data.data.totalPages);
          setAccessLogList(data.data.list);
        }
      } else {
        throw new CustomException(RESULT_CODE[`CODE_${data.code}`]);
      }
    } catch (error) {
      Utils.handleError(error, customSwalGoError, false);
    }
  };

  const customSwalGoError = (text, isGo) => {
    CustomSwal.fire({
      text,
      confirmButtonText: '확인',
      finally: () => {
        if (isGo) {
          props.history.push(RouterPath.Error);
        }
      },
    });
  };

  const setSearchState = () => {
    setSearchStartDate(startDate || null);
    setSearchEndDate(endDate || null);
    setSelectSearchType(
      searchTypeOptions.find(v => v.value === searchType) || selectData,
    );
    setSearchKeyword(keyword || '');
  };

  // 페이지 이동
  const movePage = (mvPage, isSearch) => {
    const newPage = mvPage || 1;
    const state = {
      page: newPage,
      keyword: isSearch ? searchKeyword : keyword,
      pageRefresh: !pageRefresh,
      searchType: isSearch ? selectSearchType.value : searchType,
      startDate: isSearch ? searchStartDate : startDate,
      endDate: isSearch ? searchEndDate : endDate,
    };

    props.history.push({
      pathname: RouterPath.admacceslog,
      state,
    });
  };

  useEffect(() => {
    setSearchState();
    getAccessLogList();
  }, [pageRefresh]);

  return (
    <main id="prm-request-list">
      <Header title="관리자 접속 로그" />
      <Container className="contents">
        <article>
          <Form className="search-area">
            <Form.Group className="form-group">
              <Form.Label>생성일</Form.Label>
              <div className="datepicker-wrap mw-280">
                <CustomDatePicker
                  value={searchStartDate}
                  // minDate={Utils.getTomorrow()}
                  maxDate={searchEndDate}
                  onChange={e => {
                    setSearchStartDate(e);
                  }}
                  placeholderText="yyyy.mm.dd"
                />
                <span>-</span>
                <CustomDatePicker
                  value={searchEndDate}
                  minDate={searchStartDate}
                  onChange={setSearchEndDate}
                  placeholderText="yyyy.mm.dd"
                />
              </div>
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>검색어</Form.Label>
              <InputGroup>
                <CustomSelect
                  options={[selectData, ...searchTypeOptions]}
                  value={selectSearchType}
                  onChange={setSelectSearchType}
                  className="mw-160"
                  placeholder="선택"
                />
                <Form.Control
                  placeholder="검색"
                  className="mw-280"
                  value={searchKeyword}
                  onKeyUp={e => {
                    if (e.key === 'Enter') {
                      if (searchKeyword && !selectSearchType.value) {
                        CustomSwal.fire({
                          text: '검색어 타입을 선택해주세요.',
                          allowEnterKey: false,
                          confirmButtonText: '확인',
                        });
                      } else {
                        movePage(1, true, e);
                      }
                    }
                  }}
                  onChange={e => setSearchKeyword(e.target.value)}
                />
                <Button
                  onClick={() => {
                    movePage(1, true);
                  }}
                >
                  검색
                </Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </article>
        <article className="mt-4">
          <Table className="table-hover text-start">
            <colgroup>
              <col width={120} />
              <col width={100} />
              <col width={100} />
              <col width={120} />
            </colgroup>
            <thead>
              <tr>
                <th>아이디</th>
                <th>접속정보</th>
                <th>접속IP</th>
                <th>생성일시</th>
              </tr>
            </thead>

            <tbody>
              {accessLogList.length > 0 ? (
                accessLogList.map((v, i) => {
                  return (
                    <tr>
                      <td>{Utils.decrypt(v.admNm)}</td>
                      <td>{v.browserType}</td>
                      <td>{v.accessIp}</td>
                      <td>
                        {v.createDate ? (
                          <Moment
                            date={v.createDate}
                            format="YYYY.MM.DD"
                            interval={0}
                          />
                        ) : (
                          '-'
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="no-data" colSpan={4}>
                    접속 로그 내역이 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <CustomPagination
            pageCount={totalPage}
            pageNum={page}
            setPage={e => {
              movePage(e);
            }}
          />
        </article>
      </Container>
    </main>
  );
});
const acclogTableList = [
  {
    sysId: 'avcd',
    acclog: 'os타/브라우저타입',
    accip: '123.123.123',
    startDate: new Date(),
  },
  {
    sysId: 'avcd',
    acclog: 'os타/브라우저타입',
    accip: '123.123.123',
    startDate: new Date(),
  },
  {
    sysId: 'avcd',
    acclog: 'os타/브라우저타입',
    accip: '123.123.123',
    startDate: new Date(),
  },
];

const serchOptions = [
  { label: '선택', value: 'all' },
  { label: '아이디', value: 'id' },
  { label: '접속 IP', value: 'IP' },
  { label: '접속정보', value: 'acc' },
];
