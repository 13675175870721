import React from 'react';
import { Field } from 'formik';

function FormikCheck({ type, ...props }) {
  return (
    <div className="form-check">
      <Field type={type} {...props} className="form-check-input" />
      <label htmlFor={props.id} className="form-check-label">
        {props.label}
      </label>
    </div>
  );
}

export default FormikCheck;
